import { Component, OnInit } from '@angular/core';
import { AppService } from '../../app.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../../auth/auth.service';
import { CustomerService } from '../../dashboard/customer.service';
import { AlertService } from '../../alert/alert.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable'
import { applyPlugin } from 'jspdf-autotable'
applyPlugin(jsPDF);
declare var Chart: any;
const doc = new jsPDF();

declare var $: any;


@Component({
  selector: 'app-q-invoice',
  templateUrl: './q-invoice.component.html',
  styleUrls: ['./q-invoice.component.css']
})
export class QInvoiceComponent implements OnInit {
  active = 1;

  spinner: boolean = false;
  spinnerSave: boolean = false;

  noInvoice: string = 'INV' + (new Date().getTime() + '').substr(3, 7);
  createdAt: string = (new Date()).toLocaleDateString('en-US');
  dueAt: string = (new Date()).toLocaleDateString('en-US');

  companyLogo: string="https://res.cloudinary.com/quisoft/image/upload/v1614723615/Quisoft/logo-qinvc_lztmnb.jpg";
  companyName: string = "Quisoft, Inc.";
  companyAddress: string = "12345 Sunny Road";
  companyState: string = "Miami, FL 12345";

  billedTo1: string = "Acme Corp.";
  billedTo2: string = "John Doe";
  billedTo3: string = "john@example.com";

  paymentMethod: string = "Check";
  paymentMethodNo: string = "12345";

  otherFees: number = 0;
  subTotal: number = 0;
  tax: number = 0;
  taxPercent: number = 7;
  total: number = 0;

  invoiceItems: Array<Object> = [];

  search: string = '';

  pagination: any = {
    page: 1,
    pages: [],
    rows: 10,
    entries: 0,
    filter: -1
  }

  range = new FormGroup({
    start: new FormControl(new Date((new Date().getMonth() + 1).toString() + '/01/' + new Date().getFullYear().toString())),
    end: new FormControl(new Date())
  });

  invoiceId: -1;

  constructor(public appService: AppService, public authService: AuthService, public alertService: AlertService, public customerService: CustomerService) { }

  ngOnInit(): void {
  
    if(this.authService.decodedTocken.actort!='Basic'){
        this.companyLogo=this.authService.decodedTocken.primarysid;
    }

    console.log(this.authService.decodedTocken);
    this.addItem();

    this.pagination.rows = 10;
    this.pagination.page = 1;
    this.pagination.pages = [1];


    setTimeout(() => {

    }, 4000);

    this.getInvoices();
  }


  getInvoices(): void {
    this.spinner = true;
    let values = this.range.value;
    
    this.customerService.getAllQInvoices(this.search, new Date(this.appService.fixInitDate(values.start)), new Date(this.appService.fixEndDate(values.end)), this.pagination.page, this.pagination.rows, this.pagination.filter, parseInt(this.authService.decodedTocken.nameid)).subscribe(r => {

      if (r.invoices) {
        this.customerService.qInvoices = r.invoices;
        this.pagination.entries = r.entries;
        let a = Math.ceil(r.entries / this.pagination.rows);
        this.pagination.pages = [];
        for (var i = 1; i <= a; i++) {
          this.pagination.pages.push(i);
        }

      }
      this.spinner = false;

    }, err => {
      this.spinner = false;
      console.log(err);
    });
  }


  addItem(): void {

    var descrp = "Service or product description";
    if (this.appService.defaultLanguage.getValue() == 'es') {
      descrp = "Descripción del producto o servicio";
    }
    this.invoiceItems.push({
      description: descrp,
      count: 1,
      unitPrice: 0
    });
    this.changeValues();
  }

  clearTemplate(): void {
    this.noInvoice = 'INV' + (new Date().getTime() + '').substr(3, 7);;
    this.createdAt = (new Date()).toLocaleDateString('en-US').toString();
    this.dueAt = (new Date()).toLocaleDateString('en-US').toString();

    this.companyName = "Quisoft, Inc.";
    this.companyAddress = "12345 Sunny Road";
    this.companyState = "Miami, FL 12345";

    this.billedTo1 = "Acme Corp.";
    this.billedTo2 = "John Doe";
    this.billedTo3 = "john@example.com";

    this.paymentMethod = "Check";
    this.paymentMethodNo = "12345";

    this.otherFees = 0;
    this.subTotal = 0;
    this.tax = 0;
    this.taxPercent = 7;
    this.total = 0;

    this.invoiceItems = [];

    this.addItem();
  }

  downloadInvoice(): void {
    if (this.authService.decodedTocken.actort != "None") {
      if (this.appService.defaultLanguage.getValue() == 'es') {
        this.downloadESInvoice();
      } else {
        this.downloadENInvoice();
      }
    }
  }


  downloadENInvoice(): void {
    try {

      if (this.noInvoice != undefined) {

        let count = 0;
        let doc = new jsPDF('p', 'pt', 'letter'), rows = [], images = [], pos = 0;

        let columns = [{ title: 'Payment Method                                                   ', dataKey: 'value1' }, { title: 'Reference #', dataKey: 'value2' }];
        rows.push(
          { 'value1': this.paymentMethod, 'value2': this.paymentMethodNo },
        );



        /********************************************* */
        const img: any= document.getElementById('companyLogo');
        img.crossOrigin = 'Anonymous';
        var canvas:any = document.getElementById("canvas");
        canvas.width = 300;
        canvas.height = 80;
        var ctx = canvas.getContext("2d");
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        
        ctx.drawImage(img, 0, 0);
        console.log(ctx);
        var base64 = canvas.toDataURL();      
        doc.addImage(base64, this.appService.logoQInvoice.x, this.appService.logoQInvoice.y, this.appService.logoQInvoice.w, this.appService.logoQInvoice.h);
    
        //doc.addImage(this.appService.logoQInvoice.base64, this.appService.logoQInvoice.x, this.appService.logoQInvoice.y, this.appService.logoQInvoice.w, this.appService.logoQInvoice.h);
        
        doc.setFontSize(18); doc.setTextColor(255, 255, 255);

        doc.setTextColor(0, 0, 0); doc.setFontSize(12);
        doc.text(this.companyName, 25, 123);
        doc.text(this.companyAddress, 25, 141);
        doc.text(this.companyState, 25, 159);


        doc.text('Invoice #: ' + this.noInvoice, 585, 30, { align: 'right' });
        doc.text('Created: ' + new Date(this.createdAt).toLocaleDateString('en-US'), 585, 48, { align: 'right' });
        doc.text('Due: ' + new Date(this.dueAt).toLocaleDateString('en-US'), 585, 66, { align: 'right' });

        doc.text('Billed To', 585, 123, { align: 'right' });
        doc.text(this.billedTo1, 585, 141, { align: 'right' });
        doc.text(this.billedTo2, 585, 159, { align: 'right' });
        doc.text(this.billedTo3, 585, 177, { align: 'right' });

        let d = new Date();
        //Table 1
        autoTable(doc, {
          columns: columns,
          body: rows,
          startY: 220,
          margin: [90, 20, 70, 20],
          bodyStyles: {
            lineWidth: 0.5,
            cellPadding: 10,
            textColor: 20,
          },
          styles: {
            overflow: 'linebreak'
          },

          headStyles: {
            fillColor: "#eeeeee", textColor: "#555555", fontSize: 12,
            halign: 'right'
          },
          columnStyles: {
            value1: { cellWidth: 280, fontSize: 12, lineWidth: 0, halign: 'left', fillColor: [255, 255, 255] },
            value2: { cellWidth: 290, fontSize: 12, lineWidth: 0, halign: 'right', fillColor: [255, 255, 255] },

          },

        });
        let rows2 = [];
        let columns2 = [{ title: 'Item                                                                        ', dataKey: 'item' }, { title: 'Qty   ', dataKey: 'count' }, { title: 'Price/Unit', dataKey: 'price' }];
        this.invoiceItems.map(v => {
          console.log(v);

          let value: any = v;
          rows2.push(
            { 'item': value.description, 'count': value.count, 'price': '$' + parseFloat(value.unitPrice).toFixed(2) },
          );
        });

        rows2.push(
          { 'item': ' ', 'count': ' ', 'price': ' ' },
          { 'item': ' ', 'count': ' ', 'price': ' ' },
          { 'item': 'Other Fees', 'count': ' ', 'price': '$' + parseFloat(this.otherFees.toString()).toFixed(2) },
          { 'item': 'Sub Total', 'count': ' ', 'price': '$' + parseFloat(this.subTotal.toString()).toFixed(2) },
          { 'item': 'Tax (' + this.taxPercent + '%)', 'count': ' ', 'price': '$' + parseFloat(this.tax.toString()).toFixed(2) },
          { 'item': ' ', 'count': ' ', 'price': 'Total: $' + parseFloat(this.total.toString()).toFixed(2), fontStyle: 'bold' },
        );
        //Table 2
        let finalY = (doc as any).lastAutoTable.finalY;
        autoTable(doc, {
          columns: columns2,
          body: rows2,
          startY: finalY,
          margin: [90, 20, 70, 20],
          bodyStyles: {
            lineWidth: 0.5,
            cellPadding: 10,
            textColor: 20,
          },
          styles: {
            overflow: 'linebreak'
          },

          headStyles: {
            fillColor: "#eeeeee", textColor: "#555555", fontSize: 12,
            halign: 'right'
          },
          columnStyles: {
            item: { cellWidth: 280, fontSize: 12, lineWidth: 0.3, halign: 'left', fillColor: [255, 255, 255] },
            count: { cellWidth: 50, fontSize: 12, lineWidth: 0.3, halign: 'center', fillColor: [255, 255, 255] },
            price: { cellWidth: 240, fontSize: 12, lineWidth: 0.3, halign: 'right', fillColor: [255, 255, 255] },


          },

        });


        doc.save('Invoice(' + this.noInvoice + ').pdf');
      } else {

      }
    } catch (err) {

    }
  }

  downloadESInvoice(): void {
    try {

      if (this.noInvoice != undefined) {

        let count = 0;
        let doc = new jsPDF('p', 'pt', 'letter'), rows = [], images = [], pos = 0;

        let columns = [{ title: 'Forma de Pago                                                     ', dataKey: 'value1' }, { title: 'Referencia #', dataKey: 'value2' }];
        rows.push(
          { 'value1': this.paymentMethod, 'value2': this.paymentMethodNo },
        );



        /********************************************* */

        doc.addImage(this.appService.logoQInvoice.base64, this.appService.logoQInvoice.x, this.appService.logoQInvoice.y, this.appService.logoQInvoice.w, this.appService.logoQInvoice.h);

        doc.setFontSize(18); doc.setTextColor(255, 255, 255);

        doc.setTextColor(0, 0, 0); doc.setFontSize(12);
        doc.text(this.companyName, 25, 123);
        doc.text(this.companyAddress, 25, 141);
        doc.text(this.companyState, 25, 159);


        doc.text('Factura #: ' + this.noInvoice, 585, 30, { align: 'right' });
        doc.text('Creado el: ' + new Date(this.createdAt).toLocaleDateString('en-US'), 585, 48, { align: 'right' });
        doc.text('Pagar antes de: ' + new Date(this.dueAt).toLocaleDateString('en-US'), 585, 66, { align: 'right' });

        doc.text('Facturado a', 585, 123, { align: 'right' });
        doc.text(this.billedTo1, 585, 141, { align: 'right' });
        doc.text(this.billedTo2, 585, 159, { align: 'right' });
        doc.text(this.billedTo3, 585, 177, { align: 'right' });

        let d = new Date();
        //Table 1
        autoTable(doc, {
          columns: columns,
          body: rows,
          startY: 220,
          margin: [90, 20, 70, 20],
          bodyStyles: {
            lineWidth: 0.5,
            cellPadding: 10,
            textColor: 20,
          },
          styles: {
            overflow: 'linebreak'
          },

          headStyles: {
            fillColor: "#eeeeee", textColor: "#555555", fontSize: 12,
            halign: 'right'
          },
          columnStyles: {
            value1: { cellWidth: 280, fontSize: 12, lineWidth: 0, halign: 'left', fillColor: [255, 255, 255] },
            value2: { cellWidth: 290, fontSize: 12, lineWidth: 0, halign: 'right', fillColor: [255, 255, 255] },

          },

        });
        let rows2 = [];
        let columns2 = [{ title: 'Articulo o Servicio                                               ', dataKey: 'item' }, { title: 'Cant   ', dataKey: 'count' }, { title: 'Precio/Unidad', dataKey: 'price' }];
        this.invoiceItems.map(v => {
          console.log(v);

          let value: any = v;
          rows2.push(
            { 'item': value.description, 'count': value.count, 'price': '$' + parseFloat(value.unitPrice).toFixed(2) },
          );
        });

        rows2.push(
          { 'item': ' ', 'count': ' ', 'price': ' ' },
          { 'item': ' ', 'count': ' ', 'price': ' ' },
          { 'item': 'Otros Cargos', 'count': ' ', 'price': '$' + parseFloat(this.otherFees.toString()).toFixed(2) },
          { 'item': 'Sub Total', 'count': ' ', 'price': '$' + parseFloat(this.subTotal.toString()).toFixed(2) },
          { 'item': 'Impuestos (' + this.taxPercent + '%)', 'count': ' ', 'price': '$' + parseFloat(this.tax.toString()).toFixed(2) },
          { 'item': ' ', 'count': ' ', 'price': 'Total: $' + parseFloat(this.total.toString()).toFixed(2), fontStyle: 'bold' },
        );
        //Table 2
        let finalY = (doc as any).lastAutoTable.finalY;
        autoTable(doc, {
          columns: columns2,
          body: rows2,
          startY: finalY,
          margin: [90, 20, 70, 20],
          bodyStyles: {
            lineWidth: 0.5,
            cellPadding: 10,
            textColor: 20,
          },
          styles: {
            overflow: 'linebreak'
          },

          headStyles: {
            fillColor: "#eeeeee", textColor: "#555555", fontSize: 12,
            halign: 'right'
          },
          columnStyles: {
            item: { cellWidth: 280, fontSize: 12, lineWidth: 0.3, halign: 'left', fillColor: [255, 255, 255] },
            count: { cellWidth: 50, fontSize: 12, lineWidth: 0.3, halign: 'center', fillColor: [255, 255, 255] },
            price: { cellWidth: 240, fontSize: 12, lineWidth: 0.3, halign: 'right', fillColor: [255, 255, 255] },

          },

        });


        doc.save('Factura(' + this.noInvoice + ').pdf');
      } else {

      }
    } catch (err) {

    }
  }

  removeItem(item): void {
    this.invoiceItems = this.invoiceItems.filter(v => v != item);
    this.changeValues();
  }

  changeValues(): void {
    let subT = 0;
    this.invoiceItems.map(v => {
      let value: any = v;
      subT += value.count * value.unitPrice;
    });
    this.subTotal = subT + this.otherFees;
    this.tax = ((this.subTotal * this.taxPercent) / 100);
    this.total = this.subTotal + this.tax;

  }


  saveInvoice(): void {
    if (this.authService.decodedTocken.actort != "Basic") {
      this.spinnerSave = true;

      this.customerService.saveQInvoice(parseInt(this.authService.decodedTocken.nameid), this.noInvoice, this.dueAt, 0, this.paymentMethod, this.paymentMethodNo, this.otherFees, "N/A", this.subTotal, this.tax, this.taxPercent,
        this.total, this.companyName, this.companyAddress, this.companyState, this.billedTo1, this.billedTo2, this.billedTo3, this.invoiceItems).subscribe(r => {

          if (r.statusCode == 201) {

            this.alertService.showAlert("fe fe-bell", "Factura guardada con éxito", "Invoice saved successfully.", "success");
            this.clearTemplate();
            this.getInvoices();
          } else {
            this.alertService.showAlert("fe fe-alert-octagon", "No se pudo guardar la factura", "Could not save invoice.", "danger");

          }
          this.spinnerSave = false;
        });
    }
  }


  paginate(p): void {
    this.pagination.page = p;
    this.getInvoices();
  }

  previousPage(): void {
    if (this.pagination.page - 1 >= 1) {
      this.paginate(this.pagination.page - 1);
    }

  }

  nextPage(): void {

    if (this.pagination.page + 1 <= this.pagination.pages.length) {
      this.paginate(this.pagination.page + 1);
    }
  }

  changeDateRange(): void {
    this.pagination.filter = -1;
    this.pagination.page = 1;
    this.getInvoices();
  }

  searchInvoice(e): void {
    if (e.code === 'Enter') {
      this.pagination.filter = -1;
      this.getInvoices();
    }
  }


  printInvoice(invoice: any): void {
    if (this.appService.defaultLanguage.getValue() == 'es') {
      this.printESInvoice(invoice);
    } else {
      this.printENInvoice(invoice);
    }
  }

  printENInvoice(invoice: any): void {
    try {

      if (invoice != undefined) {

        let count = 0;
        let doc = new jsPDF('p', 'pt', 'letter'), rows = [], images = [], pos = 0;

        let columns = [{ title: 'Payment Method                                                   ', dataKey: 'value1' }, { title: 'Reference #', dataKey: 'value2' }];
        rows.push(
          { 'value1': invoice.paymentMode, 'value2': invoice.paymentReference },
        );



        /********************************************* */
       
          doc.addImage(this.appService.logoQInvoice.base64, this.appService.logoQInvoice.x, this.appService.logoQInvoice.y, this.appService.logoQInvoice.w, this.appService.logoQInvoice.h);
      
         if(invoice.status==1){
          doc.addImage(this.appService.paidLogo.base64, this.appService.paidLogo.x, this.appService.paidLogo.y, this.appService.paidLogo.w, this.appService.paidLogo.h);

        }else{
          doc.addImage(this.appService.unpaidLogo.base64, this.appService.unpaidLogo.x, this.appService.unpaidLogo.y, this.appService.unpaidLogo.w, this.appService.unpaidLogo.h);
        }
        doc.setFontSize(18); doc.setTextColor(255, 255, 255);

        doc.setTextColor(0, 0, 0); doc.setFontSize(12);
        doc.text(invoice.fromData1, 25, 123);
        doc.text(invoice.fromData2, 25, 141);
        doc.text(invoice.fromData3, 25, 159);


        doc.text('Invoice #: ' + invoice.noInvoice, 585, 30, { align: 'right' });
        doc.text('Created: ' + new Date(invoice.createdAt).toLocaleDateString('en-US'), 585, 48, { align: 'right' });
        doc.text('Due: ' + new Date(invoice.dueDate).toLocaleDateString('en-US'), 585, 66, { align: 'right' });

        doc.text('Billed To', 585, 123, { align: 'right' });
        doc.text(invoice.billedToData1, 585, 141, { align: 'right' });
        doc.text(invoice.billedToData2, 585, 159, { align: 'right' });
        doc.text(invoice.billedToData3, 585, 177, { align: 'right' });

        let d = new Date();
        //Table 1
        autoTable(doc, {
          columns: columns,
          body: rows,
          startY: 220,
          margin: [90, 20, 70, 20],
          bodyStyles: {
            lineWidth: 0.5,
            cellPadding: 10,
            textColor: 20,
          },
          styles: {
            overflow: 'linebreak'
          },

          headStyles: {
            fillColor: "#eeeeee", textColor: "#555555", fontSize: 12,
            halign: 'right'
          },
          columnStyles: {
            value1: { cellWidth: 280, fontSize: 12, lineWidth: 0, halign: 'left', fillColor: [255, 255, 255] },
            value2: { cellWidth: 290, fontSize: 12, lineWidth: 0, halign: 'right', fillColor: [255, 255, 255] },

          },

        });
        let rows2 = [];
        let columns2 = [{ title: 'Item                                                                        ', dataKey: 'item' }, { title: 'Qty   ', dataKey: 'count' }, { title: 'Price/Unit', dataKey: 'price' }];
        invoice.items.map(v => {
          let value: any = v;
          rows2.push(
            { 'item': value.description, 'count': value.count, 'price': '$' + parseFloat(value.unitPrice).toFixed(2) },
          );
        });

        rows2.push(
          { 'item': ' ', 'count': ' ', 'price': ' ' },
          { 'item': ' ', 'count': ' ', 'price': ' ' },
          { 'item': 'Other Fees', 'count': ' ', 'price': '$' + parseFloat(invoice.otherFees.toString()).toFixed(2) },
          { 'item': 'Sub Total', 'count': ' ', 'price': '$' + parseFloat(invoice.subTotal.toString()).toFixed(2) },
          { 'item': 'Tax (' + invoice.taxPercent + '%)', 'count': ' ', 'price': '$' + parseFloat(invoice.tax.toString()).toFixed(2) },
          { 'item': ' ', 'price': 'Total: $' + parseFloat(invoice.total.toString()).toFixed(2), fontStyle: 'bold' },
        );
        //Table 2
        let finalY = (doc as any).lastAutoTable.finalY;
        autoTable(doc, {
          columns: columns2,
          body: rows2,
          startY: finalY,
          margin: [90, 20, 70, 20],
          bodyStyles: {
            lineWidth: 0.5,
            cellPadding: 10,
            textColor: 20,
          },
          styles: {
            overflow: 'linebreak'
          },

          headStyles: {
            fillColor: "#eeeeee", textColor: "#555555", fontSize: 12,
            halign: 'right'
          },
          columnStyles: {
            item: { cellWidth: 280, fontSize: 12, lineWidth: 0.3, halign: 'left', fillColor: [255, 255, 255] },
            count: { cellWidth: 50, fontSize: 12, lineWidth: 0.3, halign: 'center', fillColor: [255, 255, 255] },
            price: { cellWidth: 240, fontSize: 12, lineWidth: 0.3, halign: 'right', fillColor: [255, 255, 255] },

          },

        });


        doc.save('Invoice(' + invoice.noInvoice + ').pdf');
      } else {

      }
    } catch (err) {

    }
  }

  printESInvoice(invoice: any): void {
    try {

      if (invoice != undefined) {

        let count = 0;
        let doc = new jsPDF('p', 'pt', 'letter'), rows = [], images = [], pos = 0;

        let columns = [{ title: 'Forma de pago                                                      ', dataKey: 'value1' }, { title: 'Referencia #', dataKey: 'value2' }];
        rows.push(
          { 'value1': invoice.paymentMode, 'value2': invoice.paymentReference },
        );



        /********************************************* */

        doc.addImage(this.appService.logoQInvoice.base64, this.appService.logoQInvoice.x, this.appService.logoQInvoice.y, this.appService.logoQInvoice.w, this.appService.logoQInvoice.h);
        if(invoice.status==1){
          doc.addImage(this.appService.pagadoLogo.base64, this.appService.pagadoLogo.x, this.appService.pagadoLogo.y, this.appService.pagadoLogo.w, this.appService.pagadoLogo.h);

        }else{
          doc.addImage(this.appService.noPagadoLogo.base64, this.appService.noPagadoLogo.x, this.appService.noPagadoLogo.y, this.appService.noPagadoLogo.w, this.appService.noPagadoLogo.h);
        }
        doc.setFontSize(18); doc.setTextColor(255, 255, 255);

        doc.setTextColor(0, 0, 0); doc.setFontSize(12);
        doc.text(invoice.fromData1, 25, 123);
        doc.text(invoice.fromData2, 25, 141);
        doc.text(invoice.fromData3, 25, 159);


        doc.text('Factura #: ' + invoice.noInvoice, 585, 30, { align: 'right' });
        doc.text('Creada el: ' + new Date(invoice.createdAt).toLocaleDateString('en-US'), 585, 48, { align: 'right' });
        doc.text('Pagar antes de: ' + new Date(invoice.dueDate).toLocaleDateString('en-US'), 585, 66, { align: 'right' });

        doc.text('Facturado a', 585, 123, { align: 'right' });
        doc.text(invoice.billedToData1, 585, 141, { align: 'right' });
        doc.text(invoice.billedToData2, 585, 159, { align: 'right' });
        doc.text(invoice.billedToData3, 585, 177, { align: 'right' });

        let d = new Date();
        //Table 1
        autoTable(doc, {
          columns: columns,
          body: rows,
          startY: 220,
          margin: [90, 20, 70, 20],
          bodyStyles: {
            lineWidth: 0.5,
            cellPadding: 10,
            textColor: 20,
          },
          styles: {
            overflow: 'linebreak'
          },

          headStyles: {
            fillColor: "#eeeeee", textColor: "#555555", fontSize: 12,
            halign: 'right'
          },
          columnStyles: {
            value1: { cellWidth: 280, fontSize: 12, lineWidth: 0, halign: 'left', fillColor: [255, 255, 255] },
            value2: { cellWidth: 290, fontSize: 12, lineWidth: 0, halign: 'right', fillColor: [255, 255, 255] },

          },

        });
        let rows2 = [];
        let columns2 = [{ title: 'Producto/Servicio                                                                        ', dataKey: 'item' }, { title: 'Cant   ', dataKey: 'count' }, { title: 'Precio/Unidad', dataKey: 'price' }];
        invoice.items.map(v => {
          let value: any = v;
          rows2.push(
            { 'item': value.description, 'count': value.count, 'price': '$' + parseFloat(value.unitPrice).toFixed(2) },
          );
        });

        rows2.push(
          { 'item': ' ', 'count': ' ', 'price': ' ' },
          { 'item': ' ', 'count': ' ', 'price': ' ' },
          { 'item': 'Otros Cargos', 'count': ' ', 'price': '$' + parseFloat(invoice.otherFees.toString()).toFixed(2) },
          { 'item': 'Sub Total', 'count': ' ', 'price': '$' + parseFloat(invoice.subTotal.toString()).toFixed(2) },
          { 'item': 'Impuestos (' + invoice.taxPercent + '%)', 'count': ' ', 'price': '$' + parseFloat(invoice.tax.toString()).toFixed(2) },
          { 'item': ' ', 'price': 'Total: $' + parseFloat(invoice.total.toString()).toFixed(2), fontStyle: 'bold' },
        );
        //Table 2
        let finalY = (doc as any).lastAutoTable.finalY;
        autoTable(doc, {
          columns: columns2,
          body: rows2,
          startY: finalY,
          margin: [90, 20, 70, 20],
          bodyStyles: {
            lineWidth: 0.5,
            cellPadding: 10,
            textColor: 20,
          },
          styles: {
            overflow: 'linebreak'
          },

          headStyles: {
            fillColor: "#eeeeee", textColor: "#555555", fontSize: 12,
            halign: 'right'
          },
          columnStyles: {
            item: { cellWidth: 280, fontSize: 12, lineWidth: 0.3, halign: 'left', fillColor: [255, 255, 255] },
            count: { cellWidth: 50, fontSize: 12, lineWidth: 0.3, halign: 'center', fillColor: [255, 255, 255] },
            price: { cellWidth: 240, fontSize: 12, lineWidth: 0.3, halign: 'right', fillColor: [255, 255, 255] },

          },

        });


        doc.save('Factura(' + invoice.noInvoice + ').pdf');
      } else {

      }
    } catch (err) {

    }
  }

  changeLogo(): void{
    /*var canvas:any = document.getElementById("canvas");
    canvas.width = 300;
    canvas.height = 80;
    var ctx = canvas.getContext("2d");
    
    ctx.drawImage(img, 0, 0);
    console.log(ctx);
    var dataURL = canvas.toDataURL();

    console.log(dataURL);*/
    const img: any= document.getElementById('companyLogo');
    img.crossOrigin = 'Anonymous';
    var base64 = this.appService.getBase64Image(img);
    console.log(base64);
  }
}
