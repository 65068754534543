import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-comig-soon',
  templateUrl: './comig-soon.component.html',
  styleUrls: ['./comig-soon.component.css']
})
export class ComigSoonComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    //put scroll on top
    window.scrollTo({ top: 0, behavior: 'smooth' });

    this.countdown();
  }

  countdown(): void {

    let coundown = document.querySelectorAll('.cs-countdown');

    if (coundown == null) return;

    for (let i = 0; i < coundown.length; i++) {
    
      let endDate = 1633060800000,
        daysVal = coundown[i].querySelector('.cs-countdown-days .cs-countdown-value'),
        hoursVal = coundown[i].querySelector('.cs-countdown-hours .cs-countdown-value'),
        minutesVal = coundown[i].querySelector('.cs-countdown-minutes .cs-countdown-value'),
        secondsVal = coundown[i].querySelector('.cs-countdown-seconds .cs-countdown-value'),
        days, hours, minutes, seconds;

      endDate = new Date(endDate).getTime();

      if (isNaN(endDate)) return;

      setInterval(calculate, 0);
     

      function calculate() {
        let startDate = new Date().getTime();

        let timeRemaining = Number((endDate - startDate) / 1000);

        if (timeRemaining >= 0) {
          days = parseInt((timeRemaining / 86400).toString());
          timeRemaining = parseInt((timeRemaining % 86400).toString());

          hours = parseInt((timeRemaining / 3600).toString());
          timeRemaining = parseInt((timeRemaining % 3600).toString());

          minutes = parseInt((timeRemaining / 60).toString());
          timeRemaining = parseInt((timeRemaining % 60).toString());

          seconds = timeRemaining;

          seconds = parseInt(timeRemaining.toString());

          if (daysVal != null) {
            daysVal.innerHTML = days;
          }
          if (hoursVal != null) {
            hoursVal.innerHTML = hours < 10 ? '0' + hours : hours;
          }
          if (minutesVal != null) {
            minutesVal.innerHTML = minutes < 10 ? '0' + minutes : minutes;
          }
          if (secondsVal != null) {
            secondsVal.innerHTML = seconds < 10 ? '0' + seconds : seconds;
          }

        } else {
          return;
        }
      }


    }
  }

}
