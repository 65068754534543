<!-- headers-->
<app-header-dark></app-header-dark>
<app-header-light *ngIf="showLightBar"></app-header-light>
<!-- Intro-->
<section class="position-relative bg-dark overflow-hidden pt-7 pb-lg-7">
    <div class="container-fluid pt-4 pb-5 pt-lg-5">
        <div class="row align-items-center py-3">
            <div class="col-xl-6 col-lg-5 d-flex justify-content-end">
                <div class="pt-2 pb-3 pb-lg-0 mx-auto mb-5 mb-lg-0 ml-lg-0 mr-xl-7 text-center text-lg-left"
                    style="max-width: 495px;">
                    <h1 class="display-4 text-light pb-2"><span class="font-weight-light" translate>homepage.intro.title1</span>
                    </h1>
                    <p class="h4 font-weight-light text-light opacity-70 line-height-base" translate>homepage.intro.title2</p><a
                        class="d-inline-flex align-items-center text-decoration-none pt-2 mt-4 mb-5" (click)="moveToDemos(700)"
                        data-scroll><span class="btn btn-icon rounded-circle border-primary"><i
                                class="fe-arrow-down h4 text-primary my-1"></i></span><span
                            class="ml-3 text-primary font-weight-medium" translate>homepage.intro.btn1</span></a>
                    <hr class="hr-light mb-5">
                    <div class="row">
                        <div class="col-sm-4 mb-4 mb-sm-0">
                            <div class="h1 text-light mb-1">7</div>
                            <div class="h5 text-light font-weight-normal opacity-70 mb-2" translate>homepage.intro.title3 </div><span
                                class="badge badge-pill badge-success" translate>homepage.intro.title6</span>
                        </div>
                        <div class="col-sm-4 mb-4 mb-sm-0">
                            <div class="h1 text-light mb-1">50+</div>
                            <div class="h5 text-light font-weight-normal opacity-70 mb-1" translate>homepage.intro.title4</div>
                        </div>
                        <div class="col-sm-4">
                            <div class="h1 text-light mb-1">47</div>
                            <div class="h5 text-light font-weight-normal opacity-70 mb-1" translate>homepage.intro.title5</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-lg-7">
                <div class="cs-parallax" style="max-width: 898px;">
                    <div class="cs-parallax-layer position-relative" data-depth="0.1"><img
                            src="assets/img/homepage/intro/layer01.png" alt="Layer" />
                    </div>
                    <div class="cs-parallax-layer" data-depth="0.15"><img src="assets/img/homepage/intro/layer02.png"
                            alt="Layer" />
                    </div>
                    <div class="cs-parallax-layer" data-depth="0.25"><img src="assets/img/homepage/intro/layer03.png"
                            alt="Layer" />
                    </div>
                    <div class="cs-parallax-layer" data-depth="0.35"><img src="assets/img/homepage/intro/layer04.png"
                            alt="Layer" />
                    </div>
                    <div class="cs-parallax-layer" data-depth="0.5"><img src="assets/img/homepage/intro/layer05.png"
                            alt="Layer" />
                    </div>
                    <div class="cs-parallax-layer" data-depth="0.28"><img src="assets/img/homepage/intro/layer06.png"
                            alt="Layer" />
                    </div>
                    <div class="cs-parallax-layer" data-depth="0.4"><img src="assets/img/homepage/intro/layer07.png"
                            alt="Layer" />
                    </div>
                    <div class="cs-parallax-layer" data-depth="0.5"><img src="assets/img/homepage/intro/layer08.png"
                            alt="Layer" />
                    </div>
                    <div class="cs-parallax-layer" data-depth="0.28"><img src="assets/img/homepage/intro/layer09.png"
                            alt="Layer" />
                    </div>
                    <div class="cs-parallax-layer" data-depth="0.4"><img src="assets/img/homepage/intro/layer10.png"
                            alt="Layer" />
                    </div>
                    <div class="cs-parallax-layer" data-depth="0.25"><img src="assets/img/homepage/intro/layer11.png"
                            alt="Layer" />
                    </div>
                    <div class="cs-parallax-layer" data-depth="0.45"><img src="assets/img/homepage/intro/layer12.png"
                            alt="Layer" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="cs-shape cs-shape-bottom cs-shape-curve bg-secondary">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3000 185.4">
            <path fill="currentColor" d="M3000,0v185.4H0V0c496.4,115.6,996.4,173.4,1500,173.4S2503.6,115.6,3000,0z">
            </path>
        </svg>
    </div>
</section>
<!-- Demos-->
<section class="bg-secondary" id="demos">   
    <div class="container pt-5 pb-4 py-md-6 py-lg-7">
        <div class="text-center mb-5 pt-3 pt-lg-4">
            <h2 class="h1 mb-4" translate>homepage.demos.title1 <span class='bg-faded-primary rounded text-primary px-3 py-2' translate>homepage.demos.title2</span>
            </h2>
            <p class="text-muted" translate>homepage.demos.title3</p>
        </div>
        <div class="row">
            <div class="col-lg-4 col-sm-6 mb-grid-gutter pb-3"><a class="d-block nav-heading text-center"
                [routerLink]='["/demo-admin"]'>
                    <div class="card card-hover border-0 box-shadow-lg mb-4"><img class="card-img"
                            src="assets/img/homepage/demo/admin-app.jpg" alt="Business Consulting" />
                    </div>
                    <h3 class="h5 nav-heading-title mb-0">NetBag-Manage</h3><span
                        class="font-size-sm font-weight-normal text-muted" translate>homepage.demos.title4</span>
                </a></div>
            <div class="col-lg-4 col-sm-6 mb-grid-gutter pb-3"><a class="d-block nav-heading text-center"
                [routerLink]='["/demo-pos"]'>
                    <div class="card card-hover border-0 box-shadow-lg mb-4"><img class="card-img"
                            src="assets/img/homepage/demo/pos-app.jpg" alt="Shop Homepage" />
                    </div>
                    <h3 class="h5 nav-heading-title mb-0">NetBag-POS</h3><span
                        class="font-size-sm font-weight-normal text-muted" translate>homepage.demos.title5</span>
                </a></div>
            <div class="col-lg-4 col-sm-6 mb-grid-gutter pb-3"><a class="d-block nav-heading text-center"
                [routerLink]='["/demo-onlinestore"]'>
                    <div class="card card-hover border-0 box-shadow-lg mb-4"><img class="card-img"
                            src="assets/img/homepage/demo/online-shop.jpg" alt="Booking / Directory" />
                    </div>
                    <h3 class="h5 nav-heading-title mb-0">NetBag-Online Store</h3><span
                        class="font-size-sm font-weight-normal text-muted" translate>homepage.demos.title6</span>
                </a></div>
           
            <div class="col-lg-4 col-sm-6 mb-grid-gutter pb-3"><a class="d-block nav-heading text-center"
                [routerLink]='["/demo-smartsalon"]'>
                <div class="card card-hover border-0 box-shadow-lg mb-4"><img class="card-img"
                            src="assets/img/homepage/demo/salon-app.png" alt="Booking / Directory" />
                </div>
                <h3 class="h5 nav-heading-title mb-0">SmartSalon</h3><span
                    class="font-size-sm font-weight-normal text-muted" translate>homepage.demos.title8</span>
                </a></div>
            <div class="col-lg-4 col-sm-6 mb-grid-gutter pb-3"><a class="d-block nav-heading text-center"
                    [routerLink]='["/demo-rpcargo"]'>
                    <div class="card card-hover border-0 box-shadow-lg mb-4"><img class="card-img"
                            src="assets/img/homepage/demo/rpcargo-app.jpg" alt="Booking / Directory" />
                    </div>
                    <h3 class="h5 nav-heading-title mb-0">RPCargo</h3><span
                        class="font-size-sm font-weight-normal text-muted" translate>homepage.demos.title9</span>
                </a></div>
            <div class="col-lg-4 col-sm-6 mb-grid-gutter pb-3"><a class="d-block nav-heading text-center"
                    [routerLink]='["/demo-qinvoice"]'>
                    <div class="card card-hover border-0 box-shadow-lg mb-4"><img class="card-img"
                            src="assets/img/homepage/demo/qinvoice-app.jpg" alt="Booking / Directory" />
                    </div>
                    <h3 class="h5 nav-heading-title mb-0">QInvoices</h3><span
                        class="font-size-sm font-weight-normal text-muted" translate>homepage.demos.title7</span>
                </a></div>
            <div class="col-lg-4 col-sm-6 mb-grid-gutter pb-3"><img class="d-block mb-4"
                    src="assets/img/homepage/demo/coming.png" alt="Coming" />
                <h3 class="h5 text-center mb-0" translate>Menu01.sub04</h3>
            </div>           
        </div>
    </div>
</section>
<!-- Company Services-->
<section class="bg-gradient py-5 py-md-6 py-lg-7" style="background: #1e1e2e !important;">
    <div class="container-fluid py-3 py-lg-4 overflow-hidden">
        <div class="row align-items-center">
            <div class="col-xl-6 col-lg-5 d-flex justify-content-end mb-5 mb-lg-0">
                <div class="mx-auto ml-lg-0 mr-xl-7 text-center text-lg-left" style="max-width: 495px;">
                    <h2 class="h1 text-light mb-4">Quisoft <span
                            class='bg-faded-light rounded px-3 py-2' translate>homepage.services.title1</span></h2>
                    <p class="text-light opacity-70 mb-5" translate>homepage.services.title2</p>
                    <div class="row mb-2 mx-n2">
                        <div class="col-6 px-2 mb-3"><a
                                class="d-block border border-light rounded-lg pt-3 pb-4 px-3 text-center text-decoration-none"
                                [routerLink]="['/coming-soon']" ><img class="d-inline-block opacity-60 mb-3" width="45"
                                    src="assets/img/homepage/icons/code.svg" alt="Sliders & Carousels" />
                                <h3 class="h6 text-light mb-0" translate>homepage.services.title3</h3>
                            </a></div>
                        <div class="col-6 px-2 mb-3"><a
                                class="d-block border border-light rounded-lg pt-3 pb-4 px-3 text-center text-decoration-none"
                                [routerLink]="['/coming-soon']"><img class="d-inline-block opacity-60 mb-3" width="45"
                                    src="assets/img/homepage/icons/email.svg" alt="Image / Video Gallery" />
                                <h3 class="h6 text-light mb-0" translate>homepage.services.title4</h3>
                            </a></div>
                        <div class="col-6 px-2 mb-3"><a
                                class="d-block border border-light rounded-lg pt-3 pb-4 px-3 text-center text-decoration-none"
                                [routerLink]="['/coming-soon']"><img class="d-inline-block opacity-60 mb-3" width="48"
                                    src="assets/img/homepage/icons/ecommerce.svg" alt="Line, Bar & Pie Charts" />
                                <h3 class="h6 text-light mb-0" translate>homepage.services.title5</h3>
                            </a></div>
                        <div class="col-6 px-2 mb-3"><a
                                class="d-block border border-light rounded-lg pt-3 pb-4 px-3 text-center text-decoration-none"
                                [routerLink]="['/coming-soon']"><img class="d-inline-block opacity-60 mb-3"
                                    width="50" src="assets/img/homepage/icons/reloj.svg" alt="Video Popup & Embed" />
                                <h3 class="h6 text-light mb-0" translate>homepage.services.title6</h3>
                            </a></div>
                        <div class="col-6 px-2 mb-3"><a
                                class="d-block border border-light rounded-lg pt-3 pb-4 px-3 text-center text-decoration-none"
                                [routerLink]="['/coming-soon']"><img class="d-inline-block opacity-60 mb-3" width="48"
                                    src="assets/img/homepage/icons/formacion.svg" alt="Form Controls" />
                                <h3 class="h6 text-light mb-0" translate>homepage.services.title7</h3>
                            </a></div>
                        <div class="col-6 px-2 mb-3"><a
                                class="d-block border border-light rounded-lg pt-3 pb-4 px-3 text-center text-decoration-none"
                                [routerLink]="['/coming-soon']"><img class="d-inline-block opacity-60 mb-3"
                                    width="48" src="assets/img/homepage/icons/monitor.svg"
                                    alt="Testimonials & Reviews" />
                                <h3 class="h6 text-light mb-0" translate>homepage.services.title8</h3>
                            </a></div>
                    </div><a class="btn btn-success" [routerLink]="['/coming-soon']" translate>homepage.services.btn1</a>
                </div>
            </div>
            <div class="col-xl-6 col-lg-7 d-flex justify-content-end pr-0"><a class="d-block mr-n3"
                                [routerLink]="['/coming-soon']"><img class="d-block" src="assets/img/homepage/macbook.png"
                        alt="Components" /></a></div>
        </div>
    </div>
</section>
<!-- Features-->
<section class="container py-5 py-md-6 py-lg-7">
    <div class="text-center mb-5 pt-3 pt-lg-4">
        <h2 class="h1">Quisoft <span class='bg-faded-primary rounded text-primary px-3 py-2' translate>homepage.features.title1</span>
        </h2>
    </div>
    <div class="row pt-3 pt-lg-4">
        <div class="col-md-6">
            <div class="d-flex mb-4 pb-2"><i class="fe-check-circle h4 text-success"></i>
                <div class="pl-3">
                    <h3 class="h5" translate>homepage.features.title2</h3>
                    <p class="font-size-md mb-0" translate>homepage.features.text1</p>
                </div>
            </div>
            <div class="d-flex mb-4 pb-2"><i class="fe-check-circle h4 text-success"></i>
                <div class="pl-3">
                    <h3 class="h5" translate>homepage.features.title3</h3>
                    <p class="font-size-md mb-0" translate>homepage.features.text2</p>
                </div>
            </div>
            <div class="d-flex mb-4 pb-2"><i class="fe-check-circle h4 text-success"></i>
                <div class="pl-3">
                    <h3 class="h5" translate>homepage.features.title4</h3>
                    <p class="font-size-md mb-0"  translate>homepage.features.text3</p>
                </div>
            </div>
            <div class="d-flex mb-4 pb-2"><i class="fe-check-circle h4 text-success"></i>
                <div class="pl-3">
                    <h3 class="h5" translate>homepage.features.title5</h3>
                    <p class="font-size-md mb-0" translate>homepage.features.text4</p>
                </div>
            </div>
            <div class="d-flex mb-4 pb-2"><i class="fe-check-circle h4 text-success"></i>
                <div class="pl-3">
                    <h3 class="h5" translate>homepage.features.title6</h3>
                    <p class="font-size-md mb-0" translate>homepage.features.text5</p>
                </div>
            </div>
            <div class="d-flex mb-4 pb-2"><i class="fe-check-circle h4 text-success"></i>
                <div class="pl-3">
                    <h3 class="h5" translate>homepage.features.title7</h3>
                    <p class="font-size-md mb-0" translate>homepage.features.text6</p>
                </div>
            </div>
            <div class="d-flex mb-4 pb-2"><i class="fe-check-circle h4 text-success"></i>
                <div class="pl-3">
                    <h3 class="h5" translate>homepage.features.title8</h3>
                    <p class="font-size-md mb-0" translate>homepage.features.text7</p>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="d-flex mb-4 pb-2"><i class="fe-check-circle h4 text-success"></i>
                <div class="pl-3">
                    <h3 class="h5" translate>homepage.features.title9</h3>
                    <p class="font-size-md mb-0" translate>homepage.features.text8</p>
                </div>
            </div>
            <div class="d-flex mb-4 pb-2"><i class="fe-check-circle h4 text-success"></i>
                <div class="pl-3">
                    <h3 class="h5" translate>homepage.features.title10</h3>
                    <p class="font-size-md mb-0" translate>homepage.features.text9</p>
                </div>
            </div>
            <div class="d-flex mb-4 pb-2"><i class="fe-check-circle h4 text-success"></i>
                <div class="pl-3">
                    <h3 class="h5" translate>homepage.features.title11</h3>
                    <p class="font-size-md mb-0" translate>homepage.features.text10</p>
                </div>
            </div>
            <div class="d-flex mb-4 pb-2"><i class="fe-check-circle h4 text-success"></i>
                <div class="pl-3">
                    <h3 class="h5" translate>homepage.features.title12</h3>
                    <p class="font-size-md mb-0" translate>homepage.features.text11</p>
                </div>
            </div>
            <div class="d-flex mb-4 pb-2"><i class="fe-check-circle h4 text-success"></i>
                <div class="pl-3">
                    <h3 class="h5" translate>homepage.features.title13</h3>
                    <p class="font-size-md mb-0" translate>homepage.features.text12</p>
                </div>
            </div>
            <h3 class="h5" translate>homepage.features.text13</h3>
        </div>
    </div>
</section>
<!--Mobile interface-->
<section class="bg-secondary py-5 py-md-6 py-lg-7">
    <div class="container py-3 py-lg-4">
        <div class="row align-items-center">
            <div class="col-md-6 mb-5 mb-md-0">
                <div class="mx-auto mx-md-0" style="max-width: 525px;"><img class="d-block"
                        src="assets/img/homepage/mobile-screens.jpg" alt="Mobile screens" />
                </div>
            </div>
            <div class="col-md-6 text-center text-md-left">
                <h2 class="h1 mb-4" translate><span class='bg-faded-primary rounded text-primary px-3 py-2' translate>homepage.interface.title1</span>
                    homepage.interface.title2</h2>
                <p class="text-muted mb-5" translate> homepage.interface.text1</p>
                <div class="d-flex align-items-center justify-content-center justify-content-md-start"><img width="120"
                        src="assets/img/homepage/qrcode.png" alt="QR Code" />
                    <div class="pl-3 pl-sm-4">
                        <div class="text-nav text-left" style="max-width: 175px;" translate>homepage.interface.title3
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--Price-->
<app-price></app-price>
<!--Footer-->
<footer class="cs-footer bg-dark pt-5 pt-md-6 pt-lg-7">
    <div class="container pt-3 pt-md-0">
      <div class="pb-md-4 text-center">
        <h3 class="text-light font-weight-light mb-3" translate> homepage.footer.title1</h3>
        <h2 class="text-light mb-5" translate> homepage.footer.title2</h2><a class="btn btn-primary"
        [routerLink]='["/auth"]' translate> homepage.footer.btn1</a>
      </div>
      <hr class="hr-light my-5 pb-md-4">
      <div class="row justify-content-center">
        <div class="col-lg-6 col-md-7 pb-md-4 text-center">
          <h4 class="text-light mb-grid-gutter" translate> homepage.footer.title3</h4>
          <form  [formGroup]="requestForm" (ngSubmit)="sendRequest()" class="row needs-validation pt-3" novalidate>
            <div class="input-group input-group-overlay flex-nowrap">
              <div class="input-group-prepend-overlay"><span class="input-group-text text-muted"><i
                    class="fe-mail"></i></span></div>
              <input formControlName="emailAddress" class="form-control prepended-form-control" type="email" name="EMAIL" placeholder="{{'input_placeholder.label1' | translate}}"
              [ngClass]="{'is-invalid': requestForm.controls.emailAddress.errors && (requestForm.controls.emailAddress.touched || requestForm.controls.emailAddress.dirty) }">
              
              <div class="input-group-append">                
                <button class="btn btn-primary btn-email-form" id="profile-btn" type="submit">
                    <i *ngIf="!spinner" class="fe-mail font-size-lg mr-2"></i>
                    <span *ngIf="!spinner" translate>homepage.footer.btn2</span>
                    <div *ngIf="spinner" class="btn-spinner">
                      <div class="spinner-border spinner-border-sm mr-1" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                      <div class="spinner-grow spinner-grow-sm mr-1" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div>
                  </button>
              </div>
            </div>
            <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
            <div style="position: absolute; left: -5000px;" aria-hidden="true">
              <input class="cs-subscribe-form-antispam" type="text" name="b_c7103e2c981361a6639545bd5_29ca296126"
                tabindex="-1">
            </div>
            <div class="text-center" style="width: 100%;">
                <small class="form-text text-light opacity-50 pt-2" translate> homepage.footer.title4</small>
            </div>
            
            <div class="cs-subscribe-status"></div>
          </form>
        </div>
      </div>
      <hr class="hr-light mt-5 pb-4">
      <p class="font-size-sm text-center mb-4 py-3"><span class="text-light opacity-50 mr-1" translate> homepage.footer.title5</span><a class="nav-link-style nav-link-light" href="https://www.quisoft.us/" target="_blank"
          rel="noopener" translate> homepage.footer.title6</a></p>
    </div>
</footer>
<!--Coommon-->
<app-top-arrow></app-top-arrow>

