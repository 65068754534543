import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ActivatedRoute } from '@angular/router';


const API_URI = environment.backend_url;
const BASE_URI =environment.base_url;

@Injectable({
  providedIn: 'root'
})

export class PartnerService {
  public partners = [];
  public requests = [];
  public spinner=false;
  public customers =[];

  autopayBalance:string= '0.00';  
  autoPayDate='mm/dd/yyyy';
  public leftBarHeaders={    
    paymntDue:'0.00'
  };
  
  constructor(private http: HttpClient,private activeRoute: ActivatedRoute) { }

  getPartnersList(): Observable<any> {

    return this.http.get(API_URI + '/api/partners/');

  }

  getPartnerInfo(id: number): Observable<any> {

    return this.http.get(API_URI + '/api/partners/' + id);

  }

  updateProfileInfo(id: number, data: any): Observable<any> {

    return this.http.put(API_URI + '/api/partners/' + id, data);

  }

  uploadAvatar(partnerId: number, oldAvatar:string,name: string, strBase64: any):Observable<any>{    

    return this.http.post(API_URI + '/api/partners/uploadavatar',{
      customerId:partnerId,
      oldAvatar:oldAvatar,
      name:name,
      strBase64:strBase64
    });
  }

  sendVerificationAccountEmail(fullName:string, toEmailAddress:string): Observable<any> {
    return this.http.post(API_URI + '/api/mailgun/sendemail',{
      fullName:fullName,
      domain:"quisoft.us",
      fromEmailAddress:"Quisoft Developers Group<noreply@quisoft.us>",
      toEmailAddress:toEmailAddress,
      Subject:"Action required",
      templateName:"accountConfirmation",
      setTtoken:true,
      tokenUrl:BASE_URI+"/auth-partner/p-email-confirmed?token="
    });

  }

  sendResetPasswordEmail(toEmailAddress:string): Observable<any> {
   
    return this.http.post(API_URI + '/api/mailgun/send-reset-pass-email-partner',{
      fullName:"N/A",
      domain:"quisoft.us",
      fromEmailAddress:"Quisoft Developers Group<noreply@quisoft.us>",
      toEmailAddress:toEmailAddress,
      Subject:"Reset account password",
      templateName:"resetCustomerPassword",
      setTtoken:true,
      tokenUrl:BASE_URI+"/auth-partner/reset-passw?token="
    });

  }

  verifyAccount(token:string): Observable<any>{
    let helper = new JwtHelperService();     
     let email= helper.decodeToken(token).email;    
    return this.http.post(API_URI + '/api/partners/verifyaccount',{
      token:token,    
      email:email 
    });

    

  }

  updatePasword(token:string, password:string): Observable<any>{
    let helper = new JwtHelperService();     
     let email= helper.decodeToken(token).email;     
    return this.http.post(API_URI + '/api/partners/updatepass',{
      token:token,    
      email:email,
      password:password
    });

  }

  delete(id: number): Observable<any> {

    return this.http.delete(API_URI + '/api/partners/'+id);

  }

  createCompensation(partnerId: number, start: Date, end:Date): Observable<any> {

    return this.http.post(API_URI + '/api/compensations/newcompensation', {
      PartnerId:partnerId,
      InitDate:start,
      EndDate:end
    });

  }

  getAllCompensations( search: string, start: Date, end:Date, page:number, limit:number, filter:number): Observable<any> {
      
    return this.http.post(API_URI + '/api/compensations/getcompensations',{
      Search:search,
      InitDate:start,
      EndDate:end,
      Page:page,
      Limit:limit,
      filter:filter
    });
  }

  getCompensations(year:number, partnerId: number): Observable<any> {
  
    return this.http.post(API_URI + '/api/compensations/getbypartner',{
      CustomerId:partnerId,     
      Year:year
      
    });

  }
  
  getCompensationHeaders( search: string, start: Date, end:Date, page:number, limit:number, filter:number): Observable<any> {
      
    return this.http.post(API_URI + '/api/compensations/getheaders',{
      Search:search,
      InitDate:start,
      EndDate:end,
      Page:page,
      Limit:limit,
      filter:filter
    });
  }

  deleteCompensation(id: number): Observable<any> {

    return this.http.delete(API_URI + '/api/compensations/'+id);

  }

  getMyCustomers( partnerId: number): Observable<any> {
      
    return this.http.post(API_URI + '/api/partners/getcustomers',{
      id:partnerId
    });
  }

  getMyRequests(id: number, sortMode: number): Observable<any> {

    return this.http.post(API_URI + '/api/partners/myrequests', {
      customerId: id,
      sortMode: sortMode
    });

  }

  searchRequest(noRequest: string): Observable<any> {

    return this.http.post(API_URI + '/api/partners/searchrequest', {
      Id: noRequest
    });

  }

  sendRequestPartnerLink(requestId: number, partnerId:number): Observable<any> {
    return this.http.post(API_URI + '/api/partners/requestlink', {
      RequestId: requestId,
      PartnerId:partnerId
    });

  }

  linkRequestAndPartner(token: string,requestId:number, partnerId:number): Observable<any> {
    //let helper = new JwtHelperService();
    //let email = helper.decodeToken(token).email;   
    return this.http.post(API_URI + '/api/partners/updatelink', {
      RequestId: requestId,
      PartnerId:partnerId
    });

  }
  updateCompensationData(compensationId:number, status:number, paymentMode:number, bankTransaction:string, amountPaid:number): Observable<any> {
   
    return this.http.post(API_URI + '/api/compensations/update', {
      Id: compensationId,
      Status:status,
      PaymentMode:paymentMode,
      BankTransaction:bankTransaction,
      AmountPaid:amountPaid,



    });

  }

}
