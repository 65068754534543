import { Component, OnInit } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { FormGroup, FormBuilder, Validators, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { AuthService } from '../auth.service';

import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { AsYouType } from 'libphonenumber-js'
import { parsePhoneNumber, ParseError } from 'libphonenumber-js'
import { AppService } from 'src/app/app.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CustomerService } from 'src/app/dashboard/customer.service';



export function validPhoneNumber(country: any): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const phoneNumber = parsePhoneNumberFromString(control.value, country);
    return !phoneNumber?.isValid() ? { invalid: { value: control.value } } : null;
  };
}


export const passwordValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
  const password = control.get('password');
  const confirmPassword = control.get('confirmPassword');
  return password && confirmPassword && password.value === confirmPassword.value ? null : { 'differentPassword': true };
};


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('200ms', style({ opacity: 1 })),
      ]),
    ]),
  ]
})
export class RegisterComponent implements OnInit {

  registerForm: FormGroup;
  spinner: boolean = false;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private appService: AppService,
    private router: Router,
    private customerService: CustomerService,
    private route: ActivatedRoute) {

    this.registerForm = fb.group({
      fullName: ['', Validators.required],
      phone: [''],
      emailAddress: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', [Validators.required]]
    }, { validators: passwordValidator });

    this.appService.defaultCountry.subscribe(country => {
      this.registerForm.controls.phone.setValidators([Validators.required, validPhoneNumber(country)]);
    })

  }

  ngOnInit(): void {

  }

  loko(e) {
    console.log(e.target.checked);
  }

  onKeyPhone(e: KeyboardEvent): void {
    const mask = new AsYouType(this.appService.getCountry()).input(e.target['value']);
    if (e.keyCode >= 48 && e.keyCode <= 57 || this.registerForm.get('phone').valid) {
      this.registerForm.get('phone').setValue(mask);
    }
  }

  register(): void {

    this.registerForm.markAllAsTouched();
    if (this.registerForm.valid) {
      this.spinner = true;
      const asYouType: AsYouType = new AsYouType(this.appService.getCountry());
      asYouType.input(this.registerForm.value.phone);

      const customer = Object.assign(this.registerForm.value, {
        phone: asYouType.getNumber().number
      });

      this.authService.register(customer).subscribe(r => {
        if (r.status === 201) {
          this.spinner = false;
          console.log('user registered successfully'); 
          this.customerService.sendVerificationAccountEmail(this.registerForm.value.fullName, this.registerForm.value.emailAddress).subscribe(z => {
          });
          this.router.navigate(['auth/confirm-email']);
        }
      }, err => {
        console.log(err);
        if (err.status == 400) {
          this.registerForm.controls.emailAddress.setErrors({ exist: err });
        }
        this.spinner = false;
      });
    }

  }



}
