<header [ngClass]="{'navbar-stuck': stuckBar}"
  class="cs-header navbar navbar-expand-lg navbar-light bg-light navbar-sticky" data-scroll-header>
  <div class="container px-0 px-xl-3">
    <button class="navbar-toggler ml-n2 mr-2" type="button" data-toggle="offcanvas" (click)="collapse()"
      data-offcanvas-id="primaryMenu"><span class="navbar-toggler-icon"></span></button><a
      class="navbar-brand order-lg-1 mx-auto ml-lg-0 pr-lg-2 mr-lg-4" [routerLink]='["/"]'><img
        class="d-none d-lg-block" width="153" src="assets/img/logo/logo-dark.png" alt="Around" /><img class="d-lg-none"
        width="58" src="assets/img/logo/logo-icon.png" alt="Around" /></a>
    <!--Header-login-menu-->
    <div *ngIf="!authService.logued" class="d-flex align-items-center order-lg-3 ml-lg-auto"><a
        class="nav-link-style font-size-sm text-nowrap" [routerLink]='["/auth"]' translate><i
          class="fe-user font-size-xl mr-2"></i>header_dark.btn1</a>
      <a class="btn btn-primary ml-grid-gutter d-none d-lg-inline-block" [routerLink]='["/auth/register"]' 
        data-view="login" translate>header_dark.btn2</a>
    </div>
    <!--Nav-profile-menu-->
    <app-profile-menu *ngIf="(authService.logued && authService.decodedTocken.role=='Customer')"
      class="d-flex align-items-center order-lg-3 ml-lg-auto"></app-profile-menu>
    <!--Nav-profile-partner-menu-->
    <app-profile-partner-menu *ngIf="(authService.logued && authService.decodedTocken.role=='Partner')"
      class="d-flex align-items-center order-lg-3 ml-lg-auto"></app-profile-partner-menu>
    <!--Nav-profile-admin-menu-->
    <app-admin-menu *ngIf="(authService.logued && authService.decodedTocken.role=='Admin')"
      class="d-flex align-items-center order-lg-3 ml-lg-auto"></app-admin-menu>

    <div class="cs-offcanvas-collapse order-lg-2" id="primaryMenu" [class.show]="show">
      <div class="cs-offcanvas-cap navbar-box-shadow">
        <h5 class="mt-1 mb-0">Menu</h5>
        <button class="close lead" type="button" data-toggle="offcanvas" data-offcanvas-id="primaryMenu" (click)="collapse()"><span
            aria-hidden="true">&times;</span></button>
      </div>
      <div class="cs-offcanvas-body">
        <!-- Menu-->
        <app-menu></app-menu>
      </div>
    </div>
  </div>
</header>