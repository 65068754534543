import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Invoice } from 'src/app/Models/Invoice'
import { AppService } from '../../app.service'
import { CustomerService } from 'src/app/dashboard/customer.service'
import { AlertService } from '../../alert/alert.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable'
import { applyPlugin } from 'jspdf-autotable'
applyPlugin(jsPDF);
declare var Chart: any;
const doc = new jsPDF();

declare var $: any;
@Component({

  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.css']
})
export class InvoicesComponent implements OnInit {

  search: string = '';

  pagination: any = {
    page: 1,
    pages:[],
    rows: 10,
    entries:0,
    filter:-1
  }


  range = new FormGroup({
    start: new FormControl(new Date((new Date().getMonth() + 1).toString() + '/01/' + new Date().getFullYear().toString())),
    end: new FormControl(new Date())
  });

  spinner: boolean = false;
  invoices: Invoice[];
  invoiceId: -1;
  spinnerBtn: boolean = false;
  spinnerHeaders: boolean = false;

  headers: any = {   
    unpaid: 0,
    manualPayment: 0,
    autoPayment: 0
  }

  constructor(public appService: AppService, public customerService: CustomerService, public alertService: AlertService) { }

  ngOnInit(): void {

    this.pagination.rows = 10;
    this.pagination.page = 1;
    this.pagination.pages = [1];

  
    setTimeout(() => {

    }, 4000);

    this.getInvoices();
    this.getHeaders();

  }


  getInvoices(): void {
    this.spinner = true;   
    let values = this.range.value;
    this.customerService.getAllInvoices(this.search, new Date(this.appService.fixInitDate(values.start)), new Date(this.appService.fixEndDate(values.end)), this.pagination.page, this.pagination.rows, this.pagination.filter).subscribe(r => {

      if (r.invoices) {
        this.invoices = r.invoices;
        this.pagination.entries=r.entries;
        let a=Math.ceil(r.entries / this.pagination.rows);
        this.pagination.pages = [];
        for (var i=1;i<=a;i++) {
          this.pagination.pages.push(i);
        }

      }
      this.spinner = false;     
     
    }, err => {
      this.spinner = false;
      console.log(err);
    });
  }

  getHeaders(): void {   
    this.spinnerHeaders=true;
    let values = this.range.value;
    this.customerService.getInvoicesHeaders(this.search, new Date(values.start), new Date(values.end), this.pagination.page, this.pagination.rows, this.pagination.filter).subscribe(r => {

      if (r.invoices) {       
        this.headers = {   
          unpaid: r.unPaid,
          manualPayment: r.manualPayment,
          autoPayment: r.autoPayment
        }
      }
     
      this.spinnerHeaders=false;
     
    }, err => {
      this.spinner = false;
      console.log(err);
    });
  }

  paginate(p): void {
    this.pagination.page = p;
    this.getInvoices();
  }

  previousPage(): void{
    if(this.pagination.page-1 >= 1){
      this.paginate(this.pagination.page-1);
    }
   
  }

  nextPage(): void{    
    
    if(this.pagination.page+1 <= this.pagination.pages.length){
      this.paginate(this.pagination.page+1);
    }
  }

  changeDateRange(): void{
    this.pagination.filter=-1;
    this.pagination.page = 1;
    this.getInvoices();
    this.getHeaders();
  }

  filterInvoice(filterInvoice: number): void {
    this.pagination.filter=filterInvoice;   
    this.getInvoices();
  }


  deleteInvoice(): void {
    this.spinnerBtn = true;
    this.customerService.deleteInvoice(this.invoiceId).subscribe(r => {

      if (r.statusCode === 201) {//Remove invoice from view-list
        this.invoices = this.invoices.filter(x => x.noInvoice != r.invoice.noInvoice);
        $('#modal-remove-invoice').modal('hide');
        this.spinner = false;
        this.alertService.showAlert("fe fe-bell", "Factura eliminada correctamente.","Invoice removed successfully.", "success");

        this.spinnerBtn = false;
        //this.loadHeaders();
      }
    }, err => {
      this.alertService.showAlert("fe fe-alert-octagon","No se pudo eliminar la factura.", "Could not remove invoice.", "danger");
      this.spinnerBtn = false;

    });

  }

  searchInvoice(e): void {
    if (e.code === 'Enter') {
      this.pagination.filter=-1;  
      this.getInvoices();
      this.getHeaders();
    }
  }

  printInvoice(statement): void {
    try {
      
      if (statement.noInvoice != undefined) {

        let count = 0;
        let doc = new jsPDF('p', 'pt', 'letter'), rows = [], images = [], pos = 0;

        let columns = [{ title: 'Customer Information', dataKey: 'customer' }, { title: ' ', dataKey: 'empty' }];
        rows.push(
          { 'customer': 'Customer Name', 'empty': statement.clientName },
          { 'customer': 'Customer Phone', 'empty': statement.clientPhone },
          { 'customer': 'Billing Period', 'empty': 'From: ' + new Date(statement.initDate).toLocaleDateString('en-US') + '     To: ' + new Date(statement.endDate).toLocaleDateString('en-US') },

        );



        /********************************************* */
        doc.setDrawColor(this.appService.config.pdf.headerColor.R, this.appService.config.pdf.headerColor.G, this.appService.config.pdf.headerColor.B); doc.setFillColor(this.appService.config.pdf.headerColor.R, this.appService.config.pdf.headerColor.G, this.appService.config.pdf.headerColor.B);
        doc.rect(0, 0, 800, 70, 'F');
        doc.rect(0, doc.internal.pageSize.height - 25, 800, 25, 'F');
        doc.addImage(this.appService.logo.base64, this.appService.logo.x, this.appService.logo.y, this.appService.logo.w, this.appService.logo.h);

        doc.setFontSize(18); doc.setTextColor(255, 255, 255);

        doc.setTextColor(0, 0, 0); doc.setFontSize(10);
        doc.text(this.appService.config.pdf.address1, 25, 90);
        doc.text(this.appService.config.pdf.address2, 25, 100);
        doc.text(this.appService.config.pdf.phone, 25, 110);
        doc.text(this.appService.config.pdf.web, 25, 120);


        doc.text('Statement Report', 425, 90);
        doc.text('______________________________', 425, 98);
        doc.text('Invoice No: ' + statement.noInvoice, 425, 112);

        let d = new Date();

        doc.text('Created At: ' + new Date(statement.createdAt).toLocaleDateString('en-US'), 425, 123);

        autoTable(doc, {
          columns: columns,
          body: rows,
          startY: 160,
          margin: [90, 20, 70, 20],
          bodyStyles: {
            lineWidth: 0.5,
            cellPadding: 10,
            textColor: 20,
          },
          styles: {
            overflow: 'linebreak'
          },
          headStyles: {
            fillColor: '#86c233'
          },
          columnStyles: {
            customer: { cellWidth: 280 },
            empty: { cellWidth: 290 },

          },

        });

        let rows3 = [];
        let columns3 = [{ title: 'Statement Resume', dataKey: 'customer' }, { title: ' ', dataKey: 'empty' }];
        rows3.push(
          { 'customer': ' Online Services', 'empty': statement.items.length.toString() },
          { 'customer': 'Services Cost', 'empty': '$' + parseFloat(statement.invoiceSalePrice).toFixed(2) },
          { 'customer': 'Other Charges', 'empty': '$0.0' },
          { 'customer': 'Total Due', 'empty': '$' + parseFloat(statement.invoiceSalePrice).toFixed(2) },

        );
        let finalY = (doc as any).lastAutoTable.finalY;
        autoTable(doc, {
          columns: columns3,
          body: rows3,
          startY: finalY + 25,
          margin: [90, 20, 70, 20],
          bodyStyles: {
            lineWidth: 0.5,
            cellPadding: 10,
            textColor: 20,
          },
          styles: {
            overflow: 'linebreak'
          },
          headStyles: {
            fillColor: '#86c233'
          },
          columnStyles: {
            customer: { cellWidth: 280 },
            empty: { cellWidth: 290 },

          },
          willDrawCell: (data) => {                      
            if (data.row.index === 3) {
              doc.setFont('helvetica', 'bold');
              doc.setFontSize(12);
            }
          }
        });

        let rows4 = [];
        let columns4 = [{ title: 'Payment Resume', dataKey: 'payment' }, { title: ' ', dataKey: 'empty' }];
        rows4.push(
          { 'payment': 'Payment Mode', 'empty': (statement.paymentMode==0 ?'Unpaid' : (statement.paymentMode==1 ?'Auto payment' : 'Manual payment'))},
          { 'payment': 'Pay in Cash', 'empty': '$' + parseFloat(statement.payInCash).toFixed(2) },
          { 'payment': 'Pay in Card', 'empty': '$' + parseFloat(statement.payInCard).toFixed(2)},
          { 'payment': 'Bank Transaction', 'empty': statement.bankTransaction},

        );
        finalY = (doc as any).lastAutoTable.finalY;
        autoTable(doc, {
          columns: columns4,
          body: rows4,
          startY: finalY + 25,
          margin: [90, 20, 70, 20],
          bodyStyles: {
            lineWidth: 0.5,
            cellPadding: 10,
            textColor: 20,
          },
          styles: {
            overflow: 'linebreak'
          },
          headStyles: {
            fillColor: '#86c233'
          },
          columnStyles: {
            payment: { cellWidth: 280 },
            empty: { cellWidth: 290 },

          },
          
        });
        doc.addPage();
        let position=0;
        statement.items.map(v => {

          let rows2 = [];
          let columns2 = [{ title: 'Service Information', dataKey: 'request' }, { title: ' ', dataKey: 'empty' }];

          let service = v;
          if (service != undefined) {
            count++;
            position+=40;
            rows2.push(
              { 'request': 'Application No', 'empty': service.number },
              { 'request': 'Application Name', 'empty': service.appName },
              { 'request': 'Plan', 'empty': service.plan },
              { 'request': 'Info collected on', 'empty': new Date(service.createdAt).toLocaleDateString('en-US') },
              { 'request': 'Status', 'empty': (service.status == 0 ? 'Offline' : 'Online') },
              { 'request': 'Service Mode', 'empty': (service.isProduction == 0 ? 'Demo' : 'Production') },
              { 'request': 'Partner Name', 'empty': service.partnerName },
              { 'request': 'Sale Price', 'empty': '$' + service.salePrice.toString() },

            );
          }
          let finalY = (doc as any).lastAutoTable.finalY;
          autoTable(doc, {
            columns: columns2,
            body: rows2,
            startY: (position==40 ? position : finalY+40),
            margin: [90, 20, 70, 20],

            bodyStyles: {
              lineWidth: 0.5,
              cellPadding: 10,
              textColor: 20,
            },            
            styles: {
              overflow: 'linebreak'
            },
            headStyles: {
              fillColor: '#86c233'
            },
            showHead:"firstPage",
            columnStyles: {
              request: { cellWidth: 280 },
              empty: { cellWidth: 290 },
  
            },
            willDrawCell: (data) => {                      
              if (data.row.index === 7) {
                doc.setFont('helvetica', 'bold');
                doc.setFontSize(12);
              }
            }
          });

        });


        //doc.output('dataurlnewwindow');
        doc.save('Service(' + statement.noInvoice + ').pdf');
      } else {
        //this.showError("Does not have pending statements.");
      }
    } catch (err) {

      //this.showError(err);
      //this.hideBarTable();
    }
  }
}
