<!--Header-->
<app-header-light></app-header-light>
 <!-- Intro-->
 <section class="container my-lg-2 pt-5 pb-lg-7">
    <div class="row align-items-center">
      <div class="col-lg-5 py-3 py-lg-0">
        <h1 translate>about.title1</h1>
        <h2 class="h3" translate>about.title2</h2>
        <div class="py-4">
          <p class="cs-callout"translate>about.title3</p>
        </div><a class="cs-video-btn cs-video-btn-primary cs-video-btn-sm mr-3" href="https://www.youtube.com/watch?v=hTu0a4o97dU"></a><span class="font-size-sm text-muted" translate>about.title4</span>
      </div>
      <div class="col-xl-6 col-lg-7 offset-xl-1">
        <div class="py-5" style="min-height: 543px;">
          <div class="d-none d-lg-block position-absolute bg-no-repeat bg-position-center h-100" style="top: 0; left: -45px; width: 646px; background-image: url(assets/img/pages/about/bg-shape.svg);"></div>
          <div class="row no-gutters mx-n2 pt-lg-4">
            <div class="col-sm-4 px-2 mb-3"><a class="card h-100 card-body py-5 justify-content-center border-0 box-shadow-lg text-center" [routerLink]='["/"]'><i class="fe-zap text-primary h1 mb-3"></i>
                <h3 class="h5 mb-0" translate>about.label1</h3></a></div>
            <div class="col-sm-4 px-2 mb-3"><a class="card card-body py-5 border-0 box-shadow-lg text-center mb-3" [routerLink]='["/"]'><i class="fe-pie-chart text-danger h1 mb-3"></i>
                <h3 class="h5 mb-0" translate>about.label2</h3></a><a class="card card-body py-5 border-0 box-shadow-lg text-center" [routerLink]='["/"]'><i class="fe-refresh-ccw text-info h1 mb-3"></i>
                <h3 class="h5 mb-0" translate>about.label3</h3></a></div>
            <div class="col-sm-4 px-2 mb-3"><a class="card card-body py-5 border-0 box-shadow-lg text-center mb-3" [routerLink]='["/"]'><i class="fe-folder-plus text-success h1 mb-3"></i>
                <h3 class="h5 mb-0" translate>about.label4</h3></a><a class="card card-body py-5 border-0 box-shadow-lg text-center" [routerLink]='["/"]'><i class="fe-share text-warning h1 mb-3"></i>
                <h3 class="h5 mb-0" translate>about.label5</h3></a></div>
          </div>
        </div>
      </div>
    </div>
  </section>
 <!-- Statistics-->
 <section class="bg-secondary py-5 mt-n4 mb-6 mt-lg-0 mb-lg-7">
    <div class="container py-3">
      <div class="row">
        <div class="col-md-3 col-sm-6 text-center py-3">
          <h2 class="display-4">15</h2>
          <p class="text-muted mb-0" translate>about.title5</p>
        </div>
        <div class="col-md-3 col-sm-6 text-center py-3">
          <h2 class="display-4">2k+</h2>
          <p class="text-muted mb-0" translate>about.title6</p>
        </div>
        <div class="col-md-3 col-sm-6 text-center py-3">
          <h2 class="display-4">90%</h2>
          <p class="text-muted mb-0" translate>about.title7</p>
        </div>
        <div class="col-md-3 col-sm-6 text-center py-3">
          <h2 class="display-4">100</h2>
          <p class="text-muted mb-0" translate>about.title8</p>
        </div>
      </div>
    </div>
  </section>
 <!-- Team-->
 <section class="container mb-5 pb-3 pb-lg-0 mb-lg-7">
    <h2 class="pb-4 mb-4 text-center" translate>about.title9</h2>
    <div class="row mb-3">
      <div class="col-lg-3 col-md-4 col-sm-6 mb-grid-gutter">
        <div class="card card-curved-body card-hover border-0 box-shadow mx-auto" style="max-width: 21rem;"><a class="card-floating-icon" href="mailto:sarah.cole@example.com"><i class="fe-mail"></i></a>
          <div class="card-img-top card-img-gradient"><img src="assets/img/team/01.jpg" alt="Sarah Cole"/>
          </div>
          <div class="card-body text-center">
            <h3 class="h6 card-title mb-2">Robiel Perdomo</h3>
            <p class="font-size-xs text-body mb-0" translate>about.roles.role01</p>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-4 col-sm-6 mb-grid-gutter">
        <div class="card card-curved-body card-hover border-0 box-shadow mx-auto" style="max-width: 21rem;"><a class="card-floating-icon" href="mailto:charlie.welch@example.com"><i class="fe-mail"></i></a>
          <div class="card-img-top card-img-gradient"><img src="assets/img/team/02.jpg" alt="Charlie Welch"/>
          </div>
          <div class="card-body text-center">
            <h3 class="h6 card-title mb-2">Rolando Betancourt</h3>
            <p class="font-size-xs text-body mb-0" translate>about.roles.role01</p>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-4 col-sm-6 mb-grid-gutter">
        <div class="card card-curved-body card-hover border-0 box-shadow mx-auto" style="max-width: 21rem;"><a class="card-floating-icon" href="mailto:emma.brown@example.com"><i class="fe-mail"></i></a>
          <div class="card-img-top card-img-gradient"><img src="assets/img/team/03.jpg" alt="Emma Brown"/>
          </div>
          <div class="card-body text-center">
            <h3 class="h6 card-title mb-2">Emma Brown</h3>
            <p class="font-size-xs text-body mb-0" translate>about.roles.role02</p>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-4 col-sm-6 mb-grid-gutter">
        <div class="card card-curved-body card-hover border-0 box-shadow mx-auto" style="max-width: 21rem;"><a class="card-floating-icon" href="mailto:rosalie.lyons@example.com"><i class="fe-mail"></i></a>
          <div class="card-img-top card-img-gradient"><img src="assets/img/team/04.jpg" alt="Rosalie Lyons"/>
          </div>
          <div class="card-body text-center">
            <h3 class="h6 card-title mb-2">Rosalie Lyons</h3>
            <p class="font-size-xs text-body mb-0" translate>about.roles.role03</p>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-4 col-sm-6 mb-grid-gutter">
        <div class="card card-curved-body card-hover border-0 box-shadow mx-auto" style="max-width: 21rem;"><a class="card-floating-icon" href="mailto:jane.tanaka@example.com"><i class="fe-mail"></i></a>
          <div class="card-img-top card-img-gradient"><img src="assets/img/team/05.jpg" alt="Jane Tanaka"/>
          </div>
          <div class="card-body text-center">
            <h3 class="h6 card-title mb-2">Jane Tanaka</h3>
            <p class="font-size-xs text-body mb-0" translate>about.roles.role04</p>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-4 col-sm-6 mb-grid-gutter">
        <div class="card card-curved-body card-hover border-0 box-shadow mx-auto" style="max-width: 21rem;"><a class="card-floating-icon" href="mailto:sanomi.smith@example.com"><i class="fe-mail"></i></a>
          <div class="card-img-top card-img-gradient"><img src="assets/img/team/06.jpg" alt="Sanomi Smith"/>
          </div>
          <div class="card-body text-center">
            <h3 class="h6 card-title mb-2">Sanomi Smith</h3>
            <p class="font-size-xs text-body mb-0" translate>about.roles.role05</p>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-4 col-sm-6 mb-grid-gutter">
        <div class="card card-curved-body card-hover border-0 box-shadow mx-auto" style="max-width: 21rem;"><a class="card-floating-icon" href="mailto:olivia.jones@example.com"><i class="fe-mail"></i></a>
          <div class="card-img-top card-img-gradient"><img src="assets/img/team/07.jpg" alt="Olivia Jones"/>
          </div>
          <div class="card-body text-center">
            <h3 class="h6 card-title mb-2">Olivia Jones</h3>
            <p class="font-size-xs text-body mb-0" translate>about.roles.role06</p>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-4 col-sm-6 mb-grid-gutter">
        <div class="card card-curved-body card-hover border-0 box-shadow mx-auto" style="max-width: 21rem;"><a class="card-floating-icon" href="mailto:richard.davis@example.com"><i class="fe-mail"></i></a>
          <div class="card-img-top card-img-gradient"><img src="assets/img/team/08.jpg" alt="Richard Davis"/>
          </div>
          <div class="card-body text-center">
            <h3 class="h6 card-title mb-2">Richard Davis</h3>
            <p class="font-size-xs text-body mb-0" translate>about.roles.role07</p>
          </div>
        </div>
      </div>
    </div>
  </section>
<!--Footer-->
<app-contact-footer></app-contact-footer>
<!--Coommon-->
<app-top-arrow></app-top-arrow>

