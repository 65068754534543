import { Injectable } from '@angular/core';
import { AppService } from '../app.service';
declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(public appService:AppService) { }

  showAlert(icon: string, messageES: string, messageEN: string, type: string): void {
    var message="";
    if(this.appService.defaultLanguage.getValue() == 'es'){
      message=messageES;
    }else{
      message=messageEN;
    }
    $.notify(
      {
        icon:icon,
        message: message,
        target: "_blank"
      },
      {
        element: "body",
        position: "fixed",
        type: type,
        allow_dismiss: true,
        newest_on_top: false,
        showProgressbar: false,
        placement: {
          from: "top",
          align: "right"
        },
        offset: 20,
        spacing: 10,
        z_index: 9999,
        delay: 5000,
        timer: 1000,
        url_target: "_blank",
        mouse_over: null,
        animate: {
          enter: "animated fadeInDown",
          exit: "animated fadeOutUp"
        },

        icon_type: "class",
        template:
          '<div data-notify="container" class="col-xs-11 col-sm-3 alert alert-{0}" role="alert">' +
          '<button type="button" style="outline: none;" aria-hidden="true" class="close text-muted mt-1" data-notify="dismiss">×</button>' +
          '<span data-notify="icon"></span> ' +
          '<span data-notify="title">{1}</span> ' +
          '<span data-notify="message" class="ml-4">{2}</span>' +
          '<div class="progress" data-notify="progressbar">' +
          '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
          "</div>" +
          '<a href="{3}" target="{4}" data-notify="url"></a>' +
          "</div>"
      }
    );
  }
}
