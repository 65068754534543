import { Component, OnInit } from '@angular/core';
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { AsYouType } from 'libphonenumber-js'
import { parsePhoneNumber, ParseError } from 'libphonenumber-js'
import { FormGroup, FormBuilder, Validators, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { CustomerService } from '../../dashboard/customer.service'
import { AlertService } from '../../alert/alert.service'
export function validPhoneNumber(country: any): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const phoneNumber = parsePhoneNumberFromString(control.value, country);
    return !phoneNumber?.isValid() ? { invalid: { value: control.value } } : null;
  };
}

@Component({
  selector: 'app-submit-request',
  templateUrl: './submit-request.component.html',
  styleUrls: ['./submit-request.component.css']
})
export class SubmitRequestComponent implements OnInit {

  requestForm: FormGroup;
  spinner:boolean=false;

  constructor(private fb: FormBuilder, public customerService:CustomerService, public alertService:AlertService) { 
    this.requestForm = fb.group({     
      topic: ['', [Validators.required,]],
      subject: ['', [Validators.required]],
      message: ['', [Validators.required]],
      fullName: ['', Validators.required],     
      emailAddress: ['', [Validators.required, Validators.email]],     
    });
  }

  ngOnInit(): void {
  }

  sendRequest(): void{
    this.requestForm.markAllAsTouched();
    
    if (this.requestForm.valid) {
      this.spinner = true;
      let values = this.requestForm.value;

      let data = {       
        topic: values.topic,
        subject: values.subject,
        message: values.message,
        fullName: values.fullName,
        emailAddress: values.emailAddress,        
      }
      
      this.customerService.sendQuestionEmail(data).subscribe(r => {
        
        if(r.statusCode==201){
          this.requestForm.reset();
          this.alertService.showAlert( "fe fe-bell", "Correo enviado satisfactoriamente.","Mail sent successfully.","success");
           
        }else{
          this.alertService.showAlert( "fe fe-alert-octagon","Error al enviar el mensaje.","Error sending message, try again.","danger");
  
        }
        this.spinner = false;
      });
    }
    
  }

}
