<!-- Link expired -->
<div *ngIf="resetPassView == 0" @fadeIn class="container d-flex flex-column justify-content-center pt-5 mt-n6"
  style="flex: 1 0 auto;">
  <div class="pt-7 pb-5">
    <div class="text-center mb-2 pb-4">
      <h1 class="mb-grid-gutter"><img class="d-inline-block" src="/assets/img/pages/404/404-illustration.svg"
          alt="Error 404" /><span class="sr-only">Error 401</span><span
          class="d-block pt-3 font-size-sm font-weight-semibold text-danger">Error: Code 401</span>
      </h1>
      <h2>Link expired!</h2>
      <p class="pb-2">It seems that your link was expired.</p><a class="btn btn-translucent-primary mr-3"
        [routerLink]='["/auth-admin/forgot-passw"]'>Resend link</a>
    </div>
  </div>
</div>
<!-- Form view -->
<div *ngIf="resetPassView == 1" @fadeIn>
  <h1 class="h2">Reset your password</h1>
  <p class="font-size-ms text-muted mb-4">
    Enter your new password with at least 6 digits length.
  </p>

  <form [formGroup]="newPasswordForm" (ngSubmit)="resetPassord()" class="needs-validation" novalidate>

    <!-- Password Input -->
    <div class="cs-password-toggle form-group">
      <input #password
        [ngClass]="{'is-invalid': newPasswordForm.controls.password.errors && (newPasswordForm.controls.password.touched || newPasswordForm.controls.password.dirty) }"
        formControlName="password" class="form-control" type="password" placeholder="Password">

      <label class="cs-password-toggle-btn">
        <input (change)="$event.target.checked ? password.type = 'text' : password.type = 'password'"
          class="custom-control-input" type="checkbox">
        <i [style.color]="newPasswordForm.controls.password.errors && (newPasswordForm.controls.password.touched || newPasswordForm.controls.password.dirty) ? '#f74f78' : ''"
          class="fe-eye cs-password-toggle-indicator"></i><span class="sr-only">Show password</span>
      </label>
      <div *ngIf="newPasswordForm.controls.password.errors?.required" class="invalid-feedback ml-2">
        Password is required.
      </div>
      <div *ngIf="newPasswordForm.controls.password.errors?.minlength" class="invalid-feedback ml-2">
        Password must have at least 6 characters.
      </div>
    </div>

    <!-- Confirm password Input -->
    <div class="cs-password-toggle form-group">
      <input #confirmPassword
        [ngClass]="{'is-invalid': ( newPasswordForm.controls.confirmPassword.errors || newPasswordForm.errors?.differentPassword) && (newPasswordForm.controls.confirmPassword.touched || newPasswordForm.controls.confirmPassword.dirty) }"
        formControlName="confirmPassword" class="form-control" type="password" placeholder="Confirm password">
      <label class="cs-password-toggle-btn">
        <input (change)="$event.target.checked ? confirmPassword.type = 'text' : confirmPassword.type = 'password'"
          class="custom-control-input" type="checkbox">
        <i [style.color]="( newPasswordForm.controls.confirmPassword.errors || newPasswordForm.errors?.differentPassword) && (newPasswordForm.controls.confirmPassword.touched || newPasswordForm.controls.confirmPassword.dirty) ? '#f74f78' : ''"
          class="fe-eye cs-password-toggle-indicator"></i><span class="sr-only">Show
          password</span>
      </label>
      <div *ngIf="newPasswordForm.controls.confirmPassword.errors?.required" class="invalid-feedback ml-2">
        Password confirmation is required.
      </div>
      <div
        *ngIf="newPasswordForm.errors?.differentPassword && !newPasswordForm.controls.confirmPassword.errors?.required"
        class="invalid-feedback ml-2">
        Password does not match.
      </div>
    </div>

    <button class="btn btn-primary btn-block" type="submit">
      <span *ngIf="!spinner">Change password</span>
      <div *ngIf="spinner" class="btn-spinner">
        <div class="spinner-border spinner-border-sm mr-1" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow spinner-grow-sm mr-1" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </button>

    <p class="font-size-sm pt-3 mb-0">
      Don't want to reset the password? <a [routerLink]="['/auth-admin/login-admin']" class='font-weight-medium'>Sign in</a>
    </p>
  </form>
</div>

<!-- Password changed successfully -->
<div *ngIf="resetPassView == 2" @fadeIn class="container d-flex flex-column justify-content-center pt-5 mt-n6"
  style="flex: 1 0 auto;">
  <div class="pt-7 pb-5">
    <div class="text-center mb-2 pb-4">
      <h1 class="mb-grid-gutter"><img class="d-inline-block" src="/assets/img/pages/pass-changed/image-1.png"
          alt="Code 200" /><span class="sr-only">Password changed successfully</span>
      </h1>
      <h2>Password changed!</h2>
      <p class="pb-2">Your credentials was changed successfully.</p><a class="btn btn-translucent-primary mr-3"
        [routerLink]='["/auth-admin/login-admin"]'>Sign in</a>
    </div>
  </div>
</div>

