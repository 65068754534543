<div @fadeIn>
    <h1 class="h2">Sign up</h1>
    <p class="font-size-ms text-muted mb-4">
      Admins need additional autorization for sign in.
    </p>
  
    <form [formGroup]="registerForm" (ngSubmit)="register()" class="needs-validation" novalidate>
  
      <!-- Full name Input -->
      <div class="form-group">
        <input formControlName="fullName"
          [ngClass]="{'is-invalid': registerForm.controls.fullName.errors && (registerForm.controls.fullName.touched || registerForm.controls.fullName.dirty) }"
          class="form-control" type="text" placeholder="Full name">
        <div class="invalid-feedback ml-2">
          Full name is required.
        </div>
      </div>
  
      <!-- Phone Input -->
      <div class="form-group">
        <input formControlName="phone" (keyup)="onKeyPhone($event)"
          [ngClass]="{'is-invalid': registerForm.controls.phone.errors && (registerForm.controls.phone.touched || registerForm.controls.phone.dirty) }"
          class="form-control" type="text" placeholder="Phone">
        <div *ngIf="registerForm.controls.phone.errors?.required" class="invalid-feedback ml-2">
          Phone number is required.
        </div>
        <div *ngIf="registerForm.controls.phone.errors?.invalid && !registerForm.controls.phone.errors?.required"
          class="invalid-feedback ml-2">
          Invalid phone number.
        </div>
      </div>
  
      <!-- Email Input -->
      <div class="form-group">
        <input
          [ngClass]="{'is-invalid': registerForm.controls.emailAddress.errors && (registerForm.controls.emailAddress.touched || registerForm.controls.emailAddress.dirty) }"
          formControlName="emailAddress" class="form-control" type="text" placeholder="Email">
        <div *ngIf="registerForm.controls.emailAddress.errors?.required" class="invalid-feedback ml-2">
          Email address is required.
        </div>
        <div
          *ngIf="registerForm.controls.emailAddress.errors?.email && !registerForm.controls.emailAddress.errors?.required"
          class="invalid-feedback ml-2">
          Invalid email address.
        </div>
        <div
          *ngIf="registerForm.controls.emailAddress.errors?.exist"
          class="invalid-feedback ml-2">
          Email address already exists.
        </div>
      </div>
  
      <!-- Password Input -->
      <div class="cs-password-toggle form-group">
        <input #password
          [ngClass]="{'is-invalid': registerForm.controls.password.errors && (registerForm.controls.password.touched || registerForm.controls.password.dirty) }"
          formControlName="password" class="form-control" type="password" placeholder="Password">
  
        <label class="cs-password-toggle-btn">
          <input (change)="$event.target.checked ? password.type = 'text' : password.type = 'password'"
            class="custom-control-input" type="checkbox">
          <i [style.color]="registerForm.controls.password.errors && (registerForm.controls.password.touched || registerForm.controls.password.dirty) ? '#f74f78' : ''"
            class="fe-eye cs-password-toggle-indicator"></i><span class="sr-only">Show password</span>
        </label>
        <div *ngIf="registerForm.controls.password.errors?.required" class="invalid-feedback ml-2">
          Password is required.
        </div>
        <div *ngIf="registerForm.controls.password.errors?.minlength" class="invalid-feedback ml-2">
          Password must have at least 6 characters.
        </div>
      </div>
  
      <!-- Confirm password Input -->
      <div class="cs-password-toggle form-group">
        <input #confirmPassword
          [ngClass]="{'is-invalid': ( registerForm.controls.confirmPassword.errors || registerForm.errors?.differentPassword) && (registerForm.controls.confirmPassword.touched || registerForm.controls.confirmPassword.dirty) }"
          formControlName="confirmPassword" class="form-control" type="password" placeholder="Confirm password">
        <label class="cs-password-toggle-btn">
          <input (change)="$event.target.checked ? confirmPassword.type = 'text' : confirmPassword.type = 'password'"
            class="custom-control-input" type="checkbox">
          <i [style.color]="( registerForm.controls.confirmPassword.errors || registerForm.errors?.differentPassword) && (registerForm.controls.confirmPassword.touched || registerForm.controls.confirmPassword.dirty) ? '#f74f78' : ''"
            class="fe-eye cs-password-toggle-indicator"></i><span class="sr-only">Show
            password</span>
        </label>
        <div *ngIf="registerForm.controls.confirmPassword.errors?.required" class="invalid-feedback ml-2">
          Password confirmation is required.
        </div>
        <div *ngIf="registerForm.errors?.differentPassword && !registerForm.controls.confirmPassword.errors?.required"
          class="invalid-feedback ml-2">
          Password does not match.
        </div>
      </div>
  
      <button class="btn btn-primary btn-block" type="submit">
        <span *ngIf="!spinner">Sign up</span>
        <div *ngIf="spinner" class="btn-spinner">
          <div class="spinner-border spinner-border-sm mr-1" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          <div class="spinner-grow spinner-grow-sm mr-1" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </button>
  
      <p class="font-size-sm pt-3 mb-0">
        Already have an account? <a [routerLink]="['/auth-admin/login-admin']" class='font-weight-medium'>Sign in</a>
      </p>
    </form>
   
  </div>
  
  <div class="border-top text-center mt-4 pt-4">
    <p class="font-size-sm font-weight-medium text-heading">Or sign in with</p><a
      class="social-btn sb-facebook sb-outline sb-lg mx-1 mb-2" [routerLink]='["/"]'><i class="fe-facebook"></i></a><a
      class="social-btn sb-twitter sb-outline sb-lg mx-1 mb-2" [routerLink]='["/"]'><i class="fe-twitter"></i></a><a
      class="social-btn sb-instagram sb-outline sb-lg mx-1 mb-2" [routerLink]='["/"]'><i class="fe-instagram"></i></a><a
      class="social-btn sb-google sb-outline sb-lg mx-1 mb-2" [routerLink]='["/"]'><i class="fe-google"></i></a>
  </div>
