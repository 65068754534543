import { Component, OnInit,HostListener } from '@angular/core';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-header-light',
  templateUrl: './header-light.component.html',
  styleUrls: ['./header-light.component.css']
})
export class HeaderLightComponent implements OnInit {
  show: boolean = false;
  stuckBar: boolean = false;
 
  constructor(public authService:AuthService) { }

  ngOnInit(): void {
 
  }

  collapse(): void {
    this.show = !this.show;
  }

  @HostListener('window:scroll', ['$event.currentTarget'])
  onClick(e) {  
    if(e.scrollY > 600) {
      this.stuckBar=true;
    }else{
      this.stuckBar=false;
    }
  }

}
