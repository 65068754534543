<div id="profile-menu">
    <div class="navbar-tool dropdown">
      <a class="navbar-tool-icon-box" [routerLink]="['/dashboard-partner/profile-partner']">
        <img  class="navbar-tool-icon-box-img avatar_container" avatar_container [src]="authService.decodedTocken.certpublickey" alt="Avatar"/>
      
      </a>
      <a class="navbar-tool-label dropdown-toggle" [routerLink]="['/dashboard-partner/profile-partner']">
        <small translate>profile_partner_menu.title1</small>{{authService.decodedTocken.unique_name}}
      </a>
      <ul class="dropdown-menu dropdown-menu-right" style="width: 15rem;">
        <li><a class="dropdown-item d-flex align-items-center" [routerLink]="['/dashboard-partner/earnings-partner']" routerLinkActive="router-link-active" translate><i class="fe-dollar-sign font-size-lg opacity-60 mr-2"></i>profile_partner_menu.title2<span class="nav-indicator"></span><span class="ml-auto font-size-xs text-muted"></span></a></li>
        <li class="dropdown-divider"></li>
        <li><a class="dropdown-item d-flex align-items-center" [routerLink]="['/dashboard-partner/my-customers']" translate><i class="fe-users font-size-base opacity-60 mr-2"></i>profile_partner_menu.title3</a></li>
        <li class="dropdown-divider"></li>
        <li><a class="dropdown-item d-flex align-items-center"[routerLink]="['/dashboard-partner/my-requests']" translate><i class="fe-folder font-size-base opacity-60 mr-2"></i>profile_partner_menu.title4<span class="nav-indicator"></span><span class="ml-auto font-size-xs text-muted">1</span></a></li>
        <li class="dropdown-divider"></li>
        <li><a class="dropdown-item d-flex align-items-center" [routerLink]="['/dashboard-partner/link-request']" translate><i class="fe-mail font-size-base opacity-60 mr-2"></i>profile_partner_menu.title5<span class="ml-auto font-size-xs text-muted"></span></a></li>
        <li class="dropdown-divider"></li>        
        <li><a class="dropdown-item d-flex align-items-center" style="cursor: pointer;" (click)="authService.logout()" translate><i class="fe-log-out font-size-base opacity-60 mr-2"></i>profile_partner_menu.title6</a></li>
      </ul>
    </div>
</div>

