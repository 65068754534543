import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-header-dash',
  templateUrl: './header-dash.component.html',
  styleUrls: ['./header-dash.component.css']
})
export class HeaderDashComponent implements OnInit {

  show: boolean = false;
  
  ngOnInit(): void {   
  }

  collapse(): void {
    this.show = !this.show;
  }

}
