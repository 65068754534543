<div class="d-flex flex-column h-100 bg-light rounded-lg box-shadow-lg p-4">
  <div class="py-2 p-md-3">
    <!-- Title-->
    <h1 class="h3 mb-3 pb-2 text-center text-sm-left" translate>dashboard.payment_methods.title1</h1>
    <!-- Alert-->
    <div class="alert alert-info font-size-sm mb-4" role="alert" translate>
      <i class="fe-alert-circle font-size-lg mt-n1 mr-2"></i>dashboard.payment_methods.title2
    </div>
    <!-- Payment methods (table)-->
    <div *ngIf="paymentsMethods.spinner==false" class="table-responsive font-size-md">
      <table class="table table-hover mb-0">
        <thead>
          <tr>
            <th translate>dashboard.payment_methods.title3</th>
            <th translate>dashboard.payment_methods.title4</th>
            <th translate>dashboard.payment_methods.title5</th>
            <th translate>dashboard.payment_methods.title6</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let card of paymentsMethods.cards">
            <td class="py-3 align-middle">
              <div class="media align-items-center"><img class="mr-2" width="39" [src]="card.logo" alt="Visa" />
                <div class="media-body"><span class="font-weight-medium text-heading mr-1">{{card.brand}}</span><span translate>dashboard.payment_methods.title7</span>
                  {{card.last4}}
                  <!--span *ngIf="card.id==defaultCard" class="align-middle badge badge-info ml-2">Primary</span-->
                </div>
              </div>
            </td>
            <td class="py-3 align-middle">{{card.name}}</td>
            <td class="py-3 align-middle">{{card.expMonth}} / {{card.expYear}}</td>
            <td class="py-3 align-middle">
              <div class="custom-control custom-switch" (click)="paymentsMethods.changeDefaultCard(card.id)"
                style="cursor: pointer;">
                <input type="checkbox" id="'#'+'card.id'" [checked]="card.id==paymentsMethods.defaultCard"
                  class="custom-control-input" style="cursor: pointer;">
                <label class="custom-control-label"></label>
              </div>
            </td>
            <td class="py-3 align-middle" data-toggle="tooltip" title="Remove">
              <a class="nav-link-style text-danger" (click)="paymentsMethods.cardId = card.id"
                [href]="(card.id!=paymentsMethods.defaultCard ? '#modal-delete' : '#')" data-toggle="modal">
                <div class="fe-trash-2"></div>
              </a>
            </td>
          </tr>

        </tbody>
      </table>
    </div>
    <div *ngIf="paymentsMethods.spinner==true" class="loading">
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <hr class="pb-4">
    <div class="text-sm-right"><a class="btn btn-primary" href="#new-card-modal" data-toggle="modal" translate>dashboard.payment_methods.btn01</a></div>
  </div>

</div>