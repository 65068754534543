import { Component, OnInit } from '@angular/core';
import {PartnerService} from '../../dashboard-admin/partner.service'
import {AuthService} from '../../auth/auth.service'

@Component({
  selector: 'app-my-requests',
  templateUrl: './my-requests.component.html',
  styleUrls: ['./my-requests.component.css']
})
export class MyRequestsComponent implements OnInit {
  spinner: boolean = false;
  orderBy:number=-1;
  alert: any={
    show:false,
    icon:"fe-bell",
    message:"Partner View",
    type:"success"
  }
  constructor(public partnerService: PartnerService, public authService: AuthService) { }

  ngOnInit(): void {
    this.spinner=true;
    this.partnerService.getMyRequests(parseInt(this.authService.decodedTocken.nameid),this.orderBy).subscribe(r => {
      this.partnerService.requests=r;     
      this.spinner=false;
    });
  }

  
  orderRequests(deviceValue): void{
    this.orderBy=deviceValue;
    this.spinner=true;
    this.partnerService.getMyRequests(parseInt(this.authService.decodedTocken.nameid),parseInt(this.orderBy.toString())).subscribe(r => {
      this.partnerService.requests=r;
      this.spinner=false;
    });
  }


}
