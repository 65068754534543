import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-content09',
  templateUrl: './content09.component.html',
  styleUrls: ['./content09.component.css']
})
export class Content09Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
      //put scroll on top
      window.scrollTo({top: 0, behavior: 'smooth'});
  }

}
