import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Customer } from "../Models/Customer";
import { environment } from "../../environments/environment";
import { Observable } from 'rxjs';
import { AppService } from '../app.service';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';

const API_URI = environment.backend_url;

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  decodedTocken:any;
  logued:boolean=false;
 
  constructor(private http: HttpClient, private appService:AppService, private router: Router ) { }

  signIn(customer: {emailAddress: string, password:string}): Observable<any> {

    return this.http.post(API_URI + '/api/auth/login', customer, { observe: 'response' });

  }

  register(customer: Customer): Observable<any> {
    return this.http.post(API_URI + '/api/auth/register', customer, { observe: 'response' });
  }

  logout(redirect: boolean = true): void {
    localStorage.removeItem('token');
    this.decodedTocken=null;
    this.logued=false;
    if(redirect){
      this.router.navigate(['/']);
    }  

  }

  public isAuthenticated(): boolean {
    let helper = new JwtHelperService();   
    const token = localStorage.getItem('token');
    // Check whether the token is expired and return
    // true or false
    var decodedTocken=helper.decodeToken(token);    
    if(decodedTocken && decodedTocken.role=="Customer"){
      if(window.location.href.includes('q-invoice') && decodedTocken.actort == "None"){
        return false;
      }
      return !helper.isTokenExpired(token);
    }else{
      return false;
    }
    
  }  


}
