import { Component, OnInit } from '@angular/core';
import { PartnerService } from '../../dashboard-admin/partner.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-link-request-done',
  templateUrl: './link-request-done.component.html',
  styleUrls: ['./link-request-done.component.css']
})
export class LinkRequestDoneComponent implements OnInit {
  confirmedView:boolean;
  constructor(private partnerService: PartnerService, private activeRoute: ActivatedRoute) { }

  ngOnInit(): void {
    
    if(this.activeRoute.snapshot.queryParams.requestId){     
      this.partnerService.linkRequestAndPartner(this.activeRoute.snapshot.queryParams.token, parseInt(this.activeRoute.snapshot.queryParams.requestId),parseInt(this.activeRoute.snapshot.queryParams.partnerId)).subscribe(r=>{       
        if(r.statusCode!=201){
          this.confirmedView=false;
        }else{
          this.confirmedView=true;
        }        
      });
    }else{
      this.confirmedView=false;
    }
  }

}
