import { Component, OnInit } from '@angular/core';
import { CustomerService } from '.././dashboard/customer.service'
import { AlertService } from '../alert/alert.service'
import { FormGroup, FormBuilder, Validators, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.css']
})
export class ContactusComponent implements OnInit {

  requestForm: FormGroup;
  spinner:boolean=false;

  constructor(private fb: FormBuilder, public customerService:CustomerService, public alertService:AlertService) {
    this.requestForm = fb.group({    
      emailAddress: ['', [Validators.required, Validators.email]],   
      subject: ['', [Validators.required, Validators.required]],   
      message: ['', [Validators.required, Validators.required]],   
      fullName: ['', [Validators.required, Validators.required]],   
      phone: ['', [Validators.required, Validators.required]],   
      company: ['', [Validators.required, Validators.required]],     
    });
   }

  ngOnInit(): void {
     //put scroll on top
     window.scrollTo({top: 0, behavior: 'smooth'});
    
  }


  sendRequest(): void{
    this.requestForm.markAllAsTouched();
  
    if (this.requestForm.valid) {
      this.spinner = true;
      let values = this.requestForm.value;

      let data = {    
        fullName: values.fullName+" Phone: "+values.phone,   
        emailAddress: values.emailAddress,
        topic: values.subject,
        subject: "Message from customer",
        message: values.message,       
        phone: values.fullName,
               
      }
      
      this.customerService.sendQuestionEmail(data).subscribe(r => {
        
        if(r.statusCode==201){
          this.requestForm.reset();
          this.alertService.showAlert( "fe fe-bell", "Correo enviado satisfactoriamente.","Mail sent successfully.","success");
           
        }else{
          this.alertService.showAlert( "fe fe-alert-octagon","Error al enviar el mensaje.","Error sending message, try again.","danger");
  
        }
        this.spinner = false;
      });
    }
    
  }

}
