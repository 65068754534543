import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Admin } from "../Models/Admin";
import { environment } from "../../environments/environment";
import { Observable } from 'rxjs';
import { AppService } from '../app.service';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';

const API_URI = environment.backend_url;

@Injectable({
  providedIn: 'root'
})
export class AuthAdminService {
  decodedTocken:any;
  logued:boolean=false;

  constructor(private http: HttpClient, private appService:AppService, private router: Router ) { }

  signIn(admin: {emailAddress: string, password:string}): Observable<any> {

    return this.http.post(API_URI + '/api/authAdmin/login', admin, { observe: 'response' });

  }

  register(admin: Admin): Observable<any> {
    return this.http.post(API_URI + '/api/authAdmin/register', admin, { observe: 'response' });
  }

  public isAuthenticated(): boolean {
    let helper = new JwtHelperService();   
    const token = localStorage.getItem('token');
    // Check whether the token is expired and return
    // true or false
    var decodedTocken=helper.decodeToken(token);   
    if(decodedTocken && decodedTocken.role=="Admin"){
      return !helper.isTokenExpired(token);
    }else{
      return false;
    }
    
  }

  logout(redirect: boolean = true): void {
    localStorage.removeItem('token');
    this.decodedTocken=null;
    this.logued=false;
    if(redirect){
      this.router.navigate(['/']);
    }  

  }

}
