<div class="container-fluid ">
    <!--Profile menu on phone view-->
    <app-admin-phone-menu></app-admin-phone-menu>
    <div class="row page-title">
        <div class="col-12">
            <h4 class="page-title">Dasboard/Customers</h4>
        </div>
        <!-- Headers info -->
        <div class="col-12">
            <div class="row">
                <div class="col-xl-3 col-md-6">
                    <div class="card widget-inline border-left-primary shadow h-100 py-2">
                        <div class="card-body">
                            <div class="row no-gutters align-items-center">
                                <div class="col mr-2">
                                    <div class="text-xs font-weight-bold text-purple text-uppercase mb-1"
                                        (click)="filterCustomer(0)" style="cursor: pointer;">All Customers
                                    </div>
                                    <div *ngIf="spinnerHeaders==false" class="h5 mb-0 font-weight-bold text-gray-800">
                                        {{headers.allCustomers}}
                                    </div>
                                    <div *ngIf="spinnerHeaders==true" class="">
                                        <div class="spinner-border text-muted" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-auto"><i class="fe-briefcase text-muted fe-2x"></i></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-md-6">
                    <div class="card  widget-inline border-left-danger shadow h-100 py-2">
                        <div class="card-body">
                            <div class="row no-gutters align-items-center">
                                <div class="col mr-2">
                                    <div class="text-xs font-weight-bold text-danger text-uppercase mb-1"
                                        (click)="filterCustomer(1)" style="cursor: pointer;">Unconfirmed
                                        (Email)
                                    </div>
                                    <div *ngIf="spinnerHeaders==false" class="h5 mb-0 font-weight-bold text-gray-800">
                                        {{headers.unconfirmed}}
                                    </div>
                                    <div *ngIf="spinnerHeaders==true" class="">
                                        <div class="spinner-border text-muted" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-auto"><i class="fe-alert-triangle text-muted fe-2x"></i></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-md-6">
                    <div class="card widget-inline border-left-info shadow h-100 py-2">
                        <div class="card-body">
                            <div class="row no-gutters align-items-center">
                                <div class="col mr-2">
                                    <div class="text-xs font-weight-bold text-info text-uppercase mb-1"
                                        (click)="filterCustomer(2)" style="cursor: pointer;">Confirmed
                                    </div>
                                    <div *ngIf="spinnerHeaders==false" class="h5 mb-0 font-weight-bold text-gray-800">
                                        {{headers.confirmed}}
                                    </div>
                                    <div *ngIf="spinnerHeaders==true" class="">
                                        <div class="spinner-border text-muted" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-auto"><i class="fe-mail text-muted fe-2x"></i></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-md-6">
                    <div class="card  widget-inline border-left-warning shadow h-100 py-2">
                        <div class="card-body">
                            <div class="row no-gutters align-items-center">
                                <div class="col mr-2">
                                    <div class="text-xs font-weight-bold text-warning text-uppercase mb-1"
                                        (click)="filterCustomer(3)" style="cursor: pointer;">No Stripe
                                        Account</div>
                                    <div *ngIf="spinnerHeaders==false" class="h5 mb-0 font-weight-bold text-gray-800">
                                        {{headers.noStripe}}</div>
                                    <div *ngIf="spinnerHeaders==true" class="">
                                        <div class="spinner-border text-muted" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-auto"><i class="fe-folder-minus text-muted fe-2x"></i></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--Table-->
        <div class="col-12">
            <div class="card card-partners">
                <div class="card-body">
                    <div class="float-right">
                        <input type="text" class="form-control custom-select custom-select-sm" [(ngModel)]="search"
                            (keyup)="searchCustomer($event)" placeholder="search">
                    </div>
                    <h4 class="header-title mb-3">Customers</h4>
                    <div *ngIf="customerService.spinner==false" class="table-responsive">
                        <table class="table table-centered table-nowrap table-hover mb-0">
                            <tbody>
                                <tr *ngFor="let item of customerService.customers">
                                    <td>
                                        <img [src]="item.avatar"
                                            class="navbar-tool-icon-box-img avatar_container icon-box-img" alt="Icon"
                                            width="50">
                                    </td>
                                    <td>
                                        <h5 class="font-14 my-1"><a href="javascript:void(0);"
                                                class="text-body">{{item.fullName}}</a>
                                        </h5>
                                        <span class="text-muted font-13">Start on: {{item.createdAt}}</span>
                                    </td>
                                    <td><span class="text-muted font-13">Email
                                            Status</span><br><span
                                            [class.badge-success-lighten]="item.emailStatus=='confirmed'"
                                            class="badge badge-danger-lighten">{{(item.emailStatus=="confirmed"?'Confirmed'
                                            :'Unconfirmed')}}</span>
                                    </td>
                                    <td><span class="text-muted font-13">Phone</span>

                                        <h5 class="font-14 my-1"><a class="text-body">{{item.phone}}</a>
                                        </h5>
                                    </td>
                                    <td><span class="text-muted font-13">Stripe Id</span>

                                        <h5 class="font-14 my-1"><a class="text-body">{{(item.stripeCustomerId==null
                                                ?'Null':item.stripeCustomerId)}}</a>
                                        </h5>
                                    </td>

                                    <td class="py-3 align-middle">
                                        <div class="btn-group font-size-sm text-center bt-menu">
                                            <button type="button"
                                                class="btn btn-primary btn-sm btn-menu mt-2 dropdown dropdown-toggle navbar"
                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <i class="fe-align-justify text-white "></i>
                                            </button>
                                            <div class="dropdown-menu dropdown-menu-right">
                                                <div id="settings-menu">
                                                    <div class="dropdown-divider"></div>
                                                    <div>
                                                        <a href="javascript: void(0);" class="action-icon"><i
                                                                class="fe-mail text-muted fe-1x" data-toggle="tooltip"
                                                                title="Send email"></i></a>
                                                    </div>
                                                    <div class="dropdown-divider"></div>
                                                    <div>
                                                        <a (click)="customerId = item.id" href="#modal-delete"
                                                            data-toggle="modal" class="action-icon">
                                                            <i class="fe fe-trash-2 fe-1x text-muted"
                                                                data-toggle="tooltip" title="Remove"></i>
                                                        </a>

                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                    <div *ngIf="customerService.spinner==true" class="loading">
                        <div class="spinner-border text-primary" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>


                    <!--Pagination-->

                    <hr><br>
                    <nav aria-label="Page navigation example">
                        <ul class="pagination justify-content-center">
                            <li class="page-item disabled">
                                <a class="page-link" href="#" tabindex="-1" aria-disabled="true">Previous</a>
                            </li>
                            <li class="page-item"><a class="page-link" href="#">1</a></li>
                            <li class="page-item"><a class="page-link" href="#">2</a></li>
                            <li class="page-item"><a class="page-link" href="#">3</a></li>
                            <li class="page-item">
                                <a class="page-link" href="#">Next</a>
                            </li>
                        </ul>
                    </nav>
                    <!--End pagination-->
                </div>

                <!-- Remove customer Modal-->
                <div class="modal fade" id="modal-delete" tabindex="-1">
                    <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content border-0">
                            <div class="cs-view show" id="modal-heroku-view">
                                <div class="modal-header bg-danger border-1 px-4">
                                    <h3 class="text-white">Remove</h3>
                                    <button class="close text-white" type="button" data-dismiss="modal"
                                        aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                </div>

                                <div class="modal-body px-4">
                                    <div class="container">
                                        <h3>Are you sure you want remove this customer?</h3>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button class="btn btn ajax-button btn-danger btn-size" type="button"
                                        (click)="deleteCustomer()">
                                        <span *ngIf="!spinnerBtn">Remove</span>
                                        <div *ngIf="spinnerBtn" class="btn-spinner">
                                            <div class="spinner-border spinner-border-sm mr-1" role="status">
                                                <span class="sr-only">Loading...</span>
                                            </div>
                                            <div class="spinner-grow spinner-grow-sm mr-1" role="status">
                                                <span class="sr-only">Loading...</span>
                                            </div>
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>