import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Customer } from "../Models/Customer";
import { environment } from "../../environments/environment";
import { Observable } from 'rxjs';
import { AppService } from '../app.service';
import { Router } from '@angular/router';



const API_URI = environment.backend_url;

@Injectable({
  providedIn: 'root'
})
export class PriceService {

  constructor(private http: HttpClient, private appService:AppService, private router: Router ) { }


  createRequest(data: {emailAddress: string, plan: string, product: string, price:number}): Observable<any> {

    return this.http.post(API_URI + '/api/requests/newrequest', data, { observe: 'response' });

  }
}
