<div class="card card-partners">
  <div class="card-body">
    <div class="dropdown float-right"><a href="#" data-toggle="dropdown" aria-expanded="false"
        class="dropdown-toggle arrow-none card-drop"><i class="mdi mdi-dots-vertical"></i></a>
      <div class="dropdown-menu dropdown-menu-right"><a href="javascript:void(0);" class="dropdown-item">Weekly
          Report</a><a href="javascript:void(0);" class="dropdown-item">Monthly
          Report</a><a href="javascript:void(0);" class="dropdown-item">Action</a><a href="javascript:void(0);"
          class="dropdown-item">Settings</a></div>
    </div>
    <h4 class="header-title mb-3">Partners</h4>
    <div *ngIf="partnerService.spinner==false" class="table-responsive">
      <table class="table table-centered table-nowrap table-hover mb-0">
        <tbody>
          <tr *ngFor="let item of partnerService.partners">
            <td>
              <h5 class="font-14 my-1"><a href="javascript:void(0);" class="text-body">{{item.fullName}}</a>
              </h5>
              <span class="text-muted font-13">Start on: {{item.createdAt}}</span>
            </td>
            <td><span class="text-muted font-13">Email
                Status</span><br><span [class.badge-success-lighten]="item.emailStatus=='confirmed'"
                class="badge badge-danger-lighten">{{(item.emailStatus=="confirmed"?'Confirmed' :'Unconfirmed')}}</span>
            </td>
            <td><span class="text-muted font-13">Phone</span>

              <h5 class="font-14 my-1"><a class="text-body">{{item.phone}}</a>
              </h5>
            </td>
            <td class="py-3 align-middle">
              <div class="btn-group font-size-sm text-center bt-menu">
                <button type="button" class="btn btn-primary btn-sm btn-menu mt-2 dropdown dropdown-toggle navbar"
                  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <i class="fe-align-justify text-white "></i>
                </button>
                <div class="dropdown-menu dropdown-menu-right">
                  <div id="settings-menu">
                    <div>
                      <a (click)="partnerId = item.id" href="#modal-new-compensation" data-toggle="modal"
                        class="action-icon mr-2"><i data-toggle="tooltip" title="Create Compensation"
                          class="fe fe-settings text-muted fe-1x"></i>
                      </a>
                    </div>
                    <div class="dropdown-divider"></div>
                    <div>
                      <a href="javascript: void(0);" class="action-icon"><i class="fe-mail text-muted fe-1x"
                          data-toggle="tooltip" title="Send email"></i></a>
                    </div>
                    <div class="dropdown-divider"></div>
                    <div>
                      <a (click)="partnerId = item.id" href="#modal-delete" data-toggle="modal" class="action-icon"><i
                          data-toggle="tooltip" title="Remove" class="fe fe-trash-2 text-muted fe-1x"></i>
                      </a>
                    </div>
                  </div>

                </div>
              </div>

            </td>
          </tr>

        </tbody>
      </table>
    </div>
    <div *ngIf="partnerService.spinner==true" class="loading">
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>


    <!--Pagination-->

    <hr><br>
    <nav aria-label="Page navigation example">
      <ul class="pagination justify-content-center">
        <li class="page-item disabled">
          <a class="page-link" href="#" tabindex="-1" aria-disabled="true">Previous</a>
        </li>
        <li class="page-item"><a class="page-link" href="#">1</a></li>
        <li class="page-item"><a class="page-link" href="#">2</a></li>
        <li class="page-item"><a class="page-link" href="#">3</a></li>
        <li class="page-item">
          <a class="page-link" href="#">Next</a>
        </li>
      </ul>
    </nav>
    <!--End pagination-->
  </div>

  <!-- Remove partner Modal-->
  <div class="modal fade" id="modal-delete" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content border-0">
        <div class="cs-view show" id="modal-heroku-view">
          <div class="modal-header bg-danger border-1 px-4">
            <h3 class="text-white">Remove</h3>
            <button class="close text-white" type="button" data-dismiss="modal" aria-label="Close"><span
                aria-hidden="true">&times;</span></button>
          </div>

          <div class="modal-body px-4">
            <div class="container">
              <h3>Are you sure you want remove this partner?</h3>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn ajax-button btn-danger btn-size" type="button" (click)="deletePartner()">
              <span *ngIf="!spinnerBtn">Remove</span>
              <div *ngIf="spinnerBtn" class="btn-spinner">
                <div class="spinner-border spinner-border-sm mr-1" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow spinner-grow-sm mr-1" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- New Compensation Modal-->
  <div class="modal fade" id="modal-new-compensation" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content border-0">
        <div class="cs-view show" id="modal-heroku-view">
          <div class="modal-header bg-primary border-1 px-4">
            <h3 class="text-white">New Compensation</h3>
            <button class="close text-white" type="button" data-dismiss="modal" aria-label="Close"><span
                aria-hidden="true">&times;</span></button>
          </div>

          <div class="modal-body px-4">
            <div class="container row">
              <div class="col-xl-12 col-md-6">
                <div class="card widget-inline border-left-success shadow h-100 py-2">
                  <div class="card-body">
                    <div class="row no-gutters align-items-center">
                      <div class="col mr-2">
                        <div class="text-xs font-weight-bold text-dark text-uppercase mb-1">Select date range
                          <mat-form-field appearance="fill" style="z-index: 50000;">
                            <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
                              <input matStartDate formControlName="start" placeholder="Start date">
                              <input matEndDate formControlName="end" (dateChange)="changeDateRange()"
                                placeholder="End date">
                            </mat-date-range-input>
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-date-range-picker #picker color="warn"></mat-date-range-picker>

                            <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date
                            </mat-error>
                            <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date
                            </mat-error>
                          </mat-form-field>
                        </div>
                        <div class="row no-gutters align-items-center">

                        </div>
                      </div>
                      <div class="col-auto"><i class="fa fa-file-text-o fa-2x text-gray-300"></i></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn ajax-button btn-primary btn-size" type="button" (click)="createCompensation()">
              <span *ngIf="!spinnerBtn">Create</span>
              <div *ngIf="spinnerBtn" class="btn-spinner">
                <div class="spinner-border spinner-border-sm mr-1" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow spinner-grow-sm mr-1" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>