<div class="d-flex flex-column h-100 bg-light rounded-lg box-shadow-lg p-4">
    <div class="py-2 p-md-3">
      <!-- Title + Delete link-->
      <div class="d-sm-flex align-items-center justify-content-between pb-4 text-center text-sm-left">
        <h1 class="h3 mb-2 text-nowrap">Profile info</h1><a
          class="btn btn-link text-danger font-weight-medium btn-sm mb-2" href="#"><i
            class="fe-trash-2 font-size-base mr-2"></i>Delete account </a>
      </div>
  
      <!-- Content-->
      <form [formGroup]="profileForm" (ngSubmit)="updateProfile()" class="needs-validation" novalidate>  
        <div class="bg-secondary rounded-lg p-4 mb-4">
          <div class="media d-block d-sm-flex align-items-center">
            <img class="d-block rounded-circle mx-auto mb-3 mb-sm-0 avatar_container" [src]="avatar" alt="Avatar" />
            <div class="media-body pl-sm-3 text-center text-sm-left">
              <div class="row">
                <div class="col-md-9">
                  <label class="custom-file-upload">
                    <input type="file" (change)="previewFile($event)" />
                    <i class="fa fa-cloud-upload"></i>
                    <span *ngIf="!spin">Upload Image</span>
                    <div *ngIf="spin" class="btn-spinner">
                      <div class="spinner-border spinner-border-sm mr-1" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                      <div class="spinner-grow spinner-grow-sm mr-1" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
  
  
              <div class="p mb-0 font-size-ms text-muted">Upload JPG, GIF or PNG image. 300 x 300 required.</div>
            </div>
          </div>
        </div>
  
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group">
              <label for="account-country">Full Name</label>
              <input formControlName="fullName"
                [ngClass]="{'is-invalid': profileForm.controls.fullName.errors && (profileForm.controls.fullName.touched || profileForm.controls.fullName.dirty) }"
                class="form-control" type="text" placeholder="Text">
              <div class="invalid-feedback ml-2">
                Full name is required.
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <!-- Email Input -->
            <div class="form-group">
              <label for="account-country">Email</label>
              <input disabled
                [ngClass]="{'is-invalid': profileForm.controls.emailAddress.errors && (profileForm.controls.emailAddress.touched || profileForm.controls.emailAddress.dirty) }"
                formControlName="emailAddress" class="form-control" type="text" placeholder="Email">
              <div *ngIf="profileForm.controls.emailAddress.errors?.required" class="invalid-feedback ml-2">
                Email address is required.
              </div>
              <div
                *ngIf="profileForm.controls.emailAddress.errors?.email && !profileForm.controls.emailAddress.errors?.required"
                class="invalid-feedback ml-2">
                Invalid email address.
              </div>
            </div>
  
          </div>
          <div class="col-sm-6">
            <!-- Phone Input -->
            <div class="form-group">
              <label for="account-country">Phone</label>
              <input formControlName="phone" (keyup)="onKeyPhone($event)"
                [ngClass]="{'is-invalid': profileForm.controls.phone.errors && (profileForm.controls.phone.touched || profileForm.controls.phone.dirty) }"
                class="form-control" type="text" placeholder="Text">
              <div *ngIf="profileForm.controls.phone.errors?.required" class="invalid-feedback ml-2">
                Phone number is required.
              </div>
              <div *ngIf="profileForm.controls.phone.errors?.invalid && !profileForm.controls.phone.errors?.required"
                class="invalid-feedback ml-2">
                Invalid phone number.
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group">
              <label for="account-country">Address</label>
              <input formControlName="address"
                [ngClass]="{'is-invalid': profileForm.controls.address.errors && (profileForm.controls.address.touched || profileForm.controls.address.dirty) }"
                class="form-control" type="text" placeholder="Text">
              <div class="invalid-feedback ml-2">
                Address is required.
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group">
              <label for="account-country">City</label>
              <input formControlName="city"
                [ngClass]="{'is-invalid': profileForm.controls.city.errors && (profileForm.controls.city.touched || profileForm.controls.city.dirty) }"
                class="form-control" type="text" placeholder="Text">
              <div class="invalid-feedback ml-2">
                City is required.
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group">
              <label for="account-country">State</label>
              <input formControlName="state"
                [ngClass]="{'is-invalid': profileForm.controls.state.errors && (profileForm.controls.state.touched || profileForm.controls.state.dirty) }"
                class="form-control" type="text" placeholder="Text">
              <div class="invalid-feedback ml-2">
                State is required.
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group">
              <label for="account-country">Zip Code</label>
              <input formControlName="zipCode"
                [ngClass]="{'is-invalid': profileForm.controls.zipCode.errors && (profileForm.controls.zipCode.touched || profileForm.controls.zipCode.dirty) }"
                class="form-control" type="text" placeholder="Text">
              <div class="invalid-feedback ml-2">
                Zip code is required.
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group">
              <label for="account-country">Country</label>
              <select formControlName="country" class="custom-select" id="account-country"
                [ngClass]="{'is-invalid': profileForm.controls.country.errors && (profileForm.controls.country.touched || profileForm.controls.country.dirty) }"
                placeholder="Text">
                <option value>Select country</option>
                <option value="Argentina">Argentina</option>
                <option value="Belgium">Belgium</option>
                <option value="France">France</option>
                <option value="Germany">Germany</option>
                <option value="Madagascar">Madagascar</option>
                <option value="Spain">Spain</option>
                <option value="UK">United Kingdom</option>
                <option value="USA">USA</option>
              </select>
              <div class="invalid-feedback ml-2">
                Country is required.
              </div>
            </div>
          </div>
          <div class="col-12">
            <hr class="mt-2 mb-4">
            <div class="d-flex flex-wrap justify-content-between align-items-center">
              <div class="custom-control custom-checkbox d-block">
                <input class="custom-control-input" type="checkbox" id="show-email" checked disabled>
                <label class="custom-control-label" for="show-email">Receive my statements by email</label>
              </div>
              <button class="btn btn-primary mt-3 mt-sm-0" id="profile-btn" type="submit">
                <i *ngIf="!spinner" class="fe-save font-size-lg mr-2"></i>
                <span *ngIf="!spinner">Save changes</span>
                <div *ngIf="spinner" class="btn-spinner">
                  <div class="spinner-border spinner-border-sm mr-1" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                  <div class="spinner-grow spinner-grow-sm mr-1" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              </button>
  
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>

