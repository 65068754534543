import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { AuthAdminService } from '../auth-admin.service';
import { AuthService } from '../../auth/auth.service';
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { JwtHelperService } from '@auth0/angular-jwt';

@Component({
  selector: 'app-login-admin',
  templateUrl: './login-admin.component.html',
  styleUrls: ['./login-admin.component.css'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('200ms', style({ opacity: 1 })),
      ]),

    ]),
  ]
})
export class LoginAdminComponent implements OnInit {
  signIn: boolean = true;

  loginForm: FormGroup;

  spinner: boolean = false;

  options = {};

  mywindows: any = window;
  constructor(private fb: FormBuilder, private authAdminService: AuthAdminService,private authService: AuthService, private router: Router, private appService: AppService) { 
    this.loginForm = fb.group({
      emailAddress: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.minLength(6), Validators.required]]
    });
  }

  ngOnInit(): void {
  }

  submitLogin(): void {
    //console.log(this.loginForm);
    //this.authService.getTime();
  }

  changeView(): void {
    this.signIn = !this.signIn;
    window.scrollTo({ top: 0 });
  }

  handleAddressChange(e) {
    console.log(e);
  }

  
  login(): void {
    this.loginForm.markAllAsTouched();
    this.authService.logout(false);
    if (this.loginForm.valid) {
      this.spinner = true;
      this.authAdminService.signIn(this.loginForm.value).subscribe(v => {
        localStorage.setItem('token', v.body.token);
        this.spinner = false;

        let helper = new JwtHelperService();  
        this.authService.decodedTocken=helper.decodeToken(v.body.token);
        this.authService.logued=!helper.isTokenExpired(localStorage.getItem('token'));           

        this.router.navigate(['/dashboard-admin']);

      }, error => {
        if (error.status == '401') {
          console.log(error);
          this.loginForm.setErrors({ invalidCredentials: true });
        } else {
          console.log(error);
        }
        this.spinner = false;
      });
    } else {
      console.log(this.loginForm);
    }
  }  


}
