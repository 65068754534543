import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-content06',
  templateUrl: './content06.component.html',
  styleUrls: ['./content06.component.css']
})
export class Content06Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
      //put scroll on top
      window.scrollTo({top: 0, behavior: 'smooth'});
  }

}
