<!--Quisoft commerce-->
 <section class="prc-table">
    <div class="container">
      <div class='row mt-4'>
        <div class="col text-center">
          <h2 class="" translate>price_qcommerce.title</h2>

        </div>
      </div>
      <div class="row mt-4">

        <div class="col">
          <table class="table table-hover pricing-table table-responsive-md">
            <thead>
              <tr>
                <th scope="col">
                  <img class="d-inline-block"  width="260px" src="assets/img/homepage/netbag_dark.png" alt="Business people" />
           
                </th>
                <th scope="col">
                  <span class="h4" translate>price_qcommerce.plan01.name</span>
                  <div class="d-flex pt-md-3">
                    <span class="h3">$</span>
                    <span class="display-4">199</span>
                  </div>
                  <span class="text-small text-muted" translate>price_qcommerce.plan01.text1</span>
                  <a (click)="navigateTo(0)" class="btn d-block mt-4 btn-outline-primary btn-price">
                    <span *ngIf="!spinBasic" translate>price_qcommerce.plan01.btn01</span>
                    <div *ngIf="spinBasic" class="btn-spinner">
                      <div class="spinner-border spinner-border-sm mr-1" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                      <div class="spinner-grow spinner-grow-sm mr-1" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>        
                    </div> 
                  </a>
                </th>
                <th scope="col">
                  <span class="h4" translate>price_qcommerce.plan02.name</span>
                  <div class="d-flex pt-md-3">
                    <span class="h3">$</span>
                    <span class="display-4">299</span>
                  </div>
                  <span class="text-small text-muted" translate>price_qcommerce.plan02.text1</span>
                  <a (click)="navigateTo(1)"  class="btn d-block mt-4 btn-primary btn-price">
                    <span *ngIf="!spinPro" translate>price_qcommerce.plan02.btn01</span>
                    <div *ngIf="spinPro" class="btn-spinner">
                      <div class="spinner-border spinner-border-sm mr-1" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                      <div class="spinner-grow spinner-grow-sm mr-1" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>        
                    </div> 
                  </a>
                </th>
                <th scope="col">
                  <span class="h4" translate>price_qcommerce.plan03.name</span>
                  <div class="d-flex pt-md-3">
                    <span class="h3">$</span>
                    <span class="display-4">389</span>
                  </div>
                  <span class="text-small text-muted" translate>price_qcommerce.plan03.text1</span>
                  <a (click)="navigateTo(2)"  class="btn d-block mt-4 btn-outline-primary btn-price">
                    <span *ngIf="!spinUltimate"  translate>price_qcommerce.plan03.btn01 </span>
                    <div *ngIf="spinUltimate" class="btn-spinner">
                      <div class="spinner-border spinner-border-sm mr-1" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                      <div class="spinner-grow spinner-grow-sm mr-1" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>        
                    </div>     
                  </a>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">
                  <span class="h6 mb-0 d-block" translate>price_qcommerce.row1.name</span>
                  <span class="text-small text-muted" translate>price_qcommerce.row1.text1</span>
                </th>
                <td>
                  <span translate>price_qcommerce.row1.col01</span>
                </td>
                <td>
                  <span translate>price_qcommerce.row1.col02</span>
                </td>
                <td style="vertical-align: middle;">
                  <span translate>price_qcommerce.row1.col03</span>
                </td>
              </tr>
              <tr>
                <th scope="row">
                  <span class="h6 mb-0 d-block" translate>price_qcommerce.row2.name</span>
                  <span class="text-small text-muted" translate>price_qcommerce.row2.text1</span>
                </th>
                <td>
                  <span translate>price_qcommerce.row2.col01</span>
                </td>
                <td>
                  <span translate>price_qcommerce.row2.col02</span>
                </td>
                <td style="vertical-align: middle;">
                  <span translate>price_qcommerce.row2.col03</span>
                </td>
              </tr>
              <tr>
                <th scope="row">
                  <span class="h6 mb-0 d-block" translate>price_qcommerce.row3.name</span>
                  <span class="text-small text-muted" translate>price_qcommerce.row3.text1</span>
                </th>
                <td>
                  <span translate>price_qcommerce.row3.col01</span>
                </td>
                <td>
                  <span translate>price_qcommerce.row3.col02</span>
                </td>
                <td style="vertical-align: middle;">
                  <span translate>price_qcommerce.row3.col03</span>
                </td>
              </tr>
              <tr>
                <th scope="row">
                  <span class="h6 mb-0 d-block" translate>price_qcommerce.row4.name</span>
                  <span class="text-small text-muted"></span>
                </th>
                <td>
                  <div class="icon-round icon-round-xs bg-success">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                      class="injected-svg icon bg-light" data-src="assets/img/icons/interface/check.svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink">
                      <path
                        d="M18.1206 5.4111C18.5021 4.92016 19.1753 4.86046 19.6241 5.27776C20.073 5.69506 20.1276 6.43133 19.746 6.92227L10.6794 18.5889C10.2919 19.0876 9.60523 19.1401 9.15801 18.7053L4.35802 14.0386C3.91772 13.6106 3.87806 12.8732 4.26944 12.3916C4.66082 11.91 5.33503 11.8666 5.77533 12.2947L9.76023 16.1689L18.1206 5.4111Z"
                        fill="#212529"></path>
                    </svg>
                  </div>
                </td>
                <td>
                  <div class="icon-round icon-round-xs bg-success">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                      class="injected-svg icon bg-light" data-src="assets/img/icons/interface/check.svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink">
                      <path
                        d="M18.1206 5.4111C18.5021 4.92016 19.1753 4.86046 19.6241 5.27776C20.073 5.69506 20.1276 6.43133 19.746 6.92227L10.6794 18.5889C10.2919 19.0876 9.60523 19.1401 9.15801 18.7053L4.35802 14.0386C3.91772 13.6106 3.87806 12.8732 4.26944 12.3916C4.66082 11.91 5.33503 11.8666 5.77533 12.2947L9.76023 16.1689L18.1206 5.4111Z"
                        fill="#212529"></path>
                    </svg>
                  </div>
                </td>
                <td>
                  <div class="icon-round icon-round-xs bg-success">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                      class="injected-svg icon bg-light" data-src="assets/img/icons/interface/check.svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink">
                      <path
                        d="M18.1206 5.4111C18.5021 4.92016 19.1753 4.86046 19.6241 5.27776C20.073 5.69506 20.1276 6.43133 19.746 6.92227L10.6794 18.5889C10.2919 19.0876 9.60523 19.1401 9.15801 18.7053L4.35802 14.0386C3.91772 13.6106 3.87806 12.8732 4.26944 12.3916C4.66082 11.91 5.33503 11.8666 5.77533 12.2947L9.76023 16.1689L18.1206 5.4111Z"
                        fill="#212529"></path>
                    </svg>
                  </div>
                </td>
              </tr>
              <tr>
                <th scope="row">
                  <span class="h6 mb-0 d-block" translate>price_qcommerce.row5.name</span>
                  <span class="text-small text-muted"></span>
                </th>
                <td>
                  <div class="icon-round icon-round-xs bg-success">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                      class="injected-svg icon bg-light" data-src="assets/img/icons/interface/check.svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink">
                      <path
                        d="M18.1206 5.4111C18.5021 4.92016 19.1753 4.86046 19.6241 5.27776C20.073 5.69506 20.1276 6.43133 19.746 6.92227L10.6794 18.5889C10.2919 19.0876 9.60523 19.1401 9.15801 18.7053L4.35802 14.0386C3.91772 13.6106 3.87806 12.8732 4.26944 12.3916C4.66082 11.91 5.33503 11.8666 5.77533 12.2947L9.76023 16.1689L18.1206 5.4111Z"
                        fill="#212529"></path>
                    </svg>
                  </div>
                </td>
                <td>
                  <div class="icon-round icon-round-xs bg-success">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                      class="injected-svg icon bg-light" data-src="assets/img/icons/interface/check.svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink">
                      <path
                        d="M18.1206 5.4111C18.5021 4.92016 19.1753 4.86046 19.6241 5.27776C20.073 5.69506 20.1276 6.43133 19.746 6.92227L10.6794 18.5889C10.2919 19.0876 9.60523 19.1401 9.15801 18.7053L4.35802 14.0386C3.91772 13.6106 3.87806 12.8732 4.26944 12.3916C4.66082 11.91 5.33503 11.8666 5.77533 12.2947L9.76023 16.1689L18.1206 5.4111Z"
                        fill="#212529"></path>
                    </svg>
                  </div>
                </td>
                <td>
                  <div class="icon-round icon-round-xs bg-success">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                      class="injected-svg icon bg-light" data-src="assets/img/icons/interface/check.svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink">
                      <path
                        d="M18.1206 5.4111C18.5021 4.92016 19.1753 4.86046 19.6241 5.27776C20.073 5.69506 20.1276 6.43133 19.746 6.92227L10.6794 18.5889C10.2919 19.0876 9.60523 19.1401 9.15801 18.7053L4.35802 14.0386C3.91772 13.6106 3.87806 12.8732 4.26944 12.3916C4.66082 11.91 5.33503 11.8666 5.77533 12.2947L9.76023 16.1689L18.1206 5.4111Z"
                        fill="#212529"></path>
                    </svg>
                  </div>
                </td>
              </tr>
              <tr>
                <th scope="row">
                  <span class="h6 mb-0 d-block" translate>price_qcommerce.row6.name</span>
                  <span class="text-small text-muted"></span>
                </th>
                <td></td>
                <td>
                  <div class="icon-round icon-round-xs bg-success">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                      class="injected-svg icon bg-light" data-src="assets/img/icons/interface/check.svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink">
                      <path
                        d="M18.1206 5.4111C18.5021 4.92016 19.1753 4.86046 19.6241 5.27776C20.073 5.69506 20.1276 6.43133 19.746 6.92227L10.6794 18.5889C10.2919 19.0876 9.60523 19.1401 9.15801 18.7053L4.35802 14.0386C3.91772 13.6106 3.87806 12.8732 4.26944 12.3916C4.66082 11.91 5.33503 11.8666 5.77533 12.2947L9.76023 16.1689L18.1206 5.4111Z"
                        fill="#212529"></path>
                    </svg>
                  </div>
                </td>
                <td>
                  <div class="icon-round icon-round-xs bg-success">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                      class="injected-svg icon bg-light" data-src="assets/img/icons/interface/check.svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink">
                      <path
                        d="M18.1206 5.4111C18.5021 4.92016 19.1753 4.86046 19.6241 5.27776C20.073 5.69506 20.1276 6.43133 19.746 6.92227L10.6794 18.5889C10.2919 19.0876 9.60523 19.1401 9.15801 18.7053L4.35802 14.0386C3.91772 13.6106 3.87806 12.8732 4.26944 12.3916C4.66082 11.91 5.33503 11.8666 5.77533 12.2947L9.76023 16.1689L18.1206 5.4111Z"
                        fill="#212529"></path>
                    </svg>
                  </div>
                </td>
              </tr>
              <tr>
                <th scope="row">
                  <span class="h6 mb-0 d-block" translate>price_qcommerce.row7.name</span>
                  <span class="text-small text-muted"></span>
                </th>
                <td>
                </td>
                <td>
                  <div class="icon-round icon-round-xs bg-success">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                      class="injected-svg icon bg-light" data-src="assets/img/icons/interface/check.svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink">
                      <path
                        d="M18.1206 5.4111C18.5021 4.92016 19.1753 4.86046 19.6241 5.27776C20.073 5.69506 20.1276 6.43133 19.746 6.92227L10.6794 18.5889C10.2919 19.0876 9.60523 19.1401 9.15801 18.7053L4.35802 14.0386C3.91772 13.6106 3.87806 12.8732 4.26944 12.3916C4.66082 11.91 5.33503 11.8666 5.77533 12.2947L9.76023 16.1689L18.1206 5.4111Z"
                        fill="#212529"></path>
                    </svg>
                  </div>
                </td>
                <td>
                  <div class="icon-round icon-round-xs bg-success">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                      class="injected-svg icon bg-light" data-src="assets/img/icons/interface/check.svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink">
                      <path
                        d="M18.1206 5.4111C18.5021 4.92016 19.1753 4.86046 19.6241 5.27776C20.073 5.69506 20.1276 6.43133 19.746 6.92227L10.6794 18.5889C10.2919 19.0876 9.60523 19.1401 9.15801 18.7053L4.35802 14.0386C3.91772 13.6106 3.87806 12.8732 4.26944 12.3916C4.66082 11.91 5.33503 11.8666 5.77533 12.2947L9.76023 16.1689L18.1206 5.4111Z"
                        fill="#212529"></path>
                    </svg>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="row">
        <div class="col text-center">
          <span style="font-size: 1.625rem;" translate>price_qcommerce.text2 <a [routerLink]='["/contactus"]'translate> price_qcommerce.text3</a>
          </span>
        </div>
      </div>
    </div>
  </section>

  <!--Phone view-->
  <section class="prc-block container mt-n10">
    <div class="w-lg-80 mx-lg-auto position-relative">
      <div class='row mt-4'>
        <div class="col text-center">
          <h2 class="" translate>price_qcommerce.title</h2>

        </div>
      </div>
      <div class="row position-relative z-index-2 mx-n2 mb-5">
        <!-- Basic -->
        <div class="col-sm-6 col-md-4 px-2 mb-3">
          <!-- Pricing -->
          <div class="card h-100">
            <!-- Header -->
            <div class="card-header text-center">
              <span class="d-block h3" translate>price_qcommerce.plan01.name</span>
              <span class="d-block mb-2">
                <span class="text-dark align-top">$</span>
                <span class="font-size-4 text-dark font-weight-bold mr-n2">
                  <span id="pricingCount1" data-hs-toggle-switch-item-options="{
                           &quot;min&quot;: 42,
                           &quot;max&quot;: 32
                         }">199</span>
                </span>
                <span class="font-size-1" translate>price_qcommerce.inner</span>
              </span>
            </div>
            <!-- End Header -->

            <!-- Body -->
            <div class="card-body">
              <div class="media font-size-1 text-body mb-3">
                <i class="fe-check-circle text-success mt-1 mr-2"></i>
                <div class="media-body" translate>
                  price_qcommerce.phone_view.plan01.text1                  
                </div>
              </div>
              <div class="media font-size-1 text-body mb-3">
                <i class="fe-check-circle text-success mt-1 mr-2"></i>
                <div class="media-body" translate>
                  price_qcommerce.phone_view.plan01.text2 
                </div>
              </div>
              <div class="media font-size-1 text-body mb-3">
                <i class="fe-check-circle text-success mt-1 mr-2"></i>
                <div class="media-body" translate>
                  price_qcommerce.phone_view.plan01.text3
                </div>
              </div>
              <div class="media font-size-1 text-body mb-3">
                <i class="fe-check-circle text-success mt-1 mr-2"></i>
                <div class="media-body" translate>
                  price_qcommerce.phone_view.plan01.text4 
                </div>
              </div>
              <div class="media font-size-1 text-body">
                <i class="fe-check-circle text-success mt-1 mr-2"></i>
                <div class="media-body" translate>
                  price_qcommerce.phone_view.plan01.text5
                </div>
              </div>
            </div>
            <!-- End Body -->

            <div class="card-footer border-0">
              <button type="button" (click)="navigateTo(0)" class="btn btn-soft-primary btn-block transition-3d-hover" >
                <span *ngIf="!spinBasic" translate>price_qcommerce.plan01.btn01</span>
                <div *ngIf="spinBasic" class="btn-spinner">
                  <div class="spinner-border spinner-border-sm mr-1" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                  <div class="spinner-grow spinner-grow-sm mr-1" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>        
                </div>     
              </button>
            </div>
          </div>
          <!-- End Pricing -->
        </div>
        <!-- Professional -->
        <div class="col-sm-6 col-md-4 px-2 mb-3">
          <!-- Pricing -->
          <div class="card bg-primary text-white h-100 shadow-primary-lg">
            <!-- Header -->
            <div class="card-header border-0 bg-primary text-white text-center">
              <span class="d-block h3 text-white" translate>price_qcommerce.plan02.name</span>
              <span class="d-block mb-2">
                <span class="text-white align-top">$</span>
                <span class="font-size-4 text-white font-weight-bold mr-n2">
                  <span id="pricingCount2" data-hs-toggle-switch-item-options="{
                           &quot;min&quot;: 64,
                           &quot;max&quot;: 54
                         }">299</span>
                </span>
                <span class="font-size-1" translate>price_qcommerce.inner</span>
              </span>
            </div>
            <!-- End Header -->

            <div class="border-top opacity-xs"></div>

            <!-- Body -->
            <div class="card-body">
              <div class="media font-size-1 mb-3">
                <i class="fe-check-circle mt-1 mr-2"></i>
                <div class="media-body" translate>
                  price_qcommerce.phone_view.plan02.text1 
                </div>
              </div>
              <div class="media font-size-1 mb-3">
                <i class="fe-check-circle mt-1 mr-2"></i>
                <div class="media-body" translate>
                  price_qcommerce.phone_view.plan02.text2
                </div>
              </div>
              <div class="media font-size-1 mb-3">
                <i class="fe-check-circle mt-1 mr-2"></i>
                <div class="media-body" translate>
                  price_qcommerce.phone_view.plan02.text3
                </div>
              </div>
              <div class="media font-size-1 mb-3">
                <i class="fe-check-circle mt-1 mr-2"></i>
                <div class="media-body" translate>
                  price_qcommerce.phone_view.plan02.text4 
                </div>
              </div>
              <div class="media font-size-1 mb-3">
                <i class="fe-check-circle mt-1 mr-2"></i>
                <div class="media-body" translate>
                  price_qcommerce.phone_view.plan02.text5
                </div>
              </div>
              <div class="media font-size-1 mb-3">
                <i class="fe-check-circle mt-1 mr-2"></i>
                <div class="media-body" translate>
                  price_qcommerce.phone_view.plan02.text6
                </div>
              </div>
              <div class="media font-size-1">
                <i class="fe-check-circle mt-1 mr-2"></i>
                <div class="media-body" translate>
                  price_qcommerce.phone_view.plan02.text7
                </div>
              </div>
            </div>
            <!-- End Body -->

            <div class="card-footer border-0 bg-primary text-white">
              <button type="button" (click)="navigateTo(1)" class="btn btn-light text-primary btn-block transition-3d-hover" >                
                <span *ngIf="!spinPro" translate>price_qcommerce.plan02.btn01</span>
                <div *ngIf="spinPro" class="btn-spinner">
                  <div class="spinner-border spinner-border-sm mr-1" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                  <div class="spinner-grow spinner-grow-sm mr-1" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>        
                </div>    
              </button>
            </div>
          </div>
          <!-- End Pricing -->
        </div>
        <!-- Ultimate -->
        <div class="col-sm-6 col-md-4 px-2 mb-3">
          <!-- Pricing -->
          <div class="card h-100">
            <!-- Header -->
            <div class="card-header text-center">
              <span class="d-block h3" translate>price_qcommerce.plan03.name</span>
              <span class="d-block mb-2">
                <span class="text-dark align-top">$</span>
                <span class="font-size-4 text-dark font-weight-bold mr-n2">
                  <span id="pricingCount3" data-hs-toggle-switch-item-options="{
                           &quot;min&quot;: 89,
                           &quot;max&quot;: 79
                         }">389</span>
                </span>
                <span class="font-size-1" translate>price_qcommerce.inner</span>
              </span>
            </div>
            <!-- End Header -->

           <!-- Body -->
           <div class="card-body">
            <div class="media font-size-1 mb-3">
              <i class="fe-check-circle mt-1 mr-2"></i>
              <div class="media-body" translate>
                price_qcommerce.phone_view.plan03.text1 
              </div>
            </div>
            <div class="media font-size-1 mb-3">
              <i class="fe-check-circle mt-1 mr-2"></i>
              <div class="media-body" translate>
                price_qcommerce.phone_view.plan03.text2
              </div>
            </div>
            <div class="media font-size-1 mb-3">
              <i class="fe-check-circle mt-1 mr-2"></i>
              <div class="media-body" translate>
                price_qcommerce.phone_view.plan03.text3
              </div>
            </div>
            <div class="media font-size-1 mb-3">
              <i class="fe-check-circle mt-1 mr-2"></i>
              <div class="media-body" translate>
                price_qcommerce.phone_view.plan03.text4
              </div>
            </div>
            <div class="media font-size-1 mb-3">
              <i class="fe-check-circle mt-1 mr-2"></i>
              <div class="media-body" translate>
                price_qcommerce.phone_view.plan03.text5
              </div>
            </div>
            <div class="media font-size-1 mb-3">
              <i class="fe-check-circle mt-1 mr-2"></i>
              <div class="media-body" translate>
                price_qcommerce.phone_view.plan03.text6
              </div>
            </div>
            <div class="media font-size-1">
              <i class="fe-check-circle mt-1 mr-2"></i>
              <div class="media-body" translate>
                price_qcommerce.phone_view.plan03.text7
              </div>
            </div>
          </div>
          <!-- End Body -->

            <div class="card-footer border-0">
              <button (click)="navigateTo(2)" type="button" class="btn btn-soft-primary btn-block transition-3d-hover">               
                <span *ngIf="!spinUltimate" translate>price_qcommerce.plan03.btn01</span>
                <div *ngIf="spinUltimate" class="btn-spinner">
                  <div class="spinner-border spinner-border-sm mr-1" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                  <div class="spinner-grow spinner-grow-sm mr-1" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>        
                </div>    
              </button>
            </div>
          </div>
          <!-- End Pricing -->
        </div>
      </div>

      <!-- Info -->
      <div class="position-relative z-index-2 text-center mb-4">
        <div class="d-inline-block font-size-1 border bg-white text-center rounded-pill py-3 px-4" translate>
            price_qcommerce.text2<a class="d-block d-sm-inline-block font-weight-bold ml-sm-3" [routerLink]="['/contactus']" routerLinkActive="router-link-active" translate> price_qcommerce.text3<span class="fas fa-angle-right ml-1"></span></a>
        </div>
      </div>
      <!-- End Info -->    
    </div>
  </section>

