

import { Component, OnInit } from '@angular/core';
import { tns } from "tiny-slider/src/tiny-slider";
import { CustomerService } from '.././dashboard/customer.service'
import { AlertService } from '../alert/alert.service'
import { FormGroup, FormBuilder, Validators, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';

declare var lightGallery: any;
@Component({
  selector: 'app-demo-rpcargo',
  templateUrl: './demo-rpcargo.component.html',
  styleUrls: ['./demo-rpcargo.component.css']
})
export class DemoRpcargoComponent implements OnInit {
  requestForm: FormGroup;
  spinner:boolean=false;

  constructor(private fb: FormBuilder, public customerService:CustomerService, public alertService:AlertService) { 
    this.requestForm = fb.group({    
      emailAddress: ['', [Validators.required, Validators.email]],     
    });
  }

  ngOnInit(): void {
      //put scroll on top
      window.scrollTo({ top: 0, behavior: 'smooth' });

      this.carousel();
  
      this.videoPopupBtn();
      
  }
  sendRequest(): void{
    this.requestForm.markAllAsTouched();
    
    if (this.requestForm.valid) {
      this.spinner = true;
      let values = this.requestForm.value;

      let data = {       
        topic: "Schedule a free consultation for customer",
        subject: "Consultation for customer",
        message: "A customer is requesting a free consultation from the web site to learn more about the NetBag-Manage demo",
        fullName: "NetBag-Manage Demo",
        emailAddress: values.emailAddress,        
      }
      
      this.customerService.sendQuestionEmail(data).subscribe(r => {
        
        if(r.statusCode==201){
          this.requestForm.reset();
          this.alertService.showAlert( "fe fe-bell", "Correo enviado satisfactoriamente.","Mail sent successfully.","success");
           
        }else{
          this.alertService.showAlert( "fe fe-alert-octagon","Error al enviar el mensaje.","Error sending message, try again.","danger");
  
        }
        this.spinner = false;
      });
    }
    
  }

  videoPopupBtn(): void {
 
    let button = document.querySelectorAll('.cs-video-btn');
    if (button.length) {
      for (let i = 0; i < button.length; i++) {
        lightGallery(button[i], {
          selector: 'this',
          download: false,
          videojs: true,
          youtubePlayerParams: {
            modestbranding: 1,
            showinfo: 0,
            rel: 0,
            controls: 0
          },
          vimeoPlayerParams: {
            byline: 0,
            portrait: 0,
            color: '766df4'
          }
        });
      }

    }
    
  }

  public carousel(): void {

    // forEach function
    let forEach = function (array, callback, scope?) {
      for (let i = 0; i < array.length; i++) {
        callback.call(scope, i, array[i]); // passes back stuff we need
      }
    };

    // Carousel initialisation
    let carousels = document.querySelectorAll('.cs-carousel .cs-carousel-inner');

    forEach(carousels, function (index, value) {
      let defaults = {
        container: value,
        controlsText: ['<i class="fe-arrow-left"></i>', '<i class="fe-arrow-right"></i>'],
        navPosition: 'top',
        controlsPosition: 'top',
        mouseDrag: true,
        speed: 600,
        autoplayHoverPause: true,
        autoplayButtonOutput: false,
        mode: 'gallery',
        nav: false
      };
      let userOptions;
      if (value.dataset.carouselOptions != undefined) userOptions = JSON.parse(value.dataset.carouselOptions);
      let options = { ...defaults, ...userOptions };
      let carousel = tns(options);

      let carouselWrapper = value.closest('.cs-carousel'),
        carouselItems = carouselWrapper.querySelectorAll('.tns-item');

      // Custom pager
      let pager = carouselWrapper.querySelector('.cs-carousel-pager');

      if (pager != undefined) {

        let pageLinks = pager.querySelectorAll('[data-goto]');

        for (let i = 0; i < pageLinks.length; i++) {
          pageLinks[i].addEventListener('click', function (e) {
            carousel.goTo(this.dataset.goto - 1);
            e.preventDefault();
          });
        }

        carousel.events.on('indexChanged', function () {
          let info: any = carousel.getInfo();

          console.log(info);

          for (let n = 0; n < pageLinks.length; n++) {
            pageLinks[n].classList.remove('active');
          }
          pager.querySelector('[data-goto="' + info.displayIndex + '"]').classList.add('active');
        });
      }

      // Change label text
      let labelElem = carouselWrapper.querySelector('.cs-carousel-label');

      if (labelElem != undefined) {

        carousel.events.on('indexChanged', function () {
          let info = carousel.getInfo(),
            labelText = carouselItems[info.index].dataset.carouselLabel;

          labelElem.innerHTML = labelText;
        });
      }

      // Progress + slide counter
      if (carouselWrapper.querySelector('.cs-carousel-progress') === null) return;

      let carouselInfo: any = carousel.getInfo(),
        carouselCurrentSlide = carouselWrapper.querySelector('.cs-current-slide'),
        carouselTotalSlides = carouselWrapper.querySelector('.cs-total-slides'),
        carouselProgress = carouselWrapper.querySelector('.cs-carousel-progress .progress-bar');

      carouselCurrentSlide.innerHTML = carouselInfo.displayIndex;
      carouselTotalSlides.innerHTML = carouselInfo.slideCount;
      carouselProgress.style.width = (carouselInfo.displayIndex / carouselInfo.slideCount) * 100 + '%';

      carousel.events.on('indexChanged', function () {
        let info: any = carousel.getInfo();
        carouselCurrentSlide.innerHTML = info.displayIndex;
        carouselProgress.style.width = (info.displayIndex / info.slideCount) * 100 + '%';
      });


    });

  }
}
