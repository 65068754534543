import { Component, OnInit } from '@angular/core';
import { Admin } from 'src/app/Models/Admin';
import { AdminService } from 'src/app/dashboard-admin/admin.service'
import { AuthService } from 'src/app/auth/auth.service';

import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { AsYouType } from 'libphonenumber-js'
import { parsePhoneNumber, ParseError } from 'libphonenumber-js'
import { FormGroup, FormBuilder, Validators, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { AppService } from 'src/app/app.service';

export function validPhoneNumber(country: any): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const phoneNumber = parsePhoneNumberFromString(control.value, country);
    return !phoneNumber?.isValid() ? { invalid: { value: control.value } } : null;
  };
}

@Component({
  selector: 'app-admin-profile-info',
  templateUrl: './admin-profile-info.component.html',
  styleUrls: ['./admin-profile-info.component.css']
})
export class AdminProfileInfoComponent implements OnInit {
  chosenFileName: any;
  image: any;
  admin: Admin;
  profileForm: FormGroup;
  spinner: boolean = false;
  spin: boolean = false;
  avatar: any = this.authService.decodedTocken.certpublickey;
  constructor(private adminService: AdminService, private authService: AuthService, private appService: AppService, private fb: FormBuilder) {

    this.profileForm = fb.group({
      fullName: ['', Validators.required],
      phone: [''],
      emailAddress: ['', [Validators.required, Validators.email]],
      address: ['', [Validators.required,]],
      city: ['', [Validators.required]],
      zipCode: ['', [Validators.required]],
      state: ['', [Validators.required]],
      country: ['', [Validators.required]]
    });

    this.appService.defaultCountry.subscribe(country => {
      this.profileForm.controls.phone.setValidators([Validators.required, validPhoneNumber(country)]);
    })
    
   }

  ngOnInit(): void {
    this.adminService.getAdminInfo(this.authService.decodedTocken.nameid).subscribe(v => {
      this.avatar = v.avatar;
      this.profileForm.patchValue({
        fullName: v.fullName,
        phone: v.phone,
        emailAddress: v.emailAddress,
        address: v.fullAddress,
        city: v.city,
        zipCode: v.zipCode,
        state: v.state,
        country: v.country,
      });
    });
  }

  updateProfile(): void {
    this.profileForm.markAllAsTouched();
    if (this.profileForm.valid) {

      this.spinner = true;
      let values = this.profileForm.value;

      let data = {
        id: parseInt(this.authService.decodedTocken.nameid),
        fullName: values.fullName,
        phone: values.phone,
        FullAddress: values.address,
        city: values.city,
        zipCode: values.zipCode,
        state: values.state,
        country: values.country
      }
      
      this.adminService.updateProfileInfo(this.authService.decodedTocken.nameid, data).subscribe(r => {
       
        if(r.token){
          localStorage.setItem('token',r.token);   
          this.authService.decodedTocken.unique_name = data.fullName      
        }
        this.spinner = false;
      });

    }   

  }

  onKeyPhone(e: KeyboardEvent): void {
    const mask = new AsYouType(this.appService.getCountry()).input(e.target['value']);
    if (e.keyCode >= 48 && e.keyCode <= 57 || this.profileForm.get('phone').valid) {
      this.profileForm.get('phone').setValue(mask);
    }
  }

  previewFile(e): void {
    this.spin=true;
    this.image = e.target.files[0]; this.chosenFileName = e.target.files[0].name;
    var reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = (_event) => {
      this.avatar = reader.result;  
      var oldAvatar:string="Quisoft/avatars/perfil_baggut123456.svg";
      var splitedAvatar=this.authService.decodedTocken.certpublickey.split("Quisoft/avatars/");
      if(splitedAvatar[1]!=undefined){
        oldAvatar="Quisoft/avatars/"+splitedAvatar[1].split('.')[0]; 
      }
      
      this.adminService.uploadAvatar(parseInt(this.authService.decodedTocken.nameid),oldAvatar,e.target.files[0].name, reader.result).subscribe(r => {
        
        if(r.token){
          localStorage.setItem('token',r.token);
          this.authService.decodedTocken.certpublickey=r.avatarUrl;
          this.avatar=r.avatarUrl;
        }
        this.spin=false;
      });

    }
  }

}
