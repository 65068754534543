<div class="d-flex flex-column h-100 bg-light rounded-lg box-shadow-lg p-4">
    <div class="pt-2 p-md-3">
      <!-- Title-->
      <h1 class="h3 mb-3 pb-2 text-center text-sm-left" translate>dashboard_partner.earnings.title1</h1>
      <!-- headers-->
      <div class="row mx-n2 py-2">
        <div class="col-md-4 col-sm-4 px-2 mb-3">
          <div class="bg-secondary h-100 rounded-lg p-4 text-center">
            <h3 class="font-size-sm font-weight-medium text-body" translate>dashboard_partner.earnings.title2</h3>
            <div *ngIf="spinnerHeaders==true" class="loading">
              <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            <p *ngIf="spinnerHeaders==false" class="h2 mb-2">${{headers.allEarnings}}</p>
            <p *ngIf="spinnerHeaders==false" class="font-size-ms text-muted mb-2"><span translate>dashboard_partner.earnings.title3</span> {{selectedYear}}</p>
           </div>
        </div>
        <div class="col-md-4 col-sm-4 px-2 mb-3">
          <div class="bg-secondary h-100 rounded-lg p-4 text-center">
            <h3 class="font-size-sm font-weight-medium text-body" translate>dashboard_partner.earnings.title4</h3>
            <div *ngIf="spinnerHeaders==true" class="loading">
              <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            <p *ngIf="spinnerHeaders==false" class="h2 mb-2">${{headers.lastPayment}}</p>
            <p *ngIf="spinnerHeaders==false" class="font-size-ms text-muted mb-2"><span translate>dashboard_partner.earnings.title5</span> {{headers.lastPaymentDate}}</p>
          </div>
        </div>
        <div class="col-md-4 col-sm-12 px-2 mb-3">
          <div class="bg-secondary h-100 rounded-lg p-4 text-center">
            <h3 class="font-size-sm font-weight-medium text-body" translate>dashboard_partner.earnings.title6</h3>
            <div *ngIf="spinnerHeaders==true" class="loading">
              <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            <p *ngIf="spinnerHeaders==false" class="h2 mb-2">${{partnerService.autopayBalance}}</p>
            <p *ngIf="spinnerHeaders==false" class="font-size-ms mb-2" ><span translate>dashboard_partner.earnings.title7</span> {{partnerService.autoPayDate}}</p>
            
          </div>
        </div>
      </div>
      <!-- Charts-->
      <div class="row mx-n2 pt-2">
        <div class="col-lg-12 px-2">
          <div class="card mb-4">
            <div class="card-body">
              <h3 class="font-size-sm pb-3 mb-3 border-bottom" translate>dashboard_partner.earnings.title8</h3>
              <div class="d-flex align-items-center mb-3">
                <label class="text-nowrap pr-1 mr-2 mb-0" translate>dashboard_partner.earnings.title9</label>
                <select class="form-control custom-select custom-select-sm" (change)="changeYear($event.target.value)"> 
                  <option (value)="currentYear">{{currentYear}}</option> 
                  <option (value)="currentYear-1">{{currentYear-1}}</option>           
                  <option (value)="currentYear-2">{{currentYear-2}}</option>                    
                </select>
              </div>
              <div *ngIf="spinnerChart==true" class="loading">
                <div class="spinner-border text-primary" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
              <div ng-show="spinnerChart==false" class="chart-area align-middle check-element animate-show-hide">
                <canvas id="chartDailySales"></canvas>
              </div>
             
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

