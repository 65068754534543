import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-email-sended',
  templateUrl: './email-sended.component.html',
  styleUrls: ['./email-sended.component.css']
})
export class EmailSendedComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
