import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-content13',
  templateUrl: './content13.component.html',
  styleUrls: ['./content13.component.css']
})
export class Content13Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
      //put scroll on top
      window.scrollTo({top: 0, behavior: 'smooth'});
  }

}
