import { Component, OnInit } from '@angular/core';
import { CustomerService } from 'src/app/dashboard/customer.service';
import { AlertService } from '../../alert/alert.service';

declare var $: any;
@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.css']
})
export class CustomersComponent implements OnInit {
  spinnerHeaders: boolean = false;
  spinnerBtn: boolean = false;
  customerId: number;
  search: string = "";
  headers: any = {
    allCustomers: 0,
    confirmed: 0,
    unconfirmed: 0,
    noStripe: 0
  }

  alert: any = {
    show: false,
    icon: "fe fe-bell",
    message: "Customer View",
    type: "success"
  }

  constructor(public customerService: CustomerService,public alertService: AlertService) { }

  ngOnInit(): void {
    this.loadHeaders();
    this.customerService.spinner = true;
    this.customerService.getCustomerList().subscribe(r => {
      this.customerService.customers = r;
      this.customerService.spinner = false;

    });

  }

  loadHeaders(): void {
    this.spinnerHeaders = true;
    this.headers.allCustomers = 0;
    this.headers.confirmed = 0;
    this.headers.unconfirmed = 0;
    this.headers.noStripe = 0;
    this.customerService.getCustomerList().subscribe(r => {
      r.map(v => {
        if (v.emailStatus == "confirmed") {
          this.headers.confirmed++;
        } else {
          this.headers.unconfirmed++;
        }
        if (v.stripeCustomerId == null) {
          this.headers.noStripe++;
        }
      });

      this.headers.allCustomers = r.length;
      this.spinnerHeaders = false;
    });
  }

  deleteCustomer(): void {
    this.spinnerBtn = true;
    this.customerService.delete(this.customerId).subscribe(r => {

      if (r.statusCode === 201) {//Remove customer from view-list
        this.customerService.customers = this.customerService.customers.filter(x => x.id != r.customer.id);
        $('#modal-delete').modal('hide');
        this.spinnerBtn = false;
        this.alertService.showAlert( "fe fe-bell", "Cliente eliminado correctamente.","Customer removed successfully.","success");
 
        this.loadHeaders();
        this.spinnerBtn = false;

      }
    }, err => {
      this.alertService.showAlert( "fe fe-alert-octagon","No se pudo eliminar al cliente","Could not remove customer.","danger");      
      this.spinnerBtn = false;

    });

  }

  searchCustomer(e): void {
    if (e.code === 'Enter') {
      this.customerService.spinner = true;
      this.customerService.getCustomerList().subscribe(r => {
        var result = [];
        r.map(v => {
          if (v.fullName.toUpperCase().includes(this.search.toUpperCase()) || v.emailAddress.toUpperCase().includes(this.search.toUpperCase()) || v.phone.toUpperCase().includes(this.search.toUpperCase())) {
            result.push(v);
          }
        });


        if (this.search == "") {
          this.customerService.customers = r;
        } else {
          this.customerService.customers = result;
        }

        this.customerService.spinner = false;
      });
    }
  }

  filterCustomer(filter): void {
    this.customerService.spinner = true;
    this.search == ""
    this.customerService.getCustomerList().subscribe(r => {
      var result = [];
      if (filter == 0) {
        this.customerService.customers = r;
        this.customerService.spinner = false;
        
      } else {
        r.map(v => {
          if (filter == 1) {
            if (v.emailStatus == "unconfirmed") {
              result.push(v);
            }
          }
          if (filter == 2) {
            if (v.emailStatus == "confirmed") {
              result.push(v);
            }
          }
          if (filter == 3) {
            if (v.stripeCustomerId == null) {
              result.push(v);
            }
          }
        });
        this.customerService.customers = result;
        this.customerService.spinner = false;
      }
     
    });

  }


}
