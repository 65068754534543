import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Compensation } from 'src/app/Models/Compnsation'
import { AppService } from '../../app.service'
import { PartnerService } from 'src/app/dashboard-admin/partner.service'
import { AlertService } from '../../alert/alert.service';
import { FormBuilder, FormControl, FormGroup,Validators, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';


import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable'
import { applyPlugin } from 'jspdf-autotable'
applyPlugin(jsPDF);
declare var Chart: any;
const doc = new jsPDF();

declare var $: any;

@Component({
  selector: 'app-compensations',
  templateUrl: './compensations.component.html',
  styleUrls: ['./compensations.component.css']
})
export class CompensationsComponent implements OnInit {

  search: string = '';
  updateForm: FormGroup;  

  pagination: any = {
    page: 1,
    pages:[],
    rows: 10,
    entries:0,
    filter:-1
  }


  range = new FormGroup({
    start: new FormControl(new Date((new Date().getMonth() + 1).toString() + '/01/' + new Date().getFullYear().toString())),
    end: new FormControl(new Date())
  });

  spinner: boolean = false;
  compensations: Compensation[];
  compId: -1;
  spinnerBtn: boolean = false;
  spinnerHeaders: boolean = false;

  headers: any = {   
    unpaid: 0,
    transferPayment: 0,
    checkPayment: 0,
    cashPayment: 0
   
  }

  alert: any = {
    show: false,
    icon: "fe fe-bell",
    message: "Partner View",
    type: "success"
  }
  constructor( private fb: FormBuilder, public appService: AppService, public partnerService: PartnerService, public alertService: AlertService) {
    
    this.updateForm = fb.group({
      status: ['', Validators.required],
      paymentMode: ['', Validators.required],
      bankTransaction: ['', Validators.required],
      amountPaid: ['', [Validators.required]],       
     
    });

   
   }

  ngOnInit(): void {

    this.pagination.rows = 10;
    this.pagination.page = 1;
    this.pagination.pages = [1];

  
    setTimeout(() => {

    }, 4000);

    this.getCompensations();
    this.getHeaders();

    $('#modal-update').on('show.bs.modal', (e) => {
      this.loadUpdateData();
    });
    $('#modal-update').on('hidden.bs.modal', (e) => {
      this.updateForm.reset();
      this.compId=-1;
    });

  }


  getCompensations(): void {
    this.spinner = true;   
    let values = this.range.value;
    this.partnerService.getAllCompensations(this.search, new Date(this.appService.fixInitDate(values.start)), new Date(this.appService.fixEndDate(values.end)), this.pagination.page, this.pagination.rows, this.pagination.filter).subscribe(r => {

      if (r.compensations) {
        this.compensations = r.compensations;
        this.pagination.entries=r.entries;
        let a=Math.ceil(r.entries / this.pagination.rows);
        this.pagination.pages = [];
        for (var i=1;i<=a;i++) {
          this.pagination.pages.push(i);
        }

      }
      this.spinner = false;     
     
    }, err => {
      this.spinner = false;
      console.log(err);
    });
  }

  getHeaders(): void {   
    this.spinnerHeaders=true;
    let values = this.range.value;
    this.partnerService.getCompensationHeaders(this.search, new Date(values.start), new Date(values.end), this.pagination.page, this.pagination.rows, this.pagination.filter).subscribe(r => {

      if (r.compensations) {       
        this.headers = { 
          unpaid: r.unPaid,
          transferPayment: r.transferPayment,
          checkPayment: r.checkPayment,
          cashPayment:r.cashPayment
        }
      }
     
      this.spinnerHeaders=false;
     
    }, err => {
      this.spinner = false;
      console.log(err);
    });
  }

  paginate(p): void {
    this.pagination.page = p;
    this.getCompensations();
  }

  previousPage(): void{
    if(this.pagination.page-1 >= 1){
      this.paginate(this.pagination.page-1);
    }
   
  }

  nextPage(): void{    
    
    if(this.pagination.page+1 <= this.pagination.pages.length){
      this.paginate(this.pagination.page+1);
    }
  }

  changeDateRange(): void{
    this.pagination.filter=-1;
    this.pagination.page = 1;
    this.getCompensations();
    this.getHeaders();
  }

  filterCompensation(filterCompensation: number): void {
    this.pagination.filter=filterCompensation;   
    this.getCompensations();
  }

  deleteCompensation(): void {
    this.spinnerBtn = true;
    this.partnerService.deleteCompensation(this.compId).subscribe(r => {

      if (r.statusCode === 201) {//Remove compensation from view-list
        this.compensations = this.compensations.filter(x => x.NoCompensation != r.compensation.NoCompensation);
        $('#modal-remove-comp').modal('hide');
        this.spinner = false;
        this.alertService.showAlert("fe fe-bell","Compensación eliminada con éxito.","Compensation removed successfully.", "success");

        this.spinnerBtn = false;
        //this.loadHeaders();
      }
    }, err => {
      this.alertService.showAlert("fe fe-alert-octagon", "No se pudo eliminar la compensación.","Could not remove compensation.", "danger");
      this.spinnerBtn = false;

    });

  }

  searchCompensation(e): void {
    if (e.code === 'Enter') {
      this.pagination.filter=-1;  
      this.getCompensations();
      this.getHeaders();
    }
  }

  printCompensation(statement): void {
    try {
    
      if (statement.noCompensation != undefined) {

        let count = 0;
        let doc = new jsPDF('p', 'pt', 'letter'), rows = [], images = [], pos = 0;

        let columns = [{ title: 'Partner Information', dataKey: 'partner' }, { title: ' ', dataKey: 'empty' }];
        rows.push(
          { 'partner': 'Partner Name', 'empty': statement.partnerName },
          { 'partner': 'Partner Phone', 'empty': statement.partnerPhone },
          { 'partner': 'Billing Period', 'empty': 'From: ' + new Date(statement.initDate).toLocaleDateString('en-US') + '     To: ' + new Date(statement.endDate).toLocaleDateString('en-US') },

        );



        /********************************************* */
        doc.setDrawColor(this.appService.config.pdf.headerColor.R, this.appService.config.pdf.headerColor.G, this.appService.config.pdf.headerColor.B); doc.setFillColor(this.appService.config.pdf.headerColor.R, this.appService.config.pdf.headerColor.G, this.appService.config.pdf.headerColor.B);
        doc.rect(0, 0, 800, 70, 'F');
        doc.rect(0, doc.internal.pageSize.height - 25, 800, 25, 'F');
        doc.addImage(this.appService.logo.base64, this.appService.logo.x, this.appService.logo.y, this.appService.logo.w, this.appService.logo.h);

        doc.setFontSize(18); doc.setTextColor(255, 255, 255);

        doc.setTextColor(0, 0, 0); doc.setFontSize(10);
        doc.text(this.appService.config.pdf.address1, 25, 90);
        doc.text(this.appService.config.pdf.address2, 25, 100);
        doc.text(this.appService.config.pdf.phone, 25, 110);
        doc.text(this.appService.config.pdf.web, 25, 120);


        doc.text('Compensations Report', 425, 90);
        doc.text('______________________________', 425, 98);
        doc.text('Comp No: ' + statement.noCompensation, 425, 112);

        let d = new Date();

        doc.text('Created At: ' + new Date(statement.createdAt).toLocaleDateString('en-US'), 425, 123);

        autoTable(doc, {
          columns: columns,
          body: rows,
          startY: 160,
          margin: [90, 20, 70, 20],
          bodyStyles: {
            lineWidth: 0.5,
            cellPadding: 10,
            textColor: 20,
          },
          styles: {
            overflow: 'linebreak'
          },
          headStyles: {
            fillColor: '#86c233'
          },
          columnStyles: {
            partner: { cellWidth: 280 },
            empty: { cellWidth: 290 },

          },

        });

        let rows3 = [];
        let columns3 = [{ title: 'Earnings Resume', dataKey: 'partner' }, { title: ' ', dataKey: 'empty' }];
        rows3.push(
          { 'partner': ' Online Services', 'empty': statement.items.length.toString() },
          { 'partner': 'Services Profit', 'empty': '$' + parseFloat(statement.amountPaid).toFixed(2) },
          { 'partner': 'Other Payements', 'empty': '$0.0' },
          { 'partner': 'Total Earned', 'empty': '$' + parseFloat(statement.amountPaid).toFixed(2) },

        );
        let finalY = (doc as any).lastAutoTable.finalY;
        autoTable(doc, {
          columns: columns3,
          body: rows3,
          startY: finalY + 25,
          margin: [90, 20, 70, 20],
          bodyStyles: {
            lineWidth: 0.5,
            cellPadding: 10,
            textColor: 20,
          },
          styles: {
            overflow: 'linebreak'
          },
          headStyles: {
            fillColor: '#86c233'
          },
          columnStyles: {
            partner: { cellWidth: 280 },
            empty: { cellWidth: 290 },

          },
          willDrawCell: (data) => {                      
            if (data.row.index === 3) {
              doc.setFont('helvetica', 'bold');
              doc.setFontSize(12);
            }
          }
        });

        let rows4 = [];
        let columns4 = [{ title: 'Payment Resume', dataKey: 'payment' }, { title: ' ', dataKey: 'empty' }];
        rows4.push(
          { 'payment': 'Payment Mode', 'empty': (statement.paymentMode==0 ?'Unpaid' : (statement.paymentMode==1 ?'Bank Transfer' : (statement.paymentMode==2 ?'Check by mail': 'Cash Payment')))},
          { 'payment': 'Total Paid', 'empty': '$' + parseFloat(statement.amountPaid).toFixed(2) },     
          { 'payment': 'Bank Transaction / Check No.', 'empty': statement.bankTransaction},

        );
        finalY = (doc as any).lastAutoTable.finalY;
        autoTable(doc, {
          columns: columns4,
          body: rows4,
          startY: finalY + 25,
          margin: [90, 20, 70, 20],
          bodyStyles: {
            lineWidth: 0.5,
            cellPadding: 10,
            textColor: 20,
          },
          styles: {
            overflow: 'linebreak'
          },
          headStyles: {
            fillColor: '#86c233'
          },
          columnStyles: {
            payment: { cellWidth: 280 },
            empty: { cellWidth: 290 },

          },
          
        });
        doc.addPage();
        let position=0;
    
        statement.items.map(v => {

          let rows2 = [];
          let columns2 = [{ title: 'Service Information', dataKey: 'request' }, { title: ' ', dataKey: 'empty' }];

          let service = v;
          if (service != undefined) {
            count++;
            position+=40;
            rows2.push(
              { 'request': 'Application No', 'empty': service.number },
              { 'request': 'Application Name', 'empty': service.appName },
              { 'request': 'Plan', 'empty': service.plan },
              { 'request': 'Info collected on', 'empty': new Date(service.createdAt).toLocaleDateString('en-US') },
              { 'request': 'Status', 'empty': (service.status == 0 ? 'Offline' : 'Online') },             
              { 'request': 'Partner Name', 'empty': service.partnerName },
              { 'request': 'Partner Profit', 'empty': '$' + service.partnerProfit.toString() },

            );
          }
          let finalY = (doc as any).lastAutoTable.finalY;
          autoTable(doc, {
            columns: columns2,
            body: rows2,
            startY: (position==40 ? position : finalY+40),
            margin: [90, 20, 70, 20],

            bodyStyles: {
              lineWidth: 0.5,
              cellPadding: 10,
              textColor: 20,
            },            
            styles: {
              overflow: 'linebreak'
            },
            headStyles: {
              fillColor: '#86c233'
            },
            showHead:"firstPage",
            columnStyles: {
              request: { cellWidth: 280 },
              empty: { cellWidth: 290 },
  
            },
            willDrawCell: (data) => {                      
              if (data.row.index === 6) {
                doc.setFont('helvetica', 'bold');
                doc.setFontSize(12);
              }
            }
          });

        });

        //doc.output('dataurlnewwindow');
        doc.save('Service(' + statement.noCompensation + ').pdf');
      } else {
        //this.showError("Does not have pending statements.");
      }
    } catch (err) {

      //this.showError(err);
      //this.hideBarTable();
    }
  }

  loadUpdateData(): void {
    var comp=this.compensations.filter(v=>v.id==this.compId);

      if (comp.length > 0) {
        this.updateForm.get('status').setValue(comp[0].status);
        this.updateForm.get('paymentMode').setValue(comp[0].paymentMode);
        this.updateForm.get('bankTransaction').setValue(comp[0].bankTransaction);
        this.updateForm.get('amountPaid').setValue(comp[0].amountPaid);
      
      }
   
  }
  updateCompensation(): void {

    this.updateForm.markAllAsTouched();
    if (this.updateForm.valid) {
      this.spinnerBtn = true;

      this.partnerService.updateCompensationData(
        this.compId, 
        parseInt(this.updateForm.get('status').value),
        parseInt(this.updateForm.get('paymentMode').value),
        this.updateForm.get('bankTransaction').value,
        parseFloat(this.updateForm.get('amountPaid').value),
       
      ).subscribe(r => {

        if (r.statusCode === 201) {
          this.spinnerBtn = false;
          this.alertService.showAlert("fe fe-bell", "Compensación actualizada correctamente.","Compensation updated successfully.", "success");
         
          this.compensations.map(v=>{
           
            if(v.id==r.compensation.id){
              v.paymentMode=r.compensation.paymentMode;
              v.status=r.compensation.stauts;
              v.bankTransaction=r.compensation.bankTransaction;
              v.amountPaid=r.compensation.amountPaid;
            }
          });
          this.spinnerBtn = false;

        }
      }, err => {
        this.alertService.showAlert("fe fe-alert-octagon","No se pudieron actualizar los datos.", "Could not update data.", "danger");
        this.spinnerBtn = false;

      });
    }

  }
}
