<div class="d-flex flex-column h-100 bg-light rounded-lg box-shadow-lg p-4">
  <div class="py-2 p-md-3">
    <!-- Title + Filters-->
    <div class="d-sm-flex align-items-center justify-content-between pb-2">
      <h1 class="h3 mb-3 text-center text-sm-left" translate>dashboard_partner.customers.title1</h1>
      <div class="d-flex align-items-center mb-3">
        <!-- Aqui va el buscador -->
      </div>
    </div>
     <!-- Customers list-->
    <div *ngIf="spinner==false" class="accordion" id="customers-accordion">
      <!-- list-->
      <div *ngFor="let req of partnerService.customers" class="card" >
        <div class="card-header">
          <div class="accordion-heading">
            <a class="d-flex flex-wrap align-items-center justify-content-between pr-4 row">
            <div class="col-sm-2 font-size-sm font-weight-medium text-nowrap my-1 mr-2">
              <img [src]="req.avatar" class="navbar-tool-icon-box-img avatar_container icon-box-img" alt="Icon"
                width="50">
            </div>
            <div class="col-sm-2 font-size-sm font-weight-medium text-nowrap my-1 mr-2">
              <span class="d-inline-block align-middle">{{req.fullName }}</span>
            </div>           
            <div class="col-sm-3 font-size-xs font-weight-medium py-1 px-3 rounded-sm my-1 mr-2">
              <i class="fe-phone font-size-base mr-1"></i>{{req.phone}}
            </div>
            <div [ngClass]="{'bg-faded-danger text-danger': req.emailStatus!='confirmed', 'bg-faded-success text-success': req.emailStatus=='confirmed'}"
              class="col-sm-4 font-size-xs font-weight-medium py-1 px-3 rounded-sm my-1 mr-2">
              <i class="fe-mail font-size-base mr-1"></i>{{req.emailAddress}}
            </div>
          </a>
          </div>
        </div>
       
      </div>
    
    </div>
    <div *ngIf="spinner==true" class="loading">
      <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Loading...</span>
      </div>
    </div>
    <!-- Pagination-->
    <nav class="d-md-flex justify-content-between align-items-center text-center text-md-left pt-grid-gutter">
      <div class="d-md-flex align-items-center w-100"><span class="font-size-sm text-muted mr-md-3"><span translate>dashboard_partner.customers.title2</span>{{partnerService.customers.length}} <span translate>dashboard_partner.customers.title3</span>{{partnerService.customers.length}} <span translate>dashboard_partner.customers.title4</span></span>
        <div class="progress w-100 my-3 mx-auto mx-md-0" style="max-width: 10rem; height: 4px;">
          <div class="progress-bar" role="progressbar" style="width: 100%;" aria-valuenow="38" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
      </div>
      <button class="btn btn-outline-primary btn-sm" type="button" translate>dashboard_partner.customers.btn01</button>
    </nav>
  </div>
</div>










