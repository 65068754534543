<!--Header-->
<app-header-light></app-header-light>
 <!-- Hero-->
 <section class="jarallax py-7" data-jarallax data-speed="0.25">
    <div class="jarallax-img" style="background-color:#1e1e2e;"></div>
    <div class="container bg-overlay-content">
      <div class="row justify-content-center py-md-5">
        <div class="col-lg-6 col-md-8 text-center">
          <h1 class="text-light" translate>help.hero.title1</h1>
          <p class="font-size-lg text-light pb-3"translate>help.hero.title2</p>
          <form class="input-group-overlay mb-3">
            <div class="input-group-prepend-overlay"><span class="input-group-text"><i class="fe-search"></i></span></div>
            <input class="form-control prepended-form-control" type="text" placeholder="{{'input_placeholder.label8' | translate}}">
          </form>
          <div class="font-size-sm text-left"><span class="text-light opacity-70 mr-3" translate>help.hero.title3</span><a class="cs-fancy-link text-light mr-3" [routerLink]='["/faq/content04"]' translate>help.hero.title4</a><a class="cs-fancy-link text-light mr-3" [routerLink]='["/faq/content12"]' translate>help.hero.title5</a><a class="cs-fancy-link text-light mr-3" [routerLink]='["/faq/content13"]' translate>help.hero.title6</a><a class="cs-fancy-link text-light" [routerLink]='["/faq/content16"]' translate>help.hero.title7</a></div>
        </div>
      </div>
    </div>
  </section>
  <!-- Topics grid-->
  <section class="container mt-4 pt-5 mt-md-0 pt-md-7 pb-5">
    <h2 class="mb-5 text-center" translate>help.topics.header</h2>
    <div class="row">
      <div class="col-lg-4 col-sm-6 mb-grid-gutter"><a class="card h-100 border-0 box-shadow card-hover" [routerLink]='["/faq/content04"]'>
          <div class="card-body pl-grid-gutter pr-grid-gutter text-center"><i class="fe-user-check h2 text-primary mt-2 mb-4"></i>
            <h3 class="h5" translate>help.topics.title1</h3>
            <p class="font-size-sm text-body" translate>help.topics.text1</p>
            <div class="btn btn-translucent-primary btn-sm mb-2" translate>help.topics.btn1</div>
          </div></a></div>
      <div class="col-lg-4 col-sm-6 mb-grid-gutter"><a class="card h-100 border-0 box-shadow card-hover"[routerLink]='["/faq/content12"]'>
          <div class="card-body pl-grid-gutter pr-grid-gutter text-center"><i class="fe-refresh-cw h2 text-primary mt-2 mb-4"></i>
            <h3 class="h5" translate>help.topics.title2</h3>
            <p class="font-size-sm text-body" translate>help.topics.text2</p>
            <div class="btn btn-translucent-primary btn-sm mb-2" translate>help.topics.btn2</div>
          </div></a></div>
      <div class="col-lg-4 col-sm-6 mb-grid-gutter"><a class="card h-100 border-0 box-shadow card-hover" [routerLink]='["/faq/content13"]'>
          <div class="card-body pl-grid-gutter pr-grid-gutter text-center"><i class="fe-credit-card h2 text-primary mt-2 mb-4"></i>
            <h3 class="h5" translate>help.topics.title3</h3>
            <p class="font-size-sm text-body" translate>help.topics.text3</p>
            <div class="btn btn-translucent-primary btn-sm mb-2" translate>help.topics.btn3</div>
          </div></a></div>
      <div class="col-lg-4 col-sm-6 mb-grid-gutter"><a class="card h-100 border-0 box-shadow card-hover" [routerLink]='["/faq/content14"]'>
          <div class="card-body pl-grid-gutter pr-grid-gutter text-center"><i class="fe-settings h2 text-primary mt-2 mb-4"></i>
            <h3 class="h5" translate>help.topics.title4</h3>
            <p class="font-size-sm text-body" translate>help.topics.text4</p>
            <div class="btn btn-translucent-primary btn-sm mb-2" translate>help.topics.btn4</div>
          </div></a></div>
      <div class="col-lg-4 col-sm-6 mb-grid-gutter"><a class="card h-100 border-0 box-shadow card-hover" [routerLink]='["/faq/content15"]'>
          <div class="card-body pl-grid-gutter pr-grid-gutter text-center"><i class="fe-shopping-cart h2 text-primary mt-2 mb-4"></i>
            <h3 class="h5" translate>help.topics.title5</h3>
            <p class="font-size-sm text-body" translate>help.topics.text5</p>
            <div class="btn btn-translucent-primary btn-sm mb-2" translate>help.topics.btn5</div>
          </div></a></div>
      <div class="col-lg-4 col-sm-6 mb-grid-gutter"><a class="card h-100 border-0 box-shadow card-hover" [routerLink]='["/faq/content16"]'>
          <div class="card-body pl-grid-gutter pr-grid-gutter text-center"><i class="fe-share-2 h2 text-primary mt-2 mb-4"></i>
            <h3 class="h5" translate>help.topics.title6</h3>
            <p class="font-size-sm text-body" translate>help.topics.text6</p>
            <div class="btn btn-translucent-primary btn-sm mb-2" translate>help.topics.btn6</div>
          </div></a></div>
    </div>
  </section>
  <!-- FAQ-->
  <section class="container pt-md-4 mb-2 pb-5 pb-md-6 mb-md-0">
    <h2 class="mb-5 text-center">F.A.Q</h2>
    <div class="row">
      <div class="col-sm-6">
        <ul class="list-unstyled">
          <li class="d-flex align-items-center border-bottom pb-3 mb-3"><i class="fe-book text-muted mr-2"></i><a class="nav-link-style" [routerLink]='["/faq/content01"]' translate>help.faq.title1</a></li>
          <li class="d-flex align-items-center border-bottom pb-3 mb-3"><i class="fe-book text-muted mr-2"></i><a class="nav-link-style" [routerLink]='["/faq//content02"]' translate>help.faq.title2</a></li>
          <li class="d-flex align-items-center border-bottom pb-3 mb-3"><i class="fe-book text-muted mr-2"></i><a class="nav-link-style" [routerLink]='["/faq/content03"]' translate>help.faq.title3</a></li>
          <li class="d-flex align-items-center border-bottom pb-3 mb-3"><i class="fe-book text-muted mr-2"></i><a class="nav-link-style" [routerLink]='["/faq/content04"]' translate>help.faq.title4</a></li>
          <li class="d-flex align-items-center border-bottom pb-3 mb-3"><i class="fe-book text-muted mr-2"></i><a class="nav-link-style" [routerLink]='["/faq/content05"]' translate>help.faq.title5</a></li>
        </ul>
      </div>
      <div class="col-sm-6">
        <ul class="list-unstyled">
          <li class="d-flex align-items-center border-bottom pb-3 mb-3"><i class="fe-book text-muted mr-2"></i><a class="nav-link-style" [routerLink]='["/faq/content06"]' translate>help.faq.title6</a></li>
          <li class="d-flex align-items-center border-bottom pb-3 mb-3"><i class="fe-book text-muted mr-2"></i><a class="nav-link-style" [routerLink]='["/faq/content07"]' translate>help.faq.title7</a></li>
          <li class="d-flex align-items-center border-bottom pb-3 mb-3"><i class="fe-book text-muted mr-2"></i><a class="nav-link-style" [routerLink]='["/faq/content08"]' translate>help.faq.title8</a></li>
          <li class="d-flex align-items-center border-bottom pb-3 mb-3"><i class="fe-book text-muted mr-2"></i><a class="nav-link-style" [routerLink]='["/faq/content09"]' translate>help.faq.title9</a></li>
          <li class="d-flex align-items-center border-bottom pb-3 mb-3"><i class="fe-book text-muted mr-2"></i><a class="nav-link-style" [routerLink]='["/faq/content10"]' translate>help.faq.title10</a></li>
        </ul>
      </div>
    </div>
  </section>
  <!-- Submit request-->
  <section class="bg-secondary py-6">
    <div class="container text-center">
      <h2 class="h3 pb-2 mb-4" translate>help.sub_req.title1</h2><i class="fe-life-buoy d-block h2 pb-2 mb-4 text-primary"></i><a class="btn btn-primary mb-4" [routerLink]='["/faq/submit-request"]' translate>help.sub_req.btn1</a>
      <p class="font-size-sm mb-0" translate>help.sub_req.title2</p>
    </div>
  </section>
<!--Footer-->
<app-contact-footer></app-contact-footer>
<!--Coommon-->
<app-top-arrow></app-top-arrow>

