import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomepageComponent } from './homepage/homepage.component';
import { DemoAdminComponent } from './demo-admin/demo-admin.component';
import { AboutComponent } from './about/about.component';
import { ContactusComponent } from './contactus/contactus.component';
import { HelpComponent } from './help/help.component';
import { LoginComponent } from './auth/login/login.component';
import { PaymentsComponent } from './dashboard/payments/payments.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { RequestsComponent } from './dashboard/requests/requests.component';
import { ProfileInfoComponent } from './dashboard/profile-info/profile-info.component';
import { PaymentMethodsComponent } from './dashboard/payment-methods/payment-methods.component';
import { FindServiceComponent } from './dashboard/find-service/find-service.component';
import { FaqComponent } from './faq/faq.component';
import { V404Component } from './v404/v404.component';
import { ComigSoonComponent } from './comig-soon/comig-soon.component';
import { DemoPosComponent } from './demo-pos/demo-pos.component';
import { Content01Component } from './faq/content/content01/content01.component';
import { Content04Component } from './faq/content/content04/content04.component';
import { Content03Component } from './faq/content/content03/content03.component';
import { Content02Component } from './faq/content/content02/content02.component';
import { Content05Component } from './faq/content/content05/content05.component';
import { Content10Component } from './faq/content/content10/content10.component';
import { Content09Component } from './faq/content/content09/content09.component';
import { Content08Component } from './faq/content/content08/content08.component';
import { Content07Component } from './faq/content/content07/content07.component';
import { Content06Component } from './faq/content/content06/content06.component';
import { Content11Component } from './faq/content/content11/content11.component';
import { Content12Component } from './faq/content/content12/content12.component';
import { Content13Component } from './faq/content/content13/content13.component';
import { Content14Component } from './faq/content/content14/content14.component';
import { Content15Component } from './faq/content/content15/content15.component';
import { Content16Component } from './faq/content/content16/content16.component';
import { PrivacyComponent } from './legal/privacy/privacy.component';
import { TermsComponent } from './legal/terms/terms.component';
import { SubmitRequestComponent } from './faq/submit-request/submit-request.component';
import { AuthComponent } from './auth/auth.component';
import { RegisterComponent } from './auth/register/register.component';
import { ConfirmEmailComponent } from './auth/confirm-email/confirm-email.component';
import { EmailConfirmedComponent } from './auth/email-confirmed/email-confirmed.component';
import { ForgotComponent } from './auth/forgot/forgot.component';
import { EmailSendedComponent } from './auth/email-sended/email-sended.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { AuthGuardService as AuthGuard} from './auth/auth-guard.service';
import { EmailTemplateComponent} from './email-template/email-template/email-template.component';
import { from } from 'rxjs';
import { AuthPartnerComponent } from './auth-partner/auth-partner/auth-partner.component';
import { LoginPartnerComponent } from './auth-partner/login-partner/login-partner.component';
import { ProjectsComponent } from './dashboard-admin/projects/projects.component';
import { DashboardAdminComponent } from './dashboard-admin/dashboard-admin.component';
import { PartnersComponent } from './dashboard-admin/partners/partners.component';
import { PEmailConfirmedComponent } from './auth-partner/p-email-confirmed/p-email-confirmed.component';
import { PConfirmEmailComponent } from './auth-partner/p-confirm-email/p-confirm-email.component';
import { DashboardPartnerComponent } from './dashboard-partner/dashboard-partner.component';
import { EarningsPartnerComponent } from './dashboard-partner/earnings-partner/earnings-partner.component';
import { MyCustomersComponent } from './dashboard-partner/my-customers/my-customers.component';
import { MyRequestsComponent } from './dashboard-partner/my-requests/my-requests.component';
import { ProfilePartnerComponent } from './dashboard-partner/profile-partner/profile-partner.component';
import {AuthPartnerGuardService as AuthPGuard} from './auth-partner/auth-partner-guard.service';
import {ForgotPasswordComponent} from './auth-partner/forgot-password/forgot-password.component';
import {ResetPasswComponent} from './auth-partner/reset-passw/reset-passw.component';
import {AuthAdminComponent} from './auth-admin/auth-admin.component';
import {LoginAdminComponent} from './auth-admin/login-admin/login-admin.component';
import {RegisterAdminComponent} from './auth-admin/register-admin/register-admin.component';
import {AEmailConfirmedComponent} from './auth-admin/a-email-confirmed/a-email-confirmed.component';
import {AuthAdminGuardService as AuthAGuard} from './auth-admin/auth-admin-guard.service';
import {ForgotPasswComponent} from './auth-admin/forgot-passw/forgot-passw.component';
import {ResetPassComponent} from './auth-admin/reset-pass/reset-pass.component';
import {AdminProfileInfoComponent} from './dashboard-admin/admin-profile-info/admin-profile-info.component';
import {CustomersComponent} from './dashboard-admin/customers/customers.component';
import {InvoicesComponent} from './dashboard-admin/invoices/invoices.component';
import {CompensationsComponent} from './dashboard-admin/compensations/compensations.component';
import {LinkRequestComponent} from './dashboard-partner/link-request/link-request.component';
import {LinkRequestDoneComponent} from './dashboard-partner/link-request-done/link-request-done.component';
import {QInvoiceComponent} from './dashboard/q-invoice/q-invoice.component';
import {DemoQinvoiceComponent} from './demo-qinvoice/demo-qinvoice.component';
import { DemoSmartsalonComponent } from './demo-smartsalon/demo-smartsalon.component';
import { DemoOnlinestoreComponent } from './demo-onlinestore/demo-onlinestore.component';
import { DemoRpcargoComponent } from './demo-rpcargo/demo-rpcargo.component';
const routes: Routes = [
  { path: 'homepage', component: HomepageComponent },
  { 
    path: 'auth', component: AuthComponent, 
    children: [
      { path: 'login', component: LoginComponent },
      { path: 'register', component: RegisterComponent },
      { path: 'forgot', component: ForgotComponent },
      { path: 'reset-password', component: ResetPasswordComponent },
      { path: 'email-sended', component: EmailSendedComponent },
      { path: 'confirm-email', component: ConfirmEmailComponent },
      { path: 'email-confirmed', component: EmailConfirmedComponent },     
      { path: '', redirectTo: 'login', pathMatch: 'full' }
    ]
  },
  { 
    path: 'auth-partner', component: AuthPartnerComponent, 
    children: [
      { path: 'login-partner', component: LoginPartnerComponent },
      { path: 'register', component: RegisterComponent },
      { path: 'forgot-password', component: ForgotPasswordComponent },
      { path: 'reset-passw', component: ResetPasswComponent },
      { path: 'email-sended', component: EmailSendedComponent },
      { path: 'p-confirm-email', component: PConfirmEmailComponent },
      { path: 'p-email-confirmed', component: PEmailConfirmedComponent },      
      { path: '', redirectTo: 'login-partner', pathMatch: 'full' }
    ]
  },
  { 
    path: 'auth-admin', component: AuthAdminComponent, 
    children: [
      { path: 'login-admin', component: LoginAdminComponent },
      { path: 'register-admin', component: RegisterAdminComponent },
      { path: 'forgot-passw', component: ForgotPasswComponent },
      { path: 'reset-pass', component: ResetPassComponent },
     // { path: 'email-sended', component: EmailSendedComponent },
      //{ path: 'p-confirm-email', component: PConfirmEmailComponent },
      { path: 'a-email-confirmed', component: AEmailConfirmedComponent },
      { path: '', redirectTo: 'login-admin', pathMatch: 'full' }
      
    ]
  },
  { path: 'demo-admin', component: DemoAdminComponent },
  { path: 'demo-qinvoice', component: DemoQinvoiceComponent },
  { path: 'demo-smartsalon', component: DemoSmartsalonComponent },
  { path: 'demo-onlinestore', component: DemoOnlinestoreComponent },
  { path: 'demo-rpcargo', component: DemoRpcargoComponent },
  { path: 'about', component: AboutComponent },
  { path: 'contactus', component: ContactusComponent },
  { path: 'help', component: HelpComponent }, 
  { path: 'page-404', component: V404Component },
  { path: 'coming-soon', component: ComigSoonComponent },
  { path: 'demo-pos', component: DemoPosComponent },
  { path: 'privacy', component: PrivacyComponent },
  { path: 'terms', component: TermsComponent },
  { path: 'email-templates', component: EmailTemplateComponent },
  { path: 'update-link', component: LinkRequestDoneComponent },
 
  {
    path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard],
    children: [
      { path: 'payment', component: PaymentsComponent},
      { path: 'requests', component: RequestsComponent },
      { path: 'profile-info', component: ProfileInfoComponent },
      { path: 'payment-methods', component: PaymentMethodsComponent },
      { path: 'find-service', component: FindServiceComponent },  
      { path: 'q-invoice', component: QInvoiceComponent },  
      { path: '', redirectTo: 'payment', pathMatch: 'full' }
      
    ]
  },
  {
    path: 'dashboard-partner', component: DashboardPartnerComponent, canActivate: [AuthPGuard],
    children: [      
      { path: 'earnings-partner', component: EarningsPartnerComponent},
      { path: 'my-customers', component: MyCustomersComponent},
      { path: 'my-requests', component: MyRequestsComponent},
      { path: 'find-service', component: FindServiceComponent},  
      { path: 'link-request', component: LinkRequestComponent },        
      { path: 'profile-partner', component: ProfilePartnerComponent },      
      { path: '', redirectTo: 'link-request', pathMatch: 'full' }  
    ]
  },
  {
    path: 'dashboard-admin', component: DashboardAdminComponent,  canActivate: [AuthAGuard],
    children: [
      { path: 'projects', component: ProjectsComponent},  
      { path: 'partners', component: PartnersComponent},  
      { path: 'customers', component: CustomersComponent},
      { path: 'invoices', component: InvoicesComponent},
      { path: 'compensations', component: CompensationsComponent},
      { path: 'admin-profile-info', component: AdminProfileInfoComponent }, 
      { path: '', redirectTo: 'projects', pathMatch: 'full' }  
    ]
  },
  { 
    path: 'faq', component: FaqComponent,
    children: [
      { path: 'content01', component: Content01Component },
      { path: 'content02', component: Content02Component },
      { path: 'content03', component: Content03Component },
      { path: 'content04', component: Content04Component },
      { path: 'content05', component: Content05Component },
      { path: 'content06', component: Content06Component },
      { path: 'content07', component: Content07Component },
      { path: 'content08', component: Content08Component },
      { path: 'content09', component: Content09Component },
      { path: 'content10', component: Content10Component },
      { path: 'content11', component: Content11Component },
      { path: 'content12', component: Content12Component },
      { path: 'content13', component: Content13Component },
      { path: 'content14', component: Content14Component },
      { path: 'content15', component: Content15Component },
      { path: 'content16', component: Content16Component },
      { path: 'submit-request', component: SubmitRequestComponent },
      { path: '', redirectTo: 'content01', pathMatch: 'full' }
    ]
  },
  { path: '', redirectTo: '/homepage', pathMatch: 'full' }
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
