<div class="container-fluid">
  <!--Profile menu on phone view-->
  <app-admin-phone-menu></app-admin-phone-menu>

  <!-- start page title -->
  <div class="row page-title">
    <div class="col-12">
      <h4 class="page-title">Dasboard/Invoices</h4>
    </div>
  </div>
  <div class="row">
    <!-- Headers info -->
    <div class="col-12">
      <div class="row">
        <div class="col-xl-3 col-md-6">
          <div class="card widget-inline border-left-success shadow h-100 py-2">
            <div class="card-body">
              <div class="row no-gutters align-items-center">
                <div class="col mr-2">
                  <div class="text-xs font-weight-bold text-dark text-uppercase mb-1">Select date range
                    <mat-form-field appearance="fill">
                      <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
                        <input matStartDate formControlName="start" placeholder="Start date">
                        <input matEndDate formControlName="end" (dateChange)="changeDateRange()" placeholder="End date">
                      </mat-date-range-input>
                      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-date-range-picker #picker color="warn"></mat-date-range-picker>

                      <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date
                      </mat-error>
                      <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
                    </mat-form-field>
                  </div>
                  <div class="row no-gutters align-items-center">

                  </div>
                </div>
                <div class="col-auto"><i class="fa fa-file-text-o fa-2x text-gray-300"></i></div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6">
          <div class="card  widget-inline border-left-danger shadow h-100 py-2">
            <div class="card-body">
              <div class="row no-gutters align-items-center">
                <div class="col mr-2">
                  <div class="text-xs font-weight-bold text-danger text-uppercase mb-1" style="cursor: pointer;"
                    (click)="filterInvoice(0)">unpaid
                  </div>
                  <div *ngIf="spinnerHeaders==false" class="h5 mb-0 font-weight-bold text-gray-800">
                    {{headers.unpaid}}</div>
                  <div *ngIf="spinnerHeaders==true" class="">
                    <div class="spinner-border text-muted" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                </div>
                <div class="col-auto"><i class="fe-alert-triangle text-muted fe-2x"></i></div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6">
          <div class="card  widget-inline border-left-warning shadow h-100 py-2">
            <div class="card-body">
              <div class="row no-gutters align-items-center">
                <div class="col mr-2">
                  <div class="text-xs font-weight-bold text-warning text-uppercase mb-1" style="cursor: pointer;"
                    (click)="filterInvoice(2)">Manual payment</div>
                  <div *ngIf="spinnerHeaders==false" class="h5 mb-0 font-weight-bold text-gray-800">
                    {{headers.manualPayment}}</div>
                  <div *ngIf="spinnerHeaders==true" class="">
                    <div class="spinner-border text-muted" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                </div>
                <div class="col-auto"><i class="fe-edit text-muted fe-2x"></i></div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6">
          <div class="card widget-inline border-left-info shadow h-100 py-2">
            <div class="card-body">
              <div class="row no-gutters align-items-center">
                <div class="col mr-2">
                  <div class="text-xs font-weight-bold text-info text-uppercase mb-1" style="cursor: pointer;"
                    (click)="filterInvoice(1)">Auto payment
                  </div>
                  <div *ngIf="spinnerHeaders==false" class="h5 mb-0 font-weight-bold text-gray-800">
                    {{headers.autoPayment}}</div>
                  <div *ngIf="spinnerHeaders==true" class="">
                    <div class="spinner-border text-muted" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                </div>
                <div class="col-auto"><i class="fe-briefcase text-muted fe-2x"></i></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="float-right">
            <input type="text" class="form-control custom-select custom-select-sm" (keyup)="searchInvoice($event)"
              [(ngModel)]="search" placeholder="search">
          </div>
          <h4 class="header-title mb-3">Invoices</h4>
          <!--Invoices list-->
          <div class="py-2 p-md-3">
            <!-- Invoice(table)-->
            <div *ngIf="spinner==false" class="table-responsive font-size-md">
              <table class="table table-hover mb-0">
                <thead>
                  <tr>
                    <th>No Invoice</th>
                    <th>Customer</th>
                    <th>Payment Mode</th>
                    <th>CreatedAt</th>
                    <th class="algin-center">Amount</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let invoice of invoices">
                    <td class="py-3 align-middle">
                      <div class="media align-items-center">
                        <div class="media-body"><span
                            class="font-weight-medium text-heading mr-1">{{invoice.noInvoice}}</span>
                        </div>
                      </div>
                    </td>
                    <td class="py-3 align-middle">{{invoice.clientName}}</td>
                    <td class="py-3 align-middle algin-center">
                      <div style="width: 50%; min-width: max-content;"
                        [ngClass]="{'bg-faded-danger text-danger': invoice.paymentMode==0, 'bg-faded-warning text-warning': invoice.paymentMode==2, 'bg-faded-success text-success': invoice.paymentMode==1}"
                        class="font-size-xs font-weight-medium py-1 px-3 rounded-sm my-1 mr-2 ">
                        {{(invoice.paymentMode==0 ?'Unpaid' : (invoice.paymentMode==1 ?'Auto payment' : 'Manual payment'))}}
                      </div>
                    </td>
                    <td class="py-3 align-middle">
                      <i class="fe-clock text-muted mr-1 mb-1"></i>{{invoice.createdAt | date:'medium' }}
                    </td>
                    <td class="py-3 align-middle algin-center">$ {{invoice.invoiceSalePrice |  number:'.2'}}</td>
                    <td class="py-3 align-middle">
                      <div class="btn-group font-size-sm text-center bt-menu">
                        <button type="button"
                          class="btn btn-primary btn-sm btn-menu mt-2 dropdown dropdown-toggle navbar"
                          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i class="fe-align-justify text-white "></i>
                        </button>
                        <div class="dropdown-menu dropdown-menu-right">
                          <div id="settings-menu">
                            <div><a class="dropdown-item" (click)="printInvoice(invoice)" style="cursor: pointer;">
                              <i class="fe-printer fe-1x text-muted"
                                  data-toggle="tooltip" data-placement="bottom" title="Resume"></i></a>
                            </div>
                            <div class="dropdown-divider"></div>
                            <div><a class="dropdown-item" style="cursor: pointer;">
                              <i class="fe-package fe-1x text-muted"
                                  data-toggle="tooltip" data-placement="bottom" title="Apps"></i></a>
                            </div>
                            <div class="dropdown-divider"></div>
                            <div><a class="dropdown-item" (click)="invoiceId = invoice.id;" href="#modal-remove-invoice"
                                data-toggle="modal"><i class="fe-trash-2 fe-1x text-muted" data-toggle="tooltip"
                                  data-placement="bottom" title="Remove"></i></a>
                            </div>
                          </div>

                        </div>
                      </div>
                    </td>
                  </tr>

                </tbody>
              </table>
            </div>
            <div *ngIf="spinner==true" class="loading">
              <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        <!-- Pagination-->
          <hr><br>
          <nav aria-label="Page navigation example">
            <ul class="pagination justify-content-center">
              <li class="page-item" (click)="previousPage()" >
                <a class="page-link"tabindex="-1" aria-disabled="true">Previous</a>
              </li>
              <li *ngFor="let n of pagination.pages" class="page-item"><a class="page-link" [ngClass]="{'text-primary': (n==pagination.page) }" (click)="paginate(n)">{{n}}</a></li>             
              <li class="page-item" (click)="nextPage()">
                <a class="page-link" >Next</a>
              </li>
            </ul>
          </nav>
          <!--End pagination-->
        </div>
      </div>
    </div>
  </div>

  <!-- Remove invoice Modal-->
  <div class="modal fade" id="modal-remove-invoice" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content border-0">
        <div class="cs-view show" id="modal-heroku-view">
          <div class="modal-header bg-danger border-1 px-4">
            <h3 class="text-white">Remove</h3>
            <button class="close text-white" type="button" data-dismiss="modal" aria-label="Close"><span
                aria-hidden="true">&times;</span></button>
          </div>

          <div class="modal-body px-4">
            <div class="container">
              <h3>Are you sure you want remove this invoice ?</h3>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn ajax-button btn-danger btn-size" type="button" (click)="deleteInvoice()">
              <span *ngIf="!spinnerBtn">Remove</span>
              <div *ngIf="spinnerBtn" class="btn-spinner">
                <div class="spinner-border spinner-border-sm mr-1" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow spinner-grow-sm mr-1" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>