import { Component, OnInit } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AppService } from '../../app.service';
import { PartnerService} from '../../dashboard-admin/partner.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('200ms', style({ opacity: 1 })),
      ]),

    ]),
  ]
})
export class ForgotPasswordComponent implements OnInit {

  resetPassForm: FormGroup;
  signIn: boolean = true;

  spinner: boolean = false;
  constructor(private fb: FormBuilder, private partnerService: PartnerService, private router: Router, private appService: AppService) {
    this.resetPassForm = fb.group({
      emailAddress: ['', [Validators.required, Validators.email]]
    });
   }

  ngOnInit(): void {
  }

  changeView(): void {
    this.signIn = !this.signIn;
    window.scrollTo({ top: 0 });
  }

  submitResetPassword(): void {

    this.resetPassForm.markAllAsTouched();
    if (this.resetPassForm.valid) {
      this.spinner = true;
      this.partnerService.sendResetPasswordEmail(this.resetPassForm.value.emailAddress).subscribe(v => {

        if (v.statusCode == 200) {
          this.router.navigate(['/auth/email-sended']);
        }
        else if (v.statusCode == 401) {
          this.resetPassForm.setErrors({ invalidCredentials: true });
        } else {
          this.resetPassForm.setErrors({ invalidCredentials: true });
        }
        this.spinner = false;
      }, error => {
        if (error.status == '401') {
          console.log(error);
          this.resetPassForm.setErrors({ invalidCredentials: true });
        } else {
          console.log(error);
        }
        this.spinner = false;
      });

    }
  }

}
