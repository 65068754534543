import { Component, OnInit } from '@angular/core';
import { AppService } from '../app.service';
import { AuthService } from '../auth/auth.service';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AlertService } from '../alert/alert.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  country_code: string = 'US';

  constructor(private appService: AppService, public alertService: AlertService, public authService: AuthService, private translateService: TranslateService) {

    appService.ipGeoData.subscribe(v => {

    });

    appService.loadIpGeoData();
  }

  ngOnInit(): void {  
   
    this.country_code=(this.appService.defaultLanguage.getValue() == 'es' ? 'ES' : 'US')
    
  }

  setLanguage(language: string): void {
    this.appService.defaultLanguage.next(language);
    //this.appService.defaultCountry.next(language == 'ES' ? 'CU' : language);
    this.country_code=(language == 'es' ? 'ES' : 'US')
    this.translateService.setDefaultLang(language);
    this.alertService.showAlert( "fe fe-bell","El idioma cambió correctamente", "Language changed successfully.","success");
  }

}
