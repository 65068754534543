import { Component, OnInit } from '@angular/core';
import { CustomerService } from 'src/app/dashboard/customer.service';
import { AuthService } from 'src/app/auth/auth.service';


@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.css']
})
export class SideBarComponent implements OnInit {
 
  show: boolean = false;
  constructor(public authService:AuthService, public customerService: CustomerService)  { }

  ngOnInit(): void {
    
  }

  collapse(): void {
    this.show = !this.show;
  }

}
