<!-- Back to top button>
<a *ngIf="topArrow" @myInsertRemoveTrigger  (click)="goToUp()" class="btn-scroll-top show insert-remove-container" data-scroll><span
    class="btn-scroll-top-tooltip text-muted font-size-sm mr-2">Top</span><i class="btn-scroll-top-icon fe-arrow-up">
    </i>
</a-->

<a [ngClass]="{show: topArrow}" (click)="goToUp()" class="btn-scroll-top insert-remove-container" data-scroll><span
    class="btn-scroll-top-tooltip text-muted font-size-sm mr-2">Top</span><i class="btn-scroll-top-icon fe-arrow-up text-white">
    </i>
</a>
