import { Component, OnInit } from '@angular/core';
import {CustomerService} from '../customer.service'
import {AuthService} from '../../auth/auth.service'
@Component({
  selector: 'app-requests',
  templateUrl: './requests.component.html',
  styleUrls: ['./requests.component.css']
})
export class RequestsComponent implements OnInit {

  spinner: boolean = false;
  orderBy:number=-1;
  alert: any={
    show:false,
    icon:"fe-bell",
    message:"Partner View",
    type:"success"
  }

  constructor(public customerService: CustomerService, public authService: AuthService) { }

  ngOnInit(): void {
    this.spinner=true;
    this.customerService.getMyRequests(parseInt(this.authService.decodedTocken.nameid),this.orderBy).subscribe(r => {
      this.customerService.requests=r;
      this.customerService.leftBarHeaders.requestsCount=r.length;
      this.spinner=false;
    });
  }

  orderRequests(deviceValue): void{
    this.orderBy=deviceValue;
    this.spinner=true;
    this.customerService.getMyRequests(parseInt(this.authService.decodedTocken.nameid),parseInt(this.orderBy.toString())).subscribe(r => {
      this.customerService.requests=r;
      this.spinner=false;
    });
  }

}
