import { Component, OnInit } from '@angular/core';
import { AuthService} from '../auth/auth.service';
import {PriceService} from '../price/price.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-price-smartsalon',
  templateUrl: './price-smartsalon.component.html',
  styleUrls: ['./price-smartsalon.component.css']
})
export class PriceSmartsalonComponent implements OnInit {
  spinBasic: boolean = false;
  spinPro: boolean = false;
  spinUltimate: boolean = false;
 
  constructor(private authService: AuthService, private priceService: PriceService, private router: Router ) { }


  ngOnInit(): void {
  }
  navigateTo(type:number): void{
    
    
    if(this.authService.logued==true && this.authService.decodedTocken.role=="Customer"){
      if(type==0){
        this.spinBasic=true;
        this.priceService.createRequest({emailAddress:this.authService.decodedTocken.email, plan:'Basic', product:"SmartSalon", price:69.99}).subscribe(r => {
         
          if(r.status==201){
            this.router.navigate(['/dashboard/requests']);
          }
        })
        //this.spinBasic=false;
      }else if(type==1){
        this.spinPro=true;
        this.priceService.createRequest({emailAddress:this.authService.decodedTocken.email, plan:'Pro', product:"SmartSalon", price:89.99}).subscribe(r => {
         
          if(r.status==201){
            this.router.navigate(['/dashboard/requests']);
          }
        })
        //this.spinPro=false;

      }else if(type==2){
        this.spinUltimate=true;
        this.priceService.createRequest({emailAddress:this.authService.decodedTocken.email, plan:'Ultimate', product:"SmartSalon", price:129.99}).subscribe(r => {
         
          if(r.status==201){
            this.router.navigate(['/dashboard/requests']);
          }
        })
        //this.spinUltimate=true;
      }
    
    }else{
      this.router.navigate(['/auth']);
    }
  }
}
