import { Component, OnInit } from '@angular/core';
import { CustomerService } from 'src/app/dashboard/customer.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-email-confirmed',
  templateUrl: './email-confirmed.component.html',
  styleUrls: ['./email-confirmed.component.css']
})
export class EmailConfirmedComponent implements OnInit {

  confirmedView:boolean;
  constructor( private customerService: CustomerService, private activeRoute: ActivatedRoute) { }

  ngOnInit(): void {
    if(this.activeRoute.snapshot.queryParams.token){     
      this.customerService.verifyAccount(this.activeRoute.snapshot.queryParams.token).subscribe(r=>{       
        if(r.statusCode!=200){
          this.confirmedView=false;
        }else{
          this.confirmedView=true;
        }        
      });
    }else{
      this.confirmedView=false;
    }
    
  }

}
