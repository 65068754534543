<!-- Page title-->
<div class="d-md-flex justify-content-between pb-2 mb-4">
    <h1 class="mr-3" style="max-width: 38rem;">Requests</h1><span
            class="font-size-sm text-muted text-md-nowrap pt-2">Posted on May 13, 2020</span>
</div>
<p>In many places on this site you can find green buttons with title <a [routerLink]='["/faq/submit-request"]'>"Submit a request"</a> to help 
    you send questions to our different departments. To speed up the process and communicate with the 
    department best able to answer you, please select a topic before submitting your question.</p>
 <div class="row align-items-center pt-4 pb-md-3">
     <div class="col-md-7 mb-4"><img class="d-block rounded-lg" src="assets/img/pages/help-center/faq-img.jpg"
             alt="Image" />
     </div>
     <div class="col-md-5 pt-2 pt-md-0 mb-4">
         <h2 class="h5 mb-3">To know about the Quisoft apps see our demos</h2>
         <ul class="list-unstyled mb-0">
             <li class="d-flex mb-2"><i class="fe-check-circle font-size-lg text-success mt-1 mr-2" ></i><a [routerLink]="['/demo-admin']">NetBag-Manage Demo</a></li>
             <li class="d-flex mb-2"><i class="fe-check-circle font-size-lg text-success mt-1 mr-2"></i><a [routerLink]="['/demo-pos']">NetBag-POS Demo</a></li>
             <li class="d-flex mb-2"><i class="fe-check-circle font-size-lg text-success mt-1 mr-2"></i><a routerLink]="['/commin-soon']">NetBag-Online Store Demo</a></li>
            
         </ul>
     </div>
 </div>
 
