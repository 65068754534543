import { Component, OnInit } from '@angular/core';
import { PartnerService } from 'src/app/dashboard-admin/partner.service'
import { PartnersComponent } from 'src/app/dashboard-admin/partners/partners.component'
import { AlertService } from '../../../alert/alert.service';
import { AppService } from '../../../app.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

declare var $: any;


@Component({
  selector: 'app-partners-list',
  templateUrl: './partners-list.component.html',
  styleUrls: ['./partners-list.component.css']
})
export class PartnersListComponent implements OnInit {
  spinnerBtn:boolean=false;
  partnerId:number;  
  alert: any = {
    show: false,
    icon: "fe fe-bell",
    message: "Partner View",
    type: "success"
  }

  range = new FormGroup({
    start: new FormControl(new Date((new Date().getMonth() + 1).toString() + '/01/' + new Date().getFullYear().toString())),
    end: new FormControl(new Date())
  });

  constructor(public appService: AppService, public partnerService: PartnerService, public partnersComponent: PartnersComponent,public alertService: AlertService) { 

  }

  ngOnInit(): void {
    this.partnerService.spinner=true;
    this.partnerService.getPartnersList().subscribe(r => {
      this.partnerService.partners=r;
      this.partnerService.spinner=false;

    });
   
     
  }

  
  deletePartner(): void {
    this.spinnerBtn = true;
    this.partnerService.delete(this.partnerId).subscribe(r => {
     
      if (r.statusCode === 201) {//Remove partner from view-list
        this.partnerService.partners = this.partnerService.partners.filter(x => x.id != r.partner.id);
        $('#modal-delete').modal('hide');
        this.spinnerBtn = false;
        this.alertService.showAlert( "fe fe-bell", "Asociado eliminado correctamente","Partner removed successfully.","success");
        
        this.partnersComponent.loadHeaders();
        this.spinnerBtn = false;

      }
    }, err => {
      this.alertService.showAlert( "fe fe-alert-octagon","No se pudo eliminar el registro","Could not remove partner.","danger");
      
      this.spinnerBtn = false;

    });

  }


  createCompensation(): void {
    this.spinnerBtn = true;
    let values = this.range.value;
    this.partnerService.createCompensation(parseInt(this.partnerId.toString()),new Date(this.appService.fixInitDate(values.start)),new Date(this.appService.fixEndDate(values.end))).subscribe(r => {
     
      if (r.statusCode === 201) {
        
        $('#modal-new-compensation').modal('hide');
        this.spinnerBtn = false;
        this.alertService.showAlert( "fe fe-bell","Compensación creada correctamente","Compensation created successfully.","success");       
        this.spinnerBtn = false;

      }else  if (r.statusCode === 401) {
        this.spinnerBtn = false;
        this.alertService.showAlert( "fe fe-bell", "No elementos para crear compensación","Not items for create compensation.","warning");       
        this.spinnerBtn = false;
      }
    }, err => {
      console.log(err);
      
      this.alertService.showAlert( "fe fe-alert-octagon","No se pudo crear una compensación","Could not create compensation.","danger");
      
      this.spinnerBtn = false;

    });

  }

  changeDateRange(): void{
   // this.pagination.filter=-1;
    //this.pagination.page = 1;
    //this.getInvoices();
    //this.getHeaders();
  }
}
