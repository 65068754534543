<!--Header-->
<app-header-light></app-header-light>
<!--Content-->
<div *ngIf="confirmedView" class="content-app">
    <div *ngIf="confirmedView == true">
        <h2>You're request are linked successfully, thanks.</h2>
        <div class="col-md-6"><img class="d-block mx-auto" height="390px !important"
                src="assets/img/pages/coming-soon/illustration.svg" alt="Illustration" />
        </div>
        <p>Partners can help you with whatever you need.</p>
    </div>

    <div *ngIf="confirmedView == false">
        <h2>Token invalid or expired</h2>
        <div class="col-md-6"><img class="d-block mx-auto" height="390px !important"
                src="assets/img/pages/coming-soon/illustration.svg" alt="Illustration" />
        </div>
    </div>


</div>
<!--Footer-->
<app-contact-footer></app-contact-footer>
<!--Coommon-->
<app-top-arrow></app-top-arrow>
