<div class="d-flex flex-column h-100 bg-light rounded-lg box-shadow-lg p-4">
    <div class="py-2 p-md-3">
      <!-- Title + Filters-->
      <div class="d-sm-flex align-items-center justify-content-between pb-2">
        <h1 class="h3 mb-3 text-center text-sm-left" translate>dashboard.requests.title1</h1>
        <div class="d-flex align-items-center mb-3">
          <label class="text-nowrap pr-1 mr-2 mb-0" translate>dashboard.requests.title2</label>
          <select class="form-control custom-select custom-select-sm" (change)="orderRequests($event.target.value)">  
            <option value="-1" translate>dashboard.requests.filter_input.t1</option>    
            <option value="0" translate>dashboard.requests.filter_input.t2</option>           
            <option value="1" translate>dashboard.requests.filter_input.t3</option>
            <option value="2" translate>dashboard.requests.filter_input.t4</option>
    
          </select>
        </div>
      </div>
      <!-- Accordion with orders-->
      <div *ngIf="spinner==false" class="accordion" id="orders-accordion">
        <!-- Requests list-->
        <div *ngFor="let req of customerService.requests" class="card" >
          <div class="card-header">
            <div  class="accordion-heading"><a class="d-flex flex-wrap align-items-center justify-content-between pr-4" [href]="'#'+req.noRequest" role="button" data-toggle="collapse" aria-expanded="true" aria-controls="order-1">
                <div class="req-name font-size-sm font-weight-medium text-nowrap my-1 mr-2"><i class="fe-hash font-size-base mr-1"></i><span class="d-inline-block align-middle">{{req.noRequest }}</span> <span class="reqName align-middle"> ({{req.product}})</span></div>
                <div class="text-nowrap text-body font-size-sm font-weight-normal my-1 mr-2"><i class="fe-clock text-muted mr-1"></i>{{req.createdAt | date:'medium' }}</div>
                <div [ngClass]="{'bg-faded-danger text-danger': req.status==0, 'bg-faded-info text-info': req.status==1, 'bg-faded-success text-success': req.status==2}" class="font-size-xs font-weight-medium py-1 px-3 rounded-sm my-1 mr-2">
                  {{(req.status==0 ?'New service' : (req.status==1 ?'In progress' : 'Completed'))}}
                </div>
                <div class="text-body font-size-sm font-weight-medium my-1">${{req.salePrice | number:'.2'}}</div></a></div>
          </div>
          <div class="collapse" [id]="req.noRequest" data-parent="#orders-accordion">
            <div class="card-body pt-4 border-top bg-secondary">
              <!-- Item-->
              <div *ngFor="let app of req.apps" class="d-sm-flex justify-content-between mb-3 pb-1">
                <div class="order-item media media-ie-fix d-block d-sm-flex mr-sm-3">
                  <a class="d-table mx-auto" href="#">
                    <img *ngIf="app.appName=='NetBag-Manage'" width="80" src="assets/img/demo/services/04.svg" alt="Icon" class="d-inline-block mb-4 mt-2">
                    <img *ngIf="app.appName=='NetBag-POS'" width="80" src="assets/img/demo/services/05.svg" alt="Icon" class="d-inline-block mb-4 mt-2">
                    <img *ngIf="app.appName=='NetBag-Online Store'" width="80" src="assets/img/demo/services/01.svg" alt="Icon" class="d-inline-block mb-4 mt-2">
                    <img *ngIf="app.appName=='Q-Invoice'" width="80" src="assets/img/demo/services/011.svg" alt="Icon" class="d-inline-block mb-4 mt-2">
                
                  </a>
                  <div class="media-body font-size-sm pt-2 pl-sm-3 text-center text-sm-left">
                    <h5 class="nav-heading font-size-sm mb-2"><a href="#">{{app.number}}</a></h5>
                    <div><span class="text-muted mr-1" translate>dashboard.requests.items.title1</span>{{app.appName}}</div>
                    <div><span class="text-muted mr-1">Plan:</span>{{app.plan }}</div>
                  </div>
                </div>
                <div class="font-size-sm text-center pt-2 mr-sm-3">
                  <div class="text-muted" translate>dashboard.requests.items.title2</div>
                  <div  [ngClass]="{'text-danger': app.status==0, ' text-success': app.status==1}" class="font-weight-medium">{{(app.status==0 ?'Offline' : 'Online')}}</div>
                </div>
                <div class="font-size-sm text-center pt-2">
                  <div class="text-muted" translate>dashboard.requests.items.title3</div>
                  <div class="font-weight-medium">${{app.salePrice | number:'.2'}}</div>
                </div>
              </div>        
             
            </div>
          </div>
        </div>
      
      </div>
      <div *ngIf="spinner==true" class="loading">
        <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
        </div>
      </div>
      <!-- Pagination-->
      <nav class="d-md-flex justify-content-between align-items-center text-center text-md-left pt-grid-gutter">
        <div class="d-md-flex align-items-center w-100"><span class="font-size-sm text-muted mr-md-3"><span translate>dashboard.requests.title3</span> {{customerService.requests.length}} <span translate>dashboard.requests.title4</span> {{customerService.requests.length}} <span translate>dashboard.requests.title5</span></span>
          <div class="progress w-100 my-3 mx-auto mx-md-0" style="max-width: 10rem; height: 4px;">
            <div class="progress-bar" role="progressbar" style="width: 100%;" aria-valuenow="38" aria-valuemin="0" aria-valuemax="100"></div>
          </div>
        </div>
        <button class="btn btn-outline-primary btn-sm" type="button" translate>dashboard.requests.btn01</button>
      </nav>
    </div>
  </div>

