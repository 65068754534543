<!-- Menu-->
<ul class="navbar-nav">
  <li class="nav-item dropdown dropdown-mega"><a class="nav-link dropdown-toggle" data-toggle="dropdown" translate>Menu01.title</a>
    <div class="dropdown-menu">
      <a class="dropdown-column dropdown-column-img bg-secondary" [routerLink]='["/demo-admin"]'
        style="background-image: url(assets/img/homepage/demo/menu-banner.jpg);">
      </a>
      <div class="dropdown-column">
        <a class="dropdown-item" [routerLink]='["/demo-admin"]' translate>Menu01.sub01</a>
        <a class="dropdown-item" [routerLink]='["/demo-pos"]' translate>Menu01.sub02</a>
        <a class="dropdown-item" [routerLink]='["/demo-onlinestore"]' translate>Menu01.sub03</a>                
        <a class="dropdown-item" [routerLink]='["/coming-soon"]' translate>Menu01.sub04</a>
        <a class="dropdown-item" [routerLink]='["/demo-qinvoice"]' translate>Menu01.sub05</a>
        <a class="dropdown-item" [routerLink]='["/demo-smartsalon"]' translate>Menu01.sub06</a> 
        <a class="dropdown-item" [routerLink]='["/demo-rpcargo"]' translate>Menu01.sub07</a> 
        <a class="dropdown-item" [routerLink]='["/"]'></a>
      </div>

    </div>
  </li>

  <li class="nav-item dropdown"><a class="nav-link dropdown-toggle" data-toggle="dropdown" translate>Menu02.title</a>
    <ul class="dropdown-menu">
      <li *ngIf="!(authService.logued && authService.decodedTocken.role=='Customer')"><a class="dropdown-item" translate
          [routerLink]='["/auth"]'><i class="fe-users font-size-lg mr-2"></i> Menu02.sub01</a>
      </li>
      <li *ngIf="!(authService.logued)" class="dropdown-divider"></li>
      <li *ngIf="!(authService.logued && authService.decodedTocken.role=='Partner')"><a class="dropdown-item" translate
          [routerLink]='["/auth-partner"]'><i class="fe-user font-size-lg mr-2"></i> Menu02.sub02</a>
      </li>
      <li *ngIf="!(authService.logued && authService.decodedTocken.role=='Admin')" class="dropdown-divider"></li>
      <li *ngIf="!(authService.logued && authService.decodedTocken.role=='Admin')"><a class="dropdown-item" translate
          [routerLink]='["/auth-admin/login-admin"]'><i class="fe-lock font-size-lg mr-2"></i> Menu02.sub03</a></li>
    </ul>
  </li>
  <li class="nav-item dropdown"><a class="nav-link dropdown-toggle" data-toggle="dropdown" translate>Menu03.title</a>
    <ul class="dropdown-menu">
      <li><a class="dropdown-item" [routerLink]='["/about"]' translate>Menu03.sub01</a></li>
      <li class="dropdown-divider"></li>
      <li><a class="dropdown-item" [routerLink]='["/help"]' translate>Menu03.sub02</a></li>
      <li class="dropdown-divider"></li>
      <li><a class="dropdown-item" [routerLink]='["/contactus"]' translate>Menu03.sub03</a></li>
    </ul>
  </li>
  <li class="nav-item dropdown"><a class="nav-link dropdown-toggle" data-toggle="dropdown" translate>Menu04.title</a>
    <ul class="dropdown-menu">
      <li><a class="dropdown-item" [routerLink]='["/coming-soon"]'>
          <div class="d-flex align-items-center">
            <div class="font-size-xl text-muted"><i class="fe-file-text"></i></div>
            <div class="pl-3"><span class="d-block text-heading" translate>Menu04.sub01</span><small
                class="d-block text-muted" translate>Menu04.low01</small></div>
          </div>
        </a></li>
      <li class="dropdown-divider"></li>
      <li><a class="dropdown-item" [routerLink]='["/coming-soon"]'>
          <div class="d-flex align-items-center">
            <div class="font-size-xl text-muted"><i class="fe-layers"></i></div>
            <div class="pl-3"><span class="d-block text-heading" translate>Menu04.sub02<span
                  class="badge badge-danger ml-2">50+</span></span><small class="d-block text-muted" translate>Menu04.low02</small>
            </div>
          </div>
        </a></li>
      <li class="dropdown-divider"></li>
      <li><a class="dropdown-item" [routerLink]='["/faq/submit-request"]'>
          <div class="d-flex align-items-center">
            <div class="font-size-xl text-muted"><i class="fe-life-buoy"></i></div>
            <div class="pl-3"><span class="d-block text-heading" translate>Menu04.sub03</span><small
                class="d-block text-muted">developers@quisoft.us</small></div>
          </div>
        </a></li>
    </ul>
  </li>

  <li class="nav-item dropdown"><a class="nav-link dropdown-toggle pointer" data-toggle="dropdown">
      <flag-icon [country]="country_code | lowercase"></flag-icon> {{ country_code }}
    </a>
    <ul class="dropdown-menu">
      <li (click)="setLanguage('en')"><a class="dropdown-item pointer">
          <flag-icon country="us"></flag-icon> English
        </a>
      </li>
      <li class="dropdown-divider"></li>
      <li (click)="setLanguage('es')"><a class="dropdown-item pointer">
          <flag-icon country="es"></flag-icon> Español
        </a>
      </li>
    </ul>
  </li>







</ul>


