<!-- Page title-->
<div class="d-md-flex justify-content-between pb-2 mb-4">
    <h1 class="mr-3" style="max-width: 38rem;" translate>help.faq.title5</h1><span
    class="font-size-sm text-muted text-md-nowrap pt-2" translate>faq.content05.date</span>
</div>

<p translate>faq.content05.title2</p>
<div class="row align-items-center pt-4 pb-md-3">
    <div class="col-md-7 mb-4"><img class="d-block rounded-lg" src="assets/img/pages/help-center/stats-img.jpg"
            alt="Image" />
    </div>
    <div class="col-md-5 pt-2 pt-md-0 mb-4">
        <h2 class="h5 mb-3" translate>faq.content05.title3</h2>
        <ul class="list-unstyled mb-0">
            <li class="d-flex mb-2"><i class="fe-check-circle font-size-lg text-success mt-1 mr-2"></i><span translate>faq.content05.title4</span></li>
            <li class="d-flex mb-2"><i class="fe-check-circle font-size-lg text-success mt-1 mr-2"></i><span translate>faq.content05.title5</span></li>
            <li class="d-flex mb-2"><i class="fe-check-circle font-size-lg text-success mt-1 mr-2"></i><span translate>faq.content05.title6</span></li>
            <li class="d-flex mb-2"><i class="fe-check-circle font-size-lg text-success mt-1 mr-2"></i><span translate>faq.content05.title7</span></li>
            <li class="d-flex mb-2"><i class="fe-check-circle font-size-lg text-success mt-1 mr-2"></i><span translate>faq.content05.title8</span></li>

        </ul>
    </div>
</div>


