
import { Component, OnInit, HostListener } from '@angular/core';
import * as Parallax from 'parallax-js';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { CustomerService } from '.././dashboard/customer.service'
import { AlertService } from '../alert/alert.service'
import { FormGroup, FormBuilder, Validators, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';


@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.css']
})
export class HomepageComponent implements OnInit {
  showLightBar: boolean = false;
  translations;

  requestForm: FormGroup;
  spinner:boolean=false;

  constructor(private translateService: TranslateService, private fb: FormBuilder, public customerService:CustomerService, public alertService:AlertService) {
    this.translations = this.translateService.store.translations[`${this.translateService.defaultLang}`];
    
    this.requestForm = fb.group({    
      emailAddress: ['', [Validators.required, Validators.email]],     
    });

   }

  ngOnInit(): void {
   
    //put scroll on top
    window.scrollTo({top: 0, behavior: 'smooth'});

    
    let element = document.querySelectorAll('.cs-parallax');
    for (let i = 0; i < element.length; i++) {
      let parallaxInstance = new Parallax(element[i]);
    }
  }

  moveToDemos(value):void{
    window.scrollTo({top: value, behavior: 'smooth'});
  }

  @HostListener('window:scroll', ['$event.currentTarget'])
  onClick(e) {  
    if(e.scrollY > 600) {
      this.showLightBar=true;
    }else{
      this.showLightBar=false;
    }
  }


  sendRequest(): void{
   
    this.requestForm.markAllAsTouched();    
    if (this.requestForm.valid) {
      this.spinner = true;
      let values = this.requestForm.value;

      let data = {       
        topic: "Schedule a free consultation for customer",
        subject: "Consultation for customer",
        message: "A customer is requesting a free consultation from the web site to learn more about the Apps and our services",
        fullName: "Home Page Form",
        emailAddress: values.emailAddress,        
      }
      
      this.customerService.sendQuestionEmail(data).subscribe(r => {
        
        if(r.statusCode==201){
          this.requestForm.reset();
          this.alertService.showAlert( "fe fe-bell", "Correo enviado satisfactoriamente.","Mail sent successfully.","success");
           
        }else{
          this.alertService.showAlert( "fe fe-alert-octagon","Error al enviar el mensaje.","Error sending message, try again.","danger");
  
        }
        this.spinner = false;
      });
    }
    
  }

 

}
