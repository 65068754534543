<div class="d-flex flex-column h-100 bg-light rounded-lg box-shadow-lg p-4">
    <app-price></app-price>
    <br>
    <br>
    <hr class="ml-3 mr-3">
    <app-price-qinvoice></app-price-qinvoice>
    <br>
    <br>
    <hr class="ml-3 mr-3">
    <app-price-smartsalon></app-price-smartsalon>
  </div>
