import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ActivatedRoute } from '@angular/router';


const API_URI = environment.backend_url;
const BASE_URI =environment.base_url;
@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(private http: HttpClient,private activeRoute: ActivatedRoute) { }

  sendVerificationAccountEmail(fullName:string, toEmailAddress:string): Observable<any> {
    return this.http.post(API_URI + '/api/mailgun/sendemail',{
      fullName:fullName,
      domain:"quisoft.us",
      fromEmailAddress:"Quisoft Developers Group<noreply@quisoft.us>",
      toEmailAddress:toEmailAddress,
      Subject:"Action required",
      templateName:"accountConfirmation",
      setTtoken:true,
      tokenUrl:BASE_URI+"/auth-admin/a-email-confirmed?token="
    });

  }

  verifyAccount(token:string): Observable<any>{
    let helper = new JwtHelperService();     
     let email= helper.decodeToken(token).email;     
     console.log(email);
    return this.http.post(API_URI + '/api/admins/verifyaccount',{
      token:token,    
      email:email 
    });

    

  }

  sendResetPasswordEmail(toEmailAddress:string): Observable<any> {
   
    return this.http.post(API_URI + '/api/mailgun/send-reset-pass-email-admin',{
      fullName:"N/A",
      domain:"quisoft.us",
      fromEmailAddress:"Quisoft Developers Group<noreply@quisoft.us>",
      toEmailAddress:toEmailAddress,
      Subject:"Reset account password",
      templateName:"resetCustomerPassword",
      setTtoken:true,
      tokenUrl:BASE_URI+"/auth-admin/reset-pass?token="
    });

  }

  updatePasword(token:string, password:string): Observable<any>{
    let helper = new JwtHelperService();     
     let email= helper.decodeToken(token).email;     
    return this.http.post(API_URI + '/api/admins/updatepass',{
      token:token,    
      email:email,
      password:password
    });

  }

  getAdminInfo(id: number): Observable<any> {

    return this.http.get(API_URI + '/api/admins/' + id);

  }

  updateProfileInfo(id: number, data: any): Observable<any> {

    return this.http.put(API_URI + '/api/admins/' + id, data);

  }

  uploadAvatar(adminId: number, oldAvatar:string,name: string, strBase64: any):Observable<any>{    

    return this.http.post(API_URI + '/api/admins/uploadavatar',{
      customerId:adminId,
      oldAvatar:oldAvatar,
      name:name,
      strBase64:strBase64
    });
  }

}
