import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { AuthAdminService } from '../auth-admin/auth-admin.service';

@Injectable({
  providedIn: 'root'
})
export class AuthAdminGuardService implements CanActivate {

  constructor(public auth: AuthService, public authAdminService:AuthAdminService, public router: Router) { }

  canActivate(): boolean {   
   
    if (!this.authAdminService.isAuthenticated()) {
      this.router.navigate(['auth-admin/login-admin']);
      return false;
    }
    return true;
  }
}
