<app-header-login></app-header-login>
<!-- Page content-->
<!-- Background image-->
<div class="d-none d-md-block position-absolute w-50 h-100 bg-size-cover"
  style="top: 0; right: 0; background-image: url(assets/img/account/signin-admin.jpg);"></div>

<!-- Actual content-->
<section class="container d-flex align-items-center pt-7 pb-3 pb-md-4" style="flex: 1 0 auto;">
  <div class="w-100 pt-3">
    <div class="row">
      <div class="col-lg-4 col-md-6 offset-lg-1">

        <!-- Login and Register components are loading HERE -->
        <router-outlet></router-outlet>       

      </div>
    </div>
  </div>
</section>
<footer class="cs-footer py-4">
  <div class="container font-size-sm mb-0 py-3"><span class="text-muted mr-1" translate>homepage.footer.title5</span><a
      class="nav-link-style" href="https://www.quisoft.us" target="_blank" rel="noopener"  translate>homepage.footer.title6</a></div>
</footer>
