import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-content12',
  templateUrl: './content12.component.html',
  styleUrls: ['./content12.component.css']
})
export class Content12Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
    console.log('sasasasas');
      //put scroll on top
      window.scrollTo({top: 0, behavior: 'smooth'});
  }

}
