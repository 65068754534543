 <!-- Footer-->
 <footer class="cs-footer bg-dark pt-5 pt-md-6">
    <div class="container pt-3 pt-md-0">
      <div class="row pb-3">
        <div class="col-md-4 mt-n2 pb-3 pb-md-0 mb-4"><a class="d-block mb-3" [routerLink]='["/"]' style="width: 153px;"><img src="assets/img/logo/logo-footer-alt.png" alt="Around"/></a>
          <p class="font-size-sm text-light opacity-60 pb-2 pb-sm-3" translate>contact_footer.title1</p>
          <a class="social-btn sb-outline sb-facebook sb-light sb-lg mr-2 mb-2"  target="_blank" href="https://www.facebook.com/Quisoft-Developers-Group-107246347470754/"><i class="fe-facebook"></i></a>
          <a class="social-btn sb-outline sb-twitter sb-light sb-lg mr-2 mb-2"  target="_blank" href="https://twitter.com/QuisoftG"><i class="fe-twitter"></i></a>
          <a class="social-btn sb-outline sb-instagram sb-light sb-lg mr-2 mb-2" target="_blank" href="https://www.youtube.com/channel/UCqsXCFv6aNkqQyT0Knun30A?view_as=subscriber"><i class="fe-instagram"></i></a>
          <a class="social-btn sb-outline sb-google sb-light sb-lg mr-2 mb-2"  target="_blank" href="https://www.google.com/search?q=Quisoft&ludocid=9249108169776554310&lsig=AB86z5XhKMnjB0d1MJdL95VpEXzu"><i class="fe-google"></i></a>
        </div>
        <div class="col-md-2 col-sm-4 ml-auto pb-1 mb-4">
          <div class="cs-widget cs-widget-light">
            <h4 class="cs-widget-title" translate>contact_footer.title2</h4>
            <ul>
              <li><a class="cs-widget-link" [routerLink]='["/about"]' translate>contact_footer.sub01</a></li>
              <li><a class="cs-widget-link" [routerLink]='["/privacy"]' translate>contact_footer.sub02</a></li>
              <li><a class="cs-widget-link" [routerLink]='["/"]' translate>contact_footer.sub03</a></li>
            </ul>
          </div>
        </div>
        <div class="col-lg-2 col-md-3 col-sm-4 pb-1 mb-4">
          <div class="cs-widget cs-widget-light">
            <h4 class="cs-widget-title" translate>contact_footer.title3</h4>
            <ul>
              <li><a class="cs-widget-link" [routerLink]='["/"]' translate>contact_footer.sub04</a></li>
              <li><a class="cs-widget-link" [routerLink]='["/faq"]' translate>contact_footer.sub05</a></li>
              <li><a class="cs-widget-link" [routerLink]='["/"]' translate>contact_footer.sub06</a></li>              
            </ul>
          </div>
        </div>
        <div class="col-lg-2 col-md-3 col-sm-4 mb-4">
          <div class="cs-widget cs-widget-light">
            <h4 class="cs-widget-title" translate>contact_footer.title4</h4>
            <ul>
              <li><a class="cs-widget-link" [routerLink]='["/"]' translate>contact_footer.sub07</a></li>
              <li><a class="cs-widget-link" [routerLink]='["/"]' translate>contact_footer.sub08</a></li>
              <li><a class="cs-widget-link" [routerLink]='["/"]' translate>contact_footer.sub09</a></li>
            </ul>
          </div>
        </div>
      </div>
      <hr class="border-light">
      <div class="row align-items-center my-3 pt-4">
        <div class="col-md-6 order-md-2 text-md-right mb-3">
          <ul class="list-inline font-size-sm mb-0">
            <li class="list-inline-item"><a class="nav-link-style nav-link-light" [routerLink]='["/faq/submit-request"]' translate>contact_footer.title5</a></li>
            <li class="list-inline-item"><a class="nav-link-style nav-link-light" [routerLink]='["/contactus"]' translate>contact_footer.title6</a></li>
            <li class="list-inline-item"><a class="nav-link-style nav-link-light" [routerLink]='["/terms"]' translate>contact_footer.title7</a></li>
          </ul>
        </div>
        <div class="col-md-6 order-md-1 mb-3">
          <p class="font-size-sm mb-0"><span class="text-light opacity-50 mr-1" translate>homepage.footer.title5</span>
            <a class="nav-link-style nav-link-light" href="https://www.quisoft.us/" target="_blank" rel="noopener" translate>homepage.footer.title6</a></p>
        </div>
      </div>
    </div>
  </footer>
