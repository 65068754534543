import { Component, OnInit } from '@angular/core';
import { AppService } from '../../app.service'
import { trigger, state, style, animate, transition } from '@angular/animations';
import { CustomerService } from '../../dashboard/customer.service'
import { PartnerService } from '../../dashboard-admin/partner.service'
import { AuthService } from '../../auth/auth.service'
import { PayemntsMothodsService } from 'src/app/dashboard/payemnts-mothods.service'
import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable'
import { applyPlugin } from 'jspdf-autotable'
applyPlugin(jsPDF);
declare var Chart: any;
const doc = new jsPDF();
declare var $: any;
@Component({
  selector: 'app-earnings-partner',
  templateUrl: './earnings-partner.component.html',
  styleUrls: ['./earnings-partner.component.css'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('200ms', style({ opacity: 1 })),
      ]),

    ]),
  ]
})
export class EarningsPartnerComponent implements OnInit {
 
  spinnerChart: boolean = false;
  selectedYear: number = new Date().getFullYear();
  currentYear: number = new Date().getFullYear();
  compensations=[];

  labels=[];
  chartTitle=""

  spinnerHeaders: boolean = false;

  headers: any = {
    allEarnings: 0,
    currentBalance: 0,    
    lastPayment: 0,
    lastPaymentDate: 'mm/dd/yyyy',


  }
  constructor(public appService: AppService, public customerService: CustomerService, public partnerService: PartnerService, public auhtService: AuthService, public payemntsMothodsService: PayemntsMothodsService) { }

  ngOnInit(): void {
    if(this.appService.defaultLanguage.getValue() == 'es'){
      this.labels=['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];
      this.chartTitle="Ganancias";
    }else{
      this.labels=['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      this.chartTitle="Earnings";
    }
    this.loadEarningsStats();
    
  }

  loadEarningsStats(): void {
    this.spinnerChart = true;
    this.compensations=[];
    this.partnerService.getCompensations(parseInt(this.selectedYear.toString()), parseInt(this.auhtService.decodedTocken.nameid)).subscribe(r => {
      let amountList: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];     
      if (r) {
        r.map(v => {
          if (v.paymentMode > 0) {
            amountList[(new Date(v.initDate).getMonth())] += v.amountPaid;
            this.compensations[(new Date(v.initDate).getMonth())] = v;
          }
        });
        this.buildBarChart(amountList);
      }
      this.spinnerChart = false;
    });

  }

  buildBarChart(amountList): void {

    $( "div.chart-area" ).html( '<canvas id="chartDailySales"></canvas>' );
   
    var ctx = (document.getElementById('chartDailySales') as any).getContext('2d');    
   
    var gradient = ctx.createLinearGradient(0, 0, 0, 180);
    gradient.addColorStop(0, 'rgba(134, 194, 51, 0.4)');
    gradient.addColorStop(0.5, 'rgba(134, 194, 51, 0.1)');
    gradient.addColorStop(1, 'rgba(134, 194, 51, 0)');   

    var myChart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: this.labels,
        datasets: [{
          label:  this.chartTitle,
          data: amountList,          
          // borderCapStyle: 'round',
          backgroundColor: gradient,
          borderColor: ['rgb(116, 175, 31)',
            'rgb(116, 175, 31)',
            'rgb(116, 175, 31)',
            'rgb(116, 175, 31)',
            'rgb(116, 175, 31)',
            'rgb(116, 175, 31)',
            'rgb(116, 175, 31)',
            'rgb(116, 175, 31)',
            'rgb(116, 175, 31)',
            'rgb(116, 175, 31)',
            'rgb(116, 175, 31)',
            'rgb(116, 175, 31)'
          ],
          borderWidth: 3
        }]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        },
        onClick: (e) => {
          try{
            var activePoints = myChart.getElementAtEvent(e);  
          
            if(this.appService.defaultLanguage.getValue() == 'es'){
              this.imprimirCompensacion(this.compensations[activePoints[0]._index])
            } else{
              this.printCompensation(this.compensations[activePoints[0]._index])
            }   
           
          }catch (err) {

          }
         
      }
      }
    });
   
    myChart.canvas.parentNode.style.height = '200px';    
    // myChart.canvas.parentNode.style.width = '550px';    
    this.loadHeaders();
  }

  changeYear(deviceValue): void {
    this.selectedYear = deviceValue;
    this.loadEarningsStats();
  }

  loadHeaders(): void {
    this.spinnerHeaders = true;
  
    this.partnerService.getCompensations(parseInt(this.selectedYear.toString()), parseInt(this.auhtService.decodedTocken.nameid)).subscribe(r => {

      this.headers = {    
        allEarnings:0,   
        pendingPayouts: 0, 
        lastPayment: 0,
        lastPaymentDate: 'mm/dd/yyyy',
      }

      if (r) {
        var sum:number=0;
        r.map(v => {
          if (v.paymentMode == 0) {           
            this.headers.pendingPayouts = parseFloat(v.amountPaid).toFixed(2);           
            var createdAt = new Date(v.createdAt);
            var fixedDate = new Date();
            fixedDate.setDate(createdAt.getDate() + 5);
            this.partnerService.autoPayDate = fixedDate.toLocaleDateString('en-US');
            this.partnerService.autopayBalance = parseFloat(v.amountPaid).toFixed(2).toString();
           
          
            this.partnerService.leftBarHeaders.paymntDue = parseFloat(v.amountPaid).toFixed(2).toString();
            
          } else {
            //if (v.updatedAt != null) {
              if (this.headers.lastPaymentDate == 'mm/dd/yyyy') {
                this.headers.lastPayment = parseFloat(v.amountPaid).toFixed(2);
                this.headers.lastPaymentDate = new Date(v.updatedAt).toLocaleDateString('en-US');
              } else if ((new Date(this.headers.updatedAt) != null) && new Date(this.headers.updatedAt) > new Date(this.headers.lastPaymentDate)) {
                this.headers.lastPayment = parseFloat(v.amountPaid).toFixed(2);
                this.headers.lastPaymentDate = new Date(v.updatedAt).toLocaleDateString('en-US');
              }

            //}
              sum += parseFloat(v.amountPaid);

          }
        });
        this.headers.allEarnings= parseFloat(sum.toString()).toFixed(2);

      }
      this.spinnerHeaders = false;
    });

  }

  printCompensation(statement): void {
    try {
    
      if (statement.noCompensation != undefined) {

        let count = 0;
        let doc = new jsPDF('p', 'pt', 'letter'), rows = [], images = [], pos = 0;

        let columns = [{ title: 'Partner Information', dataKey: 'partner' }, { title: ' ', dataKey: 'empty' }];
        rows.push(
          { 'partner': 'Partner Name', 'empty': statement.partnerName },
          { 'partner': 'Partner Phone', 'empty': statement.partnerPhone },
          { 'partner': 'Billing Period', 'empty': 'From: ' + new Date(statement.initDate).toLocaleDateString('en-US') + '     To: ' + new Date(statement.endDate).toLocaleDateString('en-US') },

        );



        /********************************************* */
        doc.setDrawColor(this.appService.config.pdf.headerColor.R, this.appService.config.pdf.headerColor.G, this.appService.config.pdf.headerColor.B); doc.setFillColor(this.appService.config.pdf.headerColor.R, this.appService.config.pdf.headerColor.G, this.appService.config.pdf.headerColor.B);
        doc.rect(0, 0, 800, 70, 'F');
        doc.rect(0, doc.internal.pageSize.height - 25, 800, 25, 'F');
        doc.addImage(this.appService.logo.base64, this.appService.logo.x, this.appService.logo.y, this.appService.logo.w, this.appService.logo.h);

        doc.setFontSize(18); doc.setTextColor(255, 255, 255);

        doc.setTextColor(0, 0, 0); doc.setFontSize(10);
        doc.text(this.appService.config.pdf.address1, 25, 90);
        doc.text(this.appService.config.pdf.address2, 25, 100);
        doc.text(this.appService.config.pdf.phone, 25, 110);
        doc.text(this.appService.config.pdf.web, 25, 120);


        doc.text('Compensations Report', 425, 90);
        doc.text('______________________________', 425, 98);
        doc.text('Comp No: ' + statement.noCompensation, 425, 112);

        let d = new Date();

        doc.text('Created At: ' + new Date(statement.createdAt).toLocaleDateString('en-US'), 425, 123);

        autoTable(doc, {
          columns: columns,
          body: rows,
          startY: 160,
          margin: [90, 20, 70, 20],
          bodyStyles: {
            lineWidth: 0.5,
            cellPadding: 10,
            textColor: 20,
          },
          styles: {
            overflow: 'linebreak'
          },
          headStyles: {
            fillColor: '#86c233'
          },
          columnStyles: {
            partner: { cellWidth: 280 },
            empty: { cellWidth: 290 },

          },

        });

        let rows3 = [];
        let columns3 = [{ title: 'Earnings Resume', dataKey: 'partner' }, { title: ' ', dataKey: 'empty' }];
        rows3.push(
          { 'partner': ' Online Services', 'empty': statement.items.length.toString() },
          { 'partner': 'Services Profit', 'empty': '$' + parseFloat(statement.amountPaid).toFixed(2) },
          { 'partner': 'Other Payements', 'empty': '$0.0' },
          { 'partner': 'Total Earned', 'empty': '$' + parseFloat(statement.amountPaid).toFixed(2) },

        );
        let finalY = (doc as any).lastAutoTable.finalY;
        autoTable(doc, {
          columns: columns3,
          body: rows3,
          startY: finalY + 25,
          margin: [90, 20, 70, 20],
          bodyStyles: {
            lineWidth: 0.5,
            cellPadding: 10,
            textColor: 20,
          },
          styles: {
            overflow: 'linebreak'
          },
          headStyles: {
            fillColor: '#86c233'
          },
          columnStyles: {
            partner: { cellWidth: 280 },
            empty: { cellWidth: 290 },

          },
          willDrawCell: (data) => {                      
            if (data.row.index === 3) {
              doc.setFont('helvetica', 'bold');
              doc.setFontSize(12);
            }
          }
        });

        let rows4 = [];
        let columns4 = [{ title: 'Payment Resume', dataKey: 'payment' }, { title: ' ', dataKey: 'empty' }];
        rows4.push(
          { 'payment': 'Payment Mode', 'empty': (statement.paymentMode==0 ?'Unpaid' : (statement.paymentMode==1 ?'Bank Transfer' : (statement.paymentMode==2 ?'Check by mail': 'Cash Payment')))},
          { 'payment': 'Total Paid', 'empty': '$' + parseFloat(statement.amountPaid).toFixed(2) },     
          { 'payment': 'Bank Transaction / Check No.', 'empty': statement.bankTransaction},

        );
        finalY = (doc as any).lastAutoTable.finalY;
        autoTable(doc, {
          columns: columns4,
          body: rows4,
          startY: finalY + 25,
          margin: [90, 20, 70, 20],
          bodyStyles: {
            lineWidth: 0.5,
            cellPadding: 10,
            textColor: 20,
          },
          styles: {
            overflow: 'linebreak'
          },
          headStyles: {
            fillColor: '#86c233'
          },
          columnStyles: {
            payment: { cellWidth: 280 },
            empty: { cellWidth: 290 },

          },
          
        });
        doc.addPage();
        let position=0;
    
        statement.items.map(v => {

          let rows2 = [];
          let columns2 = [{ title: 'Service Information', dataKey: 'request' }, { title: ' ', dataKey: 'empty' }];

          let service = v;
          if (service != undefined) {
            count++;
            position+=40;
            rows2.push(
              { 'request': 'Application No', 'empty': service.number },
              { 'request': 'Application Name', 'empty': service.appName },
              { 'request': 'Plan', 'empty': service.plan },
              { 'request': 'Info collected on', 'empty': new Date(service.createdAt).toLocaleDateString('en-US') },
              { 'request': 'Status', 'empty': (service.status == 0 ? 'Offline' : 'Online') },             
              { 'request': 'Partner Name', 'empty': service.partnerName },
              { 'request': 'Partner Profit', 'empty': '$' + service.partnerProfit.toString() },

            );
          }
          let finalY = (doc as any).lastAutoTable.finalY;
          autoTable(doc, {
            columns: columns2,
            body: rows2,
            startY: (position==40 ? position : finalY+40),
            margin: [90, 20, 70, 20],

            bodyStyles: {
              lineWidth: 0.5,
              cellPadding: 10,
              textColor: 20,
            },            
            styles: {
              overflow: 'linebreak'
            },
            headStyles: {
              fillColor: '#86c233'
            },
            showHead:"firstPage",
            columnStyles: {
              request: { cellWidth: 280 },
              empty: { cellWidth: 290 },
  
            },
            willDrawCell: (data) => {                      
              if (data.row.index === 6) {
                doc.setFont('helvetica', 'bold');
                doc.setFontSize(12);
              }
            }
          });

        });

        //doc.output('dataurlnewwindow');
        doc.save('Comp(' + statement.noCompensation + ').pdf');
      } else {
        //this.showError("Does not have pending statements.");
      }
    } catch (err) {

      //this.showError(err);
      //this.hideBarTable();
    }
  }

  imprimirCompensacion(statement): void {
    try {
    
      if (statement.noCompensation != undefined) {

        let count = 0;
        let doc = new jsPDF('p', 'pt', 'letter'), rows = [], images = [], pos = 0;

        let columns = [{ title: 'Información del asociado', dataKey: 'partner' }, { title: ' ', dataKey: 'empty' }];
        rows.push(
          { 'partner': 'Nombre de Asociado', 'empty': statement.partnerName },
          { 'partner': 'Teléfono', 'empty': statement.partnerPhone },
          { 'partner': 'Período de facturación', 'empty': 'Desde: ' + new Date(statement.initDate).toLocaleDateString('en-US') + '     al: ' + new Date(statement.endDate).toLocaleDateString('en-US') },

        );



        /********************************************* */
        doc.setDrawColor(this.appService.config.pdf.headerColor.R, this.appService.config.pdf.headerColor.G, this.appService.config.pdf.headerColor.B); doc.setFillColor(this.appService.config.pdf.headerColor.R, this.appService.config.pdf.headerColor.G, this.appService.config.pdf.headerColor.B);
        doc.rect(0, 0, 800, 70, 'F');
        doc.rect(0, doc.internal.pageSize.height - 25, 800, 25, 'F');
        doc.addImage(this.appService.logo.base64, this.appService.logo.x, this.appService.logo.y, this.appService.logo.w, this.appService.logo.h);

        doc.setFontSize(18); doc.setTextColor(255, 255, 255);

        doc.setTextColor(0, 0, 0); doc.setFontSize(10);
        doc.text(this.appService.config.pdf.address1, 25, 90);
        doc.text(this.appService.config.pdf.address2, 25, 100);
        doc.text(this.appService.config.pdf.phone, 25, 110);
        doc.text(this.appService.config.pdf.web, 25, 120);


        doc.text('Informe de compensaciones', 425, 90);
        doc.text('______________________________', 425, 98);
        doc.text('Comp No: ' + statement.noCompensation, 425, 112);

        let d = new Date();

        doc.text('Creado el: ' + new Date(statement.createdAt).toLocaleDateString('en-US'), 425, 123);

        autoTable(doc, {
          columns: columns,
          body: rows,
          startY: 160,
          margin: [90, 20, 70, 20],
          bodyStyles: {
            lineWidth: 0.5,
            cellPadding: 10,
            textColor: 20,
          },
          styles: {
            overflow: 'linebreak'
          },
          headStyles: {
            fillColor: '#86c233'
          },
          columnStyles: {
            partner: { cellWidth: 280 },
            empty: { cellWidth: 290 },

          },

        });

        let rows3 = [];
        let columns3 = [{ title: 'Resumen de ganancias', dataKey: 'partner' }, { title: ' ', dataKey: 'empty' }];
        rows3.push(
          { 'partner': 'Servicios Online', 'empty': statement.items.length.toString() },
          { 'partner': 'Ganancias por servicios', 'empty': '$' + parseFloat(statement.amountPaid).toFixed(2) },
          { 'partner': 'Otros pagos', 'empty': '$0.0' },
          { 'partner': 'Total ganado', 'empty': '$' + parseFloat(statement.amountPaid).toFixed(2) },

        );
        let finalY = (doc as any).lastAutoTable.finalY;
        autoTable(doc, {
          columns: columns3,
          body: rows3,
          startY: finalY + 25,
          margin: [90, 20, 70, 20],
          bodyStyles: {
            lineWidth: 0.5,
            cellPadding: 10,
            textColor: 20,
          },
          styles: {
            overflow: 'linebreak'
          },
          headStyles: {
            fillColor: '#86c233'
          },
          columnStyles: {
            partner: { cellWidth: 280 },
            empty: { cellWidth: 290 },

          },
          willDrawCell: (data) => {                      
            if (data.row.index === 3) {
              doc.setFont('helvetica', 'bold');
              doc.setFontSize(12);
            }
          }
        });

        let rows4 = [];
        let columns4 = [{ title: 'Resumen del pago', dataKey: 'payment' }, { title: ' ', dataKey: 'empty' }];
        rows4.push(
          { 'payment': 'Modo de pago', 'empty': (statement.paymentMode==0 ?'Unpaid' : (statement.paymentMode==1 ?'Bank Transfer' : (statement.paymentMode==2 ?'Check by mail': 'Cash Payment')))},
          { 'payment': 'Total pagado', 'empty': '$' + parseFloat(statement.amountPaid).toFixed(2) },     
          { 'payment': 'Transacción bancaria / Cheque No.', 'empty': statement.bankTransaction},

        );
        finalY = (doc as any).lastAutoTable.finalY;
        autoTable(doc, {
          columns: columns4,
          body: rows4,
          startY: finalY + 25,
          margin: [90, 20, 70, 20],
          bodyStyles: {
            lineWidth: 0.5,
            cellPadding: 10,
            textColor: 20,
          },
          styles: {
            overflow: 'linebreak'
          },
          headStyles: {
            fillColor: '#86c233'
          },
          columnStyles: {
            payment: { cellWidth: 280 },
            empty: { cellWidth: 290 },

          },
          
        });
        doc.addPage();
        let position=0;
    
        statement.items.map(v => {

          let rows2 = [];
          let columns2 = [{ title: 'Información del Servicio ', dataKey: 'request' }, { title: ' ', dataKey: 'empty' }];

          let service = v;
          if (service != undefined) {
            count++;
            position+=40;
            rows2.push(
              { 'request': 'App No', 'empty': service.number },
              { 'request': 'Nombre de Aplicación', 'empty': service.appName },
              { 'request': 'Plan', 'empty': service.plan },
              { 'request': 'Información recopilada el', 'empty': new Date(service.createdAt).toLocaleDateString('en-US') },
              { 'request': 'Estado', 'empty': (service.status == 0 ? 'Offline' : 'Online') },             
              { 'request': 'Nombre del asociado', 'empty': service.partnerName },
              { 'request': 'Ganancias del asociado', 'empty': '$' + service.partnerProfit.toString() },

            );
          }
          let finalY = (doc as any).lastAutoTable.finalY;
          autoTable(doc, {
            columns: columns2,
            body: rows2,
            startY: (position==40 ? position : finalY+40),
            margin: [90, 20, 70, 20],

            bodyStyles: {
              lineWidth: 0.5,
              cellPadding: 10,
              textColor: 20,
            },            
            styles: {
              overflow: 'linebreak'
            },
            headStyles: {
              fillColor: '#86c233'
            },
            showHead:"firstPage",
            columnStyles: {
              request: { cellWidth: 280 },
              empty: { cellWidth: 290 },
  
            },
            willDrawCell: (data) => {                      
              if (data.row.index === 6) {
                doc.setFont('helvetica', 'bold');
                doc.setFontSize(12);
              }
            }
          });

        });

        //doc.output('dataurlnewwindow');
        doc.save('Comp(' + statement.noCompensation + ').pdf');
      } else {
        //this.showError("Does not have pending statements.");
      }
    } catch (err) {

      //this.showError(err);
      //this.hideBarTable();
    }
  }

}
