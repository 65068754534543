<main class="cs-page-wrapper">
    <!-- Sign In Modal-->
    <div class="modal fade" id="modal-signin" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content border-0">
          <div class="cs-view show" id="modal-signin-view">
            <div class="modal-header border-0 bg-dark px-4">
              <h4 class="modal-title text-light">Sign in</h4>
              <button class="close text-light" type="button" data-dismiss="modal" aria-label="Close"><span
                  aria-hidden="true">&times;</span></button>
            </div>
            <div class="modal-body px-4">
              <p class="font-size-ms text-muted">Sign in to your account using email and password provided during
                registration.</p>
              <form class="needs-validation" novalidate>
                <div class="input-group-overlay form-group">
                  <div class="input-group-prepend-overlay"><span class="input-group-text"><i class="fe-mail"></i></span>
                  </div>
                  <input class="form-control prepended-form-control" type="email" placeholder="Email" required>
                </div>
                <div class="input-group-overlay cs-password-toggle form-group">
                  <div class="input-group-prepend-overlay"><span class="input-group-text"><i class="fe-lock"></i></span>
                  </div>
                  <input class="form-control prepended-form-control" type="password" placeholder="Password" required>
                  <label class="cs-password-toggle-btn">
                    <input class="custom-control-input" type="checkbox"><i
                      class="fe-eye cs-password-toggle-indicator"></i><span class="sr-only">Show password</span>
                  </label>
                </div>
                <div class="d-flex justify-content-between align-items-center form-group">
                  <div class="custom-control custom-checkbox">
                    <input class="custom-control-input" type="checkbox" id="keep-signed">
                    <label class="custom-control-label" for="keep-signed">Keep me signed in</label>
                  </div><a class="nav-link-style font-size-ms" href="password-recovery.html">Forgot password?</a>
                </div>
                <button class="btn btn-primary btn-block" type="submit">Sign in</button>
                <p class="font-size-sm pt-3 mb-0">Don't have an account? <a href='#' class='font-weight-medium'
                    data-view='#modal-signup-view'>Sign up</a></p>
              </form>
            </div>
          </div>
          <div class="cs-view" id="modal-signup-view">
            <div class="modal-header border-0 bg-dark px-4">
              <h4 class="modal-title text-light">Sign up</h4>
              <button class="close text-light" type="button" data-dismiss="modal" aria-label="Close"><span
                  aria-hidden="true">&times;</span></button>
            </div>
            <div class="modal-body px-4">
              <p class="font-size-ms text-muted">Registration takes less than a minute but gives you full control over
                your orders.</p>
              <form class="needs-validation" novalidate>
                <div class="form-group">
                  <input class="form-control" type="text" placeholder="Full name" required>
                </div>
                <div class="form-group">
                  <input class="form-control" type="text" placeholder="Email" required>
                </div>
                <div class="cs-password-toggle form-group">
                  <input class="form-control" type="password" placeholder="Password" required>
                  <label class="cs-password-toggle-btn">
                    <input class="custom-control-input" type="checkbox"><i
                      class="fe-eye cs-password-toggle-indicator"></i><span class="sr-only">Show password</span>
                  </label>
                </div>
                <div class="cs-password-toggle form-group">
                  <input class="form-control" type="password" placeholder="Confirm password" required>
                  <label class="cs-password-toggle-btn">
                    <input class="custom-control-input" type="checkbox"><i
                      class="fe-eye cs-password-toggle-indicator"></i><span class="sr-only">Show password</span>
                  </label>
                </div>
                <button class="btn btn-primary btn-block" type="submit">Sign up</button>
                <p class="font-size-sm pt-3 mb-0">Already have an account? <a href='#' class='font-weight-medium'
                    data-view='#modal-signin-view'>Sign in</a></p>
              </form>
            </div>
          </div>
          <div class="modal-body text-center px-4 pt-2 pb-4">
            <hr>
            <p class="font-size-sm font-weight-medium text-heading pt-4">Or sign in with</p><a
              class="social-btn sb-facebook sb-lg mx-1 mb-2" [routerLink]='["/"]'><i class="fe-facebook"></i></a><a
              class="social-btn sb-twitter sb-lg mx-1 mb-2" [routerLink]='["/"]'><i class="fe-twitter"></i></a><a
              class="social-btn sb-instagram sb-lg mx-1 mb-2" [routerLink]='["/"]'><i class="fe-instagram"></i></a><a
              class="social-btn sb-google sb-lg mx-1 mb-2" [routerLink]='["/"]'><i class="fe-google"></i></a>
          </div>
        </div>
      </div>
    </div>
    <!-- Navbar (Solid background)-->
    <!-- Remove "navbar-sticky" class to make navigation bar scrollable with the page.-->
    <app-header-light></app-header-light>
    <!-- Page content-->
    <!-- Hero-->
    <section class="container-fluid px-0 pb-5 pb-md-6">
      <div class="row no-gutters align-items-xl-center">
        <div class="col-lg-6 col-md-7 text-right order-md-2"><img class="d-inline-block" width="945"
            src="assets/img/demo/rpcargo-img.jpg" alt="Business people" />
        </div>
        <div class="col-lg-6 col-md-5 pt-5 pb-3 py-md-5">
          <div class="mx-auto mr-md-5 mr-xl-7 pl-3 py-md-3 order-md-1" style="max-width: 516px;">
            <img class="d-inline-block" width="345"
            src="assets/img/demo/logos/rpcargo_logo.png" alt="Business people" />
            
            <p class="cs-callout d-block d-md-none d-lg-block font-size-lg text-muted mb-grid-gutter" translate>demo_rpcargo.hero.text1
              <a href="https://rpcargo.quisoft.us" style="color: #FF6600;" target="_blank" translate>https://rpcargo.quisoft.us</a>
            </p>
            <div class="pt-2"><a class="cs-video-btn cs-video-btn-primary cs-video-btn-sm mr-3" 
                href="https://youtu.be/fTL2ry2sew4" target="_blanck"></a><span
                class="font-size-sm" translate>demo_rpcargo.hero.text2</span></div>
          </div>
        </div>
      </div>
    </section>
    <!-- CTA-->
    <section class="bg-secondary bg-size-cover py-5 py-md-6"
      style="background-image: url(assets/img/demo/cta-bg.png);">
      <div class="container py-3 py-md-0">
        <div class="row align-items-center">
          <div class="col-lg-5 col-md-6 text-center text-md-left">
            <h2 class="mb-4 pb-3 pb-md-0 mb-md-0 text-white" translate>demo_rpcargo.cta.text1</h2>
          </div>
          <div class="col-lg-7 col-md-6">
            <form  [formGroup]="requestForm" (ngSubmit)="sendRequest()" class="row needs-validation pt-3" novalidate>
              <div class="col-sm-7 col-md-12 col-lg-7 mb-3 mb-lg-0">
                <input formControlName="emailAddress"  class="form-control" type="email" placeholder="{{'input_placeholder.label1' | translate}}" required
                [ngClass]="{'is-invalid': requestForm.controls.emailAddress.errors && (requestForm.controls.emailAddress.touched || requestForm.controls.emailAddress.dirty) }">
                <div class="invalid-feedback" translate>inputs_errors.error02</div>
              </div>
              <div class="col-sm-5 col-md-12 col-lg-5">              
                <button class="btn btn-primary mt-3 mt-sm-0" id="profile-btn" type="submit">
                  <i *ngIf="!spinner" class="fe-mail font-size-lg mr-2"></i>
                  <span *ngIf="!spinner" translate>demo_rpcargo.cta.btn1</span>
                  <div *ngIf="spinner" class="btn-spinner">
                    <div class="spinner-border spinner-border-sm mr-1" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow spinner-grow-sm mr-1" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                </button>
              </div>
            </form>
            <div class="d-flex align-items-center justify-content-center justify-content-sm-start pt-4 pt-sm-2 pt-md-4">
              <i class="fe-phone font-size-xl mr-2"></i><span class="mr-2" translate>demo_rpcargo.cta.text2</span><a
                class="nav-link-style text-white" href="tel:+17864705107">+ 1 786-292-1066</a></div>
          </div>
        </div>
      </div>
    </section>
    <!-- Services-->
    <section class="container py-5 py-md-6 py-lg-7 my-3">
      <div class="row align-items-center">
        <div class="col-lg-4 col-md-5 text-center text-md-left mb-5 mb-md-0">
          <h2 class="mb-3" translate>demo_rpcargo.services.title1</h2>
          <p class="text-muted mb-4 pb-2" translate>demo_rpcargo.services.text1</p><a
            class="btn btn-primary" href="https://rpcargo.quisoft.us" target="_blanck" translate>demo_rpcargo.services.btn1</a>
        </div>
        <div class="col-lg-8 col-md-7 bg-position-center bg-no-repeat"
          style="background-image: url(img/demo/business-consulting/services/bg-shape.svg);">
          <div class="mx-auto" style="max-width: 610px;">
            <div class="row align-items-center">
              <div class="col-sm-6">
                <div class="bg-light box-shadow-lg rounded-lg p-4 mb-grid-gutter text-center text-sm-left"><img
                    class="d-inline-block mb-4 mt-2" width="100" src="assets/img/demo/services/01.svg"
                    alt="Icon" />
                  <h3 class="h5 mb-2" translate>demo_rpcargo.services.title2</h3>
                  <p class="font-size-sm" translate>demo_rpcargo.services.text2</p>
                </div>
                <div class="bg-light box-shadow-lg rounded-lg p-4 mb-grid-gutter text-center text-sm-left"><img
                    class="d-inline-block mb-4 mt-2" width="100" src="assets/img/demo/services/02.svg"
                    alt="Icon" />
                  <h3 class="h5 mb-2" translate>demo_rpcargo.services.title3</h3>
                  <p class="font-size-sm" translate>demo_rpcargo.services.text3</p>
                </div>
                <div class="bg-light box-shadow-lg rounded-lg p-4 mb-grid-gutter text-center text-sm-left"><img
                    class="d-inline-block mb-4 mt-2" width="100" src="assets/img/demo/services/03.svg"
                    alt="Icon" />
                  <h3 class="h5 mb-2" translate>demo_rpcargo.services.title4</h3>
                  <p class="font-size-sm" translate>demo_rpcargo.services.text4</p>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="bg-light box-shadow-lg rounded-lg p-4 mb-grid-gutter text-center text-sm-left"><img
                    class="d-inline-block mb-4 mt-2" width="100" src="assets/img/demo/services/07.svg"
                    alt="Icon" />
                  <h3 class="h5 mb-2" translate>demo_rpcargo.services.title5</h3>
                  <p class="font-size-sm" translate>demo_rpcargo.services.text5</p>
                </div>
                <div class="bg-light box-shadow-lg rounded-lg p-4 mb-grid-gutter text-center text-sm-left"><img
                    class="d-inline-block mb-4 mt-2" width="100" src="assets/img/demo/services/05.svg"
                    alt="Icon" />
                  <h3 class="h5 mb-2" translate>demo_rpcargo.services.title6</h3>
                  <p class="font-size-sm" translate>demo_rpcargo.services.text6 </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    
    <!-- Industries-->
    <section class="bg-secondary py-5 py-md-6 mt-n4">
      <div class="container py-2">
        <div class="row justify-content-center">
          <div class="col-lg-6 col-md-8 col-sm-10 text-center">
            <h2  translate>demo_rpcargo.industries.title1</h2>
            <p class="text-muted mb-5 pb-2" translate>demo_rpcargo.industries.text1</p>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4 col-sm-6 mb-grid-gutter"><a class="card card-hover border-0 box-shadow mx-auto" [routerLink]='["/"]'
              style="max-width: 400px;"><img class="card-img-top" src="assets/img/demo/industries/07.webp"
                alt="Life Science" />
              <div class="card-body">
                <h3 class="h5 mb-0" translate>demo_rpcargo.industries.title2</h3>
              </div>
            </a></div>
          <div class="col-lg-4 col-sm-6 mb-grid-gutter"><a class="card card-hover border-0 box-shadow mx-auto" [routerLink]='["/"]'
              style="max-width: 380px;"><img class="card-img-top" src="assets/img/demo/industries/08.jpg"
                alt="Technology" />
              <div class="card-body">
                <h3 class="h5 mb-0" translate>demo_rpcargo.industries.title3</h3>
              </div>
            </a></div>
          <div class="col-lg-4 col-sm-6 mb-grid-gutter"><a class="card card-hover border-0 box-shadow mx-auto" [routerLink]='["/"]'
              style="max-width: 400px;"><img class="card-img-top" src="assets/img/demo/industries/09.jpg"
                alt="Healthcare" />
              <div class="card-body">
                <h3 class="h5 mb-0" translate>demo_rpcargo.industries.title4</h3>
              </div>
            </a></div>
        </div>
        <div class="pt-3 text-center"><a class="btn btn-primary" [routerLink]='["/"]' translate>demo_rpcargo.industries.btn1</a></div>
      </div>
    </section>

    <!-- Pricing-->
    <app-price-rpcargo></app-price-rpcargo>
    <!-- Testimonials-->
    <section class="container my-2 py-5 py-md-6 py-lg-7">
      <h2 class="text-center mb-5" translate>demo_admin.testimonials.title1</h2>
      <div class="row justify-content-center">
        <div class="col-xl-11">
          <div class="cs-carousel">
            <div class="cs-carousel-inner">
              <!-- Item-->
              <div>
                <div class="pb-3 pb-md-0 pl-md-7 ml-md-3">
                  <div class="bg-size-cover bg-position-center rounded-lg py-7"
                    style="background-image: url(assets/img/demo/testimonials/01.jpg);">
                    <div class="d-md-flex align-items-center ml-md-n7 text-center text-md-left">
                      <div
                        class="card card-body d-none d-md-flex py-grid-gutter px-grid-gutter border-0 box-shadow-lg mr-6 cs-scale-up"
                        style="max-width: 28rem;">
                        <blockquote class="blockquote">
                          <p translate>demo_admin.testimonials.text1</p>
                          <footer class="blockquote-footer">Robiel Perdomo, CEO Company Ltd.</footer>
                        </blockquote>
                      </div><a class="cs-video-btn cs-video-btn-sm my-4"
                        href="https://youtu.be/2amHHhXb12o"></a>
                    </div>
                  </div>
                  <div class="d-md-none mt-n6 px-3 cs-scale-up">
                    <div class="card card-body py-grid-gutter px-grid-gutter border-0 box-shadow mx-auto"
                      style="max-width: 28rem;">
                      <blockquote class="blockquote">
                        <p translate>demo_admin.testimonials.text1</p>
                        <footer class="blockquote-footer"> Robiel Perdomo, CEO Company Ltd.</footer>
                      </blockquote>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Item-->
              <div>
                <div class="pb-3 pb-md-0 pl-md-7 ml-md-3">
                  <div class="bg-size-cover bg-position-center rounded-lg py-7"
                    style="background-image: url(assets/img/demo/testimonials/02.jpg);">
                    <div class="d-md-flex align-items-center ml-md-n7 text-center text-md-left">
                      <div
                        class="card card-body d-none d-md-flex py-grid-gutter px-grid-gutter border-0 box-shadow-lg mr-6 cs-scale-up"
                        style="max-width: 28rem;">
                        <blockquote class="blockquote">
                          <p translate>demo_admin.testimonials.text2</p>
                          <footer class="blockquote-footer">Rolando Betancourt, CEO Company Ltd.</footer>
                        </blockquote>
                      </div><a class="cs-video-btn cs-video-btn-sm my-4"
                        href="https://www.youtube.com/watch?v=PjNJfOrKT3I"
                        data-sub-html="&lt;h6 class=&quot;font-size-sm text-light&quot;&gt;Video caption&lt;/h6&gt;"></a>
                    </div>
                  </div>
                  <div class="d-md-none mt-n6 px-3 cs-scale-up">
                    <div class="card card-body py-grid-gutter px-grid-gutter border-0 box-shadow mx-auto"
                      style="max-width: 28rem;">
                      <blockquote class="blockquote">
                        <p translate>demo_admin.testimonials.text2</p>
                        <footer class="blockquote-footer">Rolando Betancourt, CEO Company Ltd.</footer>
                      </blockquote>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

   
   
  </main>
 <!-- Footer-->
 <app-contact-footer></app-contact-footer>
  <!--Coommon-->
<app-top-arrow></app-top-arrow>




