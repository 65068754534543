import { Component, OnInit } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { FormGroup, FormBuilder, Validators, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminService } from 'src/app/dashboard-admin/admin.service';
import { AuthService } from '../../auth/auth.service';
import { JwtHelperService } from '@auth0/angular-jwt';


export const passwordValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
  const password = control.get('password');
  const confirmPassword = control.get('confirmPassword');
  return password && confirmPassword && password.value === confirmPassword.value ? null : { 'differentPassword': true };
};
@Component({
  selector: 'app-reset-pass',
  templateUrl: './reset-pass.component.html',
  styleUrls: ['./reset-pass.component.css'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('200ms', style({ opacity: 1 })),
      ]),
    ]),
  ]
})
export class ResetPassComponent implements OnInit {
  newPasswordForm: FormGroup;
  spinner: boolean = false;
  resetPassView: number;//0-Token expired, 1-form view, 2-password changed successfully

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private adminService: AdminService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private activeRoute: ActivatedRoute
  ) { 
    this.newPasswordForm = fb.group({
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', [Validators.required]]
    }, { validators: passwordValidator });
  }

  ngOnInit(): void {
    let helper = new JwtHelperService();
    if (this.activeRoute.snapshot.queryParams.token) {
      const token = this.activeRoute.snapshot.queryParams.token;
      if (!helper.isTokenExpired(token)) {
        this.resetPassView = 1;       
      } else {
        this.resetPassView = 0;
      }
    } else {
      this.resetPassView = 0;
    }
  }

  resetPassord(): void {
    let helper = new JwtHelperService();
    this.newPasswordForm.markAllAsTouched();
    if (this.newPasswordForm.valid) {
      this.spinner = true;
      const token = this.activeRoute.snapshot.queryParams.token;
      if (!helper.isTokenExpired(token)) {
        this.adminService.updatePasword(token, this.newPasswordForm.value.password).subscribe(r => {         
          if(r.statusCode!=200){
            this.resetPassView = 0;
            this.spinner = false;
          }else{
            this.resetPassView = 2;
            //this.router.navigate(['/auth/login']);
            this.spinner = false;
          }     
        });

      } else {
        this.resetPassView = 0;

      }


    }
  }

}
