import { Component, OnInit, HostListener } from '@angular/core';
import { AuthService} from '../auth/auth.service';
import { CustomerService} from '../dashboard/customer.service';

@Component({
  selector: 'app-dashboad-admin',
  templateUrl: './dashboard-admin.component.html',
  styleUrls: ['./dashboard-admin.component.css']
})
export class DashboardAdminComponent implements OnInit {
  show: boolean = false;
  showLightBar: boolean = false;
  constructor(public authService:AuthService, public customerService:CustomerService) { }

  ngOnInit(): void {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }

  collapse(): void {
    this.show = !this.show;
  }

  @HostListener('window:scroll', ['$event.currentTarget'])
  onClick(e) {  
    if(e.scrollY > 600) {
      this.showLightBar=true;
    }else{
      this.showLightBar=false;
    }
  }

}
