import { Component, OnInit } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { FormGroup, FormBuilder, Validators, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { AuthAdminService } from '../auth-admin.service';

import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { AsYouType } from 'libphonenumber-js'
import { parsePhoneNumber, ParseError } from 'libphonenumber-js'
import { AppService } from '../../app.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AdminService } from '../../dashboard-admin/admin.service';




export function validPhoneNumber(country: any): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const phoneNumber = parsePhoneNumberFromString(control.value, country);
    return !phoneNumber?.isValid() ? { invalid: { value: control.value } } : null;
  };
}


export const passwordValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
  const password = control.get('password');
  const confirmPassword = control.get('confirmPassword');
  return password && confirmPassword && password.value === confirmPassword.value ? null : { 'differentPassword': true };
};
@Component({
  selector: 'app-register-admin',
  templateUrl: './register-admin.component.html',
  styleUrls: ['./register-admin.component.css'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('200ms', style({ opacity: 1 })),
      ]),
    ]),
  ]
})
export class RegisterAdminComponent implements OnInit {
  
  registerForm: FormGroup;
  spinner: boolean = false;

  constructor( private fb: FormBuilder,
    private authAdminService: AuthAdminService,
    private appService: AppService,
    private router: Router,
    private adminService: AdminService,
    private route: ActivatedRoute) {

    this.registerForm = fb.group({
      fullName: ['', Validators.required],
      phone: [''],
      emailAddress: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', [Validators.required]]
    }, { validators: passwordValidator });

    this.appService.defaultCountry.subscribe(country => {
      this.registerForm.controls.phone.setValidators([Validators.required, validPhoneNumber(country)]);
    })
  }

  ngOnInit(): void {
  }

  onKeyPhone(e: KeyboardEvent): void {
    const mask = new AsYouType(this.appService.getCountry()).input(e.target['value']);
    if (e.keyCode >= 48 && e.keyCode <= 57 || this.registerForm.get('phone').valid) {
      this.registerForm.get('phone').setValue(mask);
    }
  }


  register(): void {

    this.registerForm.markAllAsTouched();
    if (this.registerForm.valid) {
      this.spinner = true;
      const asYouType: AsYouType = new AsYouType(this.appService.getCountry());
      asYouType.input(this.registerForm.value.phone);

      const admin = Object.assign(this.registerForm.value, {
        phone: asYouType.getNumber().number
      });

      this.authAdminService.register(admin).subscribe(r => {
        if (r.status === 201) {
          this.spinner = false;
          console.log('Admin registered successfully'); 
          this.adminService.sendVerificationAccountEmail(this.registerForm.value.fullName, this.registerForm.value.emailAddress).subscribe(z => {
          });
          this.router.navigate(['auth/confirm-email']);
        }
      }, err => {
        console.log(err);
        if (err.status == 400) {
          this.registerForm.controls.emailAddress.setErrors({ exist: err });
        }
        this.spinner = false;
      });
    }

  }



}
