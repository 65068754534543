<app-header-light></app-header-light>
<!-- Page content-->
<div class="container d-flex flex-column justify-content-center py-5" style="flex: 1 0 auto;">
    <div class="row align-items-center pb-2">
        <div class="col-xl-5 col-md-6 text-center text-md-left mb-5 mb-md-0">
            <h1 class="mb-3" translate>cooming_soon.title1</h1>
            <p class="mb-grid-gutter" translate>cooming_soon.title2</p>
            <div class="cs-countdown h2 justify-content-center justify-content-md-start"
                data-countdown="10/01/2021 07:00:00 PM">
                <div class="cs-countdown-days mr-grid-gutter"><span class="cs-countdown-value">0</span><span
                        class="cs-countdown-label text-body font-size-sm" translate>cooming_soon.title3</span></div>
                <div class="cs-countdown-hours mr-grid-gutter"><span class="cs-countdown-value">0</span><span
                        class="cs-countdown-label text-body font-size-sm" translate>cooming_soon.title4</span></div>
                <div class="cs-countdown-minutes mr-grid-gutter"><span class="cs-countdown-value">0</span><span
                        class="cs-countdown-label text-body font-size-sm" translate>cooming_soon.title5</span></div>
                <div class="cs-countdown-seconds"><span class="cs-countdown-value">0</span><span
                        class="cs-countdown-label text-body font-size-sm" translate>cooming_soon.title6</span></div>
            </div>
            <p class="pt-4" translate>cooming_soon.title7</p>
            <form class="d-flex align-items-start needs-validation" novalidate>
                <div class="mr-2 mr-sm-3 w-100">
                    <input class="form-control" type="email" placeholder="{{'input_placeholder.label1' | translate}}" required>
                    <div class="invalid-tooltip"translate>cooming_soon.title8</div>
                </div>
                <button class="btn btn-primary" type="submit" translate>cooming_soon.btn01</button>
            </form>
        </div>
        <div class="col-md-6 offset-xl-1"><img class="d-block mx-auto" src="assets/img/pages/coming-soon/illustration.svg"
                alt="Illustration" />
        </div>
    </div>
</div>
<!-- Footer-->
<app-contact-footer></app-contact-footer>
<!--Coommon-->
<app-top-arrow></app-top-arrow>