import { Component, OnInit } from '@angular/core';
import { PayemntsMothodsService } from 'src/app/dashboard/payemnts-mothods.service'


declare var $: any;
@Component({
  selector: 'app-payment-methods',
  templateUrl: './payment-methods.component.html',
  styleUrls: ['./payment-methods.component.css']
})
export class PaymentMethodsComponent implements OnInit {
 
  constructor(public paymentsMethods: PayemntsMothodsService ) { }

  ngOnInit(): void {
    this.paymentsMethods.getPaymentMethods();
    //this.paymentsMethods.newCardModalOpened();
  }




}
