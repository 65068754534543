<div *ngIf="confirmedView">
    <div *ngIf="confirmedView == true">
        <h2 translate>email_confirmed.title1</h2>
        <div class="col-md-6 offset-xl-1"><img class="d-block mx-auto" height="390px !important"
                src="assets/img/pages/coming-soon/illustration.svg" alt="Illustration" />
        </div>
        <p translate>email_confirmed.title2 <a [routerLink]="['/auth-partner']" translate>email_confirmed.title3</a></p>
    </div>


    <div *ngIf="confirmedView == false">
        <h2 translate>email_confirmed.title4</h2>
        <div class="col-md-6 offset-xl-1"><img class="d-block mx-auto" height="390px !important"
                src="assets/img/pages/coming-soon/illustration.svg" alt="Illustration" />
        </div>
    </div>


</div>
