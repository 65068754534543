<!--Header-->
<app-header-light></app-header-light>
<!--Content-->
<div  id="terms">
    <div class="container mt-4">
      <h1 translate>terms.title1</h1>
  
      <p translate>terms.title2 </p>
  
      <p translate>terms.title3</p>
  
      <h3 translate>terms.title4</h3>
  
      <p translate>terms.title5</p>
  
      <h3 translate>terms.title6</h3>
  
      <p translate>terms.title7</p>
  
      <p translate>terms.title8</p>
  
      <h3 translate>terms.title9</h3>
  
      <p translate>terms.title10</p>
  
      <h3 translate>terms.title11</h3>
  
      <p translate>terms.title12</p>
  
      <h3 translate>terms.title13</h3>
  
      <p translate>terms.title14</p>
  
      <h3 translate>terms.title15</h3>
  
      <p translate>terms.title16</p>
  
      <p translate>terms.title17</p>
  
      <h3 translate>terms.title18</h3>
  
      <p translate>terms.title19</p>
  
      <p translate>terms.title20</p>
  
    </div>
  </div>
<!--Footer-->
<app-contact-footer></app-contact-footer>
<!--Coommon-->
<app-top-arrow></app-top-arrow>
