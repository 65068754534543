<div class="cs-sidebar-enabled">
    <div class="container">
        <div class="row">
            <!-- Sidebar-->
            <div class="cs-sidebar col-lg-3 pt-lg-5">
                <div class="cs-offcanvas-collapse" id="help-sidebar">
                    <div class="cs-offcanvas-cap navbar-box-shadow px-4 mb-3">
                        <h5 class="mt-1 mb-0" translate>faq.left_bar.title1</h5>
                        <button class="close lead" type="button" data-toggle="offcanvas"
                            data-offcanvas-id="help-sidebar"><span aria-hidden="true">&times;</span></button>
                    </div>
                    <div class="cs-offcanvas-body px-4 pt-3 pt-lg-0 pl-lg-0 pr-lg-2 pr-xl-4" data-simplebar>
                        <!-- Search widget-->
                        <div class="cs-widget mb-5">
                            <h3 class="cs-widget-title" translate>faq.left_bar.title2</h3>
                            <div class="input-group-overlay">
                                <div class="input-group-prepend-overlay"><span class="input-group-text"><i
                                            class="fe-search"></i></span></div>
                                <input class="form-control prepended-form-control" type="text" placeholder="{{'input_placeholder.label14' | translate}}">
                            </div>
                        </div>
                        <!-- F.A.Q-->
                        <div class="cs-widget mb-5">
                            <h3 class="cs-widget-title" translate>faq.left_bar.title3</h3>
                            <ul>
                                <li class="d-flex"><i class="fe-book text-muted mt-2 mr-2"></i><a class="cs-widget-link"
                                    [routerLink]='["/faq/content01"]' routerLinkActive="active" translate>help.faq.title1</a></li>
                                <li class="d-flex"><i class="fe-book text-muted mt-2 mr-2"></i><a class="cs-widget-link"
                                    [routerLink]='["/faq/content02"]' routerLinkActive="active" translate>help.faq.title2</a></li>
                                <li class="d-flex"><i class="fe-book text-muted mt-2 mr-2"></i><a class="cs-widget-link"
                                    [routerLink]='["/faq/content03"]' routerLinkActive="active" translate>help.faq.title3</a></li>
                                <li class="d-flex"><i class="fe-book text-muted mt-2 mr-2"></i><a class="cs-widget-link"
                                    [routerLink]='["/faq/content04"]' routerLinkActive="active" translate>help.faq.title4</a></li>
                                <li class="d-flex"><i class="fe-book text-muted mt-2 mr-2"></i><a class="cs-widget-link"
                                    [routerLink]='["/faq/content05"]' routerLinkActive="active" translate>help.faq.title5</a></li>
                                <li class="d-flex"><i class="fe-book text-muted mt-2 mr-2"></i><a class="cs-widget-link"
                                    [routerLink]='["/faq/content06"]' routerLinkActive="active" translate>help.faq.title6</a></li>
                                <li class="d-flex"><i class="fe-book text-muted mt-2 mr-2"></i><a class="cs-widget-link"
                                    [routerLink]='["/faq/content07"]' routerLinkActive="active" translate>help.faq.title7</a></li>
                                <li class="d-flex"><i class="fe-book text-muted mt-2 mr-2"></i><a class="cs-widget-link"
                                    [routerLink]='["/faq/content08"]' routerLinkActive="active" translate>help.faq.title8</a></li>
                                <li class="d-flex"><i class="fe-book text-muted mt-2 mr-2"></i><a class="cs-widget-link"
                                    [routerLink]='["/faq/content09"]' routerLinkActive="active" translate>help.faq.title9</a></li>
                                <li class="d-flex"><i class="fe-book text-muted mt-2 mr-2"></i><a class="cs-widget-link"
                                    [routerLink]='["/faq/content10"]' routerLinkActive="active" translate>help.faq.title10</a></li>
                            </ul>
                        </div>
                         <!-- F.A.Q-->
                         <div class="cs-widget mb-5">
                            <h3 class="cs-widget-title" translate>faq.left_bar.title4</h3>
                            <ul>                                
                                <li class="d-flex"><i class="fe-book text-muted mt-2 mr-2"></i><a class="cs-widget-link"
                                    [routerLink]='["/faq/content12"]' routerLinkActive="active" translate>faq.left_bar.other_topics.title1</a></li>
                                <li class="d-flex"><i class="fe-book text-muted mt-2 mr-2"></i><a class="cs-widget-link"
                                    [routerLink]='["/faq/content13"]' routerLinkActive="active" translate>faq.left_bar.other_topics.title2</a></li>
                                <li class="d-flex"><i class="fe-book text-muted mt-2 mr-2"></i><a class="cs-widget-link"
                                    [routerLink]='["/faq/content14"]' routerLinkActive="active" translate>faq.left_bar.other_topics.title3</a></li>
                                <li class="d-flex"><i class="fe-book text-muted mt-2 mr-2"></i><a class="cs-widget-link"
                                    [routerLink]='["/faq/content15"]' routerLinkActive="active" translate>faq.left_bar.other_topics.title4</a></li>
                                <li class="d-flex"><i class="fe-book text-muted mt-2 mr-2"></i><a class="cs-widget-link"
                                    [routerLink]='["/faq/content16"]' routerLinkActive="active" translate>faq.left_bar.other_topics.title5</a></li>                               
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-9 cs-content py-4">
                <nav aria-label="breadcrumb">
                    <ol class="py-1 my-2 breadcrumb">
                        <li class="breadcrumb-item"><a [routerLink]='["/"]' translate>faq.left_bar.link1</a></li>
                        <li class="breadcrumb-item"><a [routerLink]='["/help"]' translate>faq.left_bar.link2</a>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page" translate>faq.left_bar.link3</li>
                    </ol>
                </nav>               
                <!-- Content-->
                <router-outlet></router-outlet>
                <!-- Rate article-->
                <app-last-content></app-last-content>
            </div>
        </div>
    </div>
</div>
