import { Component, HostListener } from '@angular/core';
import { AppService } from './app.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthService } from './auth/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Official-Site';
  
  constructor(private authService:AuthService){

  }

  ngOnInit(): void {
    let helper = new JwtHelperService();  
    const token=localStorage.getItem('token');
    if(token){
      this.authService.decodedTocken=helper.decodeToken(token);
      this.authService.logued=!helper.isTokenExpired(localStorage.getItem('token'));    
     
    }
   
  }
}
