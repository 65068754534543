<!-- Link expired -->
<div *ngIf="resetPassView == 0" @fadeIn class="container d-flex flex-column justify-content-center pt-5 mt-n6"
  style="flex: 1 0 auto;">
  <div class="pt-7 pb-5">
    <div class="text-center mb-2 pb-4">
      <h1 class="mb-grid-gutter"><img class="d-inline-block" src="/assets/img/pages/404/404-illustration.svg"
          alt="Error 404" /><span class="sr-only">Error 401</span><span
          class="d-block pt-3 font-size-sm font-weight-semibold text-danger">Error: Code 401</span>
      </h1>
      <h2 translate>password_reset.form_view.title1</h2>
      <p class="pb-2" translate>password_reset.form_view.title2</p><a class="btn btn-translucent-primary mr-3"
        [routerLink]='["/auth/forgot"]' translate>password_reset.form_view.btn01</a>
    </div>
  </div>
</div>
<!-- Form view -->
<div *ngIf="resetPassView == 1" @fadeIn>
  <h1 class="h2" translate>password_reset.form_view.title4</h1>
  <p class="font-size-ms text-muted mb-4" translate>password_reset.form_view.title5</p>

  <form [formGroup]="newPasswordForm" (ngSubmit)="resetPassord()" class="needs-validation" novalidate>

    <!-- Password Input -->
    <div class="cs-password-toggle form-group">
      <input #password
        [ngClass]="{'is-invalid': newPasswordForm.controls.password.errors && (newPasswordForm.controls.password.touched || newPasswordForm.controls.password.dirty) }"
        formControlName="password" class="form-control" type="password" placeholder="{{'input_placeholder.label2' | translate}}">

      <label class="cs-password-toggle-btn">
        <input (change)="$event.target.checked ? password.type = 'text' : password.type = 'password'"
          class="custom-control-input" type="checkbox">
        <i [style.color]="newPasswordForm.controls.password.errors && (newPasswordForm.controls.password.touched || newPasswordForm.controls.password.dirty) ? '#f74f78' : ''"
          class="fe-eye cs-password-toggle-indicator"></i><span class="sr-only">Show password</span>
      </label>
      <div *ngIf="newPasswordForm.controls.password.errors?.required" class="invalid-feedback ml-2" translate>
        inputs_errors.error04
      </div>
      <div *ngIf="newPasswordForm.controls.password.errors?.minlength" class="invalid-feedback ml-2" translate>
        inputs_errors.error05
      </div>
    </div>

    <!-- Confirm password Input -->
    <div class="cs-password-toggle form-group">
      <input #confirmPassword
        [ngClass]="{'is-invalid': ( newPasswordForm.controls.confirmPassword.errors || newPasswordForm.errors?.differentPassword) && (newPasswordForm.controls.confirmPassword.touched || newPasswordForm.controls.confirmPassword.dirty) }"
        formControlName="confirmPassword" class="form-control" type="password" placeholder="{{'input_placeholder.label3' | translate}}">
      <label class="cs-password-toggle-btn">
        <input (change)="$event.target.checked ? confirmPassword.type = 'text' : confirmPassword.type = 'password'"
          class="custom-control-input" type="checkbox">
        <i [style.color]="( newPasswordForm.controls.confirmPassword.errors || newPasswordForm.errors?.differentPassword) && (newPasswordForm.controls.confirmPassword.touched || newPasswordForm.controls.confirmPassword.dirty) ? '#f74f78' : ''"
          class="fe-eye cs-password-toggle-indicator"></i><span class="sr-only">Show
          password</span>
      </label>
      <div *ngIf="newPasswordForm.controls.confirmPassword.errors?.required" class="invalid-feedback ml-2" translate>
        inputs_errors.error10
      </div>
      <div
        *ngIf="newPasswordForm.errors?.differentPassword && !newPasswordForm.controls.confirmPassword.errors?.required"
        class="invalid-feedback ml-2" translate>
        inputs_errors.error11
      </div>
    </div>

    <button class="btn btn-primary btn-block" type="submit">
      <span *ngIf="!spinner" translate>password_reset.form_view.btn02</span>
      <div *ngIf="spinner" class="btn-spinner">
        <div class="spinner-border spinner-border-sm mr-1" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow spinner-grow-sm mr-1" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </button>

    <p class="font-size-sm pt-3 mb-0" translate>password_reset.form_view.title6 <a [routerLink]="['/auth/login']" class='font-weight-medium' translate>password_reset.form_view.title7</a>
    </p>
  </form>
</div>

<!-- Password changed successfully -->
<div *ngIf="resetPassView == 2" @fadeIn class="container d-flex flex-column justify-content-center pt-5 mt-n6"
  style="flex: 1 0 auto;">
  <div class="pt-7 pb-5">
    <div class="text-center mb-2 pb-4">
      <h1 class="mb-grid-gutter"><img class="d-inline-block" src="/assets/img/pages/pass-changed/image-1.png"
          alt="Code 200" /><span class="sr-only" translate>password_reset.form_view.title8</span>
      </h1>
      <h2 translate>password_reset.form_view.title9</h2>
      <p class="pb-2" translate>password_reset.form_view.title10</p><a class="btn btn-translucent-primary mr-3"
        [routerLink]='["/auth/login"]' translate>password_reset.form_view.title7 </a>
    </div>
  </div>
</div>