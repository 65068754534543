<!--header-->
<app-header-dash></app-header-dash>
<!-- Slanted background-->
<div class="position-relative bg-gradient" style="height: 480px; background: #1e1e2e !important;">
  <div class="cs-shape cs-shape-bottom cs-shape-slant bg-secondary d-none d-lg-block">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3000 260">
      <polygon fill="currentColor" points="0,257 0,260 3000,260 3000,0"></polygon>
    </svg>
  </div>
</div>

<!-- Sidebar-->
<app-side-bar></app-side-bar>
<!--Coommon-->
<app-top-arrow></app-top-arrow>

<!-- Remove card Modal-->
<div class="modal fade" id="modal-delete" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content border-0">
      <div class="cs-view show" id="modal-heroku-view">
        <div class="modal-header bg-danger border-1 px-4">
          <h3 class="text-white" translate>dashboard.remove_modal.title1</h3>
          <button class="close text-white" type="button" data-dismiss="modal" aria-label="Close"><span
              aria-hidden="true">&times;</span></button>
        </div>

        <div class="modal-body px-4">
          <div class="container">
            <h3 translate>dashboard.remove_modal.title2</h3>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn ajax-button btn-danger btn-size" type="button"
            (click)="paymentsMethods.deletePaymentMethods()">
            <span *ngIf="!paymentsMethods.spinnerDelete" translate>dashboard.remove_modal.btn01</span>
            <div *ngIf="paymentsMethods.spinnerDelete" class="btn-spinner">
              <div class="spinner-border spinner-border-sm mr-1" role="status">
                <span class="sr-only">Loading...</span>
              </div>
              <div class="spinner-grow spinner-grow-sm mr-1" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- New credit card Modal -->
<div class="modal fade" id="new-card-modal">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content border-0">
      <div class="modal-header border-1 px-4">
        <img src="assets/img/logo/Stripe-Payment-Logo.png" height="40px" style="height: 40px!important;" />
        <button class="close text-dark" type="button" data-dismiss="modal" aria-label="Close"><span
            aria-hidden="true">&times;</span></button>
      </div>
      <div class="modal-body px-4">
        <!--app-stripe-card (stripe)="createToken($event)"-->


        <form [formGroup]="newCardForm" (ngSubmit)="addNewcard()" class="needs-validation" novalidate>

          <div class="form-group">
            <label for="name" translate>input_placeholder.label21</label>
            <stripe-card class="form-control" [class.is-invalid]="invalidError" #stripeCard
              (catch)="onStripeError($event)" [(invalid)]="invalidError" (cardMounted)="cardReady = true"
              (tokenChange)="setStripeToken($event)" (sourceChange)="setStripeSource($event)"></stripe-card>
            <div *ngIf="invalidError" class="invalid-feedback" style="display: block;">
              {{ invalidError.message }}
            </div>
          </div>

          <!-- name Input -->
          <div class="form-group">
            <label _ngcontent-xbq-c130="" for="name" translate>input_placeholder.label5</label>
            <input formControlName="name"
              [ngClass]="{'is-invalid': newCardForm.controls.name.errors && (newCardForm.controls.name.touched || newCardForm.controls.name.dirty) }"
              class="form-control" type="text" placeholder="{{'input_placeholder.label6' | translate}}">
            <div *ngIf="newCardForm.controls.name.errors?.required" class="invalid-feedback ml-2" translate>
              inputs_errors.error06
            </div>
          </div>

          <!-- address_line1 Input -->
          <div class="form-group">
            <label _ngcontent-xbq-c130="" for="address_line1" translate>input_placeholder.label16</label>
            <input formControlName="address_line1"
              [ngClass]="{'is-invalid': newCardForm.controls.address_line1.errors && (newCardForm.controls.address_line1.touched || newCardForm.controls.address_line1.dirty) }"
              class="form-control" type="text" placeholder="{{'input_placeholder.label6' | translate}}">
            <div class="invalid-feedback ml-2" translate>
              inputs_errors.error15
            </div>
          </div>

          <!-- address_city -->
          <div class="form-group">
            <label _ngcontent-xbq-c130="" for="account-country" translate>input_placeholder.label17</label>
            <input formControlName="address_city"
              [ngClass]="{'is-invalid': newCardForm.controls.address_city.errors && (newCardForm.controls.address_city.touched || newCardForm.controls.address_city.dirty) }"
              class="form-control" type="text" placeholder="{{'input_placeholder.label6' | translate}}">
            <div class="invalid-feedback ml-2" translate>
              inputs_errors.error16
            </div>
          </div>

          <!-- address_state Input -->
          <div class="form-group">
            <label _ngcontent-xbq-c130="" for="address_state" translate>input_placeholder.label18</label>
            <input formControlName="address_state"
              [ngClass]="{'is-invalid': newCardForm.controls.address_state.errors && (newCardForm.controls.address_state.touched || newCardForm.controls.address_state.dirty) }"
              class="form-control" type="text" placeholder="{{'input_placeholder.label6' | translate}}">
            <div class="invalid-feedback ml-2" translate>
              inputs_errors.error17
            </div>
          </div>

          <!-- address_zip -->
          <div class="form-group">
            <label _ngcontent-xbq-c130="" for="address_zip" translate>input_placeholder.label19</label>
            <input formControlName="address_zip"
              [ngClass]="{'is-invalid': newCardForm.controls.address_zip.errors && (newCardForm.controls.address_zip.touched || newCardForm.controls.address_zip.dirty) }"
              class="form-control" type="text" placeholder="{{'input_placeholder.label7' | translate}}">
            <div class="invalid-feedback ml-2" translate>
              inputs_errors.error18
            </div>
          </div>

          <!--button type="button" (click)="stripeCard.createToken(extraData)">createToken</button-->


          <button class="btn btn-primary btn-block" type="submit" (click)="addNewcard() ? stripeCard.createToken(extraData) : undefined ">
            <span *ngIf="!paymentsMethods.spinnerBtn" translate>dashboard.payment_methods.btn02</span>
            <div *ngIf="paymentsMethods.spinnerBtn" class="btn-spinner">
              <div class="spinner-border spinner-border-sm mr-1" role="status">
                <span class="sr-only">Loading...</span>
              </div>
              <div class="spinner-grow spinner-grow-sm mr-1" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </button>


        </form>


        <!--/app-stripe-card-->
      </div>
    </div>

  </div>
</div>


<!-- Make payment Modal-->
<div class="modal fade" id="modal-payment" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content border-0">
      <div class="cs-view show" id="modal-heroku-view">
        <div class="modal-header bg-primary border-1 px-4">
          <h3 class="text-white" translate><i class="fe fe-lock"></i> dashboard.make_payment_modal.title1</h3>
          <button class="close text-white" type="button" data-dismiss="modal" aria-label="Close"><span
              aria-hidden="true">&times;</span></button>
        </div>

        <div class="modal-body px-4">
          <div class="container">
            <div class="col px-2 mb-3">
              <div class="bg-white h-100 rounded-lg p-4 text-center">
                <h2 class="font-weight-medium text-body" translate>dashboard.make_payment_modal.title2</h2>
               
                <p class="h2 mb-2">${{customerService.autopayBalance}}</p>
                <h3 class="text-muted mt-0" translate>dashboard.make_payment_modal.title3</h3>
                <div *ngIf="paymentsMethods.cards.length>0" class="media align-items-center">
                  <div class="media-body">
                    <img class="mr-2" width="39" [src]="paymentsMethods.cards[0].logo" alt="Visa" />
                    <h3><span class="font-weight-medium text-heading mr-1">{{paymentsMethods.cards[0].brand}}</span><span translate>dashboard.make_payment_modal.title4</span>
                     {{paymentsMethods.cards[0].last4}}</h3>
                    <!--span *ngIf="card.id==defaultCard" class="align-middle badge badge-info ml-2">Primary</span-->
                  </div>
                </div>
               
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn ajax-button btn-primary btn-size" [disabled]="spinnerBtn" (click)="makePayment()" type="button">            
            <span *ngIf="!spinnerBtn" translate><i class="fe-credit-card font-size-lg mr-2"></i>dashboard.make_payment_modal.btn01</span>
            <div *ngIf="spinnerBtn" class="btn-spinner">
              <div class="spinner-border spinner-border-sm mr-1" role="status">
                <span class="sr-only">Loading...</span>
              </div>
              <div class="spinner-grow spinner-grow-sm mr-1" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<!--Update invoice Modal -->
<div class="modal fade" id="modal-update-invoice">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content border-0">
      <div class="modal-header border-1 px-4">
        <img src="https://res.cloudinary.com/quisoft/image/upload/v1612747112/Quisoft/QinvoiceLogo_bqbx3v.jpg" height="40px" style="height: 40px!important;" />
        <button class="close text-dark" type="button" data-dismiss="modal" aria-label="Close"><span
            aria-hidden="true">&times;</span></button>
      </div>
      <div class="modal-body px-4">
       <form [formGroup]="updInvoiceForm" (ngSubmit)="updateInvoice()" class="needs-validation" novalidate>         
          <!-- PaymentMode Input -->
          <div class="form-group">
            <label _ngcontent-xbq-c130="" for="paymentMode" translate>input_placeholder.label22</label>
            <input formControlName="paymentMode"
              [ngClass]="{'is-invalid': updInvoiceForm.controls.paymentMode.errors && (updInvoiceForm.controls.paymentMode.touched || updInvoiceForm.controls.paymentMode.dirty) }"
              class="form-control" type="text" placeholder="{{'input_placeholder.label6' | translate}}">
            <div *ngIf="updInvoiceForm.controls.paymentMode.errors?.required" class="invalid-feedback ml-2" translate>
              inputs_errors.error20
            </div>
          </div>

          <!-- PaymentReference Input -->
          <div class="form-group">
            <label _ngcontent-xbq-c130="" for="paymentReference" translate>input_placeholder.label23</label>
            <input formControlName="paymentReference"
              [ngClass]="{'is-invalid': updInvoiceForm.controls.paymentReference.errors && (updInvoiceForm.controls.paymentReference.touched || updInvoiceForm.controls.paymentReference.dirty) }"
              class="form-control" type="text" placeholder="{{'input_placeholder.label6' | translate}}">
            <div class="invalid-feedback ml-2" translate>
              inputs_errors.error20
            </div>
          </div>

           <!-- status Input -->
           <div class="form-group">
            <label _ngcontent-xbq-c130="" for="account-country" translate>input_placeholder.label24</label>
            <select formControlName="status"
              [ngClass]="{'is-invalid': updInvoiceForm.controls.status.errors && (updInvoiceForm.controls.status.touched || updInvoiceForm.controls.status.dirty) }"
              class="form-control" type="text" placeholder="Text">
              <option value="0" translate>input_placeholder.label26</option>
              <option value="1" translate>input_placeholder.label25</option>
            </select>
            <div class="invalid-feedback ml-2" translate>
              inputs_errors.error20
            </div>
          </div>
        
          <button class="btn btn-primary btn-block" type="submit">
            <span *ngIf="!spinnerBtn" translate>dashboard.q_invoice.nav2.btn01</span>
            <div *ngIf="spinnerBtn" class="btn-spinner">
              <div class="spinner-border spinner-border-sm mr-1" role="status">
                <span class="sr-only">Loading...</span>
              </div>
              <div class="spinner-grow spinner-grow-sm mr-1" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </button>


        </form>


        <!--/app-stripe-card-->
      </div>
    </div>

  </div>
</div>


<!-- Remove QInvoice Modal-->
<div class="modal fade" id="modal-remove-qinvoice" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content border-0">
      <div class="cs-view show" id="modal-heroku-view">
        <div class="modal-header bg-danger border-1 px-4">
          <h3 class="text-white" translate>dashboard.remove_modal.title1</h3>
          <button class="close text-white" type="button" data-dismiss="modal" aria-label="Close"><span
              aria-hidden="true">&times;</span></button>
        </div>

        <div class="modal-body px-4">
          <div class="container">
            <h3 translate>dashboard.remove_modal.title3</h3>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn ajax-button btn-danger btn-size" type="button"
            (click)="customerService.deleteQInvoiceById()">
            <span *ngIf="!customerService.spinnerDelete" translate>dashboard.remove_modal.btn01</span>
            <div *ngIf="customerService.spinnerDelete" class="btn-spinner">
              <div class="spinner-border spinner-border-sm mr-1" role="status">
                <span class="sr-only">Loading...</span>
              </div>
              <div class="spinner-grow spinner-grow-sm mr-1" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>