<!--Header-->
<app-header-light></app-header-light>
<!-- Page title-->
<section class="jarallax bg-gradient pt-5 pb-7 pt-md-7" data-jarallax data-speed="0.25"><span
        class="bg-overlay bg-gradient" style="opacity: 1;background: #1e1e2e !important;"></span>
    <div class="jarallax-img" style="background-image: url(img/pages/contacts/page-title-bg.jpg);"></div>
    <div class="cs-shape cs-shape-bottom cs-shape-slant bg-body">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3000 260">
            <polygon fill="currentColor" points="0,257 0,260 3000,260 3000,0"></polygon>
        </svg>
    </div>
    <div class="container bg-overlay-content pt-3 pb-7 pt-md-0">
        <div class="row justify-content-center pb-7">
            <div class="col-lg-10">
                <h1 class="text-light" translate>contactus.page_title.text1</h1>
                <p class="text-light" translate>contactus.page_title.text2</p>
            </div>
        </div>
    </div>
</section>
<!-- Contact form + details-->
<section class="container bg-overlay-content pb-5">
    <div class="row">
        <div class="col-lg-6 col-md-7 offset-lg-1 pb-2 mb-5" style="margin-top: -260px;">
            <div class="card border-0 box-shadow-lg">
                <form [formGroup]="requestForm" (ngSubmit)="sendRequest()" class="card-body needs-validation p-5" novalidate>
                    <div class="form-group">
                        <label class="form-label" for="fullName" translate>input_placeholder.label5<sup class="text-danger ml-1">*</sup></label>
                        <input  formControlName="fullName" class="form-control" type="text" id="fullName" placeholder="John Doe" required
                        [ngClass]="{'is-invalid': requestForm.controls.fullName.errors && (requestForm.controls.fullName.touched || requestForm.controls.fullName.dirty) }">
                        <div class="invalid-feedback" translate>inputs_errors.error06</div>
                    </div>
                    <div class="form-group">
                        <label class="form-label" for="cont-email" translate>input_placeholder.label1<sup
                                class="text-danger ml-1">*</sup></label>
                        <input formControlName="emailAddress" class="form-control" type="email" id="cont-email" placeholder="j.doe@example.com" required
                            [ngClass]="{'is-invalid': requestForm.controls.emailAddress.errors && (requestForm.controls.emailAddress.touched || requestForm.controls.emailAddress.dirty) }">
                        <div class="invalid-feedback" translate>inputs_errors.error02</div>
                    </div>
                    <div class="form-group">
                        <label class="form-label" for="cont-phone" translate>input_placeholder.label4<sup
                            class="text-danger ml-1">*</sup></label>
                        <input formControlName="phone" class="form-control" type="text" id="cont-phone" data-format="custom"
                            data-delimiter="-" data-blocks="2 4 2 2" placeholder="000-000-0000" required
                            [ngClass]="{'is-invalid': requestForm.controls.phone.errors && (requestForm.controls.phone.touched || requestForm.controls.phone.dirty) }">
                            <div class="invalid-feedback" translate>inputs_errors.error07</div>
                    </div>
                    <div class="form-group">
                        <label class="form-label" for="cont-company" translate>input_placeholder.label9<sup
                            class="text-danger ml-1">*</sup></label>
                        <input formControlName="company" class="form-control" type="text" id="cont-company" placeholder="{{'input_placeholder.label6' | translate}}" required
                        [ngClass]="{'is-invalid': requestForm.controls.company.errors && (requestForm.controls.company.touched || requestForm.controls.company.dirty) }">
                    </div>
                    <div class="form-group">
                        <label class="form-label" for="cont-subject" translate>input_placeholder.label10<sup
                                class="text-danger ml-1">*</sup></label>
                        <input formControlName="subject" class="form-control" type="text" id="cont-subject" placeholder="{{'input_placeholder.label11' | translate}}" required
                        [ngClass]="{'is-invalid': requestForm.controls.subject.errors && (requestForm.controls.subject.touched || requestForm.controls.subject.dirty) }">
                        <div class="invalid-feedback" translate>inputs_errors.error12</div>
                    </div>
                    <div class="form-group">
                        <label class="form-label" for="cont-message" translate>input_placeholder.label12<sup
                                class="text-danger ml-1">*</sup></label>
                        <textarea formControlName="message"  class="form-control" id="cont-message" rows="5" placeholder="{{'input_placeholder.label13' | translate}}" required
                            [ngClass]="{'is-invalid': requestForm.controls.message.errors && (requestForm.controls.message.touched || requestForm.controls.message.dirty) }">
                        </textarea>
                        <div class="invalid-feedback" translate>inputs_errors.error13</div>
                    </div>
                    <div class="text-center pt-2">
                      
                        <button class="btn btn-primary" id="profile-btn" type="submit">
                            <i *ngIf="!spinner" class="fe-mail font-size-lg mr-2"></i>
                            <span *ngIf="!spinner" translate>contactus.page_title.btn1</span>
                            <div *ngIf="spinner" class="btn-spinner">
                              <div class="spinner-border spinner-border-sm mr-1" role="status">
                                <span class="sr-only">Loading...</span>
                              </div>
                              <div class="spinner-grow spinner-grow-sm mr-1" role="status">
                                <span class="sr-only">Loading...</span>
                              </div>
                            </div>
                          </button>
                    </div>
                </form>
            </div>
        </div>
        <div class="col-lg-4 col-md-4 offset-md-1">
            <h2 class="h4 pb-3" translate>contactus.page_title.text3</h2>
            <h3 class="h6 pb-2">North America - Miami, FL</h3>
            <ul class="list-unstyled font-size-sm pb-3">
                <li class="d-flex align-items-top mb-3"><i
                        class="fe-map-pin font-size-xl text-muted mt-1 mr-2 pr-1"></i>
                    <div>2345 W 3rd Ct, Hialeah,<br>FL 33010, USA<br><a class="cs-fancy-link" href="#map"
                            data-scroll translate>contactus.page_title.text4</a></div>
                </li>
                <li class="d-flex align-items-center mb-3"><i class="fe-mail font-size-xl text-muted mr-2 pr-1"></i>
                    <div>info@quisoft.us</div>
                </li>
                <li class="d-flex align-items-center mb-3"><i class="fe-phone font-size-xl text-muted mr-2 pr-1"></i>
                    <div>+ 1 786-292-1066</div>
                </li>
            </ul>
            <h3 class="h6 pb-2">Europe - Berlin, Germany</h3>
            <ul class="list-unstyled font-size-sm">
                <li class="d-flex align-items-top mb-3"><i
                        class="fe-map-pin font-size-xl text-muted mt-1 mr-2 pr-1"></i>
                    <div>Mohrenstrasse 37 10117,<br>Berlin, Germany<br></div>
                </li>
                <li class="d-flex align-items-center mb-3"><i class="fe-mail font-size-xl text-muted mr-2 pr-1"></i>
                    <div>info@quisoft.us</div>
                </li>
                <li class="d-flex align-items-center mb-3"><i class="fe-phone font-size-xl text-muted mr-2 pr-1"></i>
                    <div>030 788 545 11</div>
                </li>
            </ul>
        </div>
    </div>
</section>
<!-- Map-->
<section class="cs-gallery" id="map">
    <a  class="cs-gallery-item cs-map-popup bg-position-center bg-no-repeat py-7 text-center"
        href="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d193595.91476818218!2d-74.11976253858133!3d40.69740344296443!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62!2sNew%20York%2C%20NY%2C%20USA!5e0!3m2!1sen!2sua!4v1568574342685!5m2!1sen!2sua"
        data-iframe="true"
        data-sub-html="&lt;h6 class=&quot;font-size-sm text-light&quot;&gt;396 Lillian Blvd, Holbrook, NY 11741&lt;/h6&gt;"
        style="background-image: url(assets/img/pages/contacts/map03.jpg);"><span class="cs-gallery-caption" translate><i
                class="fe-maximize-2 font-size-xl mt-n1 mr-2"></i>contactus.map.text1</span>
        <div class="d-inline-block py-4 py-md-6"><img width="48" src="assets/img/pages/contacts/marker.png" alt="Map marker" />
        </div>
    </a>
</section>
<!--Footer-->
<app-contact-footer></app-contact-footer>
<!--Coommon-->
<app-top-arrow></app-top-arrow>
