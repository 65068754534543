import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { AuthPartnerService } from '../auth-partner/auth-partner.service';
@Injectable({
  providedIn: 'root'
})
export class AuthPartnerGuardService implements CanActivate {

  constructor(public auth: AuthService, public authPartnerService:AuthPartnerService, public router: Router) {}

  canActivate(): boolean {   
   
    if (!this.authPartnerService.isAuthenticated()) {
      this.router.navigate(['auth-partner/login-partner']);
      return false;
    }
    return true;
  }
}

