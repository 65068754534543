import { Component, OnInit } from '@angular/core';
import { PartnerService } from 'src/app/dashboard-admin/partner.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-p-email-confirmed',
  templateUrl: './p-email-confirmed.component.html',
  styleUrls: ['./p-email-confirmed.component.css']
})
export class PEmailConfirmedComponent implements OnInit {
  confirmedView:boolean;
  constructor(private partnerService: PartnerService, private activeRoute: ActivatedRoute) { }

  ngOnInit(): void {
    if(this.activeRoute.snapshot.queryParams.token){     
      this.partnerService.verifyAccount(this.activeRoute.snapshot.queryParams.token).subscribe(r=>{       
        if(r.statusCode!=200){
          this.confirmedView=false;
        }else{
          this.confirmedView=true;
        }        
      });
    }else{
      this.confirmedView=false;
    }
    
  }

}
