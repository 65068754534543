<!-- Page title-->
<div class="d-md-flex justify-content-between pb-2 mb-4">
    <h1 class="mr-3" style="max-width: 38rem;" translate>faq.submit_req.title1</h1><span
            class="font-size-sm text-muted text-md-nowrap pt-2" translate>faq.submit_req.date</span>
</div>
<div class="alert alert-info font-size-md mb-4" role="alert" translate><i class="fe-alert-circle font-size-xl mt-n1 mr-3"></i>faq.submit_req.title2</div>
             
<!-- Request form-->
<form  [formGroup]="requestForm" (ngSubmit)="sendRequest()" class="row needs-validation pt-3" novalidate>
    <div class="col-sm-6 form-group">
      <label class="form-label" for="topic" translate> input_placeholder.label15<sup class="text-danger ml-1">*</sup></label>
      <select  formControlName="topic" class="form-control custom-select" id="topic" required
      [ngClass]="{'is-invalid': requestForm.controls.topic.errors && (requestForm.controls.topic.touched || requestForm.controls.topic.dirty) }">
        <option value="" selected disabled hidden>&mdash;</option>
        <option value="Managing Account" translate>faq.submit_req.input_topics.t1</option>
        <option value="Working with Dashboard" translate>faq.submit_req.input_topics.t2</option>
        <option value="Payment Methods" translate>faq.submit_req.input_topics.t3</option>
        <option value="Delivery Information" translate>faq.submit_req.input_topics.t4</option>
        <option value="Refund Policy" translate>faq.submit_req.input_topics.t5</option>
        <option value="Affiliate Program" translate>faq.submit_req.input_topics.t6</option>
        <option value="Affiliate Program" translate>faq.submit_req.input_topics.t7</option>
      </select>
      <div class="invalid-feedback" translate>inputs_errors.error14</div>
    </div>
    <div class="col-sm-6 form-group">
      <label class="form-label" for="subject" translate>input_placeholder.label10<sup class="text-danger ml-1">*</sup></label>
      <input class="form-control" formControlName="subject" type="text" id="subject" placeholder="{{'input_placeholder.label6'| translate}}" required
      [ngClass]="{'is-invalid': requestForm.controls.subject.errors && (requestForm.controls.subject.touched || requestForm.controls.subject.dirty) }"> 
      <div class="invalid-feedback" translate>inputs_errors.error12</div>
    </div>
    <div class="col-12 form-group">
      <label class="form-label" for="message"  translate>input_placeholder.label12<sup class="text-danger ml-1">*</sup></label>
      <textarea class="form-control" formControlName="message" rows="6" id="message" placeholder="{{'input_placeholder.label6'| translate}}" required
        [ngClass]="{'is-invalid': requestForm.controls.message.errors && (requestForm.controls.message.touched || requestForm.controls.message.dirty) }">
      </textarea>
      <div class="invalid-feedback" translate>inputs_errors.error13</div>
    </div>
    <div class="col-sm-6 form-group">
      <label class="form-label" for="fullName"  translate>input_placeholder.label5<sup class="text-danger ml-1">*</sup></label>
      <input class="form-control" formControlName="fullName" type="text" id="fullName" placeholder="{{'input_placeholder.label6'| translate}}" required
      [ngClass]="{'is-invalid': requestForm.controls.fullName.errors && (requestForm.controls.fullName.touched || requestForm.controls.fullName.dirty) }">
      <div class="invalid-feedback"  translate>inputs_errors.error06</div>
    </div>
    <div class="col-sm-6 form-group">
      <label class="form-label" for="emailAddress"  translate>input_placeholder.label1<sup class="text-danger ml-1">*</sup></label>
      <input class="form-control" type="email" formControlName="emailAddress" id="emailAddress" placeholder="{{'input_placeholder.label1'| translate}}" required
      [ngClass]="{'is-invalid': requestForm.controls.emailAddress.errors && (requestForm.controls.emailAddress.touched || requestForm.controls.emailAddress.dirty) }">
      <div class="invalid-feedback" translate> inputs_errors.error02</div>
    </div>
   
    <!--div class="col-12 form-group pb-1 pt-3">
      <div class="custom-control custom-checkbox">
        <input class="custom-control-input" type="checkbox" id="request-terms" required>
        <label class="custom-control-label" for="request-terms" translate>faq.submit_req.title3 <a href='#' class='nav-link-style' translate>faq.submit_req.title4</a></label>
      </div>
    </div-->
    <div class="col-12">
      
      <button class="btn btn-primary mt-3 mt-sm-0" id="profile-btn" type="submit">
        <i *ngIf="!spinner" class="fe-mail font-size-lg mr-2"></i>
        <span *ngIf="!spinner" translate>faq.submit_req.btn1</span>
        <div *ngIf="spinner" class="btn-spinner">
          <div class="spinner-border spinner-border-sm mr-1" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          <div class="spinner-grow spinner-grow-sm mr-1" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </button>
    </div>
</form>