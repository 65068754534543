<!--Header-->
<app-header-light></app-header-light>
<!--Content-->
<div id="privacy">
    <div class="container mt-4">
      <h1 translate>privacy.title1</h1>
      <hr/>
      <h3 translate>privacy.title2</h3>
  
      <p translate>privacy.title3</p>
      <p translate>privacy.title4 <a [routerLink]="['/terms']" translate>privacy.title5</a> <span translate>privacy.title6</span></p>

      <h3 translate>privacy.title7</h3>
      <ol>
        <li translate>privacy.title8</li>
        <li translate>privacy.title9</li>
        <li translate>privacy.title10</li>
        <li translate>privacy.title11</li>
      </ol>
  
      <h4 translate>privacy.title12</h4>
  
      <p translate>privacy.title13</p>
  
      <p translate>privacy.title14</p>
      <p translate>privacy.title15</p>
      <p translate>privacy.title16</p>
      <p translate>privacy.title17</p>
  

      <h4 translate>privacy.title19</h4>
      <p translate>privacy.title20</p>
      <p translate>privacy.title21</p>
      <p translate>privacy.title22</p>
      <p translate>privacy.title23</p>
      <p translate>privacy.title24</p>
      <p translate>privacy.title25</p>
      <p translate>privacy.title26</p>

      <h4 translate>privacy.title27</h4>  
      <p translate>privacy.title28</p>
      <h4 translate>privacy.title29</h4>
      <p translate>privacy.title30</p>



      <p translate>privacy.title31<a [routerLink]="['/contactus']" translate>privacy.title32</a></p>
  
      <h4 translate>privacy.title33</h4>
  
      <p translate>privacy.title34</p>
      <p translate>privacy.title35 <a [routerLink]="['/contactus']" translate>privacy.title36</a> <span translate>privacy.title37</span></p>
  
      <h4 translate>privacy.title38</h4>
  
      <p translate>privacy.title39</p>
      <p translate>privacy.title40</p>
      <p translate>privacy.title41</p>
      <p translate>privacy.title42</p>
      <p translate>privacy.title43</p>
     
      <p translate>privacy.title44 <a [routerLink]="['/contactus']" translate>privacy.title45</a></p>
  
      <p translate>privacy.title46</p>
    </div>
  </div>
<!--Footer-->
<app-contact-footer></app-contact-footer>
<!--Coommon-->
<app-top-arrow></app-top-arrow>