import { Component, OnInit } from '@angular/core';
import {PartnerService} from '../../dashboard-admin/partner.service'
import {AuthService} from '../../auth/auth.service'

@Component({
  selector: 'app-my-customers',
  templateUrl: './my-customers.component.html',
  styleUrls: ['./my-customers.component.css']
})
export class MyCustomersComponent implements OnInit {
  spinner: boolean = false;
 
  alert: any={
    show:false,
    icon:"fe-bell",
    message:"Partner View",
    type:"success"
  }

  constructor(public partnerService: PartnerService, public authService: AuthService) { }

  ngOnInit(): void {
    this.loadCustomers();
  }



  loadCustomers(): void{
    this.spinner=true;
    this.partnerService.getMyCustomers(parseInt(this.authService.decodedTocken.nameid)).subscribe(r => {
      if( r.statusCode = 201){       
        this.partnerService.customers=r.customers;     
      }else{
        this.partnerService.customers=[];
      }      
      this.spinner=false;
    });
  }

}
