import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-header-dark',
  templateUrl: './header-dark.component.html',
  styleUrls: ['./header-dark.component.css']
})
export class HeaderDarkComponent implements OnInit {
  show: boolean = false;
  constructor(public authService:AuthService) { }

  ngOnInit(): void {
  
  }
  collapse(): void {
    this.show = !this.show;
  }
}
