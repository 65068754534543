<header class="cs-header navbar navbar-expand-lg navbar-dark navbar-floating navbar-sticky">
  <div class="container px-0 px-xl-3">
    <button class="navbar-toggler ml-n2 mr-2" type="button" data-toggle="offcanvas" (click)="collapse()"
      data-offcanvas-id="primaryMenu"><span class="navbar-toggler-icon"></span></button><a
      class="navbar-brand order-lg-1 mx-auto ml-lg-0 pr-lg-2 mr-lg-4" [routerLink]='["/"]'><img
        class="navbar-floating-logo d-none d-lg-block" width="153" src="assets/img/logo/logo-light.png"
        alt="Around" /><img class="navbar-stuck-logo" width="153" src="assets/img/logo/logo-dark.png"
        alt="Around" /><img class="d-lg-none" width="58" src="assets/img/logo/logo-icon.png" alt="Around" /></a>
    <!--Nav-profile-menu-->
    <app-profile-menu class="d-flex align-items-center order-lg-3 ml-lg-auto"></app-profile-menu>
    
    <div class="cs-offcanvas-collapse order-lg-2" id="primaryMenu" [class.show]="show">
      <div class="cs-offcanvas-cap navbar-box-shadow">
        <h5 class="mt-1 mb-0">Menu</h5>
        <button class="close lead" type="button" data-toggle="offcanvas" data-offcanvas-id="primaryMenu" (click)="collapse()"><span
            aria-hidden="true">&times;</span></button>
      </div>
      <div class="cs-offcanvas-body">
        <!-- Menu-->
        <app-menu></app-menu>
      </div>
    </div>
  </div>
</header>