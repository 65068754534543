import { Component, OnInit } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { FormGroup, FormBuilder, Validators, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { AuthPartnerService } from 'src/app/auth-partner/auth-partner.service';

import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { AsYouType } from 'libphonenumber-js'
import { parsePhoneNumber, ParseError } from 'libphonenumber-js'
import { AppService } from 'src/app/app.service';
import { Router, ActivatedRoute } from '@angular/router';
import { PartnerService } from 'src/app/dashboard-admin/partner.service';
import { AlertService } from '../../alert/alert.service';

export function validPhoneNumber(country: any): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const phoneNumber = parsePhoneNumberFromString(control.value, country);
    return !phoneNumber?.isValid() ? { invalid: { value: control.value } } : null;
  };
}


export const passwordValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
  const password = control.get('password');
  const confirmPassword = control.get('confirmPassword');
  return password && confirmPassword && password.value === confirmPassword.value ? null : { 'differentPassword': true };
};

declare var $: any;



@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.css']
})
export class PartnersComponent implements OnInit {

  registerForm: FormGroup;
  spinner: boolean = false;


  spinnerHeaders: boolean = false;
  headers: any = {
    allPartners: 0,
    unconfirmed: 0,
    confirmed: 0,
    pendingPayout: 0
  }

  alert: any = {
    show: false,
    icon: "fe fe-bell",
    message: "Partner View",
    type: "success"
  }

  constructor(
    private fb: FormBuilder,
    private authPartnerService: AuthPartnerService,
    private appService: AppService,
    private router: Router,
    private partnerService: PartnerService,
    private route: ActivatedRoute,
    public alertService: AlertService) {

    this.registerForm = fb.group({
      fullName: ['', Validators.required],
      phone: [''],
      emailAddress: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', [Validators.required]]
    }, { validators: passwordValidator });

    this.appService.defaultCountry.subscribe(country => {
      this.registerForm.controls.phone.setValidators([Validators.required, validPhoneNumber(country)]);
    })
  }

  ngOnInit(): void {
    this.loadHeaders();

  }

  register(): void {

    this.registerForm.markAllAsTouched();
    if (this.registerForm.valid) {
      this.spinner = true;
      const asYouType: AsYouType = new AsYouType(this.appService.getCountry());
      asYouType.input(this.registerForm.value.phone);

      const partner = Object.assign(this.registerForm.value, {
        phone: asYouType.getNumber().number
      });

      this.authPartnerService.register(partner).subscribe(r => {
        if (r.status === 201) {
          this.spinner = false;
          console.log('Partner registered successfully');
          this.partnerService.spinner = true;
          this.partnerService.partners.push(r.body);
          this.partnerService.spinner = false;
          this.registerForm.reset({ phone: "" });
          this.partnerService.sendVerificationAccountEmail(this.registerForm.value.fullName, this.registerForm.value.emailAddress).subscribe(z => {

          });
          this.alertService.showAlert( "fe fe-bell","Asociado registrado correctamente", "Partner registered successfully.","success"); 
         
          this.loadHeaders();
          //this.router.navigate(['auth-partner/p-confirm-email']);
        }
      }, err => {
        console.log(err);
        if (err.status == 400) {
          this.registerForm.controls.emailAddress.setErrors({ exist: err });
        }
        this.spinner = false;
        this.alertService.showAlert( "fe fe-alert-octagon",err.message,err.message,"danger");        
      });
    }

  }

  onKeyPhone(e: KeyboardEvent): void {
    const mask = new AsYouType(this.appService.getCountry()).input(e.target['value']);
    if (e.keyCode >= 48 && e.keyCode <= 57 || this.registerForm.get('phone').valid) {
      this.registerForm.get('phone').setValue(mask);
    }
  }

  public loadHeaders(): void {
    this.spinnerHeaders=true;
    this.headers.confirmed=0;
    this.headers.unconfirmed=0;
    this.headers.allPartners=0;
    this.headers.pendingPayout=0;
    this.partnerService.getPartnersList().subscribe(r => {
     
      r.map(v => {
        if (v.emailStatus == "confirmed") {
          this.headers.confirmed++;
        } else {
          this.headers.unconfirmed++;
        }
      });      
      this.headers.allPartners=r.length;
      this.headers.pendingPayout=0;
    });
    this.spinnerHeaders=false;
  }
 



}
