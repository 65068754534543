<div class="container-fluid">
  <!--Profile menu on phone view-->
  <app-admin-phone-menu></app-admin-phone-menu>
  
  <!-- start page title -->
  <div class="row page-title">
    <div class="col-12">
      <h4 class="page-title">Dasboard/Applications</h4>
    </div>

  </div>

  <!--Graphics & Requests -->
  <div class="row">
    <!-- Headers info -->
    <div class="col-12">
      <div class="row">
        <div class="col-xl-3 col-md-6">
          <div class="card widget-inline border-left-primary shadow h-100 py-2">
            <div class="card-body">
              <div class="row no-gutters align-items-center">
                <div class="col mr-2">
                  <div class="text-xs font-weight-bold text-purple text-uppercase mb-1">Developing
                    progress
                  </div>
                  <div class="row no-gutters align-items-center">
                    <div class="col-auto">
                      <div *ngIf="spinnerHeaders==false" class="h5 mb-0 mr-3 font-weight-bold text-gray-800">
                        {{headers.percent}}%
                      </div>
                      <div *ngIf="spinnerHeaders==true" class="">
                        <div class="spinner-border text-muted" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div *ngIf="spinnerHeaders==false" class="progress progress-sm mr-2">
                        <div role="progressbar" aria-valuenow="headers.percent" aria-valuemin="0" aria-valuemax="100"
                          class="progress-bar bg-info" style="width: 50%;"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-auto"><i class="fa fa-file-text-o fa-2x text-gray-300"></i></div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6">
          <div class="card  widget-inline border-left-danger shadow h-100 py-2">
            <div class="card-body">
              <div class="row no-gutters align-items-center">
                <div class="col mr-2">
                  <div class="text-xs font-weight-bold text-danger text-uppercase mb-1" style="cursor: pointer;"
                    (click)="sortRequests(0)">New Service
                  </div>
                  <div *ngIf="spinnerHeaders==false" class="h5 mb-0 font-weight-bold text-gray-800">
                    {{headers.new}}</div>
                  <div *ngIf="spinnerHeaders==true" class="">
                    <div class="spinner-border text-muted" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                </div>
                <div class="col-auto"><i class="fe-alert-triangle text-muted fe-2x"></i></div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6">
          <div class="card  widget-inline border-left-warning shadow h-100 py-2">
            <div class="card-body">
              <div class="row no-gutters align-items-center">
                <div class="col mr-2">
                  <div class="text-xs font-weight-bold text-warning text-uppercase mb-1" style="cursor: pointer;"
                    (click)="sortRequests(1)">In progress</div>
                  <div *ngIf="spinnerHeaders==false" class="h5 mb-0 font-weight-bold text-gray-800">
                    {{headers.inprogress}}</div>
                  <div *ngIf="spinnerHeaders==true" class="">
                    <div class="spinner-border text-muted" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                </div>
                <div class="col-auto"><i class="fe-edit text-muted fe-2x"></i></div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6">
          <div class="card widget-inline border-left-info shadow h-100 py-2">
            <div class="card-body">
              <div class="row no-gutters align-items-center">
                <div class="col mr-2">
                  <div class="text-xs font-weight-bold text-info text-uppercase mb-1" style="cursor: pointer;"
                    (click)="sortRequests(2)">Completed
                  </div>
                  <div *ngIf="spinnerHeaders==false" class="h5 mb-0 font-weight-bold text-gray-800">
                    {{headers.completed}}</div>
                  <div *ngIf="spinnerHeaders==true" class="">
                    <div class="spinner-border text-muted" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                </div>
                <div class="col-auto"><i class="fe-briefcase text-muted fe-2x"></i></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Requests-->
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <!--Requests list-->
          <div class="py-2 p-md-3">
            <!-- Title + Filters-->
            <div class="d-sm-flex align-items-center justify-content-between pb-2">
              <h1 class="h3 mb-3 text-center text-sm-left">Request history</h1>
              <div class="d-flex align-items-center mb-3">
                <!--label class="text-nowrap pr-1 mr-2 mb-0">Sort requests</label>
                <select class="form-control custom-select custom-select-sm"
                  (change)="sortRequests($event.target.value)">
                  <option value="0">New service</option>
                  <option value="1">In progress</option>
                  <option value="2">Completed</option>
                </select-->
                <input type="text" class="form-control custom-select custom-select-sm" [(ngModel)]="search"
                  (keyup)="searchRequest($event)" placeholder="search">
              </div>
            </div>
            <!-- Accordion with orders-->
            <div *ngIf="spinnerRqs==false" class="accordion" id="orders-accordion">
              <!-- Requests list-->
              <div *ngFor="let req of customerService.requests" class="card">
                <div class="card-header">
                  <div class="accordion-heading"><a
                      class="d-flex flex-wrap align-items-center justify-content-between pr-4"
                      [href]="'#'+req.noRequest" role="button" data-toggle="collapse" aria-expanded="true"
                      aria-controls="order-1">
                      <div class="req-name font-size-sm font-weight-medium text-nowrap my-1 mr-2"><i
                          class="fe-hash font-size-base mr-1"></i><span
                          class="d-inline-block align-middle">{{req.noRequest }}</span> <span
                          class="reqName align-middle"> ({{req.product}})</span></div>
                      <div class="text-nowrap text-body font-size-sm font-weight-normal my-1 mr-2">
                        <i class="fe-user text-muted mr-1 mb-1"></i>{{req.customerName }}
                      </div>
                      <div class="text-nowrap text-body font-size-sm font-weight-normal my-1 mr-2">
                        <i class="fe-clock text-muted mr-1 mb-1"></i>{{req.createdAt | date:'medium' }}
                      </div>
                      <div
                        [ngClass]="{'bg-faded-danger text-danger': req.status==0, 'bg-faded-warning text-warning': req.status==1, 'bg-faded-success text-success': req.status==2}"
                        class="font-size-xs font-weight-medium py-1 px-3 rounded-sm my-1 mr-2">
                        {{(req.status==0 ?'New service' : (req.status==1 ?'In progress' : 'Completed'))}}
                      </div>
                      <div *ngIf="spinnerBtn.id!=req.id"><a (click)="createInvoice(req)" style="cursor: pointer;"
                        data-toggle="modal"><i class="fe-file-text fe-1x" data-toggle="tooltip"
                          data-placement="bottom" title="Create Invoice"></i></a>
                      </div>
                      <div *ngIf="spinnerBtn.loading==true && spinnerBtn.id==req.id" class="loading">
                        <div class="spinner-border text-primary" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                      </div>
                      <div class="text-body font-size-sm font-weight-medium my-1">
                        ${{req.salePrice | number:'.2'}}
                      </div>

                    </a>

                  </div>

                </div>
                <div class="collapse" [id]="req.noRequest" data-parent="#orders-accordion">
                  <div class="card-body pt-4 border-top bg-secondary">
                    <!-- Item-->
                    <div *ngFor="let app of req.apps" class="d-sm-flex justify-content-between">
                      <div class="order-item media media-ie-fix d-block d-sm-flex mr-sm-3">
                        <a class="d-table mx-auto" href="#">
                          <img *ngIf="app.appName=='NetBag-Manage'" width="80" src="assets/img/demo/services/04.svg"
                            alt="Icon" class="d-inline-block mb-4 mt-2">
                          <img *ngIf="app.appName=='NetBag-POS'" width="80" src="assets/img/demo/services/05.svg"
                            alt="Icon" class="d-inline-block mb-4 mt-2">
                          <img *ngIf="app.appName=='NetBag-Online Store'" width="80"
                            src="assets/img/demo/services/01.svg" alt="Icon" class="d-inline-block mb-4 mt-2">
                          <img *ngIf="app.appName=='Q-Invoice'" width="80"
                            src="assets/img/demo/services/011.svg" alt="Icon" class="d-inline-block mb-4 mt-2">
                

                        </a>
                        <div class="media-body font-size-sm pt-2 pl-sm-3 text-center text-sm-left">
                          <h5 class="nav-heading font-size-sm mb-2"><a href="#">{{app.number}}</a></h5>
                          <div><span class="text-muted mr-1">Name:</span>{{app.appName}}</div>
                          <div><span class="text-muted mr-1">Plan:</span>{{app.plan }}</div>
                        </div>
                      </div>
                      <div class="font-size-sm text-center pt-2 mr-sm-3">
                        <div class="text-muted">Service Status:</div>
                        <div [ngClass]="{'text-danger': app.status==0, ' text-success': app.status==1}"
                          class="font-weight-medium">{{(app.status==0 ?'Offline' : 'Online')}}
                        </div>
                      </div>
                      <div class="font-size-sm text-center pt-2">
                        <div class="text-muted">Sale Price:</div>
                        <div class="font-weight-medium">${{app.salePrice | number:'.2'}}</div>
                      </div>
                      <div class="font-size-sm text-center pt-2">
                        <div class="font-weight-medium">
                          <img [src]="app.avatar" class="navbar-tool-icon-box-img avatar_container icon-box-img"
                            alt="Icon" width="50" data-toggle="tooltip" data-placement="bottom"
                            [title]="'Partner: '+app.partnerName">
                        </div>
                      </div>
                      <div class="btn-group font-size-sm text-center pt-2 bt-menu">
                        <button type="button"
                          class="btn btn-primary btn-sm btn-menu mt-2 dropdown dropdown-toggle navbar"
                          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i class="fe-align-justify text-white "></i>
                        </button>
                        <div class="dropdown-menu dropdown-menu-right">
                          <div id="settings-menu">
                            <div><a class="dropdown-item" (click)="printAppResume(app, req)" style="cursor: pointer;"><i class="fe-file-text fe-1x"
                                  data-toggle="tooltip" data-placement="bottom" title="Resume"></i></a>
                            </div>
                            <div class="dropdown-divider"></div>
                            <div><a class="dropdown-item dropdown-item-group" (click)="appNumber = app.number"
                                href="#modal-heroku" data-toggle="modal"><i class="fe-server fe-1x"
                                  data-toggle="tooltip" data-placement="bottom" title="Heroku Settings"></i></a></div>
                            <div class="dropdown-divider"></div>
                            <div><a class="dropdown-item" (click)="appNumber = app.number" href="#modal-database"
                                data-toggle="modal"><i class="fe-database fe-1x" data-toggle="tooltip"
                                  data-placement="bottom" title="Database Settings"></i></a></div>
                            <div class="dropdown-divider"></div>
                            <div><a class="dropdown-item" (click)="appNumber = app.number" href="#modal-cloudinary"
                                data-toggle="modal"><i class="fe-image fe-1x" data-toggle="tooltip"
                                  data-placement="bottom" title="Cloudinary Settings"></i></a>
                            </div>
                            <div class="dropdown-divider"></div>
                            <div><a class="dropdown-item" (click)="appNumber = app.number" href="#modal-cardconnect"
                                data-toggle="modal"><i class="fe-credit-card fe-1x" data-toggle="tooltip"
                                  data-placement="bottom" title="CardConnect  Settings"></i></a>
                            </div>
                            <div class="dropdown-divider"></div>
                            <div><a class="dropdown-item" (click)="appNumber = app.number" href="#modal-stripe"
                                data-toggle="modal"><i class="fe-settings fe-1x" data-toggle="tooltip"
                                  data-placement="bottom" title="Stripe Settings"></i></a>
                            </div>
                            <div class="dropdown-divider"></div>
                            <div><a class="dropdown-item" (click)="appNumber = app.number" href="#modal-mailgun"
                                data-toggle="modal"><i class="fe-send fe-1x" data-toggle="tooltip"
                                  data-placement="bottom" title="Mailgun Settings"></i></a>
                            </div>
                            <div class="dropdown-divider"></div>
                            <div><a class="dropdown-item" (click)="appNumber = app.number" href="#modal-customDomain"
                                data-toggle="modal"><i class="fe-globe fe-1x" data-toggle="tooltip"
                                  data-placement="bottom" title="Domain Settings"></i></a>
                            </div>
                            <div class="dropdown-divider"></div>
                            <div><a class="dropdown-item" (click)="appNumber = app.number" href="#modal-update"
                                data-toggle="modal"><i class="fe-edit fe-1x" data-toggle="tooltip"
                                  data-placement="bottom" title="Update"></i></a>
                            </div>
                            <div class="dropdown-divider"></div>
                            <div><a class="dropdown-item" (click)="appNumber = app.number; appId=app.id;"
                                href="#modal-remove-app" data-toggle="modal"><i class="fe-trash-2 fe-1x"
                                  data-toggle="tooltip" data-placement="bottom" title="Remove"></i></a>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>

                  </div>
                </div>

              </div>

            </div>
            <div *ngIf="spinnerRqs==true" class="loading">
              <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            <!-- Pagination-->
            <nav class="d-md-flex justify-content-between align-items-center text-center text-md-left pt-grid-gutter">
              <div class="d-md-flex align-items-center w-100"><span class="font-size-sm text-muted mr-md-3">Showing
                  {{customerService.requests.length}}
                  of {{customerService.requests.length}} requests</span>
                <div class="progress w-100 my-3 mx-auto mx-md-0" style="max-width: 10rem; height: 4px;">
                  <div class="progress-bar" role="progressbar" style="width: 100%;" aria-valuenow="38" aria-valuemin="0"
                    aria-valuemax="100"></div>
                </div>
              </div>
              <button class="btn btn-outline-primary btn-sm" type="button">Load more requests</button>
            </nav>
          </div>

          <!-- end table-responsive-->

        </div> <!-- end card body-->
      </div> <!-- end card -->
    </div>
  </div>

  <!-- Heroku Modal-->
  <div class="modal fade" id="modal-heroku" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content border-0">
        <div class="cs-view show" id="modal-heroku-view">
          <div class="modal-header border-1 px-4">
            <img src="assets/img/logo/heroku-logo.png" height="40px" style="height: 40px!important;" />
            <hr>
            <button class="close text-dark" type="button" data-dismiss="modal" aria-label="Close"><span
                aria-hidden="true">&times;</span></button>
          </div>
          <div class="modal-body px-4">

            <form [formGroup]="herokuForm" (ngSubmit)="updateHeroku()" class="needs-validation" novalidate>
              <!-- Plan Input -->
              <div class="form-group">
                <label _ngcontent-xbq-c130="" for="plan">Plan</label>
                <input formControlName="plan"
                  [ngClass]="{'is-invalid': herokuForm.controls.plan.errors && (herokuForm.controls.plan.touched || herokuForm.controls.plan.dirty) }"
                  class="form-control" type="text" placeholder="Text">
                <div class="invalid-feedback ml-2">
                  Plan is required.
                </div>
              </div>

              <!-- Proyect Name Input -->
              <div class="form-group">
                <label _ngcontent-xbq-c130="" for="proyectName">Proyect Name</label>
                <input formControlName="proyectName"
                  [ngClass]="{'is-invalid': herokuForm.controls.proyectName.errors && (herokuForm.controls.proyectName.touched || herokuForm.controls.proyectName.dirty) }"
                  class="form-control" type="text" placeholder="Text">
                <div *ngIf="herokuForm.controls.proyectName.errors?.required" class="invalid-feedback ml-2">
                  Proyect name is required.
                </div>
              </div>

              <!-- Domain Input -->
              <div class="form-group">
                <label _ngcontent-xbq-c130="" for="account-country">Heroku Domain</label>
                <input formControlName="domain"
                  [ngClass]="{'is-invalid': herokuForm.controls.domain.errors && (herokuForm.controls.domain.touched || herokuForm.controls.domain.dirty) }"
                  class="form-control" type="text" placeholder="Text">
                <div class="invalid-feedback ml-2">
                  Domain is required.
                </div>
              </div>

              <!-- Heroku User Input -->
              <div class="form-group">
                <label _ngcontent-xbq-c130="" for="account-country">Web User</label>
                <input formControlName="herokuUser"
                  [ngClass]="{'is-invalid': herokuForm.controls.herokuUser.errors && (herokuForm.controls.herokuUser.touched || herokuForm.controls.herokuUser.dirty) }"
                  class="form-control" type="text" placeholder="email">
                <div class="invalid-feedback ml-2">
                  Web user is required.
                </div>
              </div>

              <!-- Heroku password -->
              <div class="form-group">
                <label _ngcontent-xbq-c130="" for="account-country">Web Password</label>
                <input formControlName="herokuPassword"
                  [ngClass]="{'is-invalid': herokuForm.controls.herokuPassword.errors && (herokuForm.controls.herokuPassword.touched || herokuForm.controls.herokuPassword.dirty) }"
                  class="form-control" type="text" placeholder="Text">
                <div class="invalid-feedback ml-2">
                  Password is required.
                </div>
              </div>

              <!-- cliToken password -->
              <div class="form-group">
                <label _ngcontent-xbq-c130="" for="account-country">Cli Token</label>
                <input formControlName="cliToken"
                  [ngClass]="{'is-invalid': herokuForm.controls.cliToken.errors && (herokuForm.controls.cliToken.touched || herokuForm.controls.cliToken.dirty) }"
                  class="form-control" type="text" placeholder="Text">
                <div class="invalid-feedback ml-2">
                  CLI Token is required.
                </div>
              </div>

              <button class="btn btn-primary btn-block" type="submit">
                <span *ngIf="!spinner">Update</span>
                <div *ngIf="spinner" class="btn-spinner">
                  <div class="spinner-border spinner-border-sm mr-1" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                  <div class="spinner-grow spinner-grow-sm mr-1" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              </button>
            </form>
          </div>
        </div>


      </div>
    </div>
  </div>

  <!-- Data Base Modal-->
  <div class="modal fade" id="modal-database" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content border-0">
        <div class="cs-view show" id="modal-heroku-view">
          <div class="modal-header border-1 px-4">
            <img src="assets/img/logo/microsoft-azure.png" height="40px" style="height: 40px!important;" />
            <button class="close text-dark" type="button" data-dismiss="modal" aria-label="Close"><span
                aria-hidden="true">&times;</span></button>
          </div>

          <div class="modal-body px-4">

            <form [formGroup]="databaseForm" (ngSubmit)="updateDatabase()" class="needs-validation" novalidate>
              <!-- DB Provider Input -->
              <div class="form-group">
                <label _ngcontent-xbq-c130="" for="account-country">Db Provider</label>
                <input formControlName="dbProvider"
                  [ngClass]="{'is-invalid': databaseForm.controls.dbProvider.errors && (databaseForm.controls.dbProvider.touched || databaseForm.controls.dbProvider.dirty) }"
                  class="form-control" type="text" placeholder="Text">
                <div class="invalid-feedback ml-2">
                  Provider is required.
                </div>
              </div>

              <!-- DB Adapter Input -->
              <div class="form-group">
                <label _ngcontent-xbq-c130="" for="account-country">Db Adapter</label>
                <input formControlName="dbAdapter"
                  [ngClass]="{'is-invalid': databaseForm.controls.dbAdapter.errors && (databaseForm.controls.dbAdapter.touched || databaseForm.controls.dbAdapter.dirty) }"
                  class="form-control" type="text" placeholder="Text">
                <div *ngIf="databaseForm.controls.dbAdapter.errors?.required" class="invalid-feedback ml-2">
                  Adapter is required.
                </div>
              </div>

              <!-- DB Url Input -->
              <div class="form-group">
                <label _ngcontent-xbq-c130="" for="account-country">Db Url</label>
                <input formControlName="dbUrl"
                  [ngClass]="{'is-invalid': databaseForm.controls.dbUrl.errors && (databaseForm.controls.dbUrl.touched || databaseForm.controls.dbUrl.dirty) }"
                  class="form-control" type="text" placeholder="Text">
                <div class="invalid-feedback ml-2">
                  Url is required.
                </div>
              </div>

              <!-- DB Name Input -->
              <div class="form-group">
                <label _ngcontent-xbq-c130="" for="account-country">Db Name</label>
                <input formControlName="dbName"
                  [ngClass]="{'is-invalid': databaseForm.controls.dbName.errors && (databaseForm.controls.dbName.touched || databaseForm.controls.dbName.dirty) }"
                  class="form-control" type="text" placeholder="Text">
                <div class="invalid-feedback ml-2">
                  Db name is required.
                </div>
              </div>

              <!-- DB User -->
              <div class="form-group">
                <label _ngcontent-xbq-c130="" for="account-country">Db User</label>
                <input formControlName="dbUser"
                  [ngClass]="{'is-invalid': databaseForm.controls.dbUser.errors && (databaseForm.controls.dbUser.touched || databaseForm.controls.dbUser.dirty) }"
                  class="form-control" type="text" placeholder="Text">
                <div class="invalid-feedback ml-2">
                  User is required.
                </div>
              </div>

              <!-- DB Password -->
              <div class="form-group">
                <label _ngcontent-xbq-c130="" for="account-country">Db Password</label>
                <input formControlName="dbPassword"
                  [ngClass]="{'is-invalid': databaseForm.controls.dbPassword.errors && (databaseForm.controls.dbPassword.touched || databaseForm.controls.dbPassword.dirty) }"
                  class="form-control" type="text" placeholder="Text">
                <div class="invalid-feedback ml-2">
                  Password is required.
                </div>
              </div>

              <button class="btn btn-primary btn-block" type="submit">
                <span *ngIf="!spinner">Update</span>
                <div *ngIf="spinner" class="btn-spinner">
                  <div class="spinner-border spinner-border-sm mr-1" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                  <div class="spinner-grow spinner-grow-sm mr-1" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Cloudinary Modal-->
  <div class="modal fade" id="modal-cloudinary" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content border-0">
        <div class="cs-view show" id="modal-heroku-view">
          <div class="modal-header border-1 px-4">
            <img src="assets/img/logo/cloudinary-vector-logo.png" height="40px" style="height: 40px!important;" />
            <button class="close text-dark" type="button" data-dismiss="modal" aria-label="Close"><span
                aria-hidden="true">&times;</span></button>
          </div>

          <div class="modal-body px-4">

            <form [formGroup]="cloudinaryForm" (ngSubmit)="updateCloudinary()" class="needs-validation" novalidate>
              <!-- cloudinaryUser Input -->
              <div class="form-group">
                <label _ngcontent-xbq-c130="" for="account-country">Web User</label>
                <input formControlName="cloudinaryUser"
                  [ngClass]="{'is-invalid': cloudinaryForm.controls.cloudinaryUser.errors && (cloudinaryForm.controls.cloudinaryUser.touched || cloudinaryForm.controls.cloudinaryUser.dirty) }"
                  class="form-control" type="text" placeholder="Text">
                <div class="invalid-feedback ml-2">
                  Web user is required.
                </div>
              </div>

              <!-- cloudinaryPassword Input -->
              <div class="form-group">
                <label _ngcontent-xbq-c130="" for="account-country">Web Password</label>
                <input formControlName="cloudinaryPassword"
                  [ngClass]="{'is-invalid': cloudinaryForm.controls.cloudinaryPassword.errors && (cloudinaryForm.controls.cloudinaryPassword.touched || cloudinaryForm.controls.cloudinaryPassword.dirty) }"
                  class="form-control" type="text" placeholder="Text">
                <div *ngIf="cloudinaryForm.controls.cloudinaryPassword.errors?.required" class="invalid-feedback ml-2">
                  Web password is required.
                </div>
              </div>

              <!-- cloudinaryPlan Input -->
              <div class="form-group">
                <label _ngcontent-xbq-c130="" for="account-country">Cloudinary Plan</label>
                <input formControlName="cloudinaryPlan"
                  [ngClass]="{'is-invalid': cloudinaryForm.controls.cloudinaryPlan.errors && (cloudinaryForm.controls.cloudinaryPlan.touched || cloudinaryForm.controls.cloudinaryPlan.dirty) }"
                  class="form-control" type="text" placeholder="Text">
                <div class="invalid-feedback ml-2">
                  Plan is required.
                </div>
              </div>

              <!-- cloudName Input -->
              <div class="form-group">
                <label _ngcontent-xbq-c130="" for="account-country">Cloud Name</label>
                <input formControlName="cloudName"
                  [ngClass]="{'is-invalid': cloudinaryForm.controls.cloudName.errors && (cloudinaryForm.controls.cloudName.touched || cloudinaryForm.controls.cloudName.dirty) }"
                  class="form-control" type="text" placeholder="Text">
                <div class="invalid-feedback ml-2">
                  Cloud name is required.
                </div>
              </div>

              <!-- cloudApiKey -->
              <div class="form-group">
                <label _ngcontent-xbq-c130="" for="account-country">Api Key</label>
                <input formControlName="cloudApiKey"
                  [ngClass]="{'is-invalid': cloudinaryForm.controls.cloudApiKey.errors && (cloudinaryForm.controls.cloudApiKey.touched || cloudinaryForm.controls.cloudApiKey.dirty) }"
                  class="form-control" type="text" placeholder="Text">
                <div class="invalid-feedback ml-2">
                  Api key is required.
                </div>
              </div>

              <!-- cloudApiSecret -->
              <div class="form-group">
                <label _ngcontent-xbq-c130="" for="account-country">Api Secret</label>
                <input formControlName="cloudApiSecret"
                  [ngClass]="{'is-invalid': cloudinaryForm.controls.cloudApiSecret.errors && (cloudinaryForm.controls.cloudApiSecret.touched || cloudinaryForm.controls.cloudApiSecret.dirty) }"
                  class="form-control" type="text" placeholder="Text">
                <div class="invalid-feedback ml-2">
                  Api-Secret is required.
                </div>
              </div>

              <button class="btn btn-primary btn-block" type="submit">
                <span *ngIf="!spinner">Update</span>
                <div *ngIf="spinner" class="btn-spinner">
                  <div class="spinner-border spinner-border-sm mr-1" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                  <div class="spinner-grow spinner-grow-sm mr-1" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              </button>
            </form>
          </div>
        </div>

      </div>
    </div>
  </div>

  <!-- CardConnect Modal-->
  <div class="modal fade" id="modal-cardconnect" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content border-0">
        <div class="cs-view show" id="modal-heroku-view">
          <div class="modal-header border-1 px-4">
            <img src="assets/img/logo/CardConnect-Logo-Hi-Res.jpg" height="40px" style="height: 40px!important;" />
            <button class="close text-dark" type="button" data-dismiss="modal" aria-label="Close"><span
                aria-hidden="true">&times;</span></button>
          </div>

          <div class="modal-body px-4">

            <form [formGroup]="cardconnectForm" (ngSubmit)="updateCardconnect()" class="needs-validation" novalidate>
              <!-- usingBoltApi Input -->
              <div class="form-group">
                <label _ngcontent-xbq-c130="" for="account-country">Using Bolt Api</label>
                <select formControlName="usingBoltApi"
                  [ngClass]="{'is-invalid': cardconnectForm.controls.usingBoltApi.errors && (cardconnectForm.controls.usingBoltApi.touched || cardconnectForm.controls.usingBoltApi.dirty) }"
                  class="form-control" type="text" placeholder="Text">
                  <option value="0">No</option>
                  <option value="1">Yes</option>
                </select>
                <div class="invalid-feedback ml-2">
                  Option is required.
                </div>
              </div>

              <!-- merchantId Input -->
              <div class="form-group">
                <label _ngcontent-xbq-c130="" for="account-country">Merchant Id</label>
                <input formControlName="merchantId"
                  [ngClass]="{'is-invalid': cardconnectForm.controls.merchantId.errors && (cardconnectForm.controls.merchantId.touched || cardconnectForm.controls.merchantId.dirty) }"
                  class="form-control" type="text" placeholder="Text">
                <div *ngIf="cardconnectForm.controls.merchantId.errors?.required" class="invalid-feedback ml-2">
                  MmerchantId is required.
                </div>
              </div>

              <!-- boltBaseURL Input -->
              <div class="form-group">
                <label _ngcontent-xbq-c130="" for="account-country">BoltBase URL</label>
                <input formControlName="boltBaseURL"
                  [ngClass]="{'is-invalid': cardconnectForm.controls.boltBaseURL.errors && (cardconnectForm.controls.boltBaseURL.touched || cardconnectForm.controls.boltBaseURL.dirty) }"
                  class="form-control" type="text" placeholder="Url">
                <div class="invalid-feedback ml-2">
                  Url is required.
                </div>
              </div>

              <!-- boltAuthorizationKey Input -->
              <div class="form-group">
                <label _ngcontent-xbq-c130="" for="account-country">Bolt Authorization Key</label>
                <input formControlName="boltAuthorizationKey"
                  [ngClass]="{'is-invalid': cardconnectForm.controls.boltAuthorizationKey.errors && (cardconnectForm.controls.boltAuthorizationKey.touched || cardconnectForm.controls.boltAuthorizationKey.dirty) }"
                  class="form-control" type="text" placeholder="Text">
                <div class="invalid-feedback ml-2">
                  Key is required.
                </div>
              </div>

              <!-- boltHsn -->
              <div class="form-group">
                <label _ngcontent-xbq-c130="" for="account-country">Bolt Hsn</label>
                <input formControlName="boltHsn"
                  [ngClass]="{'is-invalid': cardconnectForm.controls.boltHsn.errors && (cardconnectForm.controls.boltHsn.touched || cardconnectForm.controls.boltHsn.dirty) }"
                  class="form-control" type="text" placeholder="Text">
                <div class="invalid-feedback ml-2">
                  Hsn is required.
                </div>
              </div>

              <div class="form-group">
                <hr>
              </div>

              <!-- usingGatewayApi Input -->
              <div class="form-group">
                <label _ngcontent-xbq-c130="" for="account-country">Using Gateway Api</label>
                <select formControlName="usingGatewayApi"
                  [ngClass]="{'is-invalid': cardconnectForm.controls.usingGatewayApi.errors && (cardconnectForm.controls.usingGatewayApi.touched || cardconnectForm.controls.usingGatewayApi.dirty) }"
                  class="form-control" type="text" placeholder="Text">
                  <option value="0">No</option>
                  <option value="1">Yes</option>
                </select>
                <div class="invalid-feedback ml-2">
                  Option is required.
                </div>
              </div>

              <!-- gatewayId -->
              <div class="form-group">
                <label _ngcontent-xbq-c130="" for="account-country">Gateway Id</label>
                <input formControlName="gatewayId"
                  [ngClass]="{'is-invalid': cardconnectForm.controls.gatewayId.errors && (cardconnectForm.controls.gatewayId.touched || cardconnectForm.controls.gatewayId.dirty) }"
                  class="form-control" type="text" placeholder="Text">
                <div class="invalid-feedback ml-2">
                  GatewayId is required.
                </div>
              </div>

              <!-- gatewayApiKey-->
              <div class="form-group">
                <label _ngcontent-xbq-c130="" for="account-country">Gateway Api-Key</label>
                <input formControlName="gatewayApiKey"
                  [ngClass]="{'is-invalid': cardconnectForm.controls.gatewayApiKey.errors && (cardconnectForm.controls.gatewayApiKey.touched || cardconnectForm.controls.gatewayApiKey.dirty) }"
                  class="form-control" type="text" placeholder="Text">
                <div class="invalid-feedback ml-2">
                  Gateway Api-Key is required.
                </div>
              </div>

              <!-- gatewayApiUser -->
              <div class="form-group">
                <label _ngcontent-xbq-c130="" for="account-country">Gateway Api-User</label>
                <input formControlName="gatewayApiUser"
                  [ngClass]="{'is-invalid': cardconnectForm.controls.gatewayApiUser.errors && (cardconnectForm.controls.gatewayApiUser.touched || cardconnectForm.controls.gatewayApiUser.dirty) }"
                  class="form-control" type="text" placeholder="Text">
                <div class="invalid-feedback ml-2">
                  Api-User is required.
                </div>
              </div>

              <!-- gatewayBaseURL -->
              <div class="form-group">
                <label _ngcontent-xbq-c130="" for="account-country">Gateway BaseURL</label>
                <input formControlName="gatewayBaseURL"
                  [ngClass]="{'is-invalid': cardconnectForm.controls.gatewayBaseURL.errors && (cardconnectForm.controls.gatewayBaseURL.touched || cardconnectForm.controls.gatewayBaseURL.dirty) }"
                  class="form-control" type="text" placeholder="Text">
                <div class="invalid-feedback ml-2">
                  Gateway BaseURL is required.
                </div>
              </div>

              <!-- cardPointUser -->
              <div class="form-group">
                <label _ngcontent-xbq-c130="" for="account-country">CardPoint User</label>
                <input formControlName="cardPointUser"
                  [ngClass]="{'is-invalid': cardconnectForm.controls.cardPointUser.errors && (cardconnectForm.controls.cardPointUser.touched || cardconnectForm.controls.cardPointUser.dirty) }"
                  class="form-control" type="text" placeholder="Email">
                <div class="invalid-feedback ml-2">
                  cardPoint user is required.
                </div>
              </div>

              <!-- cardPointPass -->
              <div class="form-group">
                <label _ngcontent-xbq-c130="" for="account-country">CardPoint password</label>
                <input formControlName="cardPointPass"
                  [ngClass]="{'is-invalid': cardconnectForm.controls.cardPointPass.errors && (cardconnectForm.controls.cardPointPass.touched || cardconnectForm.controls.cardPointPass.dirty) }"
                  class="form-control" type="text" placeholder="Text">
                <div class="invalid-feedback ml-2">
                  Password is required.
                </div>
              </div>

              <button class="btn btn-primary btn-block" type="submit">
                <span *ngIf="!spinner">Update</span>
                <div *ngIf="spinner" class="btn-spinner">
                  <div class="spinner-border spinner-border-sm mr-1" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                  <div class="spinner-grow spinner-grow-sm mr-1" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              </button>
            </form>
          </div>
        </div>

      </div>
    </div>
  </div>

  <!-- Stripe Modal-->
  <div class="modal fade" id="modal-stripe" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content border-0">
        <div class="cs-view show" id="modal-heroku-view">
          <div class="modal-header border-1 px-4">
            <img src="assets/img/logo/Stripe-Payment-Logo.png" height="40px" style="height: 40px!important;" />
            <button class="close text-dark" type="button" data-dismiss="modal" aria-label="Close"><span
                aria-hidden="true">&times;</span></button>
          </div>

          <div class="modal-body px-4">

            <form [formGroup]="stripeForm" (ngSubmit)="updateStripe()" class="needs-validation" novalidate>
              <!-- usingStripeApi Input -->
              <div class="form-group">
                <label _ngcontent-xbq-c130="" for="account-country">Using Stripe Api</label>
                <select formControlName="usingStripeApi"
                  [ngClass]="{'is-invalid': stripeForm.controls.usingStripeApi.errors && (stripeForm.controls.usingStripeApi.touched || stripeForm.controls.usingStripeApi.dirty) }"
                  class="form-control" type="text" placeholder="Text">
                  <option value="0">No</option>
                  <option value="1">Yes</option>
                </select>
                <div class="invalid-feedback ml-2">
                  Provider is required.
                </div>
              </div>

              <!-- stripeId Input -->
              <div class="form-group">
                <label _ngcontent-xbq-c130="" for="account-country">Stripe Id</label>
                <input formControlName="stripeId"
                  [ngClass]="{'is-invalid': stripeForm.controls.stripeId.errors && (stripeForm.controls.stripeId.touched || stripeForm.controls.stripeId.dirty) }"
                  class="form-control" type="text" placeholder="Text">
                <div *ngIf="stripeForm.controls.stripeId.errors?.required" class="invalid-feedback ml-2">
                  Id is required.
                </div>
              </div>

              <!-- stripePublicApiKey Input -->
              <div class="form-group">
                <label _ngcontent-xbq-c130="" for="account-country">Public Api-Key</label>
                <input formControlName="stripePublicApiKey"
                  [ngClass]="{'is-invalid': stripeForm.controls.stripePublicApiKey.errors && (stripeForm.controls.stripePublicApiKey.touched || stripeForm.controls.stripePublicApiKey.dirty) }"
                  class="form-control" type="text" placeholder="Text">
                <div class="invalid-feedback ml-2">
                  Public Api-Key is required.
                </div>
              </div>

              <!-- stripePrivateApiKey Input -->
              <div class="form-group">
                <label _ngcontent-xbq-c130="" for="account-country">Private Api-Key</label>
                <input formControlName="stripePrivateApiKey"
                  [ngClass]="{'is-invalid': stripeForm.controls.stripePrivateApiKey.errors && (stripeForm.controls.stripePrivateApiKey.touched || stripeForm.controls.stripePrivateApiKey.dirty) }"
                  class="form-control" type="text" placeholder="Text">
                <div class="invalid-feedback ml-2">
                  Private Api-Key is required.
                </div>
              </div>

              <!-- stripeUser -->
              <div class="form-group">
                <label _ngcontent-xbq-c130="" for="account-country">Web User</label>
                <input formControlName="stripeUser"
                  [ngClass]="{'is-invalid': stripeForm.controls.stripeUser.errors && (stripeForm.controls.stripeUser.touched || stripeForm.controls.stripeUser.dirty) }"
                  class="form-control" type="text" placeholder="Email">
                <div class="invalid-feedback ml-2">
                  User is required.
                </div>
              </div>

              <!-- stripePass -->
              <div class="form-group">
                <label _ngcontent-xbq-c130="" for="account-country">Web Password</label>
                <input formControlName="stripePass"
                  [ngClass]="{'is-invalid': stripeForm.controls.stripePass.errors && (stripeForm.controls.stripePass.touched || stripeForm.controls.stripePass.dirty) }"
                  class="form-control" type="text" placeholder="Text">
                <div class="invalid-feedback ml-2">
                  Password is required.
                </div>
              </div>

              <button class="btn btn-primary btn-block" type="submit">
                <span *ngIf="!spinner">Update</span>
                <div *ngIf="spinner" class="btn-spinner">
                  <div class="spinner-border spinner-border-sm mr-1" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                  <div class="spinner-grow spinner-grow-sm mr-1" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Mailgun Modal-->
  <div class="modal fade" id="modal-mailgun" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content border-0">
        <div class="cs-view show" id="modal-heroku-view">
          <div class="modal-header border-1 px-4">
            <img src="assets/img/logo/mailgun-logo.png" height="40px" style="height: 40px!important;" />
            <button class="close text-dark" type="button" data-dismiss="modal" aria-label="Close"><span
                aria-hidden="true">&times;</span></button>
          </div>

          <div class="modal-body px-4">

            <form [formGroup]="mailgunForm" (ngSubmit)="updateMailgun()" class="needs-validation" novalidate>
              <!-- UsingMailgunApi Input -->
              <div class="form-group">
                <label _ngcontent-xbq-c130="" for="account-country">Using Mailgun Api</label>
                <select formControlName="usingMailgunApi"
                  [ngClass]="{'is-invalid': mailgunForm.controls.usingMailgunApi.errors && (mailgunForm.controls.usingMailgunApi.touched || mailgunForm.controls.usingMailgunApi.dirty) }"
                  class="form-control" type="text" placeholder="Text">
                  <option value="0">No</option>
                  <option value="1">Yes</option>
                </select>
                <div *ngIf="mailgunForm.controls.usingMailgunApi.errors?.required" class="invalid-feedback ml-2">
                  Adapter is required.
                </div>
              </div>

              <!-- mailgunAccountId Input -->
              <div class="form-group">
                <label _ngcontent-xbq-c130="" for="account-country">Mailgun Account Id</label>
                <input formControlName="mailgunAccountId"
                  [ngClass]="{'is-invalid': mailgunForm.controls.mailgunAccountId.errors && (mailgunForm.controls.mailgunAccountId.touched || mailgunForm.controls.mailgunAccountId.dirty) }"
                  class="form-control" type="text" placeholder="Text">
                <div class="invalid-feedback ml-2">
                  AccountId is required.
                </div>
              </div>

              <!-- mailgunDomain Input -->
              <div class="form-group">
                <label _ngcontent-xbq-c130="" for="account-country">Mailgun Domain</label>
                <input formControlName="mailgunDomain"
                  [ngClass]="{'is-invalid': mailgunForm.controls.mailgunDomain.errors && (mailgunForm.controls.mailgunDomain.touched || mailgunForm.controls.mailgunDomain.dirty) }"
                  class="form-control" type="text" placeholder="Text">
                <div class="invalid-feedback ml-2">
                  Domain is required.
                </div>
              </div>

              <!-- mailgunApiKey Input -->
              <div class="form-group">
                <label _ngcontent-xbq-c130="" for="account-country">Api Key</label>
                <input formControlName="mailgunApiKey"
                  [ngClass]="{'is-invalid': mailgunForm.controls.mailgunApiKey.errors && (mailgunForm.controls.mailgunApiKey.touched || mailgunForm.controls.mailgunApiKey.dirty) }"
                  class="form-control" type="text" placeholder="Text">
                <div class="invalid-feedback ml-2">
                  ApiKey is required.
                </div>
              </div>

              <!-- mailgunUser -->
              <div class="form-group">
                <label _ngcontent-xbq-c130="" for="account-country">Web User</label>
                <input formControlName="mailgunUser"
                  [ngClass]="{'is-invalid': mailgunForm.controls.mailgunUser.errors && (mailgunForm.controls.mailgunUser.touched || mailgunForm.controls.mailgunUser.dirty) }"
                  class="form-control" type="text" placeholder="Text">
                <div class="invalid-feedback ml-2">
                  User is required.
                </div>
              </div>

              <!-- mailgunPass -->
              <div class="form-group">
                <label _ngcontent-xbq-c130="" for="account-country">Web Password</label>
                <input formControlName="mailgunPass"
                  [ngClass]="{'is-invalid': mailgunForm.controls.mailgunPass.errors && (mailgunForm.controls.mailgunPass.touched || mailgunForm.controls.mailgunPass.dirty) }"
                  class="form-control" type="text" placeholder="Web password">
                <div class="invalid-feedback ml-2">
                  Password is required.
                </div>
              </div>

              <button class="btn btn-primary btn-block" type="submit">
                <span *ngIf="!spinner">Update</span>
                <div *ngIf="spinner" class="btn-spinner">
                  <div class="spinner-border spinner-border-sm mr-1" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                  <div class="spinner-grow spinner-grow-sm mr-1" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- CustomDomain Modal-->
  <div class="modal fade" id="modal-customDomain" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content border-0">
        <div class="cs-view show" id="modal-heroku-view">
          <div class="modal-header border-1 px-4">
            <img src="assets/img/logo/GoDaddy_logo_logotype.png" height="40px" style="height: 40px!important;" />
            <button class="close text-dark" type="button" data-dismiss="modal" aria-label="Close"><span
                aria-hidden="true">&times;</span></button>
          </div>

          <div class="modal-body px-4">

            <form [formGroup]="customDomainForm" (ngSubmit)="updateCustomDomain()" class="needs-validation" novalidate>

              <!-- domainProvider Input -->
              <div class="form-group">
                <label _ngcontent-xbq-c130="" for="account-country">Domain Provider</label>
                <input formControlName="domainProvider"
                  [ngClass]="{'is-invalid': customDomainForm.controls.domainProvider.errors && (customDomainForm.controls.domainProvider.touched || customDomainForm.controls.domainProvider.dirty) }"
                  class="form-control" type="text" placeholder="Text">
                <div class="invalid-feedback ml-2">
                  Provider is required.
                </div>
              </div>

              <!-- domainName Input -->
              <div class="form-group">
                <label _ngcontent-xbq-c130="" for="account-country">Domain Name</label>
                <input formControlName="domainName"
                  [ngClass]="{'is-invalid': customDomainForm.controls.domainName.errors && (customDomainForm.controls.domainName.touched || customDomainForm.controls.domainName.dirty) }"
                  class="form-control" type="text" placeholder="Text">
                <div class="invalid-feedback ml-2">
                  Domain name is required.
                </div>
              </div>

              <!-- domainUser -->
              <div class="form-group">
                <label _ngcontent-xbq-c130="" for="account-country">Web User</label>
                <input formControlName="domainUser"
                  [ngClass]="{'is-invalid': customDomainForm.controls.domainUser.errors && (customDomainForm.controls.domainUser.touched || customDomainForm.controls.domainUser.dirty) }"
                  class="form-control" type="text" placeholder="Text">
                <div class="invalid-feedback ml-2">
                  User is required.
                </div>
              </div>

              <!-- domainPass -->
              <div class="form-group">
                <label _ngcontent-xbq-c130="" for="account-country">Web Password</label>
                <input formControlName="domainPass"
                  [ngClass]="{'is-invalid': customDomainForm.controls.domainPass.errors && (customDomainForm.controls.domainPass.touched || customDomainForm.controls.domainPass.dirty) }"
                  class="form-control" type="text" placeholder="Text">
                <div class="invalid-feedback ml-2">
                  Password is required.
                </div>
              </div>

              <button class="btn btn-primary btn-block" type="submit">
                <span *ngIf="!spinner">Update</span>
                <div *ngIf="spinner" class="btn-spinner">
                  <div class="spinner-border spinner-border-sm mr-1" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                  <div class="spinner-grow spinner-grow-sm mr-1" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Update Modal-->
  <div class="modal fade" id="modal-update" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content border-0">
        <div class="cs-view show" id="modal-heroku-view">
          <div class="modal-header border-1 px-4">
            <img src="assets/img/logo/logo-dark.png" height="40px" style="height: 40px!important;" />
            <button class="close text-dark" type="button" data-dismiss="modal" aria-label="Close"><span
                aria-hidden="true">&times;</span></button>
          </div>

          <div class="modal-body px-4">

            <form [formGroup]="updateForm" (ngSubmit)="updateSettings()" class="needs-validation" novalidate>

              <!-- plan Input -->
              <div class="form-group">
                <label _ngcontent-xbq-c130="" for="account-country">Plan</label>
                <select formControlName="plan"
                  [ngClass]="{'is-invalid': updateForm.controls.plan.errors && (updateForm.controls.plan.touched || updateForm.controls.plan.dirty) }"
                  class="form-control" type="text" placeholder="Text">
                  <option value="Basic">Basic</option>
                  <option value="Pro">Pro</option>
                  <option value="Ultimate">Ultimate</option>
                  <option value="Custom">Custom</option>
                </select>
                <div class="invalid-feedback ml-2">
                  Plan is required.
                </div>
              </div>

              <!-- cost Input -->
              <div class="form-group">
                <label _ngcontent-xbq-c130="" for="account-country">Cost</label>
                <input formControlName="cost"
                  [ngClass]="{'is-invalid': updateForm.controls.cost.errors && (updateForm.controls.cost.touched || updateForm.controls.cost.dirty) }"
                  class="form-control" type="text" placeholder="Number">
                <div class="invalid-feedback ml-2">
                  Cost is required.
                </div>
              </div>

              <!-- salePrice -->
              <div class="form-group">
                <label _ngcontent-xbq-c130="" for="account-country">Sale Price</label>
                <input formControlName="salePrice"
                  [ngClass]="{'is-invalid': updateForm.controls.salePrice.errors && (updateForm.controls.salePrice.touched || updateForm.controls.salePrice.dirty) }"
                  class="form-control" type="text" placeholder="Number">
                <div class="invalid-feedback ml-2">
                  Sale price is required.
                </div>
              </div>

              <!-- status -->
              <div class="form-group">
                <label _ngcontent-xbq-c130="" for="account-country">Status</label>
                <select formControlName="status"
                  [ngClass]="{'is-invalid': updateForm.controls.status.errors && (updateForm.controls.status.touched || updateForm.controls.status.dirty) }"
                  class="form-control" type="text" placeholder="Text">
                  <option value="0">Offline</option>
                  <option value="1">Online</option>
                </select>
                <div class="invalid-feedback ml-2">
                  Status is required.
                </div>
              </div>

              <!-- partnerName -->
              <div class="form-group">
                <label _ngcontent-xbq-c130="" for="account-country">Partner Name</label>
                <select formControlName="partnerName"
                  [ngClass]="{'is-invalid': updateForm.controls.partnerName.errors && (updateForm.controls.partnerName.touched || updateForm.controls.partnerName.dirty) }"
                  class="form-control" type="text" placeholder="Text">
                  <option value="0:N/A">N/A</option>
                  <option *ngFor="let partn of partners" [value]="partn.id+':'+partn.fullName">{{partn.fullName}}
                  </option>
                </select>
              </div>

              <!-- partnerProfit -->
              <div class="form-group">
                <label _ngcontent-xbq-c130="" for="account-country">Partner Profit(%)</label>
                <input formControlName="partnerProfit"
                  [ngClass]="{'is-invalid': updateForm.controls.partnerProfit.errors && (updateForm.controls.partnerProfit.touched || updateForm.controls.partnerProfit.dirty) }"
                  class="form-control" type="text" placeholder="Number">
                <div class="invalid-feedback ml-2">
                  Partner profit is required.
                </div>
              </div>

              <button class="btn btn-primary btn-block" type="submit">
                <span *ngIf="!spinner">Update</span>
                <div *ngIf="spinner" class="btn-spinner">
                  <div class="spinner-border spinner-border-sm mr-1" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                  <div class="spinner-grow spinner-grow-sm mr-1" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Remove app Modal-->
  <div class="modal fade" id="modal-remove-app" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content border-0">
        <div class="cs-view show" id="modal-heroku-view">
          <div class="modal-header bg-danger border-1 px-4">
            <h3 class="text-white">Remove</h3>
            <button class="close text-white" type="button" data-dismiss="modal" aria-label="Close"><span
                aria-hidden="true">&times;</span></button>
          </div>

          <div class="modal-body px-4">
            <div class="container">
              <h3>Are you sure you want remove app {{appNumber}} ?</h3>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn ajax-button btn-danger btn-size" type="button" (click)="deleteApp()">
              <span *ngIf="!spinner">Remove</span>
              <div *ngIf="spinner" class="btn-spinner">
                <div class="spinner-border spinner-border-sm mr-1" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow spinner-grow-sm mr-1" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>


</div>