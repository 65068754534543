<!-- Header-->
<app-header-light></app-header-light>
<!-- Page content-->
<div class="container d-flex flex-column justify-content-center pt-5 mt-n6" style="flex: 1 0 auto;">
    <div class="pt-7 pb-5">
        <div class="text-center mb-2 pb-4">
            <h1 class="mb-grid-gutter"><img class="d-inline-block" src="/assets/img/pages/404/404-illustration.svg"
                    alt="Error 404" /><span class="sr-only" translate>v404.title1</span><span
                    class="d-block pt-3 font-size-sm font-weight-semibold text-danger" translate>v404.title2</span>
            </h1>
            <h2 translate>v404.title3</h2>
            <p class="pb-2" translate>v404.title4</p><a
                class="btn btn-translucent-primary mr-3" [routerLink]='["/"]' translate>v404.title5</a><span translate>v404.title6</span>
        </div>
        <div class="input-group-overlay mx-auto" style="max-width: 390px;">
            <div class="input-group-prepend-overlay"><span class="input-group-text"><i class="fe-search"></i></span>
            </div>
            <input class="form-control prepended-form-control" type="text" placeholder="{{'input_placeholder.title14' | translate}}">
        </div>
    </div>
</div>

<!-- Footer-->
<app-contact-footer></app-contact-footer>
<!--Coommon-->
<app-top-arrow></app-top-arrow>