<!-- Sidebar-->
<div class="container bg-overlay-content pb-4 mb-md-3" style="margin-top: -350px;">
    <div class="row">
        <div class="col-lg-4 mb-4 mb-lg-0">
            <div class="bg-light rounded-lg box-shadow-lg">
                <div class="px-4 py-4 mb-1 text-center">
                    <img class="d-block rounded-circle mx-auto my-2 avatar_container"
                        [src]="authService.decodedTocken.certpublickey" alt="Avatar" />
                    <h3 class="mb-0 pt-1">{{authService.decodedTocken.unique_name}}</h3><span
                        class="text-muted font-size-sm">{{authService.decodedTocken.email}}</span>
                </div>
                <div class="d-lg-none px-4 pb-4 text-center"><a class="btn btn-primary px-5 mb-2" href="#account-menu"
                        data-toggle="collapse" translate><i class="fe-menu mr-2"></i>profile_partner_menu.title11</a></div>
                <div class="d-lg-block collapse pb-2" id="account-menu">
                    <h3 class="d-block bg-secondary font-size-sm font-weight-semibold text-muted mb-0 px-4 py-3" translate>
                        profile_partner_menu.title10</h3>
                    <a [routerLink]="['/dashboard-partner/earnings-partner']" routerLinkActive="router-link-active"
                        class="d-flex align-items-center nav-link-style px-4 py-3" translate><i
                            class="fe-dollar-sign font-size-lg opacity-60 mr-2"></i>profile_partner_menu.title2<span
                            class="nav-indicator"></span>
                        
                    </a>
                    <a class="d-flex align-items-center nav-link-style px-4 py-3 border-top"
                        [routerLink]="['/dashboard-partner/my-customers']" translate><i
                            class="fe-users font-size-lg opacity-60 mr-2"></i>profile_partner_menu.title3
                    </a>

                    <a class="d-flex align-items-center nav-link-style px-4 py-3 border-top"
                        [routerLink]="['/dashboard-partner/my-requests']" translate><i
                            class="fe-folder font-size-lg opacity-60 mr-2"></i>profile_partner_menu.title4<span
                            class="text-muted font-size-sm font-weight-normal ml-auto"> </span>
                    </a>
                    <a class="d-flex align-items-center nav-link-style px-4 py-3 border-top"
                        [routerLink]="['/dashboard-partner/link-request']" translate><i
                            class="fe-mail font-size-lg opacity-60 mr-2"></i>profile_partner_menu.title5<span
                            class="text-muted font-size-sm font-weight-normal ml-auto"> </span>
                    </a>
                    <h3 class="d-block bg-secondary font-size-sm font-weight-semibold text-muted mb-0 px-4 py-3" translate>profile_partner_menu.title7</h3>
                    <a class="d-flex align-items-center nav-link-style px-4 py-3"
                        [routerLink]="['/dashboard-partner/profile-partner']" translate>profile_partner_menu.title8
                    </a>
                    <div class="d-flex align-items-center border-top">
                        <a class="d-block w-100 nav-link-style px-4 py-3"
                            [routerLink]="['/dashboard-partner/notifications']" translate>profile_partner_menu.title9
                        </a>
                        <div class="ml-auto px-3">
                            <div class="custom-control custom-switch">
                                <input class="custom-control-input" type="checkbox" id="notifications-switch"
                                    data-master-checkbox-for="#notification-settings" checked>
                                <label class="custom-control-label" for="notifications-switch"></label>
                            </div>
                        </div>
                    </div><a class="d-flex align-items-center nav-link-style px-4 py-3 border-top"
                        href="signin-image.html" translate><i class="fe-log-out font-size-lg opacity-60 mr-2"></i>profile_partner_menu.title6</a>
                </div>
            </div>
        </div>

        <!-- Content-->
        <div class="col-lg-8">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>