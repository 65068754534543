import { Component, OnInit } from '@angular/core';
import { MailgunService } from 'src/app/email-template/mailgun.service';

@Component({
  selector: 'app-email-template',
  templateUrl: './email-template.component.html',
  styleUrls: ['./email-template.component.css']
})
export class EmailTemplateComponent implements OnInit {

  constructor(private mailgunService: MailgunService) { }

  ngOnInit(): void {
    this.listTemplates();
  }

  updateTemplate(): void {
    this.mailgunService.updateTemplate().subscribe(z => {
      console.log(z);
    });
  }
  newTemplate(): void {
    this.mailgunService.newTemplate().subscribe(z => {
      console.log(z);
    });
  }

  listTemplates(): void {
    this.mailgunService.listTemplates().subscribe(z => {
      console.log(z);
    });
  }

}
