import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ActivatedRoute } from '@angular/router';
import { AlertService } from '../alert/alert.service';
declare var $: any;

const API_URI = environment.backend_url;
const BASE_URI = environment.base_url;
@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  public requests = [];
  public customers = [];
  public spinner: boolean=false;
  spinnerDelete: boolean=false;
  public statement:any={};

  public qInvoiceId:number=-1;
  public qInvoices:any []=[];

  public leftBarHeaders={
    requestsCount:0,
    paymntDue:'0.00'

  };
  autopayBalance:string= '0.00';
  autoPayInvoice:string="";
  autoPayDate='mm/dd/yyyy';
  overDue=false;

  constructor(private http: HttpClient, private activeRoute: ActivatedRoute,  public alertService: AlertService) { }

  getCustomerList(): Observable<any> {

    return this.http.get(API_URI + '/api/customers/');

  }
  getCustomerInfo(id: number): Observable<any> {

    return this.http.get(API_URI + '/api/customers/' + id);

  }

  updateProfileInfo(id: number, data: any): Observable<any> {

    return this.http.put(API_URI + '/api/customers/' + id, data);

  }

  uploadAvatar(customerId: number, oldAvatar: string, name: string, strBase64: any): Observable<any> {

    return this.http.post(API_URI + '/api/customers/uploadavatar', {
      customerId: customerId,
      oldAvatar: oldAvatar,
      name: name,
      strBase64: strBase64
    });
  }

  uploadCompanyLogo(customerId: number, oldAvatar: string, name: string, strBase64: any): Observable<any> {

    return this.http.post(API_URI + '/api/customers/uploadlogo', {
      customerId: customerId,
      oldAvatar: oldAvatar,
      name: name,
      strBase64: strBase64
    });
  }

  sendVerificationAccountEmail(fullName: string, toEmailAddress: string): Observable<any> {
    return this.http.post(API_URI + '/api/mailgun/sendemail', {
      fullName: fullName,
      domain: "quisoft.us",
      fromEmailAddress: "Quisoft Developers Group<noreply@quisoft.us>",
      toEmailAddress: toEmailAddress,
      Subject: "Action required",
      templateName: "accountConfirmation",
      setTtoken: true,
      tokenUrl: BASE_URI + "/auth/email-confirmed?token="
    });

  }

  sendResetPasswordEmail(toEmailAddress: string): Observable<any> {

    return this.http.post(API_URI + '/api/mailgun/send-reset-pass-email', {
      fullName: "N/A",
      domain: "quisoft.us",
      fromEmailAddress: "Quisoft Developers Group<noreply@quisoft.us>",
      toEmailAddress: toEmailAddress,
      Subject: "Reset account password",
      templateName: "resetCustomerPassword",
      setTtoken: true,
      tokenUrl: BASE_URI + "/auth/reset-password?token="
    });

  }


  verifyAccount(token: string): Observable<any> {
    let helper = new JwtHelperService();
    let email = helper.decodeToken(token).email;
    return this.http.post(API_URI + '/api/customers/verifyaccount', {
      token: token,
      email: email
    });

  }

  updatePasword(token: string, password: string): Observable<any> {
    let helper = new JwtHelperService();
    let email = helper.decodeToken(token).email;
    return this.http.post(API_URI + '/api/customers/updatepass', {
      token: token,
      email: email,
      password: password
    });

  }


  getMyRequests(id: number, sortMode: number): Observable<any> {

    return this.http.post(API_URI + '/api/requests/getbycustomer', {
      customerId: id,
      sortMode: sortMode
    });

  }
  getAllRequests(): Observable<any> {

    return this.http.get(API_URI + '/api/requests');

  }
  getRequestsFromAdmin(id: number, sortMode: number): Observable<any> {
    return this.http.post(API_URI + '/api/requests/getfromadmin', {
      customerId: id,
      sortMode: sortMode
    });

  }

  getAppByNumber(appNumber: string): Observable<any> {
    return this.http.get(API_URI + '/api/requests/' + appNumber);

  }

  updateHerokuData(AppNumber: string, HerokuDomain: string, HerokuPassword: string, HerokuPlan: string, HerokuProyectName: string, herokuToken: string, HerokuUser: string): Observable<any> {

    return this.http.post(API_URI + '/api/requests/updateheroku', {
      AppNumber: AppNumber,
      HerokuDomain: HerokuDomain,
      HerokuPassword: HerokuPassword,
      HerokuPlan: HerokuPlan,
      HerokuProyectName: HerokuProyectName,
      herokuToken: herokuToken,
      HerokuUser: HerokuUser,
    });

  }

  updateDatabaseInfo(AppNumber: string, DbProvider: string, DbAdapter: string, DdbUrl: string, DbName: string, DbUser: string, DbPassword: string): Observable<any> {

    return this.http.post(API_URI + '/api/requests/updatedatabase', {
      AppNumber: AppNumber,
      DbProvider: DbProvider,
      DbAdapter: DbAdapter,
      DdbUrl: DdbUrl,
      DbName: DbName,
      DbUser: DbUser,
      DbPassword: DbPassword
    });

  }

  updateCloudinaryData(AppNumber: string, CloudinaryUser: string, CloudinaryPassword: string, CloudinaryPlan: string, CloudName: string, CloudApiKey: string, CloudApiSecret: string): Observable<any> {

    return this.http.post(API_URI + '/api/requests/updatecloudinary', {
      AppNumber: AppNumber,
      CloudinaryUser: CloudinaryUser,
      CloudinaryPassword: CloudinaryPassword,
      CloudinaryPlan: CloudinaryPlan,
      CloudName: CloudName,
      CloudApiKey: CloudApiKey,
      CloudApiSecret: CloudApiSecret,
    });

  }


  updateCardconnectData(AppNumber: string, UsingBoltApi: string, MerchantId: string, BoltBaseURL: string, BoltAuthorizationKey: string, BoltHsn: string, UsingGatewayApi: string, GatewayId: string, GatewayApiKey: string, GatewayApiUser: string, GatewayBaseURL: string, CardPointUser: string, CardPointPass: string): Observable<any> {

    return this.http.post(API_URI + '/api/requests/updatecardconnect', {
      AppNumber: AppNumber,
      UsingBoltApi: UsingBoltApi,
      MerchantId: MerchantId,
      BoltBaseURL: BoltBaseURL,
      BoltAuthorizationKey: BoltAuthorizationKey,
      BoltHsn: BoltHsn,
      UsingGatewayApi: UsingGatewayApi,

      GatewayId: GatewayId,
      GatewayApiKey: GatewayApiKey,
      GatewayApiUser: GatewayApiUser,
      GatewayBaseURL: GatewayBaseURL,
      CardPointUser: CardPointUser,
      CardPointPass: CardPointPass,
    });

  }

  updateStripeData(AppNumber: string, UsingStripeApi: string, StripeId: string, StripePublicApiKey: string, StripePrivateApiKey: string, StripeUser: string, StripePass: string): Observable<any> {

    return this.http.post(API_URI + '/api/requests/updatestripe', {
      AppNumber: AppNumber,
      UsingStripeApi:UsingStripeApi,
      StripeId :StripeId,
      StripePublicApiKey:StripePublicApiKey,
      StripePrivateApiKey:StripePrivateApiKey,
      StripeUser:StripeUser,
      StripePass:StripePass,
    });

  }

  updateCustomDomainData(AppNumber: string, DomainProvider: string, DomainName: string, DomainUser: string, DomainPass: string): Observable<any> {

    return this.http.post(API_URI + '/api/requests/updatedomain', {
      AppNumber: AppNumber,
      DomainProvider:DomainProvider,
      DomainName:DomainName,
      DomainUser:DomainUser,
      DomainPass:DomainPass,
    

    });

  }

  updateMailgunData(AppNumber: string, MailgunAccountId: string, UsingMailgunApi: string, MailgunDomain: string, MailgunApiKey: string, MailgunUser: string, MailgunPass: string): Observable<any> {

    return this.http.post(API_URI + '/api/requests/updatemailgun', {
      AppNumber: AppNumber,
      MailgunAccountId:MailgunAccountId,
      UsingMailgunApi:UsingMailgunApi,
      MailgunDomain:MailgunDomain,
      MailgunApiKey:MailgunApiKey,
      MailgunUser:MailgunUser,
      MailgunPass:MailgunPass,
    });

  }

  updateSettings(AppNumber: string, plan: string, cost: number, salePrice: number, status: number, partnerId: number, partnerName: string, partnerProfit: number): Observable<any> {

    var data={
      AppNumber: AppNumber,
      plan:plan,
      cost:cost,
      salePrice:salePrice,
      status:status,
      partnerId:partnerId,
      partnerName:partnerName,
      partnerProfit:partnerProfit,
    }

    return this.http.post(API_URI + '/api/requests/updatesettings', data);

  }

  deleteApp(appId: number): Observable<any> {

    return this.http.post(API_URI + '/api/requests/deleteapp',{id:appId});

  }

  deleteRequests(id: number): Observable<any> {

    return this.http.delete(API_URI + '/api/requests/'+id);

  }

  delete(id: number): Observable<any> {

    return this.http.delete(API_URI + '/api/customers/'+id);

  }

  getPaymentMethods(stripeId: string): Observable<any> {

    return this.http.post(API_URI + '/api/stripe/getcards',{
      id:stripeId
    });

  }

  deletePaymentMethods(customerId: string,cardId: string): Observable<any> {

    return this.http.post(API_URI + '/api/stripe/deletecard',{
      customerId:customerId,
      cardId:cardId
    });

  }

  setDefaultPaymentMethods(customerId: string,cardId: string): Observable<any> {
    return this.http.post(API_URI + '/api/stripe/defaultpayment',{
      customerId:customerId,
      cardId:cardId
    });

  }

  addNewCard(customerId: string,tokenId: string): Observable<any> {
    return this.http.post(API_URI + '/api/stripe/addcard',{
      customerId:customerId,
      tokenId:tokenId
    });

  }

  getAllInvoices( search: string, start: Date, end:Date, page:number, limit:number, filter:number): Observable<any> {
      
    return this.http.post(API_URI + '/api/invoices/getinvoices',{
      Search:search,
      InitDate:start,
      EndDate:end,
      Page:page,
      Limit:limit,
      filter:filter
    });
  }

  getInvoicesHeaders( search: string, start: Date, end:Date, page:number, limit:number, filter:number): Observable<any> {
      
    return this.http.post(API_URI + '/api/invoices/getheaders',{
      Search:search,
      InitDate:start,
      EndDate:end,
      Page:page,
      Limit:limit,
      filter:filter
    });
  }

  deleteInvoice(id: number): Observable<any> {

    return this.http.delete(API_URI + '/api/invoices/'+id);

  }

  createInvoice(requestId:number, customerId: number, tax: number,otherFees:number, otherFeesDescription:string): Observable<any> {
    return this.http.post(API_URI + '/api/invoices/newinvoice',{
      CustomerId:customerId,     
      Tax:tax,
      OtherFees:otherFees,
      OtherFeesDescription:otherFeesDescription,
      RequestId:requestId
      
    });

  }

  updateInvoice(NoInvoice:string, PaymentMode: number, PayInCardOrCash: number,InvoiceSalePrice:number,Tax:number,OtherFees:number,OtherFeesDescription:string,BankTransaction:string): Observable<any> {
    return this.http.post(API_URI + '/api/invoices/update',{
      NoInvoice:NoInvoice,     
      PaymentMode:PaymentMode,
      PayInCardOrCash:PayInCardOrCash, 
      InvoiceSalePrice:InvoiceSalePrice,  
      Tax:Tax,  
      OtherFees:OtherFees,  
      OtherFeesDescription:OtherFeesDescription,  
      BankTransaction:BankTransaction,    
      
    });

  }

  
  getInvoices(year:number, customerId: number): Observable<any> {
  
    return this.http.post(API_URI + '/api/invoices/getbycustomer',{
      CustomerId:customerId,     
      Year:year
      
    });

  }

  
  chargeToCard(stripeCustomerId:string, amount: number, noInvoice: string): Observable<any> {
    return this.http.post(API_URI + '/api/stripe/charge',{
      CustomerStripeId:stripeCustomerId,     
      amount:amount,
      NoInvoice:noInvoice,      
      
    });

  }

  sendQuestionEmail(data: any): Observable<any> {

    return this.http.post(API_URI + '/api/mailgun/sendmessage', {
      fullName: data.fullName+"("+data.emailAddress+")",
      domain: "quisoft.us",
      fromEmailAddress: "Quisoft Developers Group<noreply@quisoft.us>",
      toEmailAddress: "quisoft.us@gmail.com",
      Subject: data.subject,
      Topic: data.topic,
      Message: data.message,
      templateName: "sendMessage",
      setTtoken: false,
      tokenUrl: BASE_URI + "/auth/reset-password?token="
    });

  }

  saveQInvoice(CustomerId:number,  noInvoice: string,dueAt: string, status:number, paymentMethod: string,paymentMethodNo: string, otherFees: number, otherFeesDescription: string, subTotal: number, tax: number,
    taxPercent: number, total: number, companyName: string, companyAddress: string,companyState: string, billedTo1: string, billedTo2: string, billedTo3: string, items:any): Observable<any> {

    return this.http.post(API_URI + '/api/qinvoices/newinvoice', {
      CustomerId: CustomerId,
      NoInvoice: noInvoice,
      DueDate: new Date(dueAt),
      Status: status,
      PaymentMode: paymentMethod,
      PaymentReference: paymentMethodNo,
      OtherFees: otherFees,
      OtherFeesDescription: otherFeesDescription,
      SubTotal: subTotal,
      Tax: tax,

      TaxPercent: taxPercent,
      Total: total,
      FromData1: companyName,
      FromData2: companyAddress,
      FromData3: companyState,
      BilledToData1: billedTo1,
      BilledToData2: billedTo2,
      BilledToData3: billedTo3,
      Items: JSON.stringify(items)
     
    });

  }

  getAllQInvoices( search: string, start: Date, end:Date, page:number, limit:number, filter:number, customerId:number): Observable<any> {
      
    return this.http.post(API_URI + '/api/qinvoices/getinvoices',{
      Search:search,
      InitDate:start,
      EndDate:end,
      Page:page,
      Limit:limit,
      filter:filter,
      CustomerId: customerId
    });
  }

  updateQInvoice(qInvoiceId: number, paymentMode: string, paymentReference:string, status:number): Observable<any> {
      
    return this.http.post(API_URI + '/api/qinvoices/update',{
      QInvoiceId:qInvoiceId,
      PaymentMode:paymentMode,
      PaymentReference:paymentReference,
      Status:status
    });
  }

  getQInvoiceById(qInvoiceId: number): Observable<any> {      
    return this.http.get(API_URI + '/api/qinvoices/'+qInvoiceId);
  }

  deleteQInvoice(id: number): Observable<any> {

    return this.http.delete(API_URI + '/api/qinvoices/'+id);

  }

  public deleteQInvoiceById(): void{
    this.spinnerDelete=true;
    this.deleteQInvoice(this.qInvoiceId).subscribe(r => {
      
      if(r.statusCode==201){
        this.qInvoices=this.qInvoices.filter(i=>i.id!=r.invoice.id);
              
        this.qInvoiceId=-1;
        this.spinnerDelete=false;
        $('#modal-remove-qinvoice').modal('hide');
        this.alertService.showAlert( "fe fe-bell", "Factura eliminada correctamente.","Invoice removed successfully.","success");
        
      }else{
        
        this.spinnerDelete=false;
      }
      
    }, err => {
      this.alertService.showAlert( "fe fe-alert-octagon","No se pudo eliminar la factura.","Could not remove invoice.","danger");
      this.spinnerDelete = false;

    });
    
  }

}


