import { Component, OnInit } from '@angular/core';
import { AdminService } from '../../dashboard-admin/admin.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-a-email-confirmed',
  templateUrl: './a-email-confirmed.component.html',
  styleUrls: ['./a-email-confirmed.component.css']
})
export class AEmailConfirmedComponent implements OnInit {
  confirmedView:boolean;
  constructor(private adminService: AdminService, private activeRoute: ActivatedRoute) { }

  ngOnInit(): void {

    if(this.activeRoute.snapshot.queryParams.token){     
      this.adminService.verifyAccount(this.activeRoute.snapshot.queryParams.token).subscribe(r=>{       
        if(r.statusCode!=200){
          this.confirmedView=false;
        }else{
          this.confirmedView=true;
        }        
      });
    }else{
      this.confirmedView=false;
    }
  }

}
