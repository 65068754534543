<div id="profile-menu">
    <div class="navbar-tool dropdown">
      <a class="navbar-tool-icon-box" [routerLink]="['/dashboard-admin/admin-profile-info']">
        <img  class="navbar-tool-icon-box-img avatar_container" avatar_container [src]="authService.decodedTocken.certpublickey" alt="Avatar"/>
      
      </a>
      <a class="navbar-tool-label dropdown-toggle" [routerLink]="['/dashboard-admin/admin-profile-info']">
        <small>Hello,</small>{{authService.decodedTocken.unique_name}}
      </a>
      <ul class="dropdown-menu dropdown-menu-right" style="width: 15rem;">
        <li><a class="dropdown-item d-flex align-items-center" [routerLink]="['/dashboard-admin/projects']" routerLinkActive="router-link-active"><i class="fe-folder font-size-lg opacity-60 mr-2"></i>Applications<span class="nav-indicator"></span><span class="ml-auto font-size-xs text-muted"></span></a></li>
        <li class="dropdown-divider"></li>
        <li><a class="dropdown-item d-flex align-items-center" [routerLink]="['/dashboard-admin/customers']"><i class="fe-users font-size-base opacity-60 mr-2"></i>Customers<span class="ml-auto font-size-xs text-muted">2</span></a></li>
        <li class="dropdown-divider"></li>
        <li><a class="dropdown-item d-flex align-items-center" [routerLink]="['/dashboard-admin/partners']"><i class="fe-user-check font-size-base opacity-60 mr-2"></i>Partners<span class="nav-indicator"></span><span class="ml-auto font-size-xs text-muted">1</span></a></li>
        <li class="dropdown-divider"></li>       
        <li><a class="dropdown-item d-flex align-items-center"  [routerLink]="['/dashboard-admin/invoices']"><i class="fe-layers font-size-base opacity-60 mr-2"></i>Invoices<span class="ml-auto font-size-xs text-muted"></span></a></li>
        <li class="dropdown-divider"></li>
        <li><a class="dropdown-item d-flex align-items-center"  [routerLink]="['/dashboard-admin/compensations']"><i class="fe-file font-size-base opacity-60 mr-2"></i>Compensations<span class="ml-auto font-size-xs text-muted"></span></a></li>
        <li class="dropdown-divider"></li>
        <li><a class="dropdown-item d-flex align-items-center" style="cursor: pointer;" (click)="authService.logout()"><i class="fe-log-out font-size-base opacity-60 mr-2"></i>Sign out</a></li>
      </ul>
      
    </div>
</div>

