<div class="d-flex flex-column h-100 bg-light rounded-lg box-shadow-lg p-4">
    <div class="py-2 p-md-3">
        <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
            <!--Invoice template-->
            <li [ngbNavItem]="1">
                <a ngbNavLink translate>dashboard.q_invoice.title1</a>
                <ng-template ngbNavContent>
                    <!-- Title + clear link-->
                    <div class="d-sm-flex pb-4 text-center text-sm-left" style="inline-size: fit-content;">                        
                        <a class="btn btn-link text-muted font-weight-medium btn-sm mb-2 btn-form" (click)="saveInvoice()">
                            <i *ngIf="!spinnerSave" class="fe-save font-size-base mr-2"></i><span *ngIf="!spinnerSave" translate>dashboard.q_invoice.title5</span>
                            <div *ngIf="spinnerSave" class="btn-spinner">
                                <div class="text-muted spinner-border spinner-border-sm mr-1" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                                <div class="text-muted spinner-grow spinner-grow-sm mr-1" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div>
                           
                        </a>
                        <a class="btn btn-link text-muted font-weight-medium btn-sm mb-2" (click)="downloadInvoice()"
                        translate><i class="fe-download font-size-base mr-2"></i>dashboard.q_invoice.title3 </a>
                        <a class="btn btn-link text-danger font-weight-medium btn-sm mb-2" (click)="clearTemplate()"
                            translate><i class="fe-trash-2 font-size-base mr-2"></i>dashboard.q_invoice.title2 </a>
                    </div>
                    <div class="invoice-box row">
                        <div class="col-12">
                            <table  cellpadding="0" cellspacing="0">
                                <tr class="top">
                                    <td colspan="3">
                                        <table>
                                            <tr>
                                                <td  class="title">
                                                    <canvas id="canvas" hidden></canvas>
                                                    <img id="companyLogo" [src]="companyLogo"
                                                        style="width:100%; max-width:300px;">
                                                </td>
    
                                                <td>
                                                    <span translate>dashboard.q_invoice.nav1.title1 </span><span>{{noInvoice}}</span><br>
                                                    <span translate>dashboard.q_invoice.nav1.title2</span><span>{{createdAt}}</span><br>
                                                    <span translate>dashboard.q_invoice.nav1.title3</span><input type="text" id="dueAt" [(ngModel)]="dueAt"
                                                        style="width: 100px;">
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
    
                                <tr class="information">
                                    <td colspan="3">
                                        <table>
                                            <tr>
                                                <td>
                                                    <input type="text" id="companyName" [(ngModel)]="companyName"><br>
                                                    <input type="text" id="companyAddress" [(ngModel)]="companyAddress"><br>
                                                    <input type="text" id="companyState" [(ngModel)]="companyState">
    
                                                </td>
    
                                                <td>
                                                    <span translate>dashboard.q_invoice.nav1.title4</span><br>
                                                    <input type="text" id="billedTo1" [(ngModel)]="billedTo1"><br>
                                                    <input type="text" id="billedTo2" [(ngModel)]="billedTo2"><br>
                                                    <input type="text" id="billedTo3" [(ngModel)]="billedTo3">
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
    
                                <tr class="heading">
                                    <td translate>
                                        dashboard.q_invoice.nav1.title5
                                    </td>
                                    <td>
                                        <!--Empty-->
                                    </td>
                                    <td class="align-rigth" translate>
                                        dashboard.q_invoice.nav1.title6
                                    </td>
                                </tr>
    
                                <tr class="details">
                                    <td>
                                        <input type="text" id="paymentMethod" [(ngModel)]="paymentMethod">
                                    </td>
                                    <td>
                                        <!--Empty-->
                                    </td>
                                    <td class="align-rigth">
                                        <input type="text" id="paymentMethodNo" [(ngModel)]="paymentMethodNo">
                                    </td>
                                </tr>
    
                                <tr class="heading">
                                    <td translate>
                                        dashboard.q_invoice.nav1.title7
                                    </td>
                                    <td translate>
                                        dashboard.q_invoice.nav1.title8
                                    </td>
                                    <td class="align-rigth" translate>
                                        dashboard.q_invoice.nav1.title9
                                    </td>
                                </tr>
    
                                <tr *ngFor="let item of invoiceItems" class="item">
                                    <td >
                                        <input type="text" id="desc" [(ngModel)]="item.description" style="width: 310px;">
                                        ]<i class="fe-trash-2 text-danger font-size-base mr-2" (click)="removeItem(item)"
                                            style="cursor: pointer;"></i>
                                    </td>
                                    <td class="align-rigth">
                                        <input type="number" id="desc" [(ngModel)]="item.count" style="width: 75px;"
                                            (change)="changeValues()">
    
                                    </td>
                                    <td class="align-rigth">
                                        <span>$ </span><input type="number" id="pric" [(ngModel)]="item.unitPrice"
                                            style="width: 75px;" (change)="changeValues()">
                                    </td>
                                </tr>
    
                                <tr class="item">
                                    <td class="text-primary">
                                        <span (click)="addItem()" style="cursor: pointer;"><i
                                                class="fe-plus font-size-lg mr-2"></i>
                                            <span translate>dashboard.q_invoice.nav1.title10</span></span>
    
                                    </td>
                                    <td>
                                        <br>
                                    </td>
                                    <td>
                                        <br>
                                    </td>
                                </tr>
                                <tr class="item">
                                    <td>
                                        <br>
                                    </td>
                                    <td>
                                        <br>
                                    </td>
                                    <td>
                                        <br>
                                    </td>
                                </tr>
                                <tr class="item">
                                    <td translate>
                                        dashboard.q_invoice.nav1.title11
                                    </td>
                                    <td>
                                        <br>
                                    </td>
                                    <td class="align-rigth">
                                        <span>$ </span><input type="number" id="otherFees" [(ngModel)]="otherFees"
                                            style="width: 75px;" (change)="changeValues()">
                                    </td>
                                </tr>
                                <tr class="item">
                                    <td>
                                        Sub Total
                                    </td>
                                    <td>
                                        <br>
                                    </td>
                                    <td class="align-rigth">
                                        <span>${{subTotal | number:'.2'}}</span>
                                    </td>
                                </tr>
                                <tr class="item last">
                                    <td colspan="2" translate>
                                        dashboard.q_invoice.nav1.title12 <span>( <input type="number" id="taxPercent" [(ngModel)]="taxPercent"
                                                style="width: 35px;" (change)="changeValues()">%)</span>
                                    </td>
    
                                    <td class="align-rigth">
                                        <span>${{tax | number:'.2'}}</span>
                                    </td>
                                </tr>
    
                                <tr class="total">
                                    <td></td>
                                    <td>
                                        <br>
                                    </td>
                                    <td class="align-rigth" style="border-top: 2px solid #eee; font-weight: bold;">
                                        Total: <span>${{total | number:'.2'}}</span>
                                    </td>
                                </tr>
                            </table>
                        </div>
                      
                    </div>
                </ng-template>
            </li>
            <!--Invoices List-->
            <li [ngbNavItem]="2" [disabled]="authService.decodedTocken.actort=='Basic'">
                <a ngbNavLink translate>dashboard.q_invoice.title6</a>
                <ng-template ngbNavContent>
                    <div class="row">
                        <!-- Headers info -->
                        <div class="col-12">
                            <div class="row">
                                <div class="col-xl-6">
                                    <div class="card widget-inline border-left-success shadow h-100 py-2">
                                        <div class="card-body">
                                            <div class="row no-gutters align-items-center">
                                                <div class="col mr-2">
                                                    <div class="text-xs font-weight-bold text-dark text-uppercase mb-1" translate>
                                                        dashboard.q_invoice.nav2.title1
                                                        <mat-form-field appearance="fill">
                                                            <mat-date-range-input [formGroup]="range"
                                                                [rangePicker]="picker">
                                                                <input matStartDate formControlName="start"
                                                                    placeholder="Start date">
                                                                <input matEndDate formControlName="end"
                                                                    (dateChange)="changeDateRange()"
                                                                    placeholder="End date">
                                                            </mat-date-range-input>
                                                            <mat-datepicker-toggle matSuffix [for]="picker">
                                                            </mat-datepicker-toggle>
                                                            <mat-date-range-picker #picker color="warn">
                                                            </mat-date-range-picker>

                                                            <mat-error
                                                                *ngIf="range.controls.start.hasError('matStartDateInvalid')">
                                                                Invalid start date
                                                            </mat-error>
                                                            <mat-error
                                                                *ngIf="range.controls.end.hasError('matEndDateInvalid')">
                                                                Invalid end date</mat-error>
                                                        </mat-form-field>
                                                    </div>
                                                    <div class="row no-gutters align-items-center">

                                                    </div>
                                                </div>
                                                <div class="col-auto"><i
                                                        class="fa fa-file-text-o fa-2x text-gray-300"></i></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-6">
                                    <div class="float-right">
                                        <input type="text" class="form-control custom-select custom-select-sm"
                                            (keyup)="searchInvoice($event)" [(ngModel)]="search" placeholder="{{'input_placeholder.label14' | translate}}">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--Invoices list-->
                    <div class="py-2 p-md-3">
                        <!-- Invoice(table)-->
                        <div *ngIf="spinner==false" class="table-responsive font-size-md">
                            <table class="table table-hover mt-2 mb-0">
                                <thead>
                                    <tr>
                                        <th translate>dashboard.q_invoice.nav2.title2</th>
                                        <th translate>dashboard.q_invoice.nav2.title3</th>
                                        <th translate>dashboard.q_invoice.nav2.title4</th>
                                        <th translate>dashboard.q_invoice.nav2.title5</th>
                                        <th class="algin-center" translate>dashboard.q_invoice.nav2.title6</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let invoice of customerService.qInvoices">
                                        <td class="py-3 align-middle">
                                            <div class="media align-items-center">
                                                <div class="media-body"><span
                                                        class="font-weight-medium text-heading mr-1">{{invoice.noInvoice}}</span>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="py-3 align-middle">{{invoice.billedToData1}}</td>
                                        <td class="py-3 align-middle algin-center">
                                            <div style="width: 50%; min-width: max-content;"
                                                [ngClass]="{'bg-faded-danger text-danger': invoice.status==0, 'bg-faded-success text-success': invoice.status==1}"
                                                class="font-size-xs font-weight-medium py-1 px-3 rounded-sm my-1 mr-2 ">
                                                {{(invoice.status==0 ?'Unpaid' : 'Paid')}}
                                            </div>
                                        </td>
                                        <td class="py-3 align-middle">
                                            <i class="fe-clock text-muted mr-1 mb-1"></i>{{invoice.createdAt |
                                            date:'medium' }}
                                        </td>
                                        <td class="py-3 align-middle algin-center">$ {{invoice.total |  number:'.2'}}</td>
                                        <td class="py-3 align-middle">
                                            <div class="btn-group font-size-sm text-center bt-menu">
                                                <button type="button"
                                                    class="btn btn-primary btn-sm btn-menu mt-2 dropdown dropdown-toggle navbar"
                                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <i class="fe-align-justify text-white "></i>
                                                </button>
                                                <div class="dropdown-menu dropdown-menu-right">
                                                    <div id="settings-menu">
                                                        <div><a class="dropdown-item" (click)="printInvoice(invoice)"
                                                                style="cursor: pointer;">
                                                                <i class="fe-printer fe-1x text-muted"
                                                                    data-toggle="tooltip" data-placement="bottom"
                                                                    title="Print"></i></a>
                                                        </div>
                                                        <div class="dropdown-divider"></div>
                                                        <div><a class="dropdown-item"  (click)="customerService.qInvoiceId=invoice.id" href="#modal-update-invoice" data-toggle="modal" style="cursor: pointer;">
                                                                <i class="fe-edit fe-1x text-muted"
                                                                    data-toggle="tooltip" data-placement="bottom"
                                                                    title="Update"></i></a>
                                                        </div>
                                                        <div class="dropdown-divider"></div>
                                                        <div><a class="dropdown-item" (click)="customerService.qInvoiceId = invoice.id;"
                                                                href="#modal-remove-qinvoice" data-toggle="modal"><i
                                                                    class="fe-trash-2 fe-1x text-muted"
                                                                    data-toggle="tooltip" data-placement="bottom"
                                                                    title="Remove"></i></a>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                        <div *ngIf="spinner==true" class="loading">
                            <div class="spinner-border text-primary" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>
                    <!-- Pagination-->
                    <hr><br>
                    <nav aria-label="Page navigation example">
                        <ul class="pagination justify-content-center">
                            <li class="page-item" (click)="previousPage()">
                                <a class="page-link" tabindex="-1" aria-disabled="true" translate>dashboard.q_invoice.nav2.title7</a>
                            </li>
                            <li *ngFor="let n of pagination.pages" class="page-item"><a class="page-link"
                                    [ngClass]="{'text-primary': (n==pagination.page) }" (click)="paginate(n)">{{n}}</a>
                            </li>
                            <li class="page-item" (click)="nextPage()">
                                <a class="page-link" translate>dashboard.q_invoice.nav2.title8</a>
                            </li>
                        </ul>
                    </nav>
                    <!--End pagination-->
                </ng-template>
            </li>
        </ul>

        <div [ngbNavOutlet]="nav" class="mt-2"></div>



    </div>
</div>