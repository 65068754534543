import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { PayemntsMothodsService } from 'src/app/dashboard/payemnts-mothods.service'
import { FormGroup, FormBuilder, Validators, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { StripeToken, StripeSource } from "stripe-angular";
import { CustomerService } from '../dashboard/customer.service';
import { AuthService } from '../auth/auth.service';
import { ExpansionCase } from '@angular/compiler';
import {AlertService} from '../alert/alert.service'

declare var $: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  @ViewChild('stripeCard') stripeCard: ElementRef;

  newCardForm: FormGroup;
  updInvoiceForm: FormGroup;


  invalidError = false;
  cardReady = false;
  extraData = this.paymentsMethods.extraData;
  spinnerBtn=false;

  constructor(public alertService:AlertService, public paymentsMethods: PayemntsMothodsService, private fb: FormBuilder, public customerService: CustomerService, public authService: AuthService) {
    this.newCardForm = fb.group({
      name: ['', Validators.required],
      address_line1: ['', Validators.required],
      address_city: ['', [Validators.required]],
      address_state: ['', [Validators.required]],
      address_zip: ['', Validators.required]
    });

    this.updInvoiceForm = fb.group({
      paymentMode: ['', Validators.required],
      paymentReference: ['', Validators.required],
      status: ['', Validators.required],
     
    });


  }

  ngOnInit(): void {
    //put scroll on top
    window.scrollTo({ top: 0, behavior: 'smooth' });
    $('#new-card-modal').on('hidden.bs.modal', (e) => {
      this.newCardForm.reset();
    })

    this.customerService.leftBarHeaders = {
      requestsCount: 0,
      paymntDue: '0.00'

    };  

    $('#modal-update-invoice').on('show.bs.modal', (e) => {
      this.loadInvoiceData();
    });
    $('#modal-update-invoice').on('hidden.bs.modal', (e) => {
      this.updInvoiceForm.reset();
    });

  }

  onStripeInvalid(error: Error) {
    console.log('Validation Error', error)
  }

  setStripeToken(token: StripeToken) {
    this.paymentsMethods.addNewCard(token.id);
    this.paymentsMethods.spinnerBtn = false;
  }

  setStripeSource(source: StripeSource) {
    console.log('Stripe source', source)
  }

  onStripeError(error: Error) {
    console.log("***********");
    console.error('Stripe error', error)
  }


  addNewcard(): boolean {
    this.newCardForm.markAllAsTouched();

    if (this.newCardForm.valid) {
      this.paymentsMethods.spinnerBtn = true;
      this.paymentsMethods.extraData.name = this.newCardForm.get('name').value;
      this.paymentsMethods.extraData.address_line1 = this.newCardForm.get('address_line1').value;
      this.paymentsMethods.extraData.address_city = this.newCardForm.get('address_city').value;
      this.paymentsMethods.extraData.address_state = this.newCardForm.get('address_state').value;
      this.paymentsMethods.extraData.address_zip = this.newCardForm.get('address_zip').value;
      //this.paymentsMethods.spinnerBtn=false;
      //stripeCard.createToken(this.paymentsMethods.extraData);
    }

    return this.newCardForm.valid;
  }

  makePayment(): void {

    this.spinnerBtn = true;
    if (this.customerService.autopayBalance != "0.00" && this.customerService.autoPayInvoice != "") {
      this.customerService.chargeToCard(this.authService.decodedTocken.groupsid, parseInt(this.customerService.autopayBalance), this.customerService.autoPayInvoice).subscribe(r => {
        
        if (r.statusCode==200) {
          this.customerService.updateInvoice(this.customerService.autoPayInvoice,2,1,parseFloat(this.customerService.autopayBalance),0,0,"N/A",r.id).subscribe(s=>{

            if(s.statusCode==201){
              console.log(s.msg);
              $('#modal-payment').modal('hide');              
              this.alertService.showAlert( "fe fe-bell", "Pago realizado correctamente.","Payment made successfully.","success");
            }            
          }, err => {
            this.spinnerBtn = false;
            console.log(err);
          });

          this.customerService.autopayBalance = "0.00";
          this.customerService.autoPayInvoice == "";
          this.customerService.autoPayDate = 'mm/dd/yyyy';
          this.customerService.overDue = false;
        }
        this.spinnerBtn = false;

      }, err => {
        this.spinnerBtn = false;
        console.log(err);
        this.alertService.showAlert( "fe fe-alert-octagon","No se pudo completar el pago.","Could not complete the payment.","danger");
      });
    } else {
      this.alertService.showAlert( "fe fe-alert-octagon","No se pudo completar el pago.","Could not complete the payment.","danger");
      this.spinnerBtn = false;
      console.log("Error, invalid statement balance.");
    }

   
  }

  loadInvoiceData(): void {
    this.customerService.getQInvoiceById(this.customerService.qInvoiceId).subscribe(r => {
      if (r.paymentMode) {
        this.updInvoiceForm.get('paymentMode').setValue(r.paymentMode);
        this.updInvoiceForm.get('paymentReference').setValue(r.paymentReference);
        this.updInvoiceForm.get('status').setValue(r.status);      
      }
    }, err => {
      console.log(err);
    });
  }
  updateInvoice(): void {
    this.spinnerBtn = true;
    this.updInvoiceForm.markAllAsTouched();
    if (this.updInvoiceForm.valid) {
      this.customerService.updateQInvoice(
        parseInt(this.customerService.qInvoiceId.toString()),
        this.updInvoiceForm.get('paymentMode').value,
        this.updInvoiceForm.get('paymentReference').value,       
        parseInt(this.updInvoiceForm.get('status').value)
       
      ).subscribe(r => {

        if (r.statusCode === 201) {
          this.spinnerBtn = false;
          this.alertService.showAlert("fe fe-bell", "La factura cambió correctamente","Data changed successfully.", "success");
          this.customerService.qInvoices.map(v=>{
            let value:any=v;
            if(value.id==this.customerService.qInvoiceId){
              value.status=r.invoice.status;
              value.paymentMode=r.invoice.paymentMode;
              value.paymentReference=r.invoice.paymentReference;
            }
            v=value;
          })
          
          this.customerService.qInvoiceId=-1;
          this.spinnerBtn = false;

        }
      }, err => {
        this.alertService.showAlert("fe fe-alert-octagon", "No se pudieron actualizar los datos","Could not update data.", "danger");
        this.spinnerBtn = false;

      });
    } else {
       this.spinnerBtn = false;
      
    }

   
  }


}
