<!-- Rate article-->
<div class="cs-product-review d-flex align-items-center border-top border-bottom mt-5 py-4">
    <h3 class="h5 my-2 pr-sm-2 mr-4" translate>faq.last_comment.title1</h3>
    <div class="text-nowrap">
        <button class="btn-like" type="button">98</button>
        <button class="btn-dislike" type="button">3</button>
    </div>
</div>
<!-- Submit request-->
<div class="text-center pt-6 pb-5 pb-md-6">
    <h2 class="h3 pb-2 mb-4" translate>faq.last_comment.title2</h2><i
        class="fe-life-buoy d-block h2 pb-2 mb-4 text-primary"></i><a class="btn btn-primary mb-4"
        [routerLink]='["/faq/submit-request"]' translate>faq.last_comment.btn1</a>
    <p class="font-size-sm mb-0" translate>faq.last_comment.title3</p>
</div>
