import { Component, OnInit, HostListener } from '@angular/core';
//import {trigger, state, style, animate, transition,} from '@angular/animations';
//import { RouterState } from '@angular/router';

// transition: right 400ms cubic-bezier(0.68, -0.55, 0.265, 1.55), opacity 0.3s, background-color 0.25s ease-in-out;
@Component({
  selector: 'app-top-arrow',
  templateUrl: './top-arrow.component.html',
  styleUrls: ['./top-arrow.component.css'],
  /*animations: [
    trigger('myInsertRemoveTrigger', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateX(70px)' }),
        animate('400ms cubic-bezier(0.68, -0.55, 0.265, 1.55)', style({ opacity: 1, transform: 'translateX(0)' })),
      ]),
      transition(':leave', [
        animate('400ms cubic-bezier(0.68, -0.55, 0.265, 1.55)', style({ opacity: 0.3, transform: 'translateX(70px)' }))
      ])
    ]),
   
  ]*/
})
export class TopArrowComponent implements OnInit {

  topArrow: boolean = false;
  constructor() { }

  ngOnInit(): void {
  }

  @HostListener('window:scroll', ['$event.currentTarget'])
  onClick(e) {  
    if(e.scrollY > 600) {
      this.topArrow=true;
    }else{
      this.topArrow=false;
    }
  }

  public goToUp():void{
    window.scrollTo({top: 0, behavior: 'smooth'});
  }
  /*scrollTopButton: () => {

    let element = document.querySelector('.btn-scroll-top'),
        scrollOffset = 600;
    
    if (element == null) return;

    let offsetFromTop = parseInt(scrollOffset, 10);
    
    window.addEventListener('scroll', (e) => {
      if (e.currentTarget.pageYOffset > offsetFromTop) {
        element.classList.add('show');
      } else {
        element.classList.remove('show');
      }
    });
  },*/
}
