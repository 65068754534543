import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header-dash-partner',
  templateUrl: './header-dash-partner.component.html',
  styleUrls: ['./header-dash-partner.component.css']
})
export class HeaderDashPartnerComponent implements OnInit {
  show: boolean = false;
  constructor() { }

  ngOnInit(): void {
  }

  collapse(): void {
    this.show = !this.show;
  }

}
