<div id="profile-menu">
    <div class="navbar-tool dropdown">
      <a class="navbar-tool-icon-box" [routerLink]="['/dashboard/profile-info']">
        <img  class="navbar-tool-icon-box-img avatar_container" avatar_container [src]="authService.decodedTocken.certpublickey" alt="Avatar"/>
      
      </a>
      <a class="navbar-tool-label dropdown-toggle" [routerLink]="['/dashboard/profile-info']">
        <small translate>profile_menu.title1</small>{{authService.decodedTocken.unique_name}}
      </a>
      <ul class="dropdown-menu dropdown-menu-right" style="width: 15rem;">
        <li><a class="dropdown-item d-flex align-items-center" [routerLink]="['/dashboard/payment']" routerLinkActive="router-link-active" translate><i class="fe-dollar-sign font-size-lg opacity-60 mr-2"></i>profile_menu.title2<span class="nav-indicator"></span><span class="ml-auto font-size-xs text-muted"></span></a></li>
        <li class="dropdown-divider"></li>
        <li><a class="dropdown-item d-flex align-items-center" [routerLink]="['/dashboard/requests']" translate><i class="fe-shopping-bag font-size-base opacity-60 mr-2"></i>profile_menu.title3<span class="ml-auto font-size-xs text-muted"></span></a></li>
        <li class="dropdown-divider"></li>       
        <li><a class="dropdown-item d-flex align-items-center" [routerLink]="['/dashboard/find-service']" translate><i class="fe-search font-size-base opacity-60 mr-2"></i>profile_menu.title4<span class="ml-auto font-size-xs text-muted"></span></a></li>
        <li class="dropdown-divider"></li>
        <li><a class="dropdown-item d-flex align-items-center"  [routerLink]="['/dashboard/payment-methods']" translate><i class="fe-credit-card font-size-base opacity-60 mr-2"></i>profile_menu.title5<span class="ml-auto font-size-xs text-muted"></span></a></li>
        <li class="dropdown-divider"></li>
        <li *ngIf="authService.decodedTocken.actort!='None'" ><a class="dropdown-item d-flex align-items-center"  [routerLink]="['/dashboard/q-invoice']" translate><i class="fe-file-text font-size-base opacity-60 mr-2"></i>profile_menu.title12<span class="ml-auto font-size-xs text-muted"></span></a></li>
        <li *ngIf="authService.decodedTocken.actort!='None'"  class="dropdown-divider"></li>
        <li><a class="dropdown-item d-flex align-items-center" style="cursor: pointer;" (click)="authService.logout()" translate><i class="fe-log-out font-size-base opacity-60 mr-2"></i>profile_menu.title6</a></li>
      </ul>
    </div>
</div>
