import { Injectable } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { CustomerService } from 'src/app/dashboard/customer.service';
import { AuthService } from 'src/app/auth/auth.service';
import { AlertService } from '../alert/alert.service';

declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class PayemntsMothodsService {

  cards=[];
  defaultCard: string="";
  spinner: boolean=false;
  spinnerDelete:boolean=false;
  spinnerBtn:boolean=false;
  cardId="";

  extraData = {
    "name": " ",    
    "address_line1": " ",
    "address_line2": " ",
    "address_city": " ",
    "address_state": " ",    
    "address_zip": " "
  };

  alert: any = {
    show: false,
    icon: "fe fe-bell",
    message: "Payment Methods View",
    type: "success"
  }  

  constructor(private customerService: CustomerService, private authService: AuthService, public alertService: AlertService) { }

  getPaymentMethods(): void{
    this.spinner=true;
    this.customerService.getPaymentMethods(this.authService.decodedTocken.groupsid).subscribe(r => {
      this.defaultCard=r.defaultCard;
      if(r.statusCode==200){
        this.defaultCard=r.defaultCard;
        r.cards.map(v=>{
          v.logo="assets/img/account/cards/card-visa.png"; 
          if(v.brand=="Visa"){
            v.logo="assets/img/account/cards/card-visa.png";            
          } else if(v.brand=="MasterCard"){
            v.logo="assets/img/account/cards/card-master.png";            
          } else if(v.brand=="American Express"){
            v.logo="assets/img/account/cards/card-amex.png";            
          } else if(v.brand=="Discover"){
            v.logo="assets/img/account/cards/card-discover.png";            
          }
          
          
         
        });
        this.cards=r.cards;
        this.spinner=false;
      }else{
        console.log(r);
        this.spinner=false;
      }
      
    });
    
  }

  changeDefaultCard(id):void{
    this.defaultCard=id;
    this.spinnerDelete=true;
    this.customerService.setDefaultPaymentMethods(this.authService.decodedTocken.groupsid,id).subscribe(r => {     
      if(r.statusCode==200){        
        this.defaultCard=id;
        this.cardId="";
        this.spinnerDelete=false;    
        this.alertService.showAlert( "fe fe-bell","La tarjeta predeterminada se cambió correctamente.", " Default card changed successfully.","success");  
       
      }else{
        
        this.spinnerDelete=false;
      }
      
    }, err => {
      this.alertService.showAlert( "fe fe-alert-octagon","No se pudo cambiar la tarjeta.","Could not change card.","danger");      
      this.spinnerDelete = false;

    });
    
  }

  deletePaymentMethods(): void{
    this.spinnerDelete=true;
    this.customerService.deletePaymentMethods(this.authService.decodedTocken.groupsid,this.cardId).subscribe(r => {
      //this.defaultCard=r.defaultCard;
      if(r.statusCode==200){
        this.cards=this.cards.filter(c=>c.id!=r.cardId);
        this.cards.map(v=>{
          v.logo="assets/img/account/cards/card-visa.png"; 
          if(v.brand=="Visa"){
            v.logo="assets/img/account/cards/card-visa.png";            
          } else if(v.brand=="MasterCard"){
            v.logo="assets/img/account/cards/card-master.png";            
          } else if(v.brand=="American Express"){
            v.logo="assets/img/account/cards/card-amex.png";            
          } else if(v.brand=="Discover"){
            v.logo="assets/img/account/cards/card-discover.png";            
          }         
        });       
        this.cardId="";
        this.spinnerDelete=false;
        $('#modal-delete').modal('hide');
        this.alertService.showAlert( "fe fe-bell", "Tarjeta eliminada correctamente.","Card removed successfully.","success");
        
      }else{
        
        this.spinnerDelete=false;
      }
      
    }, err => {
      this.alertService.showAlert( "fe fe-alert-octagon","No se pudo eliminar la tarjeta.","Could not remove card.","danger");
      this.spinnerDelete = false;

    });
    
  }

  addNewCard(tokenId): void{
    this.spinnerBtn=true;
    this.customerService.addNewCard(this.authService.decodedTocken.groupsid,tokenId).subscribe(r => {           
      if(r.statusCode==200){      
        this.cards.push(r.card);
        this.cards.map(v=>{
          v.logo="assets/img/account/cards/card-visa.png"; 
          if(v.brand=="Visa"){
            v.logo="assets/img/account/cards/card-visa.png";            
          } else if(v.brand=="MasterCard"){
            v.logo="assets/img/account/cards/card-master.png";            
          } else if(v.brand=="American Express"){
            v.logo="assets/img/account/cards/card-amex.png";            
          } else if(v.brand=="Discover"){
            v.logo="assets/img/account/cards/card-discover.png";            
          }         
        });       
        this.cardId="";
        this.spinnerBtn=false;
        $('#new-card-modal').modal('hide');
        this.alertService.showAlert( "fe fe-bell", "Tarjeta agregada correctamente.","Card added successfully.","success");
 
      }else{
        
        this.spinnerBtn=false;
      }
      
    }, err => {
      this.alertService.showAlert( "fe fe-alert-octagon","No se pudo agregar la tarjeta.","Could not add card.","danger");      
      this.spinnerBtn = false;

    });
    
  }

 

 
}
