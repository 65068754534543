import { Component, OnInit } from '@angular/core';
import { AppService } from '../../app.service'
import { trigger, state, style, animate, transition } from '@angular/animations';
import { CustomerService } from '../customer.service'
import { AuthService } from '../../auth/auth.service'
import { PayemntsMothodsService } from 'src/app/dashboard/payemnts-mothods.service'
import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable'
import { applyPlugin } from 'jspdf-autotable'
applyPlugin(jsPDF);
declare var Chart: any;
const doc = new jsPDF();
declare var $: any;
@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.css'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('200ms', style({ opacity: 1 })),
      ]),

    ]),
  ]
})

export class PaymentsComponent implements OnInit {

  labels=[];
  chartTitle=""

  spinnerChart: boolean = false;
  selectedYear: number = new Date().getFullYear();
  currentYear: number = new Date().getFullYear();
  invoices=[];

  spinnerHeaders: boolean = false;

  headers: any = {
    statementId: -1,
    currentBalance: 0,
    initDate: ' ',
    endDate: ' ',

    lastPayment: 0,
    lastPaymentDate: 'mm/dd/yyyy',


  }


  constructor(public appService: AppService, public customerService: CustomerService, public auhtService: AuthService, public payemntsMothodsService: PayemntsMothodsService) { }

  ngOnInit(): void {
    if(this.appService.defaultLanguage.getValue() == 'es'){
      this.labels=['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];
      this.chartTitle="Moto Pagado";
    }else{
      this.labels=['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      this.chartTitle="Payments";
    }
    this.loadDailySalesStats();
    this.loadHeaders();
    this.payemntsMothodsService.getPaymentMethods();

  }

  loadDailySalesStats(): void {
    this.spinnerChart = true;
    this.invoices=[];
    this.customerService.getInvoices(parseInt(this.selectedYear.toString()), parseInt(this.auhtService.decodedTocken.nameid)).subscribe(r => {
      let amountList: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      if (r) {
        r.map(v => {
          if (v.paymentMode > 0) {
            amountList[(new Date(v.initDate).getMonth())] += v.invoiceSalePrice;
            if(this.invoices[(new Date(v.initDate).getMonth())]==undefined){
              this.invoices[(new Date(v.initDate).getMonth())]=[];
            }
            this.invoices[(new Date(v.initDate).getMonth())].push(v);
          }
        });
        this.buildBarChart(amountList);
      }
      this.spinnerChart = false;
    });

  }

  buildBarChart(amountList): void {
    
    $( "div.chart-area" ).html( '<canvas id="chartDailySales"></canvas>' );
    var ctx = (document.getElementById('chartDailySales') as any).getContext('2d');
    var gradient = ctx.createLinearGradient(0, 0, 0, 180);

    gradient.addColorStop(0, 'rgba(134, 194, 51, 0.4)');
    gradient.addColorStop(0.5, 'rgba(134, 194, 51, 0.1)');
    gradient.addColorStop(1, 'rgba(134, 194, 51, 0)');

    var myChart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: this.labels,
        datasets: [{
          label:  this.chartTitle,
          data: amountList,
          // borderCapStyle: 'round',
          backgroundColor: gradient,
          borderColor: ['rgb(116, 175, 31)',
            'rgb(116, 175, 31)',
            'rgb(116, 175, 31)',
            'rgb(116, 175, 31)',
            'rgb(116, 175, 31)',
            'rgb(116, 175, 31)',
            'rgb(116, 175, 31)',
            'rgb(116, 175, 31)',
            'rgb(116, 175, 31)',
            'rgb(116, 175, 31)',
            'rgb(116, 175, 31)',
            'rgb(116, 175, 31)'
          ],
          borderWidth: 3
        }]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        },
        onClick: (e) => {
          try{
            var activePoints = myChart.getElementAtEvent(e);    
            if(this.appService.defaultLanguage.getValue() == 'es'){
              this.invoices[activePoints[0]._index].map(a=>{
                this.imprimirEstadoDeCuenta(a);
              });
             
            } else{
              
              this.invoices[activePoints[0]._index].map(a=>{
                this.printInvoice(a);
              });
              
            }      
            
           
          }catch (err) {

          }
         
      }
      }
    });

    myChart.canvas.parentNode.style.height = '200px';
    // myChart.canvas.parentNode.style.width = '550px';
  }

  changeYear(deviceValue): void {
    this.selectedYear = deviceValue;
    this.loadDailySalesStats();
  }

  loadHeaders(): void {
    this.spinnerHeaders = true;
    this.customerService.statement = {};
    this.customerService.getInvoices(new Date().getFullYear(), parseInt(this.auhtService.decodedTocken.nameid)).subscribe(r => {

      this.customerService.autopayBalance = '0.00';
      this.customerService.autoPayInvoice = "";
      this.customerService.autoPayDate = 'mm/dd/yyyy';
      this.customerService.overDue = false;
      this.headers = {
        statementId: -1,
        currentBalance: 0,
        initDate: ' ',
        endDate: ' ',

        lastPayment: 0,
        lastPaymentDate: 'mm/dd/yyyy',
      }

      if (r) {

        r.map(v => {
          if (v.paymentMode == 0) {
            this.customerService.statement = v;
            this.headers.statementId = v.id;
            this.headers.currentBalance = parseFloat(v.invoiceSalePrice).toFixed(2);
            this.headers.initDate = new Date(v.initDate).toLocaleDateString('en-US');
            this.headers.endDate = new Date(v.endDate).toLocaleDateString('en-US');


            var createdAt = new Date(v.createdAt);
            var fixedDate = new Date();
            fixedDate.setDate(createdAt.getDate() + 5);
            this.customerService.autoPayDate = fixedDate.toLocaleDateString('en-US');
            this.customerService.autopayBalance = parseFloat(v.invoiceSalePrice).toFixed(2).toString();
            this.customerService.autoPayInvoice = v.noInvoice;

            let x = new Date();
            let y = fixedDate;
            if (+x > +fixedDate) {
              this.customerService.overDue = true;
            }

            this.customerService.leftBarHeaders.paymntDue = parseFloat(v.invoiceSalePrice).toFixed(2).toString();
            //amountList[(new Date(v.initDate).getMonth())]+=v.invoiceSalePrice;
          } else {
            if (v.updatedAt != null) {
              if (this.headers.lastPaymentDate == 'mm/dd/yyyy') {
                this.headers.lastPayment = parseFloat(v.invoiceSalePrice).toFixed(2);
                this.headers.lastPaymentDate = new Date(v.updatedAt).toLocaleDateString('en-US');
              } else if ((new Date(this.headers.updatedAt) != null) && new Date(this.headers.updatedAt) > new Date(this.headers.lastPaymentDate)) {
                this.headers.lastPayment = parseFloat(v.invoiceSalePrice).toFixed(2);
                this.headers.lastPaymentDate = new Date(v.updatedAt).toLocaleDateString('en-US');
              }

            }

          }
        });

      }
      this.spinnerHeaders = false;
    });

  }


  printStatement(): void {
    try {
      
      if (this.customerService.statement.noInvoice != undefined) {

        let count = 0;
        let doc = new jsPDF('p', 'pt', 'letter'), rows = [], images = [], pos = 0;

        let columns = [{ title: 'Customer Information', dataKey: 'customer' }, { title: ' ', dataKey: 'empty' }];
        rows.push(
          { 'customer': 'Customer Name', 'empty': this.customerService.statement.clientName },
          { 'customer': 'Customer Phone', 'empty': this.customerService.statement.clientPhone },
          { 'customer': 'Billing Period', 'empty': 'From: ' + new Date(this.customerService.statement.initDate).toLocaleDateString('en-US') + '     To: ' + new Date(this.customerService.statement.endDate).toLocaleDateString('en-US') },

        );



        /********************************************* */
        doc.setDrawColor(this.appService.config.pdf.headerColor.R, this.appService.config.pdf.headerColor.G, this.appService.config.pdf.headerColor.B); doc.setFillColor(this.appService.config.pdf.headerColor.R, this.appService.config.pdf.headerColor.G, this.appService.config.pdf.headerColor.B);
        doc.rect(0, 0, 800, 70, 'F');
        doc.rect(0, doc.internal.pageSize.height - 25, 800, 25, 'F');
        doc.addImage(this.appService.logo.base64, this.appService.logo.x, this.appService.logo.y, this.appService.logo.w, this.appService.logo.h);

        doc.setFontSize(18); doc.setTextColor(255, 255, 255);

        doc.setTextColor(0, 0, 0); doc.setFontSize(10);
        doc.text(this.appService.config.pdf.address1, 25, 90);
        doc.text(this.appService.config.pdf.address2, 25, 100);
        doc.text(this.appService.config.pdf.phone, 25, 110);
        doc.text(this.appService.config.pdf.web, 25, 120);


        doc.text('Statement Report', 425, 90);
        doc.text('______________________________', 425, 98);
        doc.text('Invoice No: ' + this.customerService.statement.noInvoice, 425, 112);

        let d = new Date();

        doc.text('Created At: ' + new Date(this.customerService.statement.createdAt).toLocaleDateString('en-US'), 425, 123);

        autoTable(doc, {
          columns: columns,
          body: rows,
          startY: 160,
          margin: [90, 20, 70, 20],
          bodyStyles: {
            lineWidth: 0.5,
            cellPadding: 10,
            textColor: 20,
          },
          styles: {
            overflow: 'linebreak'
          },
          headStyles: {
            fillColor: '#86c233'
          },
          columnStyles: {
            customer: { cellWidth: 280 },
            empty: { cellWidth: 290 },

          },

        });

        let rows3 = [];
        let columns3 = [{ title: 'Statement Resume', dataKey: 'customer' }, { title: ' ', dataKey: 'empty' }];
        rows3.push(
          { 'customer': ' Online Services', 'empty':  this.customerService.statement.items.length.toString() },
          { 'customer': 'Services Cost', 'empty': '$' + parseFloat(this.customerService.statement.invoiceSalePrice).toFixed(2) },
          { 'customer': 'Other Charges', 'empty': '$0.0' },
          { 'customer': 'Total Due', 'empty': '$' + parseFloat(this.customerService.statement.invoiceSalePrice).toFixed(2) },

        );
        let finalY = (doc as any).lastAutoTable.finalY;
        autoTable(doc, {
          columns: columns3,
          body: rows3,
          startY: finalY + 25,
          margin: [90, 20, 70, 20],
          bodyStyles: {
            lineWidth: 0.5,
            cellPadding: 10,
            textColor: 20,
          },
          styles: {
            overflow: 'linebreak'
          },
          headStyles: {
            fillColor: '#86c233'
          },
          columnStyles: {
            customer: { cellWidth: 280 },
            empty: { cellWidth: 290 },

          },
          willDrawCell: (data) => {                      
            if (data.row.index === 3) {
              doc.setFont('helvetica', 'bold');
              doc.setFontSize(12);
            }
          }
        });


        let rows4 = [];
        let columns4 = [{ title: 'Payment Resume', dataKey: 'payment' }, { title: ' ', dataKey: 'empty' }];
        rows4.push(
          { 'payment': 'Payment Mode', 'empty': (this.customerService.statement.paymentMode==0 ?'Unpaid' : (this.customerService.statement.paymentMode==1 ?'Auto payment' : 'Manual payment'))},
          { 'payment': 'Pay in Cash', 'empty': '$' + parseFloat(this.customerService.statement.payInCash).toFixed(2) },
          { 'payment': 'Pay in Card', 'empty': '$' + parseFloat(this.customerService.statement.payInCard).toFixed(2)},
          { 'payment': 'Bank Transaction', 'empty': this.customerService.statement.bankTransaction},

        );
        finalY = (doc as any).lastAutoTable.finalY;
        autoTable(doc, {
          columns: columns4,
          body: rows4,
          startY: finalY + 25,
          margin: [90, 20, 70, 20],
          bodyStyles: {
            lineWidth: 0.5,
            cellPadding: 10,
            textColor: 20,
          },
          styles: {
            overflow: 'linebreak'
          },
          headStyles: {
            fillColor: '#86c233'
          },
          columnStyles: {
            payment: { cellWidth: 280 },
            empty: { cellWidth: 290 },

          },
          
        });


        doc.addPage();
        let position=0;
        this.customerService.statement.items.map(v => {

          let rows2 = [];
          let columns2 = [{ title: 'Service Information', dataKey: 'request' }, { title: ' ', dataKey: 'empty' }];

          let service = v;
          
          if (service != undefined) {
            count++;
            position+=40;
            rows2.push(
              { 'request': 'Application No', 'empty': service.number },
              { 'request': 'Application Name', 'empty': service.appName },
              { 'request': 'Plan', 'empty': service.plan },
              { 'request': 'Info collected on', 'empty': new Date(service.createdAt).toLocaleDateString('en-US') },
              { 'request': 'Status', 'empty': (service.status == 0 ? 'Offline' : 'Online') },
              { 'request': 'Service Mode', 'empty': (service.isProduction == 0 ? 'Demo' : 'Production') },
              { 'request': 'Partner Name', 'empty': service.partnerName },
              { 'request': 'Sale Price', 'empty': '$' + service.salePrice.toString() },

            );
          }
          let finalY = (doc as any).lastAutoTable.finalY;
          autoTable(doc, {
            columns: columns2,
            body: rows2,
            startY:  (position==40 ? position : finalY+40),
            margin: [90, 20, 70, 20],

            bodyStyles: {
              lineWidth: 0.5,
              cellPadding: 10,
              textColor: 20,
            },            
            styles: {
              overflow: 'linebreak'
            },
            headStyles: {
              fillColor: '#86c233'
            },
            showHead:"firstPage",
            columnStyles: {
              request: { cellWidth: 280 },
              empty: { cellWidth: 290 },
  
            },
            willDrawCell: (data) => {                      
              if (data.row.index === 7) {
                doc.setFont('helvetica', 'bold');
                doc.setFontSize(12);
              }
            }
          });

        });


        //doc.output('dataurlnewwindow');
        doc.save('Service(' + this.customerService.statement.noInvoice + ').pdf');
      } else {
        //this.showError("Does not have pending statements.");
      }
    } catch (err) {

      //this.showError(err);
      //this.hideBarTable();
    }
  }

  printInvoice(invoice): void {
    try {
      
      if (invoice.noInvoice != undefined) {

        let count = 0;
        let doc = new jsPDF('p', 'pt', 'letter'), rows = [], images = [], pos = 0;

        let columns = [{ title: 'Customer Information', dataKey: 'customer' }, { title: ' ', dataKey: 'empty' }];
        rows.push(
          { 'customer': 'Customer Name', 'empty': invoice.clientName },
          { 'customer': 'Customer Phone', 'empty':invoice.clientPhone },
          { 'customer': 'Billing Period', 'empty': 'From: ' + new Date(invoice.initDate).toLocaleDateString('en-US') + '     To: ' + new Date(this.customerService.statement.endDate).toLocaleDateString('en-US') },

        );



        /********************************************* */
        doc.setDrawColor(this.appService.config.pdf.headerColor.R, this.appService.config.pdf.headerColor.G, this.appService.config.pdf.headerColor.B); doc.setFillColor(this.appService.config.pdf.headerColor.R, this.appService.config.pdf.headerColor.G, this.appService.config.pdf.headerColor.B);
        doc.rect(0, 0, 800, 70, 'F');
        doc.rect(0, doc.internal.pageSize.height - 25, 800, 25, 'F');
        doc.addImage(this.appService.logo.base64, this.appService.logo.x, this.appService.logo.y, this.appService.logo.w, this.appService.logo.h);

        doc.setFontSize(18); doc.setTextColor(255, 255, 255);

        doc.setTextColor(0, 0, 0); doc.setFontSize(10);
        doc.text(this.appService.config.pdf.address1, 25, 90);
        doc.text(this.appService.config.pdf.address2, 25, 100);
        doc.text(this.appService.config.pdf.phone, 25, 110);
        doc.text(this.appService.config.pdf.web, 25, 120);


        doc.text('Statement Report', 425, 90);
        doc.text('______________________________', 425, 98);
        doc.text('Invoice No: ' + invoice.noInvoice, 425, 112);

        let d = new Date();

        doc.text('Created At: ' + new Date(invoice.createdAt).toLocaleDateString('en-US'), 425, 123);

        autoTable(doc, {
          columns: columns,
          body: rows,
          startY: 160,
          margin: [90, 20, 70, 20],
          bodyStyles: {
            lineWidth: 0.5,
            cellPadding: 10,
            textColor: 20,
          },
          styles: {
            overflow: 'linebreak'
          },
          headStyles: {
            fillColor: '#86c233'
          },
          columnStyles: {
            customer: { cellWidth: 280 },
            empty: { cellWidth: 290 },

          },

        });

        let rows3 = [];
        let columns3 = [{ title: 'Statement Resume', dataKey: 'customer' }, { title: ' ', dataKey: 'empty' }];
        rows3.push(
          { 'customer': ' Online Services', 'empty': invoice.items.length.toString() },
          { 'customer': 'Services Cost', 'empty': '$' + parseFloat(invoice.invoiceSalePrice).toFixed(2) },
          { 'customer': 'Other Charges', 'empty': '$0.0' },
          { 'customer': 'Total Due', 'empty': '$' + parseFloat(invoice.invoiceSalePrice).toFixed(2) },

        );
        let finalY = (doc as any).lastAutoTable.finalY;
        autoTable(doc, {
          columns: columns3,
          body: rows3,
          startY: finalY + 25,
          margin: [90, 20, 70, 20],
          bodyStyles: {
            lineWidth: 0.5,
            cellPadding: 10,
            textColor: 20,
          },
          styles: {
            overflow: 'linebreak'
          },
          headStyles: {
            fillColor: '#86c233'
          },
          columnStyles: {
            customer: { cellWidth: 280 },
            empty: { cellWidth: 290 },

          },
          willDrawCell: (data) => {                      
            if (data.row.index === 3) {
              doc.setFont('helvetica', 'bold');
              doc.setFontSize(12);
            }
          }
        });

        let rows4 = [];
        let columns4 = [{ title: 'Payment Resume', dataKey: 'payment' }, { title: ' ', dataKey: 'empty' }];
        rows4.push(
          { 'payment': 'Payment Mode', 'empty': (invoice.paymentMode==0 ?'Unpaid' : (invoice.paymentMode==1 ?'Auto payment' : 'Manual payment'))},
          { 'payment': 'Pay in Cash', 'empty': '$' + parseFloat(invoice.payInCash).toFixed(2) },
          { 'payment': 'Pay in Card', 'empty': '$' + parseFloat(invoice.payInCard).toFixed(2)},
          { 'payment': 'Bank Transaction', 'empty': invoice.bankTransaction},

        );
        finalY = (doc as any).lastAutoTable.finalY;
        autoTable(doc, {
          columns: columns4,
          body: rows4,
          startY: finalY + 25,
          margin: [90, 20, 70, 20],
          bodyStyles: {
            lineWidth: 0.5,
            cellPadding: 10,
            textColor: 20,
          },
          styles: {
            overflow: 'linebreak'
          },
          headStyles: {
            fillColor: '#86c233'
          },
          columnStyles: {
            payment: { cellWidth: 280 },
            empty: { cellWidth: 290 },

          },
          
        });

        doc.addPage();
        let position=0;
        invoice.items.map(v => {

          let rows2 = [];
          let columns2 = [{ title: 'Service Information', dataKey: 'request' }, { title: ' ', dataKey: 'empty' }];

          let service = v;
          if (service != undefined) {
            count++;
            position+=40;
            rows2.push(
              { 'request': 'Application No', 'empty': service.number },
              { 'request': 'Application Name', 'empty': service.appName },
              { 'request': 'Plan', 'empty': service.plan },
              { 'request': 'Info collected on', 'empty': new Date(service.createdAt).toLocaleDateString('en-US') },
              { 'request': 'Status', 'empty': (service.status == 0 ? 'Offline' : 'Online') },
              { 'request': 'Service Mode', 'empty': (service.isProduction == 0 ? 'Demo' : 'Production') },
              { 'request': 'Partner Name', 'empty': service.partnerName },
              { 'request': 'Sale Price', 'empty': '$' + service.salePrice.toString() },

            );
          }
          let finalY = (doc as any).lastAutoTable.finalY;
          autoTable(doc, {
            columns: columns2,
            body: rows2,
            startY:  (position==40 ? position : finalY+40),
            margin: [90, 20, 70, 20],

            bodyStyles: {
              lineWidth: 0.5,
              cellPadding: 10,
              textColor: 20,
            },            
            styles: {
              overflow: 'linebreak'
            },
            headStyles: {
              fillColor: '#86c233'
            },
            showHead:"firstPage",
            columnStyles: {
              request: { cellWidth: 280 },
              empty: { cellWidth: 290 },
  
            },
            willDrawCell: (data) => {                      
              if (data.row.index === 7) {
                doc.setFont('helvetica', 'bold');
                doc.setFontSize(12);
              }
            }
          });

        });


        //doc.output('dataurlnewwindow');
        doc.save('Service(' + invoice.noInvoice + ').pdf');
      } else {
        //this.showError("Does not have pending statements.");
      }
    } catch (err) {

      //this.showError(err);
      //this.hideBarTable();
    }
  }

  imprimirEstadoDeCuenta(invoice): void {
    try {
      
      if (invoice.noInvoice != undefined) {

        let count = 0;
        let doc = new jsPDF('p', 'pt', 'letter'), rows = [], images = [], pos = 0;

        let columns = [{ title: 'Información del cliente', dataKey: 'customer' }, { title: ' ', dataKey: 'empty' }];
        rows.push(
          { 'customer': 'Nombre del cliente', 'empty': invoice.clientName },
          { 'customer': 'Teléfono del cliente', 'empty':invoice.clientPhone },
          { 'customer': 'Período biliado', 'empty': 'De: ' + new Date(invoice.initDate).toLocaleDateString('en-US') + '     A: ' + new Date(this.customerService.statement.endDate).toLocaleDateString('en-US') },

        );



        /********************************************* */
        doc.setDrawColor(this.appService.config.pdf.headerColor.R, this.appService.config.pdf.headerColor.G, this.appService.config.pdf.headerColor.B); doc.setFillColor(this.appService.config.pdf.headerColor.R, this.appService.config.pdf.headerColor.G, this.appService.config.pdf.headerColor.B);
        doc.rect(0, 0, 800, 70, 'F');
        doc.rect(0, doc.internal.pageSize.height - 25, 800, 25, 'F');
        doc.addImage(this.appService.logo.base64, this.appService.logo.x, this.appService.logo.y, this.appService.logo.w, this.appService.logo.h);

        doc.setFontSize(18); doc.setTextColor(255, 255, 255);

        doc.setTextColor(0, 0, 0); doc.setFontSize(10);
        doc.text(this.appService.config.pdf.address1, 25, 90);
        doc.text(this.appService.config.pdf.address2, 25, 100);
        doc.text(this.appService.config.pdf.phone, 25, 110);
        doc.text(this.appService.config.pdf.web, 25, 120);


        doc.text('Estado de cuenta', 425, 90);
        doc.text('______________________________', 425, 98);
        doc.text('Factura No: ' + invoice.noInvoice, 425, 112);

        let d = new Date();

        doc.text('Creado el: ' + new Date(invoice.createdAt).toLocaleDateString('en-US'), 425, 123);

        autoTable(doc, {
          columns: columns,
          body: rows,
          startY: 160,
          margin: [90, 20, 70, 20],
          bodyStyles: {
            lineWidth: 0.5,
            cellPadding: 10,
            textColor: 20,
          },
          styles: {
            overflow: 'linebreak'
          },
          headStyles: {
            fillColor: '#86c233'
          },
          columnStyles: {
            customer: { cellWidth: 280 },
            empty: { cellWidth: 290 },

          },

        });

        let rows3 = [];
        let columns3 = [{ title: 'Resumen del Estado de Cuenta', dataKey: 'customer' }, { title: ' ', dataKey: 'empty' }];
        rows3.push(
          { 'customer': ' Servicios en línea', 'empty': invoice.items.length.toString() },
          { 'customer': 'Costo de los servicios', 'empty': '$' + parseFloat(invoice.invoiceSalePrice).toFixed(2) },
          { 'customer': 'Otros cargos', 'empty': '$0.0' },
          { 'customer': 'Total adeudado', 'empty': '$' + parseFloat(invoice.invoiceSalePrice).toFixed(2) },

        );
        let finalY = (doc as any).lastAutoTable.finalY;
        autoTable(doc, {
          columns: columns3,
          body: rows3,
          startY: finalY + 25,
          margin: [90, 20, 70, 20],
          bodyStyles: {
            lineWidth: 0.5,
            cellPadding: 10,
            textColor: 20,
          },
          styles: {
            overflow: 'linebreak'
          },
          headStyles: {
            fillColor: '#86c233'
          },
          columnStyles: {
            customer: { cellWidth: 280 },
            empty: { cellWidth: 290 },

          },
          willDrawCell: (data) => {                      
            if (data.row.index === 3) {
              doc.setFont('helvetica', 'bold');
              doc.setFontSize(12);
            }
          }
        });

        let rows4 = [];
        let columns4 = [{ title: 'Resumen del Pago', dataKey: 'payment' }, { title: ' ', dataKey: 'empty' }];
        rows4.push(
          { 'payment': 'Modo de Pago', 'empty': (invoice.paymentMode==0 ?'Unpaid' : (invoice.paymentMode==1 ?'Auto payment' : 'Manual payment'))},
          { 'payment': 'Pagado en efectivo', 'empty': '$' + parseFloat(invoice.payInCash).toFixed(2) },
          { 'payment': 'Pagado en tarjeta', 'empty': '$' + parseFloat(invoice.payInCard).toFixed(2)},
          { 'payment': 'Transacción bancaria', 'empty': invoice.bankTransaction},

        );
        finalY = (doc as any).lastAutoTable.finalY;
        autoTable(doc, {
          columns: columns4,
          body: rows4,
          startY: finalY + 25,
          margin: [90, 20, 70, 20],
          bodyStyles: {
            lineWidth: 0.5,
            cellPadding: 10,
            textColor: 20,
          },
          styles: {
            overflow: 'linebreak'
          },
          headStyles: {
            fillColor: '#86c233'
          },
          columnStyles: {
            payment: { cellWidth: 280 },
            empty: { cellWidth: 290 },

          },
          
        });

        doc.addPage();
        let position=0;
        invoice.items.map(v => {

          let rows2 = [];
          let columns2 = [{ title: 'Información del servicio', dataKey: 'request' }, { title: ' ', dataKey: 'empty' }];

          let service = v;
          if (service != undefined) {
            count++;
            position+=40;
            rows2.push(
              { 'request': 'Aplicación No', 'empty': service.number },
              { 'request': 'Nombre de la Aplicación', 'empty': service.appName },
              { 'request': 'Plan', 'empty': service.plan },
              { 'request': 'Info colectada el', 'empty': new Date(service.createdAt).toLocaleDateString('en-US') },
              { 'request': 'Estado', 'empty': (service.status == 0 ? 'Offline' : 'Online') },
              { 'request': 'Modo del servicio', 'empty': (service.isProduction == 0 ? 'Demo' : 'Production') },
              { 'request': 'Representante Asociado', 'empty': service.partnerName },
              { 'request': 'Precio', 'empty': '$' + service.salePrice.toString() },

            );
          }
          let finalY = (doc as any).lastAutoTable.finalY;
          autoTable(doc, {
            columns: columns2,
            body: rows2,
            startY:  (position==40 ? position : finalY+40),
            margin: [90, 20, 70, 20],

            bodyStyles: {
              lineWidth: 0.5,
              cellPadding: 10,
              textColor: 20,
            },            
            styles: {
              overflow: 'linebreak'
            },
            headStyles: {
              fillColor: '#86c233'
            },
            showHead:"firstPage",
            columnStyles: {
              request: { cellWidth: 280 },
              empty: { cellWidth: 290 },
  
            },
            willDrawCell: (data) => {                      
              if (data.row.index === 7) {
                doc.setFont('helvetica', 'bold');
                doc.setFontSize(12);
              }
            }
          });

        });


        //doc.output('dataurlnewwindow');
        doc.save('Servicio(' + invoice.noInvoice + ').pdf');
      } else {
        //this.showError("Does not have pending statements.");
      }
    } catch (err) {

      //this.showError(err);
      //this.hideBarTable();
    }
  }

}
