import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header-login',
  templateUrl: './header-login.component.html',
  styleUrls: ['./header-login.component.css']
})
export class HeaderLoginComponent implements OnInit {
  show: boolean = false;
  constructor() { }

  ngOnInit(): void {
  }
  collapse(): void {
    this.show = !this.show;
  }
}
