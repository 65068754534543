<div @fadeIn id="signin-view">
    <h1 class="h2" translate>password_reset.title1</h1>
    <p class="font-size-ms text-muted mb-4" translate>password_reset.title2</p>
    <form [formGroup]="resetPassForm" (ngSubmit)="submitResetPassword()" class="needs-validation" novalidate>
      <div class="input-group-overlay form-group">
        <div class="input-group-prepend-overlay"><span class="input-group-text"><i class="fe-mail"></i></span>
        </div>
        <input
          [ngClass]="{'is-invalid': (resetPassForm.controls.emailAddress.errors || resetPassForm.errors?.invalidCredentials) && (resetPassForm.controls.emailAddress.touched || resetPassForm.controls.emailAddress.dirty) }"
          formControlName="emailAddress" class="form-control prepended-form-control" type="email" placeholder="{{'input_placeholder.label1' | translate}}" required>
        <div *ngIf="resetPassForm.controls.emailAddress.errors?.required" class="invalid-feedback ml-2" translate>
          inputs_errors.error01
        </div>
        <div *ngIf="resetPassForm.controls.emailAddress.errors?.email && !resetPassForm.controls.emailAddress.errors?.required"
          class="invalid-feedback ml-2" translate>
          inputs_errors.error02
        </div>
        <div *ngIf="resetPassForm.errors?.invalidCredentials"
          class="invalid-feedback ml-2" translate>
          inputs_errors.error02_2
          
        </div>
      </div>  
      <div class="d-flex justify-content-between align-items-center form-group">
        <div class="custom-control custom-checkbox">
          <input class="custom-control-input" type="checkbox" id="keep-signed-2">
          <label class="custom-control-label" for="keep-signed-2" translate>password_reset.title5</label>
        </div>
      </div>
      <button class="btn btn-primary btn-block" type="submit">
        <span *ngIf="!spinner" translate>password_reset.btn01</span>
        <div *ngIf="spinner" class="btn-spinner">
          <div class="spinner-border spinner-border-sm mr-1" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          <div class="spinner-grow spinner-grow-sm mr-1" role="status">
            <span class="sr-only">Loading...</span>
          </div>        
        </div>     
      </button>
      <p class="font-size-sm pt-3 mb-0" translate>password_reset.title6 <a [routerLink]="['/auth/register']" class='font-weight-medium' translate>password_reset.title7</a>
      </p>
    </form>
  </div>
