import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-last-content',
  templateUrl: './last-content.component.html',
  styleUrls: ['./last-content.component.css']
})
export class LastContentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
