import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { AppService } from "./app.service";

import { StripeModule } from "stripe-angular"

import { GooglePlaceModule } from "ngx-google-places-autocomplete";

/****************translate module********************/
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

export function translateHttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
/****************end translte************************/

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomepageComponent } from './homepage/homepage.component';
import { PriceComponent } from './price/price.component';
import { HeaderDarkComponent } from './header-dark/header-dark.component';
import { HeaderLightComponent } from './header-light/header-light.component';
import { MenuComponent } from './menu/menu.component';
import { DemoAdminComponent } from './demo-admin/demo-admin.component';
import { ContactFooterComponent } from './contact-footer/contact-footer.component';
import { AboutComponent } from './about/about.component';
import { ContactusComponent } from './contactus/contactus.component';
import { HelpComponent } from './help/help.component';
import { HeaderLoginComponent } from './header-login/header-login.component';

import { AuthComponent } from './auth/auth.component';

import { NgxFlagIconCssModule } from 'ngx-flag-icon-css';


import { LoginComponent } from './auth/login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SideBarComponent } from './dashboard/side-bar/side-bar.component';
import { RequestsComponent } from './dashboard/requests/requests.component';
import { ProfileInfoComponent } from './dashboard/profile-info/profile-info.component';
import { PaymentsComponent } from './dashboard/payments/payments.component';
import { PaymentMethodsComponent } from './dashboard/payment-methods/payment-methods.component';
import { FindServiceComponent } from './dashboard/find-service/find-service.component';
import { TopArrowComponent } from './common/top-arrow/top-arrow.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FaqComponent } from './faq/faq.component';
import { V404Component } from './v404/v404.component';
import { ComigSoonComponent } from './comig-soon/comig-soon.component';
import { HeaderDashComponent } from './dashboard/header-dash/header-dash.component';
import { DemoPosComponent } from './demo-pos/demo-pos.component';

import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { LeftBarComponent } from './faq/left-bar/left-bar.component';
import { LastContentComponent } from './faq/last-content/last-content.component';
import { Content01Component } from './faq/content/content01/content01.component';
import { Content02Component } from './faq/content/content02/content02.component';
import { Content03Component } from './faq/content/content03/content03.component';
import { Content04Component } from './faq/content/content04/content04.component';
import { Content05Component } from './faq/content/content05/content05.component';
import { Content06Component } from './faq/content/content06/content06.component';
import { Content07Component } from './faq/content/content07/content07.component';
import { Content08Component } from './faq/content/content08/content08.component';
import { Content09Component } from './faq/content/content09/content09.component';
import { Content10Component } from './faq/content/content10/content10.component';
import { Content11Component } from './faq/content/content11/content11.component';
import { Content12Component } from './faq/content/content12/content12.component';
import { Content13Component } from './faq/content/content13/content13.component';
import { Content14Component } from './faq/content/content14/content14.component';
import { Content15Component } from './faq/content/content15/content15.component';
import { Content16Component } from './faq/content/content16/content16.component';
import { PrivacyComponent } from './legal/privacy/privacy.component';
import { TermsComponent } from './legal/terms/terms.component';
import { SubmitRequestComponent } from './faq/submit-request/submit-request.component';
import { RegisterComponent } from './auth/register/register.component';
import { ConfirmEmailComponent } from './auth/confirm-email/confirm-email.component';
import { ProfileMenuComponent } from './menu/profile-menu/profile-menu.component';



import { AuthInterceptor } from 'src/app/auth/auth.interceptor';
import { EmailConfirmedComponent } from './auth/email-confirmed/email-confirmed.component';
import { ForgotComponent } from './auth/forgot/forgot.component';
import { EmailSendedComponent } from './auth/email-sended/email-sended.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { AuthGuardService } from './auth/auth-guard.service';
import { EmailTemplateComponent } from './email-template/email-template/email-template.component';
import { AuthPartnerComponent } from './auth-partner/auth-partner/auth-partner.component';
import { LoginPartnerComponent } from './auth-partner/login-partner/login-partner.component';
import { ProjectsComponent } from './dashboard-admin/projects/projects.component';
import { PartnersComponent } from './dashboard-admin/partners/partners.component';
import { DashboardAdminComponent } from './dashboard-admin/dashboard-admin.component';
import { HeaderAdminComponent } from './dashboard-admin/header-admin/header-admin.component';
import { AdminMenuComponent } from './menu/admin-menu/admin-menu.component';
import { PEmailConfirmedComponent } from './auth-partner/p-email-confirmed/p-email-confirmed.component';
import { PConfirmEmailComponent } from './auth-partner/p-confirm-email/p-confirm-email.component';
import { DashboardPartnerComponent } from './dashboard-partner/dashboard-partner.component';
import { HeaderDashPartnerComponent } from './dashboard-partner/header-dash-partner/header-dash-partner.component';
import { ProfilePartnerMenuComponent } from './menu/profile-partner-menu/profile-partner-menu.component';
import { EarningsPartnerComponent } from './dashboard-partner/earnings-partner/earnings-partner.component';
import { MyCustomersComponent } from './dashboard-partner/my-customers/my-customers.component';
import { MyRequestsComponent } from './dashboard-partner/my-requests/my-requests.component';
import { SideBarPartnerComponent } from './dashboard-partner/side-bar-partner/side-bar-partner.component';
import { ProfilePartnerComponent } from './dashboard-partner/profile-partner/profile-partner.component';
import { ForgotPasswordComponent } from './auth-partner/forgot-password/forgot-password.component';
import { ResetPasswComponent } from './auth-partner/reset-passw/reset-passw.component';
import { AuthAdminComponent } from './auth-admin/auth-admin.component';
import { LoginAdminComponent } from './auth-admin/login-admin/login-admin.component';
import { RegisterAdminComponent } from './auth-admin/register-admin/register-admin.component';
import { AEmailConfirmedComponent } from './auth-admin/a-email-confirmed/a-email-confirmed.component';
import { ForgotPasswComponent } from './auth-admin/forgot-passw/forgot-passw.component';
import { ResetPassComponent } from './auth-admin/reset-pass/reset-pass.component';
import { AdminProfileInfoComponent } from './dashboard-admin/admin-profile-info/admin-profile-info.component';
import { PartnersListComponent } from './dashboard-admin/partners/partners-list/partners-list.component';
import { AlertComponent } from './alert/alert.component';
import { AdminPhoneMenuComponent } from './menu/admin-phone-menu/admin-phone-menu.component';
import { CustomersComponent } from './dashboard-admin/customers/customers.component';
//import { StripeCardComponent } from './dashboard/stripe-card/stripe-card.component';
import { InvoicesComponent } from './dashboard-admin/invoices/invoices.component';


import { MaterialModule } from './material/material.module';
import { CompensationsComponent } from './dashboard-admin/compensations/compensations.component';
import { LinkRequestComponent } from './dashboard-partner/link-request/link-request.component';
import { LinkRequestDoneComponent } from './dashboard-partner/link-request-done/link-request-done.component';
import { QInvoiceComponent } from './dashboard/q-invoice/q-invoice.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PriceQinvoiceComponent } from './price-qinvoice/price-qinvoice.component';
import { DemoQinvoiceComponent } from './demo-qinvoice/demo-qinvoice.component';
import { DemoSmartsalonComponent } from './demo-smartsalon/demo-smartsalon.component';
import { PriceSmartsalonComponent } from './price-smartsalon/price-smartsalon.component';
import { DemoOnlinestoreComponent } from './demo-onlinestore/demo-onlinestore.component';
import { DemoRpcargoComponent } from './demo-rpcargo/demo-rpcargo.component';
import { PriceRpcargoComponent } from './price-rpcargo/price-rpcargo.component';






@NgModule({
  declarations: [
    AppComponent,
    HomepageComponent,
    PriceComponent,
    HeaderDarkComponent,
    HeaderLightComponent,
    MenuComponent,
    DemoAdminComponent,
    ContactFooterComponent,
    AboutComponent,
    ContactusComponent,
    HelpComponent,
    HeaderLoginComponent,
    LoginComponent,
    AuthComponent,
    DashboardComponent,
    SideBarComponent,
    RequestsComponent,
    ProfileInfoComponent,
    PaymentsComponent,
    PaymentMethodsComponent,
    FindServiceComponent,
    TopArrowComponent,
    FaqComponent,
    V404Component,
    ComigSoonComponent,
    HeaderDashComponent,
    DemoPosComponent,
    LeftBarComponent,
    LastContentComponent,
    Content01Component,
    Content02Component,
    Content03Component,
    Content04Component,
    Content05Component,
    Content06Component,
    Content07Component,
    Content08Component,
    Content09Component,
    Content10Component,
    Content11Component,
    Content12Component,
    Content13Component,
    Content14Component,
    Content15Component,
    Content16Component,
    PrivacyComponent,
    TermsComponent,
    SubmitRequestComponent,
    RegisterComponent,
    ConfirmEmailComponent,
    ProfileMenuComponent,
    EmailConfirmedComponent,
    ForgotComponent,
    EmailSendedComponent,
    ResetPasswordComponent,
    EmailTemplateComponent,
    AuthPartnerComponent,
    LoginPartnerComponent,
    ProjectsComponent,
    PartnersComponent,
    DashboardAdminComponent,
    HeaderAdminComponent,
    AdminMenuComponent,
    PEmailConfirmedComponent,
    PConfirmEmailComponent,
    DashboardPartnerComponent,
    HeaderDashPartnerComponent,
    ProfilePartnerMenuComponent,
    EarningsPartnerComponent,
    MyCustomersComponent,
    MyRequestsComponent,
    SideBarPartnerComponent,
    ProfilePartnerComponent,
    ForgotPasswordComponent,
    ResetPasswComponent,
    AuthAdminComponent,
    LoginAdminComponent,
    RegisterAdminComponent,
    AEmailConfirmedComponent,
    ForgotPasswComponent,
    ResetPassComponent,
    AdminProfileInfoComponent,
    PartnersListComponent,
    AlertComponent,
    AdminPhoneMenuComponent,
    CustomersComponent,
    // StripeCardComponent,
    InvoicesComponent,
    CompensationsComponent,
    LinkRequestComponent,
    LinkRequestDoneComponent,
    QInvoiceComponent,
    PriceQinvoiceComponent,
    DemoQinvoiceComponent,
    DemoSmartsalonComponent,
    PriceSmartsalonComponent,
    DemoOnlinestoreComponent,
    DemoRpcargoComponent,
    PriceRpcargoComponent,





  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    GooglePlaceModule,
    NgxFlagIconCssModule,
    MaterialModule,

    StripeModule.forRoot("pk_test_51HgZl9JPVtoS9ehO6E8xwTYXaUWmEoxJQosKlkJt4hvMaG1rZpjdg6wpRxS8yKHUrATMha3OhIvfLbYAyvetqBEe00EBJorXlv"),

    /********translate module****/
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateHttpLoaderFactory,
        deps: [HttpClient]
      }
    }),

    NgbModule
    /********end translate module****/

  ],

  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    AuthGuardService

  ],
  bootstrap: [AppComponent]
})
export class AppModule {

  //define available languages
  availableLng = ['en', 'es'];

  //start the translation service
  constructor(private translateService: TranslateService,public appService: AppService) {
    //defines the default language
    var tmpLng = 'en';

    //gets the default browser language
    const currentLng = window.navigator.language.substring(0, 2);

   if (this.availableLng.includes(currentLng))
      tmpLng = currentLng;

    translateService.setDefaultLang(tmpLng);

    if(tmpLng=='es'){
      this.appService.defaultLanguage.next('es');
    }else{
      if(tmpLng=='en'){
        this.appService.defaultLanguage.next('us');
      }
    }
  }
}
