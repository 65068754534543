import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';

const API_URI = environment.backend_url;
@Injectable({
  providedIn: 'root'
})
export class MailgunService {

  constructor(private http: HttpClient,private activeRoute: ActivatedRoute) { }


  newTemplate(): Observable<any> {
    return this.http.post(API_URI + '/api/mailgun/storetemplate',{});
  }

  updateTemplate(): Observable<any> {
    return this.http.post(API_URI + '/api/mailgun/updatetemplate',{});
  }

  listTemplates(): Observable<any> {

    return this.http.post(API_URI + '/api/mailgun/listtemplates',{});
  }
}
