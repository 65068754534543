import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-header-admin',
  templateUrl: './header-admin.component.html',
  styleUrls: ['./header-admin.component.css']
})
export class HeaderAdminComponent implements OnInit {
  show: boolean = false;
  constructor() { }

  ngOnInit(): void {
  }


  collapse(): void {
    this.show = !this.show;
  }

}
