import { Component, OnInit } from '@angular/core';
import { CustomerService } from '../../dashboard/customer.service'
import { FormGroup, FormBuilder, Validators, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { AppService } from 'src/app/app.service';
import { PartnerService } from 'src/app/dashboard-admin/partner.service';
import { Partner } from 'src/app/Models/Partner';

import { AlertService } from '../../alert/alert.service';

import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable'
import { applyPlugin } from 'jspdf-autotable'
applyPlugin(jsPDF);
const doc = new jsPDF();

export function validPhoneNumber(country: any): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const phoneNumber = parsePhoneNumberFromString(control.value, country);
    return !phoneNumber?.isValid() ? { invalid: { value: control.value } } : null;
  };
}




export const passwordValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
  const password = control.get('password');
  const confirmPassword = control.get('confirmPassword');
  return password && confirmPassword && password.value === confirmPassword.value ? null : { 'differentPassword': true };
};

declare var Chart: any;
declare var $: any;

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.css']
})
export class ProjectsComponent implements OnInit {

  spinnerRqs: boolean = false;
  orderBy: number = 0;

  herokuForm: FormGroup;
  databaseForm: FormGroup;
  cloudinaryForm: FormGroup;
  cardconnectForm: FormGroup;
  stripeForm: FormGroup;
  mailgunForm: FormGroup;
  customDomainForm: FormGroup;
  updateForm: FormGroup;
  partners: Partner[];

  spinner: boolean = false;
  appNumber: string;
  appId: number;
  search: string = "";

  spinnerHeaders: boolean = false;
  spinnerBtn: any = {
    loading: false,
    id: -1

  }
  headers: any = {
    percent: 0,
    new: 0,
    inprogress: 0,
    completed: 0
  }

  alert: any = {
    show: false,
    icon: "fe fe-bell",
    message: "Partner View",
    type: "success"
  }

  constructor(
    public customerService: CustomerService,
    public partnerService: PartnerService,
    private fb: FormBuilder,
    private appService: AppService,
    public alertService: AlertService
  ) {
    this.herokuForm = fb.group({
      plan: ['', Validators.required],
      proyectName: ['', Validators.required],
      herokuUser: ['', [Validators.required]],
      domain: ['', [Validators.required]],
      herokuPassword: ['', Validators.required],
      cliToken: ['', Validators.required]
    });

    this.databaseForm = fb.group({
      dbProvider: ['', Validators.required],
      dbAdapter: ['', Validators.required],
      dbUrl: ['', Validators.required],
      dbName: ['', [Validators.required]],
      dbUser: ['', [Validators.required]],
      dbPassword: ['', Validators.required],
    });

    this.cloudinaryForm = fb.group({
      cloudinaryUser: ['', [Validators.required]],
      cloudinaryPassword: ['', Validators.required],
      cloudinaryPlan: ['', Validators.required],

      cloudName: ['', [Validators.required]],
      cloudApiKey: ['', Validators.required],
      cloudApiSecret: ['', Validators.required],

    });

    this.cardconnectForm = fb.group({
      usingBoltApi: ['0', Validators.required],
      merchantId: ['', Validators.required],
      boltBaseURL: ['', Validators.required],
      boltAuthorizationKey: ['', [Validators.required]],
      boltHsn: ['', [Validators.required]],
      usingGatewayApi: ['0', Validators.required],
      gatewayId: ['', Validators.required],
      gatewayApiKey: ['', Validators.required],
      gatewayApiUser: ['', Validators.required],
      gatewayBaseURL: ['', Validators.required],

      cardPointUser: ['', Validators.required],
      cardPointPass: ['', Validators.required],

    });

    this.stripeForm = fb.group({
      usingStripeApi: ['0', Validators.required],
      stripeId: ['', Validators.required],
      stripePublicApiKey: ['', Validators.required],
      stripePrivateApiKey: ['', [Validators.required]],

      stripeUser: ['', [Validators.required]],
      stripePass: ['', Validators.required],
    });

    this.mailgunForm = fb.group({
      usingMailgunApi: ['0', Validators.required],
      mailgunAccountId: ['', Validators.required],
      mailgunDomain: ['', Validators.required],
      mailgunApiKey: ['', [Validators.required]],
      mailgunUser: ['', [Validators.required]],
      mailgunPass: ['', Validators.required],
    });

    this.customDomainForm = fb.group({
      domainProvider: ['', Validators.required],
      domainName: ['', Validators.required],
      domainUser: ['', Validators.required],
      domainPass: ['', [Validators.required]]
    });

    this.updateForm = fb.group({
      plan: ['', Validators.required],
      cost: ['', Validators.required],
      salePrice: ['', Validators.required],
      status: ['', [Validators.required]],
      partnerName: ['', [Validators.required]],
      partnerProfit: ['', [Validators.required]],
    });



  }

  ngOnInit(): void {
    this.getPartnersList();
    this.loadHeaders();
    this.loadCurrentRequests();


    $('#modal-heroku').on('show.bs.modal', (e) => {
      this.loadHerokuData();
    });
    $('#modal-heroku').on('hidden.bs.modal', (e) => {
      this.herokuForm.reset();
    });

    $('#modal-database').on('show.bs.modal', (e) => {
      this.loadDatabaseInfo();
    });
    $('#modal-database').on('hidden.bs.modal', (e) => {
      this.databaseForm.reset();
    });

    $('#modal-cloudinary').on('show.bs.modal', (e) => {
      this.loadCloudinaryData();
    });
    $('#modal-cloudinary').on('hidden.bs.modal', (e) => {
      this.cloudinaryForm.reset();
    });

    $('#modal-cardconnect').on('show.bs.modal', (e) => {
      this.loadCardconnectData();
    });
    $('#modal-cardconnect').on('hidden.bs.modal', (e) => {
      this.cardconnectForm.reset();
    });

    $('#modal-stripe').on('show.bs.modal', (e) => {
      this.loadStripeData();
    });
    $('#modal-stripe').on('hidden.bs.modal', (e) => {
      this.stripeForm.reset();
    });


    $('#modal-mailgun').on('show.bs.modal', (e) => {
      this.loadMailgunData();
    });
    $('#modal-mailgun').on('hidden.bs.modal', (e) => {
      this.mailgunForm.reset();
    });


    $('#modal-customDomain').on('show.bs.modal', (e) => {
      this.loadCustomDomainData();
    });
    $('#modal-customDomain').on('hidden.bs.modal', (e) => {
      this.customDomainForm.reset();
    });

    $('#modal-update').on('show.bs.modal', (e) => {
      this.loadGeneralAppData();
    });
    $('#modal-update').on('hidden.bs.modal', (e) => {
      this.updateForm.reset();
    });




  }
  loadHeaders(): void {
    this.spinnerHeaders = true;
    this.headers.new = 0;
    this.headers.inprogress = 0;
    this.headers.completed = 0;
    this.headers.percent = 0;
    this.customerService.getAllRequests().subscribe(r => {
      r.map(v => {
        if (v.status == 0) {
          this.headers.new++;
        } else if (v.status == 1) {
          this.headers.inprogress++;
        } else if (v.status == 2) {
          this.headers.completed++;
        }
      });
      this.headers.percent = parseFloat(((this.headers.completed * 100) / r.length).toString()).toFixed(2);
      this.spinnerHeaders = false;
    });
  }

  searchRequest(e): void {
    if (e.code === 'Enter') {
      this.spinnerRqs = true;
      this.customerService.getAllRequests().subscribe(r => {
        var result = [];

        r.map(v => {
          v.apps.map(a => {
            if (a.partnerId != 0) {
              a.avatar = this.partners.filter(p => p.id == a.partnerId)[0].avatar;
            } else {
              a.avatar = "https://res.cloudinary.com/quisoft/image/upload/v1595737595/Quisoft/perfil_baggut.svg";
            }
          });
          if (v.noRequest.toUpperCase().includes(this.search.toUpperCase()) || v.customerName.toUpperCase().includes(this.search.toUpperCase())) {
            result.push(v);
          }
        });


        if (this.search == "") {
          this.customerService.requests = r;
        } else {
          this.customerService.requests = result;
        }

        this.spinnerRqs = false;
      });
    }
  }

  loadCurrentRequests(): void {
    this.spinnerRqs = true;
    this.customerService.getRequestsFromAdmin(parseInt('-1'), this.orderBy).subscribe(r => {
      this.customerService.requests = r;
      this.customerService.requests.map(v => {
        v.apps.map(a => {
          if (a.partnerId != 0) {
            a.avatar = this.partners.filter(p => p.id == a.partnerId)[0].avatar;
          } else {
            a.avatar = "https://res.cloudinary.com/quisoft/image/upload/v1595737595/Quisoft/perfil_baggut.svg";
          }
        });

      });

      this.spinnerRqs = false;
    });
  }

  sortRequests(deviceValue): void {
    this.orderBy = deviceValue;
    this.spinnerRqs = true;
    this.customerService.getRequestsFromAdmin(parseInt('-1'), parseInt(this.orderBy.toString())).subscribe(r => {
      this.customerService.requests = r;

      this.customerService.requests.map(v => {
        v.apps.map(a => {
          if (a.partnerId != 0) {
            a.avatar = this.partners.filter(p => p.id == a.partnerId)[0].avatar;
          } else {
            a.avatar = "https://res.cloudinary.com/quisoft/image/upload/v1595737595/Quisoft/perfil_baggut.svg";
          }
        });

      });
      this.spinnerRqs = false;
    });


  }

  getPartnersList(): void {
    this.partnerService.getPartnersList().subscribe(r => {

      if (r.length > 0) {
        this.partners = r;
      }

    }, err => {
      console.log(err);
    });
  }


  createInvoice(req: any): void {

    this.spinnerBtn.loading = true;
    this.spinnerBtn.id = req.id
    if (req.status == 2) {//If request are valid to create invoice

      this.customerService.createInvoice(parseInt(req.id), parseInt(req.customerId), parseInt("0"), parseInt("0"), "N/A").subscribe(r => {

        if (r.statusCode === 201) {
          this.alertService.showAlert("fe fe-bell", "Factura creada con éxito","Invoice created successfully.", "success");

        } else {
          this.alertService.showAlert("fe fe-alert-octagon", "No se pudo crear la factura","Could not create invoice.", "danger");

        }
        this.spinnerBtn.id = -1
        this.spinnerBtn.loading = false;
      }, err => {
        this.alertService.showAlert("fe fe-alert-octagon", "No se pudo crear la factura","Could not create invoice.", "danger");
        this.spinnerBtn.id = -1
        this.spinnerBtn.loading = false;

      });
    } else {
      this.alertService.showAlert("fe fe-alert-octagon","No se pudo crear la factura", "Could not create invoice.", "danger");
      this.spinnerBtn.id = -1
      this.spinnerBtn.loading = false;
    }
  }

  loadHerokuData(): void {
    this.customerService.getAppByNumber(this.appNumber).subscribe(r => {
      if (r.length > 0) {
        this.herokuForm.get('plan').setValue(r[0].herokuPlan);
        this.herokuForm.get('proyectName').setValue(r[0].herokuProyectName);
        this.herokuForm.get('herokuUser').setValue(r[0].herokuUser);
        this.herokuForm.get('domain').setValue(r[0].herokuDomain);
        this.herokuForm.get('herokuPassword').setValue(r[0].herokuPassword);
        this.herokuForm.get('cliToken').setValue(r[0].herokuToken);
      }
    }, err => {
      console.log(err);
    });
  }
  updateHeroku(): void {

    this.herokuForm.markAllAsTouched();
    if (this.herokuForm.valid) {
      this.spinner = true;

      this.customerService.updateHerokuData(
        this.appNumber, this.herokuForm.get('domain').value,
        this.herokuForm.get('herokuPassword').value,
        this.herokuForm.get('plan').value,
        this.herokuForm.get('proyectName').value,
        this.herokuForm.get('cliToken').value,
        this.herokuForm.get('herokuUser').value
      ).subscribe(r => {

        if (r.statusCode === 201) {
          this.spinner = false;
          this.alertService.showAlert("fe fe-bell", "La configuración cambió correctamente","Settings changed successfully.", "success");

          this.spinner = false;

        }
      }, err => {
        this.alertService.showAlert("fe fe-alert-octagon", "No se pudieron actualizar los datos de heroku","Could not update heroku data.", "danger");
        this.spinner = false;

      });
    }

  }


  loadDatabaseInfo(): void {
    this.customerService.getAppByNumber(this.appNumber).subscribe(r => {
      if (r.length > 0) {
        this.databaseForm.get('dbProvider').setValue(r[0].dbProvider);
        this.databaseForm.get('dbAdapter').setValue(r[0].dbAdapter);
        this.databaseForm.get('dbUrl').setValue(r[0].ddbUrl);
        this.databaseForm.get('dbName').setValue(r[0].dbName);
        this.databaseForm.get('dbUser').setValue(r[0].dbUser);
        this.databaseForm.get('dbPassword').setValue(r[0].dbPassword);
      }
    }, err => {
      console.log(err);
    });
  }
  updateDatabase(): void {

    this.databaseForm.markAllAsTouched();
    if (this.databaseForm.valid) {
      this.spinner = true;

      this.customerService.updateDatabaseInfo(
        this.appNumber,
        this.databaseForm.get('dbProvider').value,
        this.databaseForm.get('dbAdapter').value,
        this.databaseForm.get('dbUrl').value,
        this.databaseForm.get('dbName').value,
        this.databaseForm.get('dbUser').value,
        this.databaseForm.get('dbPassword').value

      ).subscribe(r => {

        if (r.statusCode === 201) {
          this.spinner = false;
          this.alertService.showAlert("fe fe-bell", "La configuración cambió correctamente","Settings changed successfully.", "success");


        }
      }, err => {
        this.alertService.showAlert("fe fe-alert-octagon", "No se pudieron actualizar los datos","Could not update data.", "danger");

        this.spinner = false;

      });
    }

  }


  loadCloudinaryData(): void {
    this.customerService.getAppByNumber(this.appNumber).subscribe(r => {
      if (r.length > 0) {
        this.cloudinaryForm.get('cloudinaryUser').setValue(r[0].cloudinaryUser);
        this.cloudinaryForm.get('cloudinaryPassword').setValue(r[0].cloudinaryPassword);
        this.cloudinaryForm.get('cloudinaryPlan').setValue(r[0].cloudinaryPlan);
        this.cloudinaryForm.get('cloudName').setValue(r[0].cloudName);
        this.cloudinaryForm.get('cloudApiKey').setValue(r[0].cloudApiKey);
        this.cloudinaryForm.get('cloudApiSecret').setValue(r[0].cloudApiSecret);
      }
    }, err => {
      console.log(err);
    });
  }
  updateCloudinary(): void {

    this.cloudinaryForm.markAllAsTouched();
    if (this.cloudinaryForm.valid) {
      this.spinner = true;

      this.customerService.updateCloudinaryData(
        this.appNumber,
        this.cloudinaryForm.get('cloudinaryUser').value,
        this.cloudinaryForm.get('cloudinaryPassword').value,
        this.cloudinaryForm.get('cloudinaryPlan').value,
        this.cloudinaryForm.get('cloudName').value,
        this.cloudinaryForm.get('cloudApiKey').value,
        this.cloudinaryForm.get('cloudApiSecret').value


      ).subscribe(r => {

        if (r.statusCode === 201) {
          this.spinner = false;
          this.alertService.showAlert("fe fe-bell", "La configuración cambió correctamente","Settings changed successfully.", "success");


        }
      }, err => {
        this.alertService.showAlert("fe fe-alert-octagon","No se pudieron actualizar los datos", "Could not update data.", "danger");
        this.spinner = false;

      });
    }

  }


  loadCardconnectData(): void {
    this.customerService.getAppByNumber(this.appNumber).subscribe(r => {
      if (r.length > 0) {
        this.cardconnectForm.get('usingBoltApi').setValue(r[0].usingBoltApi);
        this.cardconnectForm.get('merchantId').setValue(r[0].merchantId);
        this.cardconnectForm.get('boltBaseURL').setValue(r[0].boltBaseURL);
        this.cardconnectForm.get('boltAuthorizationKey').setValue(r[0].boltAuthorizationKey);
        this.cardconnectForm.get('boltHsn').setValue(r[0].boltHsn);
        this.cardconnectForm.get('usingGatewayApi').setValue(r[0].usingGatewayApi);

        this.cardconnectForm.get('gatewayId').setValue(r[0].gatewayId);
        this.cardconnectForm.get('gatewayApiKey').setValue(r[0].gatewayApiKey);
        this.cardconnectForm.get('gatewayApiUser').setValue(r[0].gatewayApiUser);
        this.cardconnectForm.get('gatewayBaseURL').setValue(r[0].gatewayBaseURL);
        this.cardconnectForm.get('cardPointUser').setValue(r[0].cardPointUser);
        this.cardconnectForm.get('cardPointPass').setValue(r[0].cardPointPass);

      }
    }, err => {
      console.log(err);
    });
  }
  updateCardconnect(): void {

    this.cardconnectForm.markAllAsTouched();
    if (this.cardconnectForm.valid) {
      this.spinner = true;

      this.customerService.updateCardconnectData(
        this.appNumber,
        this.cardconnectForm.get('usingBoltApi').value,
        this.cardconnectForm.get('merchantId').value,
        this.cardconnectForm.get('boltBaseURL').value,
        this.cardconnectForm.get('boltAuthorizationKey').value,
        this.cardconnectForm.get('boltHsn').value,
        this.cardconnectForm.get('usingGatewayApi').value,

        this.cardconnectForm.get('gatewayId').value,
        this.cardconnectForm.get('gatewayApiKey').value,
        this.cardconnectForm.get('gatewayApiUser').value,
        this.cardconnectForm.get('gatewayBaseURL').value,
        this.cardconnectForm.get('cardPointUser').value,
        this.cardconnectForm.get('cardPointPass').value

      ).subscribe(r => {

        if (r.statusCode === 201) {
          this.spinner = false;
          this.alertService.showAlert("fe fe-bell", "La configuración cambió correctamente","Settings changed successfully.", "success");


        }
      }, err => {

        this.alertService.showAlert("fe fe-alert-octagon", "No se pudieron actualizar los datos","Could not update data.", "danger");
        this.spinner = false;

      });
    }

  }

  loadStripeData(): void {
    this.customerService.getAppByNumber(this.appNumber).subscribe(r => {
      if (r.length > 0) {
        this.stripeForm.get('usingStripeApi').setValue(r[0].usingStripeApi);
        this.stripeForm.get('stripeId').setValue(r[0].stripeId);
        this.stripeForm.get('stripePublicApiKey').setValue(r[0].stripePublicApiKey);
        this.stripeForm.get('stripePrivateApiKey').setValue(r[0].stripePrivateApiKey);
        this.stripeForm.get('stripeUser').setValue(r[0].stripeUser);
        this.stripeForm.get('stripePass').setValue(r[0].stripePass);

      }
    }, err => {
      console.log(err);
    });
  }
  updateStripe(): void {

    this.stripeForm.markAllAsTouched();
    if (this.stripeForm.valid) {
      this.spinner = true;

      this.customerService.updateStripeData(
        this.appNumber,
        this.stripeForm.get('usingStripeApi').value,
        this.stripeForm.get('stripeId').value,
        this.stripeForm.get('stripePublicApiKey').value,
        this.stripeForm.get('stripePrivateApiKey').value,
        this.stripeForm.get('stripeUser').value,
        this.stripeForm.get('stripePass').value


      ).subscribe(r => {

        if (r.statusCode === 201) {
          this.spinner = false;
          this.alertService.showAlert("fe fe-bell", "La configuración cambió correctamente","Settings changed successfully.", "success");


        }
      }, err => {

        this.alertService.showAlert("fe fe-alert-octagon", "No se pudieron actualizar los datos","Could not update data.", "danger");
        this.spinner = false;

      });
    }

  }


  loadMailgunData(): void {
    this.customerService.getAppByNumber(this.appNumber).subscribe(r => {
      if (r.length > 0) {
        this.mailgunForm.get('usingMailgunApi').setValue(r[0].usingMailgunApi);
        this.mailgunForm.get('mailgunAccountId').setValue(r[0].mailgunAccountId);
        this.mailgunForm.get('mailgunDomain').setValue(r[0].mailgunDomain);
        this.mailgunForm.get('mailgunApiKey').setValue(r[0].mailgunApiKey);
        this.mailgunForm.get('mailgunUser').setValue(r[0].mailgunUser);
        this.mailgunForm.get('mailgunPass').setValue(r[0].mailgunPass);

      }
    }, err => {
      console.log(err);
    });
  }
  updateMailgun(): void {

    this.mailgunForm.markAllAsTouched();
    if (this.mailgunForm.valid) {
      this.spinner = true;

      this.customerService.updateMailgunData(
        this.appNumber,
        this.mailgunForm.get('mailgunAccountId').value,
        this.mailgunForm.get('usingMailgunApi').value,
        this.mailgunForm.get('mailgunDomain').value,
        this.mailgunForm.get('mailgunApiKey').value,
        this.mailgunForm.get('mailgunUser').value,
        this.mailgunForm.get('mailgunPass').value


      ).subscribe(r => {

        if (r.statusCode === 201) {
          this.spinner = false;
          this.alertService.showAlert("fe fe-bell", "La configuración cambió correctamente","Settings changed successfully.", "success");


        }
      }, err => {

        this.alertService.showAlert("fe fe-alert-octagon", "No se pudieron actualizar los datos","Could not update data.", "danger");
        this.spinner = false;

      });
    }

  }


  loadCustomDomainData(): void {
    this.customerService.getAppByNumber(this.appNumber).subscribe(r => {
      if (r.length > 0) {
        this.customDomainForm.get('domainProvider').setValue(r[0].domainProvider);
        this.customDomainForm.get('domainName').setValue(r[0].domainName);
        this.customDomainForm.get('domainUser').setValue(r[0].domainUser);
        this.customDomainForm.get('domainPass').setValue(r[0].domainPass);
      }
    }, err => {
      console.log(err);
    });
  }
  updateCustomDomain(): void {

    this.customDomainForm.markAllAsTouched();
    if (this.customDomainForm.valid) {
      this.spinner = true;


      this.customerService.updateCustomDomainData(
        this.appNumber,
        this.customDomainForm.get('domainProvider').value,
        this.customDomainForm.get('domainName').value,
        this.customDomainForm.get('domainUser').value,
        this.customDomainForm.get('domainPass').value,


      ).subscribe(r => {

        if (r.statusCode === 201) {
          this.spinner = false;
          this.alertService.showAlert("fe fe-bell", "La configuración cambió correctamente","Settings changed successfully.", "success");

        }
      }, err => {

        this.alertService.showAlert("fe fe-alert-octagon", "No se pudieron actualizar los datos","Could not update data.", "danger");
        this.spinner = false;

      });
    }

  }


  loadGeneralAppData(): void {
    this.customerService.getAppByNumber(this.appNumber).subscribe(r => {
      if (r.length > 0) {
        this.updateForm.get('plan').setValue(r[0].plan);
        this.updateForm.get('cost').setValue(r[0].cost);
        this.updateForm.get('salePrice').setValue(r[0].salePrice);
        this.updateForm.get('status').setValue(r[0].status);
        this.updateForm.get('partnerName').setValue(r[0].partnerId + ':' + r[0].partnerName);
        this.updateForm.get('partnerProfit').setValue(r[0].partnerProfit);

      }
    }, err => {
      console.log(err);
    });
  }
  updateSettings(): void {

    this.updateForm.markAllAsTouched();
    if (this.updateForm.valid) {
      this.spinner = true;
      this.customerService.updateSettings(
        this.appNumber,
        this.updateForm.get('plan').value,
        Number(this.updateForm.get('cost').value),
        Number(this.updateForm.get('salePrice').value),
        Number(this.updateForm.get('status').value),
        Number(this.updateForm.get('partnerName').value.split(':')[0]),
        this.updateForm.get('partnerName').value.split(':')[1],
        Number(this.updateForm.get('partnerProfit').value),

      ).subscribe(r => {

        if (r.statusCode === 201) {

          this.customerService.requests.map(v => {
            if (v.noRequest == r.apps[0].noRequest) {
              let requestSalePrice = 0;
              let requestStatus = 2;
              v.apps.map(a => {
                if (a.number == this.appNumber) {
                  a.status = r.apps[0].status;
                  a.plan = r.apps[0].plan;
                  a.salePrice = r.apps[0].salePrice;
                  a.partnerId = r.apps[0].partnerId;
                  a.partnerName = r.apps[0].partnerName;
                  a.partnerProfit = r.apps[0].partnerProfit;

                  if (r.apps[0].partnerId != 0) {
                    a.avatar = this.partners.filter(p => p.id == a.partnerId)[0].avatar;
                  } else {
                    a.avatar = "https://res.cloudinary.com/quisoft/image/upload/v1595737595/Quisoft/perfil_baggut.svg";
                  }
                }
                if (a.status == 0) {
                  requestStatus = 1;
                }
                requestSalePrice += a.salePrice;

              });
              v.salePrice = parseFloat(requestSalePrice.toString()).toFixed(2);
              v.status = requestStatus;
            }

          });
          this.spinner = false;
          this.alertService.showAlert("fe fe-bell", "La configuración cambió correctamente","Settings changed successfully.", "success");
          this.loadHeaders();
        }
      }, err => {

        this.alertService.showAlert("fe fe-alert-octagon", "No se pudieron actualizar los datos","Could not update data.", "danger");
        this.spinner = false;

      });
    }

  }

  deleteApp(): void {
    this.spinner = true;
    this.customerService.deleteApp(this.appId).subscribe(r => {

      if (r.statusCode === 201) {//Remove app from view-list
        this.customerService.requests.map(v => {
          if (v.noRequest == r.request.noRequest) {
            v.salePrice = parseFloat(r.request.salePrice.toString()).toFixed(2);
            v.apps = v.apps.filter(a => a.id != r.app.id);
          }
        });
        $('#modal-remove-app').modal('hide');
        this.spinner = false;
        this.alertService.showAlert("fe fe-bell", "Aplicación eliminada correctamente","App removed successfully.", "success");

        this.spinner = false;

      }//Remove request from view-list if not have apps
      else if (r.statusCode === 202) {
        this.customerService.requests = this.customerService.requests.filter(x => x.id != r.request.id);
        $('#modal-remove-app').modal('hide');
        this.spinner = false;
        this.alertService.showAlert("fe fe-bell", "Aplicación eliminada correctamente","App removed successfully.", "success");

        this.spinner = false;
        this.loadHeaders();
      }
    }, err => {
      this.alertService.showAlert("fe fe-alert-octagon","No se pudo eliminar la aplicación", "Could not remove app.", "danger");
      this.spinner = false;

    });

  }

  loadDailySalesStats(): void {
    //var ctx = document.getElementById('myChart');
    var ctx = (document.getElementById('chartDailySales') as any).getContext('2d');
    //var ctx = $('#myChart');
    //var ctx = 'myChart';
    //var ctx = document.getElementById('myChart');

    var gradient = ctx.createLinearGradient(0, 0, 0, 180);

    gradient.addColorStop(0, 'rgba(32, 142, 241, 0.4)');
    gradient.addColorStop(0.5, 'rgba(32, 142, 241, 0.1)');
    gradient.addColorStop(1, 'rgba(32, 142, 241, 0)');

    var myChart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: ['8:00', '9:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00'],
        datasets: [{
          label: 'Sales',
          data: ['10', '20', '5', '9', '1', '7', '6', '5', '11', '3', '16', '14'],
          // borderCapStyle: 'round',
          backgroundColor: gradient,
          borderColor: ['rgb(32, 142, 241)',
            'rgb(32, 142, 241)',
            'rgb(32, 142, 241)',
            'rgb(32, 142, 241)',
            'rgb(32, 142, 241)',
            'rgb(32, 142, 241)',
            'rgb(32, 142, 241)',
            'rgb(32, 142, 241)',
            'rgb(32, 142, 241)',
            'rgb(32, 142, 241)',
            'rgb(32, 142, 241)',
            'rgb(32, 142, 241)'
          ],
          borderWidth: 3
        }]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        }
      }
    });

    // myChart.canvas.parentNode.style.height = '100px';
    // myChart.canvas.parentNode.style.width = '550px';
  }

  printAppResume(p, req): void {
    
     
      let doc = new jsPDF('p', 'pt', 'letter'), rows = [], images = [], pos = 0;

      let columns = [{ title: 'App Name', dataKey: 'name' }, { title: 'Status', dataKey: 'status' },
      { title: 'CreatedAt', dataKey: 'date' }, { title: 'Cost', dataKey: 'cost' }, { title: 'Sale Price', dataKey: 'sale' }, { title: 'Customer', dataKey: 'customer' }
      ];

      rows.push({
        'name': p.appName, 'status': (p.status == 0 ? 'Offline' : 'Online'), 'date': new Date(p.createdAt).toLocaleDateString('en-US'),
        'cost': '$' + parseFloat(p.cost).toFixed(2).toString(), 'sale': '$' + parseFloat(p.salePrice).toFixed(2).toString(), 'customer': p.customerName
      });
      
      /********************************************* */
      doc.setDrawColor(this.appService.config.pdf.headerColor.R, this.appService.config.pdf.headerColor.G, this.appService.config.pdf.headerColor.B); doc.setFillColor(this.appService.config.pdf.headerColor.R, this.appService.config.pdf.headerColor.G, this.appService.config.pdf.headerColor.B);
      doc.rect(0, 0, 800, 70, 'F');
      doc.rect(0, doc.internal.pageSize.height - 25, 800, 25, 'F');
      doc.addImage(this.appService.logo.base64, this.appService.logo.x, this.appService.logo.y, this.appService.logo.w, this.appService.logo.h);

      doc.setFontSize(18); doc.setTextColor(255, 255, 255);

      doc.setTextColor(0, 0, 0); doc.setFontSize(10);
      doc.text(this.appService.config.pdf.address1, 25, 90);
      doc.text(this.appService.config.pdf.address2, 25, 100);
      doc.text(this.appService.config.pdf.phone, 25, 110);
      doc.text(this.appService.config.pdf.web, 25, 120);

      doc.text('Application Info', 425, 90);
      doc.text('______________________________', 425, 98);
      doc.text('App No: ' + p.number, 425, 112);
      
      let d = new Date();
      doc.text('Created At: ' + new Date(p.createdAt).toLocaleDateString('en-US'), 425, 123);     
      doc.text('Request No: ' + req.noRequest, 425, 133);

      //Service Data
      autoTable(doc,{
        columns:columns, 
        body:rows,
        startY: 170,
        showHead: 'firstPage',
        margin: [90, 20, 70, 20],

        bodyStyles: {
          lineWidth: 0.5,
          cellPadding: 10,
          textColor: 20,
        },
        styles: {
          overflow: 'linebreak'
        },
        headStyles: {
          fillColor: '#86c233'
        },
        columnStyles: {
          name: { cellWidth: 170 },
          //customer: { columnWidth: 190 },

        }
        
      });


      //Heroku data
      let columnsHeroku = [{ title: 'Heroku Configuration', dataKey: 'a1' }, { title: '', dataKey: 'a2' }
      ];
      let rowsHeroku = [];
      rowsHeroku.push(
        { 'a1': 'Heroku Domain', 'a2': p.herokuDomain },
        { 'a1': 'Heroku Proyect Name', 'a2': p.herokuProyectName, },
        { 'a1': 'Heroku Plan', 'a2': p.herokuPlan },
        { 'a1': 'Heroku User', 'a2': p.herokuUser },
        { 'a1': 'Heroku Password', 'a2': p.herokuPassword }

      );
      let finalY = (doc as any).lastAutoTable.finalY;
      autoTable(doc,{
        columns: columnsHeroku, 
        body:rowsHeroku, 
        startY: finalY + 30,
        showHead: 'firstPage',
        margin: [90, 20, 70, 20],

        bodyStyles: {
          lineWidth: 0.5,
          cellPadding: 10,
          textColor: 20,
        },
        styles: {
          overflow: 'linebreak'
        },
        headStyles: {
          fillColor: '#86c233'
        },
        columnStyles: {
          a1: { cellWidth: 170 },
          a2: { cellWidth: 400 },

        }        
      });


      //Database information
      let columnsDb = [{ title: 'Database Configuration', dataKey: 'a1' }, { title: ' ', dataKey: 'a2' }
      ];
      let rowsDb = [];
      rowsDb.push(

        { 'a1': 'DB Provider', 'a2': p.dbProvider },
        { 'a1': 'DB Url', 'a2': p.ddbUrl },
        { 'a1': 'DB Adapter', 'a2': p.dbAdapter, },
        { 'a1': 'DB Name', 'a2': p.dbName },
        { 'a1': 'DB User', 'a2': p.dbUser },
        { 'a1': 'DB Password', 'a2': p.dbPassword }

      );
      finalY = (doc as any).lastAutoTable.finalY;
      autoTable(doc,{
        columns:columnsDb, 
        body: rowsDb, 
        startY: finalY + 30,
        showHead: 'firstPage',
        margin: [90, 20, 70, 20],

        bodyStyles: {
          lineWidth: 0.5,
          cellPadding: 10,
          textColor: 20,
        },
        styles: {
          overflow: 'linebreak'
        },       
        headStyles: {
          fillColor: '#86c233'
        },
        columnStyles: {
          a1: { cellWidth: 170 },
          a2: { cellWidth: 400 },
        }
      });


      //Cloudinary information
      let columnsCloud = [{ title: 'Cloudinary Configuration', dataKey: 'a1' }, { title: ' ', dataKey: 'a2' }
      ];
      let rowsClou = [];
      rowsClou.push(

        { 'a1': 'Cloudinary Plan', 'a2': p.cloudinaryPlan },
        { 'a1': 'Cloudinary Web User', 'a2': p.cloudinaryUser },
        { 'a1': 'Cloudinary Web Password', 'a2': p.cloudinaryPassword, },
        { 'a1': 'Cloud Name', 'a2': p.cloudName },
        { 'a1': 'Api Key', 'a2': p.cloudApiKey },
        { 'a1': 'Api Secret', 'a2': p.cloudApiSecret }
      );
      finalY = (doc as any).lastAutoTable.finalY;
      autoTable(doc, {
        columns:columnsCloud, 
        body: rowsClou,
        startY:finalY + 30,
        showHead: 'firstPage',
        margin: [90, 20, 70, 20],

        bodyStyles: {
          lineWidth: 0.5,
          cellPadding: 10,
          textColor: 20,
        },
        styles: {
          overflow: 'linebreak'
        },
        headStyles: {
          fillColor: '#86c233'
        },
        columnStyles: {
          a1: { cellWidth: 170 },
          a2: { cellWidth: 400 },
        }
      });


      //Cardconect Bolt information
      let columnsBolt = [{ title: 'CardConnect Bolt Api', dataKey: 'a1' }, { title: ' ', dataKey: 'a2' }
      ];
      let rowsBolt = [];
      rowsBolt.push(

        { 'a1': 'Using Bolt Api', 'a2': (p.usingBoltApi == 0 ? 'No' : 'Yes') },
        { 'a1': 'Merchant Id', 'a2': p.merchantId },
        { 'a1': 'Base URL', 'a2': p.boltBaseURL, },
        { 'a1': 'Authorization Key', 'a2': p.boltAuthorizationKey },
        { 'a1': 'Bolt Hsn', 'a2': p.boltHsn },
        { 'a1': 'CardPoint Web User', 'a2': p.cardPointUser },
        { 'a1': 'CardPoint Web Password', 'a2': p.cardPointPass },

      );
      finalY = (doc as any).lastAutoTable.finalY;
      autoTable(doc,{
        columns:columnsBolt,
        body: rowsBolt, 
        startY: finalY + 30,
        showHead: 'firstPage',
        margin: [90, 20, 70, 20],

        bodyStyles: {
          lineWidth: 0.5,
          cellPadding: 10,
          textColor: 20,
        },
        styles: {
          overflow: 'linebreak'
        },   
        headStyles: {
          fillColor: '#86c233'
        },   
        columnStyles: {
          a1: { cellWidth: 170 },
          a2: { cellWidth: 400 },
        }
      });

      //Cardconect Gateway Api information
      let columnsGateway = [{ title: 'CardConnect Gateway Api', dataKey: 'a1' }, { title: ' ', dataKey: 'a2' }
      ];
      let rowsGateway = [];
      rowsGateway.push(

        { 'a1': 'Using Gateway Api', 'a2': (p.usingGatewayApi == 0 ? 'No' : 'Yes') },
        { 'a1': 'Gateway Id', 'a2': p.gatewayId },
        { 'a1': 'Gateway Api Key', 'a2': p.gatewayApiKey, },
        { 'a1': 'Gateway Api User', 'a2': p.gatewayApiUser },
        { 'a1': 'Gateway Base URL', 'a2': p.gatewayBaseURL },

      );
      finalY = (doc as any).lastAutoTable.finalY;
      autoTable(doc,{
        columns:columnsGateway,
        body: rowsGateway, 
        startY: finalY + 30,
        showHead: 'firstPage',
        margin: [90, 20, 70, 20],

        bodyStyles: {
          lineWidth: 0.5,
          cellPadding: 10,
          textColor: 20,
        },
        styles: {
          overflow: 'linebreak'
        },
        headStyles: {
          fillColor: '#86c233'
        },
        columnStyles: {
          a1: { cellWidth: 170 },
          a2: { cellWidth: 400 },
        }
      });

      //Stripe Api information
      let columnsStripe = [{ title: 'Stripe Api', dataKey: 'a1' }, { title: ' ', dataKey: 'a2' }
      ];
      let rowsStripe = [];
      rowsStripe.push(

        { 'a1': 'Using Stripe Api', 'a2': (p.usingStripeApi == 0 ? 'No' : 'Yes') },
        { 'a1': 'Stripe Id', 'a2': p.stripeId },
        { 'a1': 'Stripe Public Api Key', 'a2': p.stripePublicApiKey, },
        { 'a1': 'Stripe Private Api Key', 'a2': p.stripePrivateApiKey },
        { 'a1': 'Stripe User', 'a2': p.stripeUser },
        { 'a1': 'Stripe Pass', 'a2': p.stripePass },

      );
      finalY = (doc as any).lastAutoTable.finalY;
      autoTable(doc,{
        columns:columnsStripe,
        body: rowsStripe, 
        startY: finalY + 30,
        showHead: 'firstPage',
        margin: [90, 20, 70, 20],

        bodyStyles: {
          lineWidth: 0.5,
          cellPadding: 10,
          textColor: 20,
        },
        styles: {
          overflow: 'linebreak'
        },
        headStyles: {
          fillColor: '#86c233'
        },
        columnStyles: {
          a1: { cellWidth: 170 },
          a2: { cellWidth: 400 },
        }
      });

       //Custom Domain  information
       let columnsDomain = [{ title: 'Custom Domain', dataKey: 'a1' }, { title: ' ', dataKey: 'a2' }
      ];
      let rowsDomain= [];
      rowsDomain.push(       
        { 'a1': 'Domain Provider', 'a2': p.domainProvider },
        { 'a1': 'Domain Name', 'a2': p.domainName, },       
        { 'a1': 'Domain User', 'a2': p.domainUser },
        { 'a1': 'Domain Pass', 'a2': p.domainPass },

      );
      finalY = (doc as any).lastAutoTable.finalY;
      autoTable(doc,{
        columns:columnsDomain,
        body: rowsDomain, 
        startY: finalY + 30,
        showHead: 'firstPage',
        margin: [90, 20, 70, 20],

        bodyStyles: {
          lineWidth: 0.5,
          cellPadding: 10,
          textColor: 20,
        },
        styles: {
          overflow: 'linebreak'
        },
        headStyles: {
          fillColor: '#86c233'
        },
        columnStyles: {
          a1: { cellWidth: 170 },
          a2: { cellWidth: 400 },
        }
      });

       //Mailgun information
       let columnsMailgun = [{ title: 'Mailgun', dataKey: 'a1' }, { title: ' ', dataKey: 'a2' }
      ];
      let rowsMailgun =[];
      rowsMailgun.push(
        { 'a1': 'Using Mailgun Api', 'a2': (p.usingMailgunApi == 0 ? 'No' : 'Yes') },
        { 'a1': 'Mailgun Account Id', 'a2': p.mailgunAccountId },
        { 'a1': 'Mailgun Domain', 'a2': p.mailgunDomain, },
        { 'a1': 'Mailgun Api Key', 'a2': p.mailgunApiKey },
        { 'a1': 'Mailgun User', 'a2': p.mailgunUser },
        { 'a1': 'Mailgun Pass', 'a2': p.mailgunPass },

      );
      finalY = (doc as any).lastAutoTable.finalY;
      autoTable(doc,{
        columns:columnsMailgun,
        body: rowsMailgun, 
        startY: finalY + 30,
        showHead: 'firstPage',
        margin: [90, 20, 70, 20],

        bodyStyles: {
          lineWidth: 0.5,
          cellPadding: 10,
          textColor: 20,
        },
        styles: {
          overflow: 'linebreak'
        },
        headStyles: {
          fillColor: '#86c233'
        },
        columnStyles: {
          a1: { cellWidth: 170 },
          a2: { cellWidth: 400 },
        }
      });



      //doc.output('dataurlnewwindow');
      doc.save('App(' + p.number.toString() + ').pdf');
    
  }


}

