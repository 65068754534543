<div class="container-fluid ">
  <!--Profile menu on phone view-->
  <app-admin-phone-menu></app-admin-phone-menu>
  <!-- start page title -->
  <div class="row page-title">
    <div class="col-12">
      <h4 class="page-title">Dasboard/Partners</h4>
    </div>
    <!-- Headers info -->
    <div class="col-12">
      <div class="row">
        <div class="col-xl-3 col-md-6">
          <div class="card widget-inline border-left-primary shadow h-100 py-2">
            <div class="card-body">
              <div class="row no-gutters align-items-center">
                <div class="col mr-2">
                  <div class="text-xs font-weight-bold text-purple text-uppercase mb-1">All Partners
                  </div>
                  <div *ngIf="spinnerHeaders==false" class="h5 mb-0 font-weight-bold text-gray-800">
                    {{headers.allPartners}}
                  </div>
                  <div *ngIf="spinnerHeaders==true" class="">
                    <div class="spinner-border text-muted" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                </div>
                <div class="col-auto"><i class="fe-briefcase text-muted fe-2x"></i></div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6">
          <div class="card  widget-inline border-left-danger shadow h-100 py-2">
            <div class="card-body">
              <div class="row no-gutters align-items-center">
                <div class="col mr-2">
                  <div  class="text-xs font-weight-bold text-danger text-uppercase mb-1">Unconfirmed (Email)
                  </div>
                  <div *ngIf="spinnerHeaders==false"  class="h5 mb-0 font-weight-bold text-gray-800">
                    {{headers.unconfirmed}}
                  </div>
                    <div *ngIf="spinnerHeaders==true" class="">
                      <div class="spinner-border text-muted" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div>
                </div>
                <div class="col-auto"><i class="fe-alert-triangle text-muted fe-2x"></i></div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6">
          <div class="card widget-inline border-left-info shadow h-100 py-2">
            <div class="card-body">
              <div class="row no-gutters align-items-center">
                <div class="col mr-2">
                  <div class="text-xs font-weight-bold text-info text-uppercase mb-1">Confirmed
                  </div>
                  <div *ngIf="spinnerHeaders==false" class="h5 mb-0 font-weight-bold text-gray-800">
                    {{headers.confirmed}}
                  </div>
                  <div *ngIf="spinnerHeaders==true" class="">
                    <div class="spinner-border text-muted" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                </div>
                <div class="col-auto"><i class="fe-mail text-muted fe-2x"></i></div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6">
          <div class="card  widget-inline border-left-warning shadow h-100 py-2">
            <div class="card-body">
              <div class="row no-gutters align-items-center">
                <div class="col mr-2">
                  <div class="text-xs font-weight-bold text-warning text-uppercase mb-1">Pending Payout</div>
                  <div *ngIf="spinnerHeaders==false" class="h5 mb-0 font-weight-bold text-gray-800">{{headers.pendingPayout}}</div>
                  <div *ngIf="spinnerHeaders==true" class="">
                    <div class="spinner-border text-muted" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                </div>
                <div class="col-auto"><i class="fe-folder-minus text-muted fe-2x"></i></div>
              </div>
            </div>
          </div>
        </div>
      </div>     
    </div>
  
    <!-- End Headers info -->
   
    <div class="col-lg-3">
      <div class="card card-register">
        <div class="card-body">
          <h4 class="header-title mb-3">Add new partner</h4>
          <form [formGroup]="registerForm" (ngSubmit)="register()" class="needs-validation" novalidate>
            <!-- Full name Input -->
            <div class="form-group">
              <input formControlName="fullName"
                [ngClass]="{'is-invalid': registerForm.controls.fullName.errors && (registerForm.controls.fullName.touched || registerForm.controls.fullName.dirty) }"
                class="form-control" type="text" placeholder="Full name">
              <div class="invalid-feedback ml-2">
                Full name is required.
              </div>
            </div>

            <!-- Phone Input -->
            <div class="form-group">
              <input formControlName="phone" (keyup)="onKeyPhone($event)"
                [ngClass]="{'is-invalid': registerForm.controls.phone.errors && (registerForm.controls.phone.touched || registerForm.controls.phone.dirty) }"
                class="form-control" type="text" placeholder="Phone">
              <div *ngIf="registerForm.controls.phone.errors?.required" class="invalid-feedback ml-2">
                Phone number is required.
              </div>
              <div *ngIf="registerForm.controls.phone.errors?.invalid && !registerForm.controls.phone.errors?.required"
                class="invalid-feedback ml-2">
                Invalid phone number.
              </div>
            </div>

            <!-- Email Input -->
            <div class="form-group">
              <input
                [ngClass]="{'is-invalid': registerForm.controls.emailAddress.errors && (registerForm.controls.emailAddress.touched || registerForm.controls.emailAddress.dirty) }"
                formControlName="emailAddress" class="form-control" type="text" placeholder="Email">
              <div *ngIf="registerForm.controls.emailAddress.errors?.required" class="invalid-feedback ml-2">
                Email address is required.
              </div>
              <div
                *ngIf="registerForm.controls.emailAddress.errors?.email && !registerForm.controls.emailAddress.errors?.required"
                class="invalid-feedback ml-2">
                Invalid email address.
              </div>
              <div *ngIf="registerForm.controls.emailAddress.errors?.exist" class="invalid-feedback ml-2">
                Email address already exists.
              </div>
            </div>

            <!-- Password Input -->
            <div class="cs-password-toggle form-group">
              <input #password
                [ngClass]="{'is-invalid': registerForm.controls.password.errors && (registerForm.controls.password.touched || registerForm.controls.password.dirty) }"
                formControlName="password" class="form-control" type="password" placeholder="Password">

              <label class="cs-password-toggle-btn">
                <input (change)="$event.target.checked ? password.type = 'text' : password.type = 'password'"
                  class="custom-control-input" type="checkbox">
                <i [style.color]="registerForm.controls.password.errors && (registerForm.controls.password.touched || registerForm.controls.password.dirty) ? '#f74f78' : ''"
                  class="fe-eye cs-password-toggle-indicator"></i><span class="sr-only">Show password</span>
              </label>
              <div *ngIf="registerForm.controls.password.errors?.required" class="invalid-feedback ml-2">
                Password is required.
              </div>
              <div *ngIf="registerForm.controls.password.errors?.minlength" class="invalid-feedback ml-2">
                Password must have at least 6 characters.
              </div>
            </div>

            <!-- Confirm password Input -->
            <div class="cs-password-toggle form-group">
              <input #confirmPassword
                [ngClass]="{'is-invalid': ( registerForm.controls.confirmPassword.errors || registerForm.errors?.differentPassword) && (registerForm.controls.confirmPassword.touched || registerForm.controls.confirmPassword.dirty) }"
                formControlName="confirmPassword" class="form-control" type="password" placeholder="Confirm password">
              <label class="cs-password-toggle-btn">
                <input
                  (change)="$event.target.checked ? confirmPassword.type = 'text' : confirmPassword.type = 'password'"
                  class="custom-control-input" type="checkbox">
                <i [style.color]="( registerForm.controls.confirmPassword.errors || registerForm.errors?.differentPassword) && (registerForm.controls.confirmPassword.touched || registerForm.controls.confirmPassword.dirty) ? '#f74f78' : ''"
                  class="fe-eye cs-password-toggle-indicator"></i><span class="sr-only">Show
                  password</span>
              </label>
              <div *ngIf="registerForm.controls.confirmPassword.errors?.required" class="invalid-feedback ml-2">
                Password confirmation is required.
              </div>
              <div
                *ngIf="registerForm.errors?.differentPassword && !registerForm.controls.confirmPassword.errors?.required"
                class="invalid-feedback ml-2">
                Password does not match.
              </div>
            </div>

            <button class="btn btn-primary btn-block" type="submit">
              <span *ngIf="!spinner">Add</span>
              <div *ngIf="spinner" class="btn-spinner">
                <div class="spinner-border spinner-border-sm mr-1" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow spinner-grow-sm mr-1" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </button>

            <p class="font-size-sm pt-3 mb-0">
              Go to partner account? <a [routerLink]="['/auth-partner/login-partner']" class='font-weight-medium'>Sign
                in</a>
            </p>
          </form>
        </div>
      </div>
    </div>
    <div class="col-lg-9">
      <app-partners-list></app-partners-list>
    </div>
  </div>

  <!--a class="btn btn-primary ml-grid-gutter d-none d-lg-inline-block" href="#modal-newPartner" data-toggle="modal"
    data-view="#modal-signup-view">New Partner</a-->


  <!-- New Partner Modal-->
  <div class="modal fade" id="modal-newPartner" tabindex="-1">

    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content border-0">
        <div class="cs-view show" id="modal-signin-view">
          <div class="modal-header border-0 bg-dark px-4">
            <h4 class="modal-title text-light">Sign in</h4>
            <button class="close text-light" type="button" data-dismiss="modal" aria-label="Close"><span
                aria-hidden="true">&times;</span></button>
          </div>
          <div class="modal-body px-4">
            <p class="font-size-ms text-muted">Add partner information for create an account.</p>
            <form [formGroup]="registerForm" (ngSubmit)="register()" class="needs-validation" novalidate>
              <!-- Full name Input -->
              <div class="form-group">
                <input formControlName="fullName"
                  [ngClass]="{'is-invalid': registerForm.controls.fullName.errors && (registerForm.controls.fullName.touched || registerForm.controls.fullName.dirty) }"
                  class="form-control" type="text" placeholder="Full name">
                <div class="invalid-feedback ml-2">
                  Full name is required.
                </div>
              </div>

              <!-- Phone Input -->
              <div class="form-group">
                <input formControlName="phone" (keyup)="onKeyPhone($event)"
                  [ngClass]="{'is-invalid': registerForm.controls.phone.errors && (registerForm.controls.phone.touched || registerForm.controls.phone.dirty) }"
                  class="form-control" type="text" placeholder="Phone">
                <div *ngIf="registerForm.controls.phone.errors?.required" class="invalid-feedback ml-2">
                  Phone number is required.
                </div>
                <div
                  *ngIf="registerForm.controls.phone.errors?.invalid && !registerForm.controls.phone.errors?.required"
                  class="invalid-feedback ml-2">
                  Invalid phone number.
                </div>
              </div>

              <!-- Email Input -->
              <div class="form-group">
                <input
                  [ngClass]="{'is-invalid': registerForm.controls.emailAddress.errors && (registerForm.controls.emailAddress.touched || registerForm.controls.emailAddress.dirty) }"
                  formControlName="emailAddress" class="form-control" type="text" placeholder="Email">
                <div *ngIf="registerForm.controls.emailAddress.errors?.required" class="invalid-feedback ml-2">
                  Email address is required.
                </div>
                <div
                  *ngIf="registerForm.controls.emailAddress.errors?.email && !registerForm.controls.emailAddress.errors?.required"
                  class="invalid-feedback ml-2">
                  Invalid email address.
                </div>
                <div *ngIf="registerForm.controls.emailAddress.errors?.exist" class="invalid-feedback ml-2">
                  Email address already exists.
                </div>
              </div>

              <!-- Password Input -->
              <div class="cs-password-toggle form-group">
                <input #password
                  [ngClass]="{'is-invalid': registerForm.controls.password.errors && (registerForm.controls.password.touched || registerForm.controls.password.dirty) }"
                  formControlName="password" class="form-control" type="password" placeholder="Password">

                <label class="cs-password-toggle-btn">
                  <input (change)="$event.target.checked ? password.type = 'text' : password.type = 'password'"
                    class="custom-control-input" type="checkbox">
                  <i [style.color]="registerForm.controls.password.errors && (registerForm.controls.password.touched || registerForm.controls.password.dirty) ? '#f74f78' : ''"
                    class="fe-eye cs-password-toggle-indicator"></i><span class="sr-only">Show password</span>
                </label>
                <div *ngIf="registerForm.controls.password.errors?.required" class="invalid-feedback ml-2">
                  Password is required.
                </div>
                <div *ngIf="registerForm.controls.password.errors?.minlength" class="invalid-feedback ml-2">
                  Password must have at least 6 characters.
                </div>
              </div>

              <!-- Confirm password Input -->
              <div class="cs-password-toggle form-group">
                <input #confirmPassword
                  [ngClass]="{'is-invalid': ( registerForm.controls.confirmPassword.errors || registerForm.errors?.differentPassword) && (registerForm.controls.confirmPassword.touched || registerForm.controls.confirmPassword.dirty) }"
                  formControlName="confirmPassword" class="form-control" type="password" placeholder="Confirm password">
                <label class="cs-password-toggle-btn">
                  <input
                    (change)="$event.target.checked ? confirmPassword.type = 'text' : confirmPassword.type = 'password'"
                    class="custom-control-input" type="checkbox">
                  <i [style.color]="( registerForm.controls.confirmPassword.errors || registerForm.errors?.differentPassword) && (registerForm.controls.confirmPassword.touched || registerForm.controls.confirmPassword.dirty) ? '#f74f78' : ''"
                    class="fe-eye cs-password-toggle-indicator"></i><span class="sr-only">Show
                    password</span>
                </label>
                <div *ngIf="registerForm.controls.confirmPassword.errors?.required" class="invalid-feedback ml-2">
                  Password confirmation is required.
                </div>
                <div
                  *ngIf="registerForm.errors?.differentPassword && !registerForm.controls.confirmPassword.errors?.required"
                  class="invalid-feedback ml-2">
                  Password does not match.
                </div>
              </div>

              <button class="btn btn-primary btn-block" type="submit">
                <span *ngIf="!spinner">Sign up</span>
                <div *ngIf="spinner" class="btn-spinner">
                  <div class="spinner-border spinner-border-sm mr-1" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                  <div class="spinner-grow spinner-grow-sm mr-1" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              </button>

              <p class="font-size-sm pt-3 mb-0">
                Already have an account? <a [routerLink]="['/auth/login']" class='font-weight-medium'>Sign in</a>
              </p>
            </form>
          </div>
        </div>
        <div class="cs-view" id="modal-signup-view">
          <div class="modal-header border-0 bg-dark px-4">
            <h4 class="modal-title text-light">Sign up</h4>
            <button class="close text-light" type="button" data-dismiss="modal" aria-label="Close"><span
                aria-hidden="true">&times;</span></button>
          </div>
          <div class="modal-body px-4">
            <p class="font-size-ms text-muted">Registration takes less than a minute but gives you full
              control over your orders.</p>
            <form class="needs-validation" novalidate>
              <div class="form-group">
                <input class="form-control" type="text" placeholder="Full name" required>
              </div>
              <div class="form-group">
                <input class="form-control" type="text" placeholder="Email" required>
              </div>
              <div class="cs-password-toggle form-group">
                <input class="form-control" type="password" placeholder="Password" required>
                <label class="cs-password-toggle-btn">
                  <input class="custom-control-input" type="checkbox"><i
                    class="fe-eye cs-password-toggle-indicator"></i><span class="sr-only">Show
                    password</span>
                </label>
              </div>
              <div class="cs-password-toggle form-group">
                <input class="form-control" type="password" placeholder="Confirm password" required>
                <label class="cs-password-toggle-btn">
                  <input class="custom-control-input" type="checkbox"><i
                    class="fe-eye cs-password-toggle-indicator"></i><span class="sr-only">Show
                    password</span>
                </label>
              </div>
              <button class="btn btn-primary btn-block" type="submit">Sign up</button>
              <p class="font-size-sm pt-3 mb-0">Already have an account? <a href='#' class='font-weight-medium'
                  data-view='#modal-signin-view'>Sign in</a></p>
            </form>
          </div>
        </div>
        <div class="modal-body text-center px-4 pt-2 pb-4">
          <hr>
          <p class="font-size-sm font-weight-medium text-heading pt-4">Or sign in with</p><a
            class="social-btn sb-facebook sb-lg mx-1 mb-2" href="#"><i class="fe-facebook"></i></a><a
            class="social-btn sb-twitter sb-lg mx-1 mb-2" href="#"><i class="fe-twitter"></i></a><a
            class="social-btn sb-instagram sb-lg mx-1 mb-2" href="#"><i class="fe-instagram"></i></a><a
            class="social-btn sb-google sb-lg mx-1 mb-2" href="#"><i class="fe-google"></i></a>
        </div>
      </div>
    </div>
  </div>
</div>