<div @fadeIn id="signin-view">
    <h1 class="h2" translate>auth_admin.title1</h1>
    <p class="font-size-ms text-muted mb-4" translate>auth_admin.title2</p>
    <form [formGroup]="loginForm" (ngSubmit)="login()" class="needs-validation" novalidate>
      <div class="input-group-overlay form-group">
        <div class="input-group-prepend-overlay"><span class="input-group-text"><i class="fe-mail"></i></span>
        </div>
        <input
          [ngClass]="{'is-invalid': (loginForm.controls.emailAddress.errors || loginForm.errors?.invalidCredentials) && (loginForm.controls.emailAddress.touched || loginForm.controls.emailAddress.dirty) }"
          formControlName="emailAddress" class="form-control prepended-form-control" type="email" placeholder="{{'input_placeholder.label1' | translate}}" required>
        <div *ngIf="loginForm.controls.emailAddress.errors?.required" class="invalid-feedback ml-2" translate>
          inputs_errors.error01
        </div>
        <div *ngIf="loginForm.controls.emailAddress.errors?.email && !loginForm.controls.emailAddress.errors?.required"
          class="invalid-feedback ml-2" translate>
          inputs_errors.error02
        </div>
        <div *ngIf="loginForm.errors?.invalidCredentials"
          class="invalid-feedback ml-2" translate>
          inputs_errors.error03
        </div>
      </div>
  
      <div class="input-group-overlay cs-password-toggle form-group">
        <div class="input-group-prepend-overlay"><span class="input-group-text"><i class="fe-lock"></i></span>
        </div>
        <input #password formControlName="password" class="form-control prepended-form-control" type="password"
          [ngClass]="{'is-invalid': (loginForm.controls.password.errors || loginForm.errors?.invalidCredentials) && (loginForm.controls.password.touched || loginForm.controls.password.dirty)}" placeholder="{{'input_placeholder.label2' | translate}}">
        <label class="cs-password-toggle-btn">
          <input (change)="$event.target.checked ? password.type = 'text' : password.type = 'password'"
            class="custom-control-input" type="checkbox">
          <i [style.color]="(loginForm.controls.password.errors || loginForm.errors?.invalidCredentials) && (loginForm.controls.password.touched || loginForm.controls.password.dirty) ? '#f74f78' : ''"
            class="fe-eye cs-password-toggle-indicator"></i><span class="sr-only">Show password</span>
        </label>
        <div *ngIf="loginForm.controls.password.errors?.required" class="invalid-feedback ml-2" translate>
          inputs_errors.error04
        </div>
        <div *ngIf="loginForm.controls.password.errors?.minlength" class="invalid-feedback ml-2" translate>
          inputs_errors.error05
        </div>
        <div *ngIf="loginForm.errors?.invalidCredentials"
          class="invalid-feedback ml-2" translate>
          inputs_errors.error03
        </div>
      </div>
  
      <div class="d-flex justify-content-between align-items-center form-group">
        <div class="custom-control custom-checkbox">
          <input class="custom-control-input" type="checkbox" id="keep-signed-2">
          <label class="custom-control-label" for="keep-signed-2" translate>auth_admin.check01</label>
        </div><a class="nav-link-style font-size-ms" [routerLink]="['/auth-admin/forgot-passw']" translate>auth_admin.title3</a>
      </div>
      <button (click)="submitLogin()" class="btn btn-primary btn-block" type="submit">
        <span *ngIf="!spinner" translate>auth_admin.btn01</span>
        <div *ngIf="spinner" class="btn-spinner">
          <div class="spinner-border spinner-border-sm mr-1" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          <div class="spinner-grow spinner-grow-sm mr-1" role="status">
            <span class="sr-only">Loading...</span>
          </div>        
        </div>     
      </button>
      <p class="font-size-sm pt-3 mb-0"translate>auth_admin.title4
        <a [routerLink]="['/contactus']" class='font-weight-medium' translate>auth_admin.title5</a>
        <!--a [routerLink]="['/auth-admin/register-admin']" class='font-weight-medium'>Sign up</a-->
      </p>
    </form>
  </div>
  
  <div class="border-top text-center mt-4 pt-4">
    <p class="font-size-sm font-weight-medium text-heading" translate>auth_admin.title6</p><a
      class="social-btn sb-facebook sb-outline sb-lg mx-1 mb-2" [routerLink]='["/"]'><i class="fe-facebook"></i></a><a
      class="social-btn sb-twitter sb-outline sb-lg mx-1 mb-2" [routerLink]='["/"]'><i class="fe-twitter"></i></a><a
      class="social-btn sb-instagram sb-outline sb-lg mx-1 mb-2" [routerLink]='["/"]'><i class="fe-instagram"></i></a><a
      class="social-btn sb-google sb-outline sb-lg mx-1 mb-2" [routerLink]='["/"]'><i class="fe-google"></i></a>
  </div>
