<!--Account Menu phone view-->
<div id="account-menu" class=row>
    <div class="col-lg-3">
        <div class="card">
            <div class="card-body bg-light rounded-lg box-shadow-lg">
                <div class="px-4 py-4 mb-1 text-center">
                    <img class="d-block rounded-circle mx-auto my-2 avatar_container"
                        [src]="authService.decodedTocken.certpublickey" alt="Avatar" />
                    <h3 class="mb-0 pt-1">{{authService.decodedTocken.unique_name}}</h3><span
                        class="text-muted font-size-sm">{{authService.decodedTocken.email}}</span>
                </div>
                <div class="d-lg-none px-4 pb-4 text-center"><a class="btn btn-primary px-5 mb-2" (click)="collapse()"
                        data-toggle="collapse"><i class="fe-menu mr-2"></i>Account menu</a></div>
                <div class="d-lg-block collapse pb-2" id="account-menu" [class.show]="show">
                    <h3 class="d-block bg-secondary font-size-sm font-weight-semibold text-muted mb-0 px-4 py-3">
                        Dashboard</h3>
                    <a [routerLink]="['/dashboard-admin/projects']" routerLinkActive="router-link-active"
                        (click)="collapse()" class="d-flex align-items-center nav-link-style px-4 py-3"><i
                            class="fe-folder font-size-lg opacity-60 mr-2"></i>Applications<span
                            class="nav-indicator"></span>
                        <span class="text-muted font-size-sm font-weight-normal ml-auto">New 20</span>
                    </a>
                    <a class="d-flex align-items-center nav-link-style px-4 py-3 border-top" (click)="collapse()"
                        [routerLink]="['/dashboard-admin/customers']"><i
                            class="fe-users font-size-lg opacity-60 mr-2"></i>Customers<span
                            class="text-muted font-size-sm font-weight-normal ml-auto">2</span>
                    </a>

                    <a class="d-flex align-items-center nav-link-style px-4 py-3 border-top" (click)="collapse()"
                        [routerLink]="['/dashboard-admin/partners']"><i
                            class="fe-user-check font-size-lg opacity-60 mr-2"></i>Partners<span
                            class="text-muted font-size-sm font-weight-normal ml-auto"> </span>
                    </a>

                    <a class="d-flex align-items-center nav-link-style px-4 py-3 border-top" (click)="collapse()"
                        [routerLink]="['/dashboard-admin/invoices']"><i
                            class="fe-layers  font-size-lg opacity-60 mr-2"></i>Invoices<span
                            class="text-muted font-size-sm font-weight-normal ml-auto"> </span>
                    </a>
                    <a class="d-flex align-items-center nav-link-style px-4 py-3 border-top" (click)="collapse()"
                        [routerLink]="['/dashboard-admin/compensations']"><i
                            class="fe-file  font-size-lg opacity-60 mr-2"></i>Compensations<span
                            class="text-muted font-size-sm font-weight-normal ml-auto"> </span>
                    </a>

                    <h3 class="d-block bg-secondary font-size-sm font-weight-semibold text-muted mb-0 px-4 py-3">Account
                        settings</h3>
                    <a class="d-flex align-items-center nav-link-style px-4 py-3" (click)="collapse()"
                        [routerLink]="['/dashboard-admin/admin-profile-info']">Profile info
                    </a>
                    <a class="d-flex align-items-center nav-link-style px-4 py-3 border-top"
                        (click)="authService.logout()"><i class="fe-log-out font-size-lg opacity-60 mr-2"></i>Sign
                        out</a>
                </div>
            </div>
        </div>

    </div>

</div>