import { Component, OnInit } from '@angular/core';
import {PartnerService} from '../../dashboard-admin/partner.service';
import {AuthService} from '../../auth/auth.service';
import {AlertService} from '../../alert/alert.service';

@Component({
  selector: 'app-link-request',
  templateUrl: './link-request.component.html',
  styleUrls: ['./link-request.component.css']
})
export class LinkRequestComponent implements OnInit {

  spinner: boolean = false;
  spinnerBtn: boolean=false;
  search: string = '';
  requests=[];

 
  constructor(public partnerService: PartnerService, public authService: AuthService, public alertService: AlertService) { }

  ngOnInit(): void {
    
  }


  findRequest(e): void{
    if (e.code === 'Enter' && this.search!="") {
      this.spinner=true;
      this.partnerService.searchRequest(this.search).subscribe(r => {
        this.requests=r;
        this.spinner=false;
      });
    }
   
  }

  sendEmailLink(requestId): void{
    this.spinnerBtn=true;
    this.partnerService.sendRequestPartnerLink(parseInt(requestId),parseInt(this.authService.decodedTocken.nameid)).subscribe(r => {
     
      if(r.statusCode==201){
        this.alertService.showAlert( "fe fe-bell","Correo electrónico enviado correctamente", "Email sended successfully.","success");
      }else{
        this.alertService.showAlert( "fe fe-alert-octagon","No se pudo enviar el correo electrónico. Vuelva a intentarlo","Could not send email, please try again.","danger");    
      }     
      this.spinnerBtn=false;
    });
  }

}
