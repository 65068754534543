import { Component, OnInit } from '@angular/core';

import { AuthService } from '../../auth/auth.service';

@Component({
  selector: 'app-side-bar-partner',
  templateUrl: './side-bar-partner.component.html',
  styleUrls: ['./side-bar-partner.component.css']
})
export class SideBarPartnerComponent implements OnInit {

  constructor(public authService:AuthService) { }

  ngOnInit(): void {
  }

}
